import React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { getFilteredTransactions } from "models/api/Transaction";
import { cols_Transaction, fields_Transaction } from "./definitions";

import { DB_User } from "models/db/User";
import { FilterGroup } from "components/DataTable/filter/FilterGroup";
import { TransactionFieldName, nameMapping_Transaction } from "models/db/Transaction";

export default function UserTransactionsTab({ targetUser }: { targetUser: DB_User }): React.JSX.Element {
  if (!targetUser.id) {
    return <>Require UserId</>;
  }

  return (
    <>
      <Helmet><title>{targetUser.name} - Transactions</title></Helmet>

      <DataTable
        title='Transactions'
        dataName='transaction'
        cols={cols_Transaction}
        fields={fields_Transaction}
        nameMapping={nameMapping_Transaction}
        rowsPerPage={25}
        settings={{ toolbar: { export: 'excel' } }}
        getFilteredRecords={async (params: FilterGroup<TransactionFieldName>) => {
          const userParams: FilterGroup<TransactionFieldName> = {
            ...params,
            filters: [[['user', { Equal: String(targetUser.id) }]], ...params.filters],
          };
          return await getFilteredTransactions(userParams)
        }}
      />
    </>
  );
}