import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import notification from 'helpers/notification';
import useUserContext from 'hooks/useUserContext';
import { Table, type ColumnDefinitionType } from 'components/DataTable/Table';
import { type FilterGroup } from 'components/DataTable/filter/FilterGroup';
import { toast } from 'react-toastify';
import { Box, Grid, Typography, styled } from '@mui/material';
import DataTablePagination from './TablePagination';
import { GenericField } from './filter/FilterType';
import { DTResponse, RES_STATUS } from 'models/Generic';
import { EMemberRoleEnumType } from 'models/db/User';
import { getItem } from 'helpers/utils';

interface DataTableProps<T, K> {
  title: string;
  dataName: string;
  rowsPerPage?: number;
  cols: Array<ColumnDefinitionType<T>>;
  fields: Array<GenericField<T>>;
  nameMapping: { [key in keyof T]: K };
  actions?: Array<{
    icon: string;
    title: string;
    event: Dispatch<SetStateAction<boolean>>;
  }>;
  fetchCounter?: [number, Dispatch<SetStateAction<number>>];
  settings?: {
    toolbar?: {
      export?: 'excel';
    }
  };
  updateRecord?: (
    row: T,
    column: ColumnDefinitionType<T>,
    value: string
  ) => Promise<any>;
  getFilteredRecords: (filterObj: FilterGroup<K>) => Promise<DTResponse<T>>
}

const DataTable = <T, K>(props: DataTableProps<T, K>): React.JSX.Element => {
  const [data, setData] = useState<T[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { authState: { user } } = useUserContext()

  const [fetchCounter, setFetchCounter] = props?.fetchCounter || useState<number>(0);
  // console.log({ fetchCounter });

  const [rowsPerPage, setRowsPerPage] = useState<number>(props.rowsPerPage || 5);

  const autoSize = (element: HTMLElement): void => {
    element.style.width = '0';
    const { borderLeftWidth, borderRightWidth } = getComputedStyle(element);
    /**
     * Turns a string like '36px' into the number 36
     */
    const numberPxToNumber = (numberPx: string): number => parseInt(numberPx.slice(0, -2));
    const borderWidth =
      numberPxToNumber(borderLeftWidth) +
      numberPxToNumber(borderRightWidth) +
      17;
    // For some reason border is not included in scrollWidth
    element.style.width = `${element.scrollWidth + borderWidth}px`;
  };

  const [curFilter, setCurFilter] = useState<FilterGroup<K>>({
    filters: [],
    orderBy: [],
    limit: rowsPerPage,
    offset: (page ?? 0) * rowsPerPage,
  });

  const applyFilters = (filterObj: FilterGroup<K>): void => {
    console.log({ filterObj });

    setCurFilter(filterObj);
    setIsLoading(true)

    setPage((page) => {
      props
        .getFilteredRecords({
          ...filterObj,
          limit: rowsPerPage,
          offset: (page ?? 0) * rowsPerPage,
        })
        .then((resonse) => {
          setTotalRows(resonse.totalRows)
          setData(resonse.data)
          setIsLoading(false)

          if (props.settings?.toolbar?.export && resonse.downloadUrl && resonse.lastParams) {
            const lastParams = resonse.lastParams || {
              ...filterObj,
              limit: rowsPerPage,
              offset: (page ?? 0) * rowsPerPage,
            };
            // console.log({ lastParams });

            const newParams = new URLSearchParams({
              filterParams: JSON.stringify({ ...lastParams, download: 'export' }),  // forced to export
              token: getItem('accessToken') as string,
            });

            window.open(`${resonse.downloadUrl}?${newParams.toString()}`, "_blank", "noreferrer")
          }
        })
        .catch((e) => {
          setIsLoading(false)
          toast.error(`Unable to get data from: ${props.dataName}`)
          console.error(JSON.stringify(e))
        });

      return page;
    });
  };

  const refresh = (): void => {
    applyFilters(curFilter);
  };

  useEffect(() => {
    refresh();
  }, [fetchCounter]);

  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
    >
      <Grid container>
        <Table
          title={props.title}
          dataName={props.dataName}
          nameMapping={props.nameMapping}
          data={data}
          offset={(page ?? 0) * rowsPerPage}
          columns={props.cols}
          fields={props.fields}
          isLoading={isLoading}
          settings={props.settings}
          applyFilters={applyFilters}
          setRefetch={setFetchCounter}
          actions={user?.permission?.role === EMemberRoleEnumType.VIEWER ? undefined : props?.actions}
          updateRecord={
            props.updateRecord === undefined || (user?.permission?.role === EMemberRoleEnumType.VIEWER)
              ? undefined
              : async (row, column, value) => {
                await props.updateRecord?.(row, column, value)
                  .then(res => {
                    if (res?.status === RES_STATUS.success) {
                      notification.success(`Data is updated from "${row[column.key]}" to "${value.toString()}"`)
                    }
                  })
                  .catch(console.error);
                refresh();
              }
          }
        />
      </Grid>

      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ m: 2 }}
      >
        <Grid>
          <Typography>
            Page {totalRows > 0 ? page + 1 : ''} of {Math.ceil(totalRows / rowsPerPage)}
          </Typography>
        </Grid>

        <Grid>
          <Box>
            <DataTablePagination
              count={totalRows}
              page={page ?? 0}
              rowsPerPage={rowsPerPage}
              onPageChange={(e: any, newPage: number): void => {
                console.log({
                  newPage,
                });

                if (newPage < 0 || newPage > Math.ceil(totalRows / rowsPerPage)) {
                  toast.error(`Pagination error: ${props.dataName}`, {
                    toastId: `${newPage}`,
                  })
                  newPage = 0;
                }

                setPage(newPage);
                refresh();
                // autoSize(e.target);
              }}
            />
          </Box>
        </Grid>

        <Grid>
          <Typography>
            {(page ?? 0) === 0 ? 1 : (((page ?? 0) - 1) * rowsPerPage + rowsPerPage + 1)} - {((page ?? 0) * rowsPerPage + rowsPerPage)} of {totalRows > 0 ? totalRows.toLocaleString() + ' total rows' : ''}
          </Typography>
        </Grid>

      </Grid>
    </Grid>
  );
};

export default DataTable;
