import React from 'react';
import { getItem } from 'helpers/utils';

export type FilterableItems = 'todos' | 'users' | 'addresses' | 'sweepers' | 'transactions';
type State = {
  showBalance?: boolean;
  filterData?: {
    type?: FilterableItems;
    status?: boolean;
    options?: {
      search?: {
        multi?: boolean;
        value: string;
      }
    };
  };
};

type Action = {
  type: string;
  payload: State;
};

type Dispatch = (action: Action) => void;

const initialState: State = {
  showBalance: getItem('showBalance') === 'true',
  filterData: {
    type: getItem('filterType') as FilterableItems,
    status: getItem('filterStatus') === 'true',
    options: {
      search: {
        multi: false,
        value: getItem('searchValue') as string,
      }
    }
  },
};

type DataContextProviderProps = { children: React.ReactNode };

const DataContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

const stateReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_DATA': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const DataContextProvider = ({ children }: DataContextProviderProps) => {
  const [state, dispatch] = React.useReducer(stateReducer, initialState);
  const value = { state, dispatch };
  return (
    <DataContext.Provider value={value}>{children}</DataContext.Provider>
  );
};

const useDataContext = () => {
  const context = React.useContext(DataContext);

  if (context) {
    return context;
  }

  throw new Error(`useDataContext must be used within a DataContextProvider`);
};

export { DataContextProvider, useDataContext };
