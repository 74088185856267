import React, { Dispatch, SetStateAction } from "react";
import {
  Button,
  Icon,
  Paper as MuiPaper,
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Slide,
  DialogContentText,
  SxProps,
} from "@mui/material";

import {
  Close as CloseIcon,
  FilterAlt as FilterAltIcon,
  // ArrowDropUp as ArrowUpIcon,
  // ArrowDropDown as ArrowDownIcon,
  ArrowUpward as ArrowUpIcon,
  ArrowDownward as ArrowDownIcon,
} from '@mui/icons-material';

import {
  SortTuple,
  type FilterGroup,
  type FilterTuple,
  FilterParams,
} from 'components/DataTable/filter/FilterGroup';
import { type Filter } from 'components/DataTable/filter/Filter';
import { type Order } from 'components/DataTable/filter/Order';
import { useState } from 'react';

import {
  ColumnFilterModalBox,
} from 'components/DataTable/ColumnFilter';
import { MyIconButton } from "../Buttons/Index";
import { LoadingButton, LoadingIconButton } from "components/Buttons/LoadingButton";
import { TableToolbar } from "./TableToolbar";
import { GenericField } from "./filter/FilterType";
import { STATUS_LABEL, balanceFileds } from "models/Generic";
import notification from "helpers/notification";
import useUserContext from "hooks/useUserContext";
import { EMemberRoleEnumType } from "models/db/User";
import { useDataContext } from "context/data.context";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export interface ColumnDefinitionType<T> {
  sx?: SxProps,
  key: keyof T;
  header: string;
  width?: number;
  wordWrap?: string;
  default?: string | STATUS_LABEL;
  hideSort?: boolean;
  hideFilter?: boolean;
  cell?: (row: T, setRefetch: Dispatch<SetStateAction<number>>) => React.ReactNode | string | undefined;
}

const SortIcon = (props: any): React.JSX.Element => {
  if (props.direction === 'ASC') {
    return <ArrowUpIcon
      // color={props.active ? "primary" : "disabled"}
      {...props}
    />
  } else {
    return <ArrowDownIcon
      // color={props.active ? "primary" : "disabled"}
      {...props}
    />
  }
};

interface TableHeaderCellProps<T> {
  fields: Array<GenericField<T>>;
  column: ColumnDefinitionType<T>;
  index: number;
  sortType: SortTuple<T> | undefined;
  colFilters: Filter[];
  setSortType: (key: SortTuple<T>) => void;
  setFilterField: (key: keyof T) => void;
  filterApply: (params?: FilterParams<T>) => void;
  filterDialogOpen: () => void;
}

/**
 * TableHeaderCell is a generic component that appears in each column of the header of a table.
 * It displays the column header, and has a sort icon and filter icon when hovering.
 * The sort icon will display without hovering when this column is currently sorted.
 * The filter icon will display without hovering when this column has a filter applied.
 *
 * Clicking the sort icon will flip the sort direction and refresh the data.
 * Clicking the filter icon will open the filter modal for that column.
 *
 * This component is not stateful beside tracking if the cell is currently hovered.
 * It calls a parent component's stateful functions to update the sort type and filter field.
 */
const TableHeaderCell = <T,>(
  props: TableHeaderCellProps<T>
): React.JSX.Element => {
  const [hovered, setHovered] = useState<boolean>(false);
  const flipSortAndRun = (): void => {
    let sortType = props.sortType;
    if (sortType !== undefined && sortType[0] === props.column.key) {
      sortType = [props.column.key, sortType[1] === 'ASC' ? 'DESC' : 'ASC'];
    } else {
      sortType = [props.column.key, 'ASC'];
    }
    props.setSortType(sortType);
    props.filterApply({ sortType });
  };

  return (
    <TableCell
      component={"th"}
      align={props.column.key !== 'actions' ? 'left' : 'center'}
      key={`headCell-${props.index}`}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      sx={{
        px: props.column.key !== 'actions' ? 0 : 1.75,
        ...(props.column.width ? { width: props.column.width } : {}),
        ...(props.column.sx || {})
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: props.column.key !== 'actions' ? 'left' : 'center'
      }}>
        {props.column.key === 'actions' ? (
          <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>{props.column.header}</Typography>
        ) : (
          <>
            {Boolean(props.column.hideSort) ? (
              <Typography variant="h4" sx={{ textTransform: 'uppercase', pl: 2.4 }}>{props.column.header}</Typography>
            ) : (
              <MyIconButton
                onClick={flipSortAndRun}
                sx={{ pl: 0, pr: 0, background: 'transparent', color: 'inherit', ":hover": { background: 'transparent' } }}
              >
                <SortIcon
                  direction={props.sortType?.[1] ?? 'ASC'}
                  visibility={hovered || props.sortType?.[0] === props.column.key ? '' : 'hidden'}
                  sx={{ fontSize: 20, mr: 0.5 }}
                />
                <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>{props.column.header}</Typography>
              </MyIconButton>
            )}
          </>
        )}

        {(props.column.key !== 'actions' && !Boolean(props.column.hideFilter) && props.fields.length > 0) && (
          <MyIconButton
            sx={{
              p: 1,
              pl: 0.5,
              color: 'inherit',
              background: 'transparent',
              ":hover": { background: 'transparent' }
            }}
            onClick={() => {
              props.setFilterField(props.column.key);
              props.filterDialogOpen();
            }}
          >
            <FilterAltIcon
              sx={{ fontSize: 20 }}
              visibility={hovered || props.colFilters.length > 0 ? '' : 'hidden'}
            />
          </MyIconButton>
        )}
      </Box>
    </TableCell>
  );
};

interface TableHeaderProps<T> {
  fields: Array<GenericField<T>>;
  columns: Array<ColumnDefinitionType<T>>;
  filterMap: Map<keyof T, Filter[]>;
  sortType?: SortTuple<T>;
  setSortType: (key: SortTuple<T>) => void;
  setFilterField: (key: keyof T) => void;
  filterApply: (params?: FilterParams<T>) => void;
  filterDialogOpen: () => void;
}

const TableHeader = <T,>(props: TableHeaderProps<T>): React.JSX.Element => {
  const { authState } = useUserContext();

  const headers = props.columns.map((column, index) => {
    if (authState.user?.permission?.role === EMemberRoleEnumType.VIEWER) {
      if (column.key === 'actions') return <></>;
    }

    return (
      <TableHeaderCell
        fields={props.fields}
        filterApply={props.filterApply}
        key={index}
        setFilterField={props.setFilterField}
        sortType={props.sortType}
        setSortType={props.setSortType}
        column={column}
        index={index}
        colFilters={props.filterMap.get(column.key) ?? []}
        filterDialogOpen={props.filterDialogOpen}
      />
    );
  });

  const rowNumCol = <TableCell component={"th"} align="center" sx={{ width: 60 }}>NO</TableCell>;

  return (
    <TableHead>
      <TableRow>
        {rowNumCol}
        {headers}
      </TableRow>
    </TableHead>
  );
};

const formatData = (d: any): string => {
  if (d === null) return '';
  else return String(d);
};

interface CellProps<T> {
  index: number;
  row: T;
  column: ColumnDefinitionType<T>;
  setRefetch: Dispatch<SetStateAction<number>>;
  updateRecord?: (
    row: T,
    column: ColumnDefinitionType<T>,
    value: string
  ) => Promise<void>;
}

const Cell = <T,>(props: CellProps<T>): React.JSX.Element => {
  const cellValue = formatData(props.row[props.column.key]);
  const isEditable = props.updateRecord !== undefined && props.column.default !== undefined;
  const [isActive, setIsActive] = useState(false);
  const [userInput, setUserInput] = useState(cellValue);
  const [isFetching, setIsFetching] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);
  const { state } = useDataContext();

  const deleteConfirmToggle = (status: boolean) => {
    setDeleteConfirm(status)

    if (!status) setIsActive(false)
  }

  const onSubmit = async (): Promise<void> => {
    setIsFetching(true)
    if (userInput !== cellValue) {
      await props
        .updateRecord?.(props.row, props.column, userInput)
        .catch(notification.error);
    }
    setIsFetching(false)
  };

  const columnRender = (row: T, column: ColumnDefinitionType<T>) => {
    if (!row) return '';

    if (balanceFileds.includes(column.key as string) && !state.showBalance) {
      return '****'
    }

    if (column.cell) {
      return column.cell(row, props.setRefetch)
    }

    return <Box sx={{ wordWrap: props.column.wordWrap ?? 'initial' }}>{row[column.key] as React.ReactNode}</Box>;
  };

  return (
    <TableCell
      align={props.column.key !== 'actions' ? 'left' : 'center'}
      sx={{
        ...(props.column.key !== 'actions' && { p: 0, pl: 3 }),
        ...(isEditable && {
          "&:hover": {
            // cursor: "pointer",
            "& .editBtn": {
              visibility: 'visible'
            }
          }
        }),
      }}
    >
      <Box sx={{
        ...(props.column.wordWrap === undefined && {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: props.column.key !== 'actions' ? 'left' : 'center',
        })
      }}>
        {!isActive ? columnRender(props.row, props.column) : (
          <FormControl sx={{ display: 'flex', flexDirection: 'row' }}>
            {typeof props.column.default === 'object' ? (
              <Select
                size='small'
                id={props.column.header}
                labelId={props.column.header + '-label'}
                value={userInput}
                onChange={(e) => setUserInput(e.target.value as string)}
              >
                {Object.entries(props.column.default).map((item) => (
                  <MenuItem key={item[0]} value={item[0]}>
                    {item[1].text}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <TextField
                autoFocus
                size="small"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
              />
            )}

            <LoadingIconButton
              loading={isFetching}
              sx={{ pl: 1, pr: 0, ":hover": { background: 'transparent' } }}
              onClick={() => {
                if (userInput === cellValue) {
                  Promise.resolve().then(() => setIsActive(false))
                  return;
                }

                deleteConfirmToggle(true)
              }}
            >
              <Icon>{userInput !== cellValue ? 'save' : 'cancel'}</Icon>
            </LoadingIconButton>

            <Dialog
              open={deleteConfirm}
              onClose={e => deleteConfirmToggle(false)}
              TransitionComponent={Transition}
              PaperProps={{ sx: { px: 1, py: 1 } }}
            >
              <DialogTitle variant="h4">Are you sure?</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  Do you want to <strong>UPDATE</strong> this {props.column.header}?
                </DialogContentText>
              </DialogContent>

              <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', mx: 1 }}>
                <LoadingButton size="small" variant='outlined' onClick={e => deleteConfirmToggle(false)}>
                  Close
                </LoadingButton>
                <LoadingButton size="small" variant='contained'
                  loading={isFetching}
                  onClick={async (e) => await onSubmit()}>
                  Confirm
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </FormControl>
        )}

        {isEditable && !isActive && (
          <LoadingIconButton onClick={() => setIsActive(true)}
            className="editBtn"
            sx={{ pl: 1, pr: 0, visibility: 'hidden', ":hover": { background: 'transparent' } }}
          >
            <Icon>edit_note</Icon>
          </LoadingIconButton>
        )}
      </Box>
    </TableCell>
  );
};

// columnsLength + 1 : for actions column
const spanRows = (text: string, columnsLength: number = 1) => (
  <TableRow aria-colspan={columnsLength + 1}>
    <TableCell colSpan={columnsLength + 1} sx={{ pl: 3.5 }}>
      {text}
    </TableCell>
  </TableRow>
);

interface TableRowsProps<T> {
  data: T[];
  columns: Array<ColumnDefinitionType<T>>;
  offset?: number;
  isLoading: boolean;
  setRefetch: Dispatch<SetStateAction<number>>;
  updateRecord?: (
    row: T,
    column: ColumnDefinitionType<T>,
    value: string
  ) => Promise<void>;
}

const TableRows = <T,>({
  data,
  columns,
  offset,
  isLoading,
  setRefetch,
  updateRecord,
}: TableRowsProps<T>): React.JSX.Element => {
  const { authState } = useUserContext();

  const rows = data.map((row, rowIndex) => {
    return (
      <TableRow key={`row-${rowIndex}`}>
        <TableCell align="center">
          {rowIndex + (offset ?? 0) + 1 /* for 1-based indexing */}
        </TableCell>
        {columns.map((column, columnIndex) => {
          if (authState.user?.permission?.role === EMemberRoleEnumType.VIEWER) {
            if (column.key === 'actions') return <></>;
          }

          return (
            <Cell
              key={`cell-${columnIndex}`}
              row={row}
              column={column}
              index={columnIndex}
              setRefetch={setRefetch}
              updateRecord={updateRecord}
            />
          )
        })}
      </TableRow>
    );
  });

  return (
    <TableBody>
      {isLoading ? spanRows("Loading ...", columns.length) : rows.length ? rows : spanRows("No data available in table", columns.length)}
    </TableBody>
  );
};

export interface TableProps<T, K> {
  title: string;
  dataName: string;
  nameMapping: { [key in keyof T]: K };
  data: T[];
  fields: Array<GenericField<T>>;
  columns: Array<ColumnDefinitionType<T>>;
  offset?: number;
  isLoading: boolean;
  setRefetch: Dispatch<SetStateAction<number>>;
  actions?: Array<{
    icon: string;
    title: string;
    event: Dispatch<SetStateAction<boolean>>;
  }>;
  settings?: {
    toolbar?: {
      export?: 'excel';
    }
  };
  applyFilters: (filterObj: FilterGroup<K>) => void;
  updateRecord?: (
    row: T,
    column: ColumnDefinitionType<T>,
    value: string
  ) => Promise<void>;
}

export type FilterMap<T> = Map<keyof T, Filter[]>;

/**
 * The Table component is the main component for the table.
 * It is responsible for managing all the state for sorting & filtering of the table.
 * The data is passed in as a prop.
 *
 * Table is generic over the type of the data and the type of the name mapping.
 * It uses a name mapping to map the column names of the data fields to the column names of filters in the FilterGroup.
 *
 * It passes stateful functions down to its children components to update the sort & filtering states.
 * For example, the TableHeaderCell component will call the setSortType function to update the sort type.
 * The ColumnFilter component will call the setFilterMapItem function to update the filter map.
 *
 * It can construct a full FilterGroup object from the state and pass it to the prop applyFilters function
 * to run filters on the data.
 */
export const Table = <T, K>(props: TableProps<T, K>): React.JSX.Element => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const filterDialogOpen = () => setOpen(true);
  const filterDialogClose = () => setOpen(false);

  const [filterMap, setFilterMap] = useState<FilterMap<T>>(new Map());
  const [sortType, setSortType] = useState<SortTuple<T> | undefined>(undefined);
  const [focusedFilterField, setFocusedFilterField] = useState<keyof T | undefined>(undefined);

  const setFilterMapItem = (key: keyof T, value: Filter[]): void => {
    const newFilterMap = new Map(filterMap);
    if (value.length === 0) {
      newFilterMap.delete(key);
    } else {
      newFilterMap.set(key, value);
    }
    setFilterMap(newFilterMap);
  };

  const createFilterGroup = ({ sortType, download }: FilterParams<T>): FilterGroup<K> => {
    const filters = new Array<Array<FilterTuple<K>>>();

    filterMap.forEach((filterTypes, key) => {
      const filter = new Array<FilterTuple<K>>();
      filterTypes.forEach((filterType) => {
        filter.push([props.nameMapping[key], filterType]);
      });
      filters.push(filter);
    });

    const orderBy =
      sortType !== undefined
        ? new Array<[K, Order]>([
          props.nameMapping[sortType[0]] as K,
          sortType[1],
        ])
        : new Array<[K, Order]>();
    const filterObj = {
      filters,
      orderBy,
      download,
    };
    return filterObj;
  };

  const filterApply = (params?: FilterParams<T>): void => {
    props.applyFilters(createFilterGroup({ sortType: params?.sortType, download: params?.download }));
  };

  return (
    <>
      <Grid container>
        <TableToolbar
          title={props.title}
          dataName={props.dataName}
          fields={props.fields}
          settings={props.settings?.toolbar}
          filterMap={filterMap}
          setFilterMapItem={setFilterMapItem}
          filterApply={filterApply}
          actions={props?.actions}
        />
      </Grid>

      <MuiTableContainer component={MuiPaper}>
        <MuiTable sx={{ minWidth: 650, tableLayout: 'fixed' }} aria-label="data-table">
          <TableHeader
            filterApply={filterApply}
            columns={props.columns}
            fields={props.fields}
            filterMap={filterMap}
            sortType={sortType}
            setSortType={setSortType}
            setFilterField={setFocusedFilterField}
            filterDialogOpen={filterDialogOpen}
          />

          <TableRows
            data={props.data}
            columns={props.columns}
            offset={props.offset}
            isLoading={props.isLoading}
            setRefetch={props.setRefetch}
            updateRecord={props.updateRecord}
          />
        </MuiTable>
      </MuiTableContainer>

      {props.fields.length > 0 && (
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={filterDialogClose}
        >
          <DialogTitle sx={{ ml: 1, mr: 1, pb: 1 }}>
            {focusedFilterField !== undefined && (
              <>{props.fields.find((f) => f.name === focusedFilterField)?.title} Filters</>
            )}

            <MyIconButton
              aria-label="close"
              onClick={filterDialogClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </MyIconButton>
          </DialogTitle>

          <DialogContent sx={{ ml: 1, mr: 1, minWidth: 400 }}>
            <Box sx={{ mt: 1 }}>
              {focusedFilterField !== undefined && (
                <ColumnFilterModalBox
                  field={props.fields.find((f) => f.name === focusedFilterField)}
                  columnFilters={filterMap.get(focusedFilterField) ?? []}
                  setColumnFilters={(filter) => {
                    setFilterMapItem(focusedFilterField, filter);
                  }}
                />
              )}
            </Box>
          </DialogContent>

          <DialogActions>
            <Button autoFocus onClick={filterDialogClose}>Cancel</Button>
            <LoadingButton
              variant='contained'
              loading={false}
              disabled={false}
              onClick={(e) => {
                setFocusedFilterField(undefined);
                filterApply();
                filterDialogClose();
              }}
            >
              APPLY
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
