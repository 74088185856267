import config from "config";

interface IRouteObject {
    path?: string,
    children?: IRouteObject[],
}

export const validatePath = (routes: IRouteObject[]): IRouteObject[] => {
    return [...(routes || [])].filter(item => item?.path === location.pathname.replace(/^\/([^\/]*).*$/, '$1'));
}

export const setItem = (name: string, item: any): void => {
    localStorage.setItem(name, item)
}

export const getItem = (name: string): string | null => {
    return localStorage.getItem(name) as string || null;
}

export const checkItem = (name: string, value: string): boolean => {
    return localStorage.getItem(name) === value;
}

export const ceil = (value: number | undefined, precision: number = 8): number => {
    return Math.ceil(Number(value) * (10 ** precision)) / (10 ** precision);
}

export const keepWaiting = (timeout = 3000) => new Promise((resolve) => setTimeout(resolve, timeout));

// helper to get an array containing the object values with the correct type infered.
export function objectValues<T extends {}>(obj: T) {
    return Object.keys(obj).map((objKey) => obj[objKey as keyof T]);
}

export function objectKeys<T extends {}>(obj: T) {
    return Object.keys(obj).map((objKey) => objKey as keyof T);
}

export type PrimitiveType = string | Symbol | number | boolean;

// Type guard for the primitive types which will support printing out of the box.
export function isPrimitive(value: any): value is PrimitiveType {
    return (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean" ||
        typeof value === "symbol"
    );
}

export function formatBalance(value: string | number, digit = config.MAX_DIGITS): string {
    return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: digit
    }).format(Number(value))
}

export function replaceKeysInString(input: string, params: { [key: string]: string }): string {
    if (!input) return '#';

    return input.replace(/\{(\w+)\}/g, (match, key) => {
        return params.hasOwnProperty(key) ? params[key] : match;
    });
}