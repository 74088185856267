import React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { cols_BankAccount, fields_BankAccount } from "./definitions";
import zionApi from "api/zionApi";
import { BankAccountFieldName, DB_BankAccount, DB_BankAccounts, nameMapping_BankAccount } from "models/db/BankAccount";
import { FilterGroup } from "components/DataTable/filter/FilterGroup";
import CrudModal from "components/Modals/crud.modal";
import AddBankAccount from "./forms/AddBankAccount";
import { DTResponse } from "models/Generic";
import { getFilteredBankAccounts } from "models/api/BankAccount";

export default function BankAccountsTab(): React.JSX.Element {
  // const { authState } = useUserContext()
  const [openCrudModal, setOpenCrudModal] = React.useState(false);
  const [refetchTable, setRefetchTable] = React.useState<number>(0);

  return (
    <>
      <Helmet><title>BankAccount Settings</title></Helmet>

      <CrudModal openCrudModal={openCrudModal} setOpenCrudModal={setOpenCrudModal} closeable>
        <AddBankAccount setOpenCrudModal={setOpenCrudModal} setRefetch={setRefetchTable} />
      </CrudModal>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <MyCard>
            <Grid>
              <DataTable
                title='Bank Accounts'
                dataName='bankAccount'
                cols={cols_BankAccount}
                fields={fields_BankAccount}
                rowsPerPage={25}
                nameMapping={nameMapping_BankAccount}
                fetchCounter={[refetchTable, setRefetchTable]}
                actions={[{
                  icon: 'add_circle_outline',
                  title: 'Add Bank Account',
                  event: setOpenCrudModal,
                }]}
                updateRecord={async (row, column, value) => {
                  const newRow = structuredClone(row);
                  newRow[column.key] = value as never;
                  const response = await zionApi.patch<DB_BankAccount>(`bankaccounts/${row.id}`, newRow);
                  return response.data
                }}
                getFilteredRecords={getFilteredBankAccounts}
              />
            </Grid>
          </MyCard>
        </Grid>
      </Grid>
    </>
  );
}