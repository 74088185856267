import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import CloseIcon from '@mui/icons-material/Close';

import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { createItemFn } from 'api/transactionApi';
import { getAllItemsFn as getAddress } from 'api/addrApi';
import zionApi from 'api/zionApi';
import { GenericResponse } from 'models/Generic';
import { DB_Transaction, TX_TYPES } from 'models/db/Transaction';
import { DB_Address } from 'models/db/Address';
import { CRYPTO_SYMBOL, DB_Currency } from 'models/db/Currency';
import { MyFormHelperText } from 'components/Forms/MyFormHelper';
import { getCurrencies } from 'models/api/Currency';
import { useFetch } from 'hooks/useFetch';
import { balanceFilterBySymbol } from 'helpers/balance';

interface ICreateItemProp {
  setRefetch: Dispatch<SetStateAction<number>>;
  setPurchaseConfirm: Dispatch<SetStateAction<boolean>>;
}

const createItemSchema = z.object({
  currencySymbol: z.nativeEnum(CRYPTO_SYMBOL),
  fromAddress: z.string().min(1, 'Sender Address is required'),
  txType: z.literal(TX_TYPES.BUYING_WALLET, { required_error: 'Transaction Type is required' }),
});

export type TCreateItem = z.TypeOf<typeof createItemSchema>;

const PurchaseForm: FC<ICreateItemProp> = ({ setRefetch, setPurchaseConfirm }) => {
  const [currencySymbol, setCurrencySymbol] = React.useState<CRYPTO_SYMBOL>();
  const [fromAddress, setFromAddress] = React.useState<string>('');
  const [targetAddress, setTargetAddress] = React.useState<DB_Address>();

  const [currencies, setCurrencies] = React.useState<DB_Currency[]>([]);
  const currencyData = useFetch({ callback: async () => await getCurrencies() })

  const queryAddrs = useQuery({
    enabled: false,
    queryKey: ['currencyAddresses'],
    queryFn: () => getAddress(currencySymbol),
    select: (result) => result.data,
    onSuccess(data) { }
  });

  // Mutations
  const createItem = useMutation({
    mutationFn: async (inputData: TCreateItem) => {
      const response = await zionApi.post<GenericResponse<DB_Transaction>>(`transactions/purchase`, inputData);
      return response.data;
    },
    onSuccess: (data) => {
      if (typeof setRefetch === 'function') setRefetch(c => ++c)
      notification.success(data?.message as string);
      setPurchaseConfirm(false);
      methods.reset();
    }
  })

  const methods = useForm<TCreateItem>({ resolver: zodResolver(createItemSchema) });

  const { formState: { errors: formError, isSubmitSuccessful } } = methods;

  if (Object.keys(formError).length) {
    console.log('formError', formError);
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      // methods.reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<TCreateItem> = (formValues) => {
    console.log('formValues', formValues);
    createItem.mutate(formValues);
  };

  const handleChangeCurrency = async (event: SelectChangeEvent) => {
    setCurrencySymbol(event.target.value as CRYPTO_SYMBOL)

    setTargetAddress(undefined)
  };

  useEffect(() => {
    setFromAddress('');
    methods.setValue("fromAddress", '');

    methods.setValue("txType", TX_TYPES.BUYING_WALLET);

    if (currencySymbol) {
      ; (async () => await queryAddrs.refetch())();
    }
  }, [currencySymbol])

  useEffect(() => {
    if (currencyData.action === 'ready') { currencyData.refetch(); }
    if (currencyData.data?.data) {
      setCurrencies(currencyData.data.data)
    }
  }, [currencyData.action])

  return (
    <>
      <DialogTitle variant="h4" sx={{ pb: 0 }}>Purchase Address</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={e => setPurchaseConfirm(false)}
        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500], }}
      >
        <CloseIcon />
      </IconButton>

      <FormProvider {...methods}>
        <Box
          noValidate
          component='form'
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          <DialogContent sx={{ py: 1 }}>
            <DialogContentText>
              Your address creation limit has expired! To increase your address creation limit, you can create 10 wallet addresses by purchasing with your balance. Your address creation authorization will be active in approximately 2-5 minutes.
            </DialogContentText>

            {currencies.length > 0 && (
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel id="currencySymbol-label">Select Payment Wallet</InputLabel>

                <Select {...methods.register('currencySymbol')}
                  label="Select Payment Wallet"
                  labelId="currencySymbol-label"
                  value={currencySymbol}
                  onChange={handleChangeCurrency}
                >
                  {currencies.map(curr => <MenuItem key={curr.id} value={curr.symbol}>{curr.name} Wallet</MenuItem>)}
                </Select>

                <MyFormHelperText error={!!formError.currencySymbol} message={formError.currencySymbol?.message} />
                <FormHelperText error={!queryAddrs?.data?.length}>
                  {currencySymbol && !queryAddrs?.data?.length && 'You must first create an address for this wallet.'}
                </FormHelperText>
              </FormControl>
            )}

            <FormControl fullWidth sx={{ mt: 2, }} disabled={!queryAddrs.data?.length}>
              <InputLabel id="fromAddress-label">Select Address</InputLabel>
              <Select  {...methods.register('fromAddress')}
                label="Select Address"
                labelId="fromAddress-label"
                value={fromAddress}
                onChange={e => {
                  setFromAddress(e.target.value)
                  const targetAddress = queryAddrs?.data?.find(item => String(item.id) === e.target.value);
                  setTargetAddress(targetAddress)
                }}
              >
                {queryAddrs?.data?.length && queryAddrs?.data.map(item => (
                  <MenuItem key={item.id} value={String(item.id)}>
                    {/* ({item.name && `${item.name} - `}{balanceFilterBySymbol(item.currencyBalances, CRYPTO_SYMBOL.USDT).balance}) - {item.address} */}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!formError?.fromAddress}>{formError?.fromAddress?.message}</FormHelperText>
            </FormControl>

            {targetAddress && (
              <FormControl fullWidth sx={{ mt: 2, }}>
                {/* <Typography>Balance: {balanceFilterBySymbol(targetAddress.currencyBalances, CRYPTO_SYMBOL.USDT).balance} {CRYPTO_SYMBOL.USDT}</Typography> */}
              </FormControl>
            )}

          </DialogContent>

          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', mx: 1.8, mb: 1, mt: 2 }}>
            <LoadingButton variant='outlined' onClick={e => setPurchaseConfirm(false)}>
              Close
            </LoadingButton>
            <LoadingButton variant='contained' type='submit' loading={createItem.isLoading || queryAddrs.isFetching} disabled={!currencySymbol}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </Box>
      </FormProvider >
    </>
  );
};

export default PurchaseForm;