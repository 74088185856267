import React, { FC, useReducer, useState } from 'react';
import { pickBy } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { updateProfileFn } from 'api/authApi';

import { useAuthState } from 'context/auth.context';
import { DB_USER_STATUS, DB_User } from 'models/db/User';

interface IUpdateUserProp {
  user: DB_User;
  setOpenCrudModal: (openCrudModal: boolean) => void;
}

const updateUserSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().min(1, "Email is required"),
  status: z.nativeEnum(DB_USER_STATUS),
  image: z.instanceof(File),
}).partial();

export type TUpdateUser = z.TypeOf<typeof updateUserSchema>;

const UserUpdateProfile: FC<IUpdateUserProp> = ({ user, setOpenCrudModal }) => {
  const { dispatch } = useAuthState();

  // const defaultValues = {
  //   status: "active",
  //   name: null,
  //   email: null,
  //   ...(user || {})
  // }

  // const updateUser: TUpdateUser = Object.assign(defaultValues)

  // console.log({ ...updateUser });

  // const [userState, updateUserState] = useReducer((state: TUpdateUser, newState: Partial<TUpdateUser>) => {
  //   return { ...state, ...newState, };
  // }, {
  //   status: updateUser.status,
  //   name: updateUser.name,
  //   email: updateUser.email,
  // });

  // console.log({ ...userState });

  const methods = useForm<TUpdateUser>({
    resolver: zodResolver(updateUserSchema),
  });

  const { formState: { errors: formErrors, isSubmitSuccessful }, } = methods;

  const { isLoading, mutate } = useMutation(({ id, formData }: { id: string; formData: FormData }) =>
    updateProfileFn({ id, formData }), {
    onSuccess: (result) => {
      dispatch({ type: 'SET_USER', payload: result.data });
      notification.success('User updated successfully');
      setOpenCrudModal(false);
      // methods.reset({ ...updateUser });
    }
  });

  if (Object.keys(formErrors).length) {
    console.log({ formErrors });
  }

  React.useEffect(() => {
    if (user) {
      methods.reset({
        name: user.name,
        email: user.email,
        status: user.status,
      });
    }
  }, [user]);

  const onSubmitHandler: SubmitHandler<TUpdateUser> = (formValues) => {
    if (Object.keys(formErrors).length) {
      Object.values(formErrors).map((item) => notification.error(item?.message as string));
      return;
    }

    const formData = new FormData();
    const filteredFormData = pickBy(
      formValues,
      (value) => value !== '' && value !== undefined
    );
    const { image, ...otherFormData } = filteredFormData;
    // if (image) {
    //   formData.append('image', image);
    // }
    formData.append('data', JSON.stringify(otherFormData));
    mutate({ id: user?.id!, formData })
  };

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Edit Personal Data
        </Typography>

        <CloseButton loading={isLoading}
          onClick={() => setOpenCrudModal(false)}>
          <Icon>close</Icon>
        </CloseButton>
      </Box>

      <FormProvider {...methods}>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField {...methods.register('name')}
              label='Name'
            // onChange={e => updateUserState({ "name": e.target.value })}
            />

            <FormHelperText error={!!formErrors?.name}>
              {formErrors?.name ? formErrors?.name.message : ''}
            </FormHelperText>
          </FormControl>

          {/* <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField {...methods.register('email')}
              label='Email'
            // onChange={e => updateUserState({ "email": e.target.value })}
            />

            <FormHelperText error={!!formErrors?.email}>
              {formErrors?.email ? formErrors?.email.message : ''}
            </FormHelperText>
          </FormControl> */}

          {/* <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="status-label">Select Status</InputLabel>
            <Controller
              name="status"
              control={methods.control}
              defaultValue={user?.status || DB_USER_STATUS['active']}
              render={({ field: { onChange: fieldOnChange, ...field } }) => (
                <Select {...field}
                  label="Select Status"
                  labelId="status-label"
                  onChange={e => {
                    fieldOnChange(e)
                    // updateUserState({ "status": e.target.value as DB_USER_STATUS })
                  }}
                >
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="SUSPEND">SUSPEND</MenuItem>
                  <MenuItem value="DELETED">DELETED</MenuItem>
                </Select>
              )}
            />

            <FormHelperText error={!!formErrors?.status}>
              {formErrors?.status ? formErrors?.status.message : ''}
            </FormHelperText>
          </FormControl> */}

          <Grid container justifyContent="center" >
            <LoadingButton
              variant='contained'
              type='submit'
              loading={isLoading}
              sx={{ color: "#fff" }}
            >
              Save
            </LoadingButton>
          </Grid>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default UserUpdateProfile;