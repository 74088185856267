import React from "react";
import { Box, Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { cols_Transaction, fields_Transaction } from "./definitions";

import { FilterGroup } from "components/DataTable/filter/FilterGroup";
import { DB_Transaction, DB_Transactions, TransactionFieldName, nameMapping_Transaction } from "models/db/Transaction";
import useUserContext from "hooks/useUserContext";
import CrudModal from "components/Modals/crud.modal";
import AddTransactionForm from "./forms/AddForm";
import zionApi from "api/zionApi";
import { DTResponse } from "models/Generic";
import AddBulkTransactionForm from "./forms/AddBulkForm";

export default function Transactions(): React.JSX.Element {
  const { authState } = useUserContext()
  const [openCrudModal, setOpenCrudModal] = React.useState(false);
  const [openCruBulkdModal, setOpenCrudBulkModal] = React.useState(false);
  const [refetchTable, setRefetchTable] = React.useState<number>(0);

  return (
    <>
      <Helmet><title>Transactions</title></Helmet>

      {/* <PageTitle
        heading="Transactions"
        // crud={{ add: authState.user?.permission?.role !== EMemberRoleEnumType.VIEWER }}
        // setOpenCrudModal={setOpenCrudModal}
        sx={{ my: 3 }}
      /> */}

      <CrudModal openCrudModal={openCrudModal} setOpenCrudModal={setOpenCrudModal} closeable>
        <AddTransactionForm setOpenCrudModal={setOpenCrudModal} setRefetch={setRefetchTable} />
      </CrudModal>

      <CrudModal openCrudModal={openCruBulkdModal} setOpenCrudModal={setOpenCrudBulkModal} closeable>
        <AddBulkTransactionForm setOpenCrudModal={setOpenCrudBulkModal} setRefetch={setRefetchTable} />
      </CrudModal>

      <Container maxWidth={false}>
        <DataTable
          title='Transactions'
          dataName='transaction'
          rowsPerPage={25}
          cols={cols_Transaction}
          fields={fields_Transaction}
          nameMapping={nameMapping_Transaction}
          actions={[
            {
              icon: 'add_circle_outline',
              title: 'Add Transaction',
              event: setOpenCrudModal,
            },
            {
              icon: 'playlist_add_circle',
              title: 'Add Transactions',
              event: setOpenCrudBulkModal,
            },
          ]}
          fetchCounter={[refetchTable, setRefetchTable]}
          getFilteredRecords={async (filterParams: FilterGroup<TransactionFieldName>) => {
            const response = await zionApi.get<DTResponse<DB_Transaction>>(`transactions`, {
              params: {
                filterParams
              }
            });
            return response.data;
          }}
        />
      </Container>
    </>
  );
}