import { GenericResponse } from 'models/Generic';
import zionApi from './zionApi';
import { DB_Currency } from 'models/db/Currency';
import { DB_Asset } from 'models/db/Asset';

export const getCurrencies = async (params: object = {}) => {
  const response = await zionApi.get<GenericResponse<DB_Currency[]>>(`currencies` + (params ? `?${new URLSearchParams(Object.entries(params)).toString()}` : ''));
  return response.data;
};

export const getUserWallets = async (params: object = {}) => {
  const response = await zionApi.get<GenericResponse<DB_Currency[]>>(`wallets` + (params ? `?${new URLSearchParams(Object.entries(params)).toString()}` : ''));
  return response.data;
};

export const getUserAssets = async () => {
  const response = await zionApi.get<GenericResponse<DB_Asset[]>>(`wallets/myassets`);
  return response.data;
};