import { Dispatch, useLayoutEffect, useRef, useState } from 'react';

import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';

import {
  Box,
  Grid,
  Container,
  CssBaseline,
} from '@mui/material';

import Footer from '../Footer';
import MyAppBar from './MyAppBar';
import { useMutation } from '@tanstack/react-query';
import { logoutUserFn } from 'api/authApi';
import { useAuthState } from 'context/auth.context';

export default function PublicLayout() {
  const headerRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)

  const [headerDimensions, setHeaderDimensions] = useState({ width: 0, height: 0 });
  const [footerDimensions, setFooterDimensions] = useState({ width: 0, height: 0 });

  const navigate = useNavigate();
  const { authState, dispatch: authDispatch } = useAuthState();

  useLayoutEffect(() => {
    if (headerRef.current) {
      setHeaderDimensions({
        width: headerRef.current.offsetWidth,
        height: headerRef.current.offsetHeight,
      });
    }

    if (footerRef.current) {
      setFooterDimensions({
        width: footerRef.current.offsetWidth,
        height: footerRef.current.offsetHeight,
      });
    }
  }, []);

  const { mutate: logoutUser, isLoading } = useMutation(
    async () => await logoutUserFn(),
    {
      onSuccess: (data) => {
        authDispatch({ type: 'SET_USER', payload: null })
        navigate('/login')
      }
    }
  );

  const onLogoutHandler = async () => {
    logoutUser();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Box component="main" sx={{
        flexGrow: 1,
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        position: 'relative',
      }}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} ref={headerRef}>
              <MyAppBar onLogoutHandler={onLogoutHandler} isLoading={isLoading} />
            </Grid>

            <Grid item xs={12}>
              <Outlet context={{
                authState,
                authDispatch,
                headerDimensions,
                footerDimensions,
              }} />
            </Grid>
          </Grid>
        </Container>


        <Container maxWidth="lg" sx={{ mt: 'auto' }} ref={footerRef}>
          <Grid container>
            <Grid item xs={12}>
              <Footer props={{
                py: 3,
                // px: 2,
                // backgroundColor: '#ccc',
              }} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}