import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Avatar,
  Box,
  FormControl,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import { getAllItemsFn as getAddress, getQrCodeFn } from 'api/addrApi';
import copy from 'copy-to-clipboard';
import DataLoader from 'components/Loaders/DataLoader';
import { CRYPTO_SYMBOL, DB_Currency } from 'models/db/Currency';
import { getCurrencies } from 'models/api/Currency';
import { useFetch } from 'hooks/useFetch';

interface ICreateItemProp {
  setOpenDepositModal: (openCrudModal: boolean) => void;
}

const DepositForm: FC<ICreateItemProp> = ({ setOpenDepositModal }) => {
  // const queryClient = useQueryClient();

  const [currencySymbol, setCurrencySymbol] = React.useState<CRYPTO_SYMBOL>();
  const [fromAddress, setFromAddress] = React.useState<string>('');
  const [addressQrImage, setAddressQrImage] = React.useState<string>('');

  const [currencies, setCurrencies] = React.useState<DB_Currency[]>([]);
  const currencyData = useFetch({ callback: async () => await getCurrencies() })

  const queryAddrs = useQuery({
    enabled: false,
    queryKey: ['currencyAddresses'],
    queryFn: () => getAddress(currencySymbol),
    select: (result) => result.data,
    onSuccess(data) { }
  });

  const qrCodeQuery = useMutation({
    mutationFn: (address: string) => getQrCodeFn({ data: address }),
    onSuccess: (qrImage) => setAddressQrImage(qrImage)
  })

  const handleChangeCurrency = async (event: SelectChangeEvent) => {
    setCurrencySymbol(event.target.value as CRYPTO_SYMBOL)
  };

  const handleChangeFromAddress = async (event: SelectChangeEvent<string>) => {
    setFromAddress(event.target.value);

    console.log(event.target.value);

    if (event.target.value && !qrCodeQuery.isLoading) {
      qrCodeQuery.mutate(event.target.value);
    }
  };

  useEffect(() => {
    setFromAddress('');
    setAddressQrImage('');

    if (currencySymbol) {
      ; (async () => await queryAddrs.refetch())();
    }
  }, [currencySymbol])

  useEffect(() => {
    if (currencyData.action === 'ready') { currencyData.refetch(); }
    if (currencyData.data?.data) {
      setCurrencies(currencyData.data.data)
    }
  }, [currencyData.action])

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Deposit
        </Typography>

        <CloseButton onClick={() => setOpenDepositModal(false)}
          loading={qrCodeQuery.isLoading || queryAddrs.isFetching}>
          <Icon>close</Icon>
        </CloseButton>
      </Box>

      <Box
        component='form'
        noValidate
        autoComplete='off'
      >
        {currencies.length > 0 && (
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="currencySymbol-label">Select Wallet</InputLabel>

            <Select
              label="Select Wallet"
              labelId="currencySymbol-label"
              value={currencySymbol}
              onChange={handleChangeCurrency}
            >
              {currencies.map(curr => <MenuItem key={curr.id} value={curr.symbol}>{curr.name} Wallet</MenuItem>)}
            </Select>

            <FormHelperText error={!queryAddrs?.data?.length}>
              {currencySymbol && !queryAddrs?.data?.length && 'You must first create an address for this wallet.'}
            </FormHelperText>
          </FormControl>
        )}

        <FormControl fullWidth sx={{ mb: 3, }} disabled={!queryAddrs.data?.length}>
          <InputLabel id="fromAddress-label">Select Address</InputLabel>
          <Select
            label="Select Address"
            labelId="fromAddress-label"
            value={fromAddress}
            onChange={handleChangeFromAddress}
          >
            {queryAddrs?.data?.length && queryAddrs?.data.map(item => (
              <MenuItem key={item.id} value={item.address}>{item.name && `${item.name} - `}{item.address}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ alignItems: 'center' }}>
          {qrCodeQuery.isLoading && <DataLoader />}

          {addressQrImage && <>
            <Avatar src={addressQrImage} alt="QR" variant="square" sx={{ width: 300, height: 300 }} />
            <br />
            <Stack direction="row" alignItems="center">
              <Typography variant='h4'>{fromAddress}</Typography>
              <IconButton aria-label="copy" size="small" onClick={e => copy(fromAddress)}>
                <Icon fontSize='inherit'>content_copy</Icon>
              </IconButton>
            </Stack>
          </>}
        </FormControl>
      </Box>
    </Box>
  );
};

export default DepositForm;