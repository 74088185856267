import zionApi from './zionApi';
import { LoginInput } from '../pages/Auth/login.page';
import { RegisterInput } from '../pages/Auth/register.page';
import { ForgotPasswordInput } from 'pages/Auth/password_forgot.page';
import { GenericResponse } from 'models/Generic';
import { DB_User, ILoginResponse, IResetPasswordRequest } from 'models/db/User';

export const signUpUserFn = async (user: RegisterInput) => {
  const response = await zionApi.post<GenericResponse<DB_User>>('auth/register', user);
  return response.data;
};

export const loginUserFn = async (user: LoginInput) => {
  const response = await zionApi.post<ILoginResponse>('auth/login', user);
  return response.data;
};

export const verifyEmailFn = async (verificationCode: string) => {
  const response = await zionApi.get<GenericResponse<DB_User>>(
    `auth/verifyemail/${verificationCode}`
  );
  return response.data;
};

export const logoutUserFn = async () => {
  const response = await zionApi.get<GenericResponse<DB_User>>('auth/logout');
  return response.data;
};

export const getMeFn = async (params?: { client?: string | ''; }) => {
  const response = await zionApi.get<GenericResponse<DB_User>>('users/me?client=' + (params?.client || ''));
  return response.data;
};

export const updateProfileFn = async ({ id, formData }: {
  id: string;
  formData: FormData;
}) => {
  const response = await zionApi.patch<GenericResponse<DB_User>>(`users/me`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data;
};

export const forgotPasswordFn = async (user: ForgotPasswordInput) => {
  const response = await zionApi.post<GenericResponse<DB_User>>('auth/forgotpassword', user);
  return response.data;
};

export const resetPasswordFn = async ({ resetCode, password, passwordConfirm }: IResetPasswordRequest) => {
  const response = await zionApi.patch<GenericResponse<DB_User>>(`auth/resetpassword/${resetCode}`, { password, passwordConfirm });
  return response.data;
};