import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import {
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import zionApi from 'api/zionApi';
import { getAllItemsFn as getAddress } from 'api/addrApi';
import { MyIconButtonDark } from 'components/Buttons/Index';
import { GenericResponse } from 'models/Generic';
import { DB_Transaction } from 'models/db/Transaction';
import { CRYPTO_SYMBOL, DB_Currency } from 'models/db/Currency';
import { useFetch } from 'hooks/useFetch';
import { getCurrencies } from 'models/api/Currency';
import { MyFormHelperText } from 'components/Forms/MyFormHelper';

interface IcsvItem {
  address: string;
  amount: string;
}

interface ICreateItemProp {
  setRefetch?: Dispatch<SetStateAction<number>>;
  setOpenCrudModal: (openCrudModal: boolean) => void;
}

const createItemSchema = z.object({
  currencySymbol: z.nativeEnum(CRYPTO_SYMBOL),
  fromAddress: z.string().min(1, 'Sender Address is required'),
  toAddresses: z.object({
    address: z.string(),
    amount: z.number().refine((amount) => amount > 0),
  }).array(),
  txType: z.string().min(1, 'Transaction Type is required'),
});

export type TCreateItem = z.infer<typeof createItemSchema>;

const AddBulkTransactionForm: FC<ICreateItemProp> = ({ setRefetch, setOpenCrudModal }) => {
  const [currencySymbol, setCurrencySymbol] = React.useState<CRYPTO_SYMBOL>();
  const [fromAddress, setFromAddress] = React.useState<string>('');

  const [currencies, setCurrencies] = React.useState<DB_Currency[]>([]);
  const currencyData = useFetch({ callback: async () => await getCurrencies() })

  const fileReader = new FileReader();
  const [file, setFile] = React.useState();
  const [csvDataObj, setCsvDataObj] = React.useState<IcsvItem[]>([]);

  const queryAddrs = useQuery({
    enabled: false,
    queryKey: ['currencyAddresses'],
    queryFn: () => getAddress(currencySymbol),
    select: (result) => result.data,
    onSuccess(data) { }
  });

  // Mutations
  const createItem = useMutation({
    mutationFn: async (inputData: TCreateItem) => {
      const response = await zionApi.post<GenericResponse<DB_Transaction>>(`transactions/bulk`, inputData)
      return response.data;
    },
    onSuccess: () => {
      if (typeof setRefetch === 'function') setRefetch(c => ++c)
      notification.success('Transaction created successfully');
      setOpenCrudModal(false);
      methods.reset();
    }
  })

  const methods = useForm<TCreateItem>({ resolver: zodResolver(createItemSchema) });

  const { formState: { errors: formError, isSubmitSuccessful } } = methods;

  if (Object.keys(formError).length) {
    console.debug('formError', formError);
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      // methods.reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<TCreateItem> = (formValues) => {
    console.log('formValues', formValues);
    createItem.mutate(formValues);
  };

  const handleChangeCurrency = async (event: SelectChangeEvent) => {
    setCurrencySymbol(event.target.value as CRYPTO_SYMBOL)
  };

  useEffect(() => {
    setFromAddress('');
    methods.setValue("fromAddress", '');

    methods.setValue("txType", "CUSTOM");

    if (currencySymbol) {
      ; (async () => await queryAddrs.refetch())();
    }
  }, [currencySymbol])

  // csv importer
  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = async (text: string) => {
    // const csvHeader = text.slice(0, text.indexOf("\n")).split(",");
    const csvRows = text.slice(text.indexOf("\n") + 1).split("\n");

    const dataArr: IcsvItem[] = [];

    for await (const item of csvRows) {
      const values = item.split(",");
      if (values[0].length) {
        dataArr.push({ address: values[0], amount: values[1] })
      }
    }

    setCsvDataObj(dataArr);
  };

  const deleteItem = async (target: IcsvItem) => {
    setCsvDataObj(data => {
      const index = data.indexOf(target)
      if (index > -1) {
        data.splice(index, 1)
      }
      return [...data]
    });
  };

  React.useEffect(() => {
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target?.result;
        if (text) {
          csvFileToArray(text as string);
        }
      };

      fileReader.readAsText(file);
    }
  }, [file])

  useEffect(() => {
    if (currencyData.action === 'ready') { currencyData.refetch(); }
    if (currencyData.data?.data) {
      setCurrencies(currencyData.data.data)
    }
  }, [currencyData.action])

  const headerKeys = Object.keys(Object.assign({}, ...csvDataObj));
  console.log({ headerKeys, csvDataObj });

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Add Transaction
        </Typography>

        <CloseButton loading={createItem.isLoading || queryAddrs.isFetching}
          onClick={() => setOpenCrudModal(false)}>
          <Icon>close</Icon>
        </CloseButton>
      </Box>

      <FormProvider {...methods}>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          {currencies.length > 0 && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="currencySymbol-label">Select Wallet</InputLabel>

              <Select {...methods.register('currencySymbol')}
                label="Select Wallet"
                labelId="currencySymbol-label"
                value={currencySymbol}
                onChange={handleChangeCurrency}
              >
                {currencies.map(curr => <MenuItem key={curr.id} value={curr.symbol}>{curr.name} Wallet</MenuItem>)}
              </Select>

              <MyFormHelperText error={!!formError.currencySymbol} message={formError.currencySymbol?.message} />
              <FormHelperText error={!queryAddrs?.data?.length}>
                {currencySymbol && !queryAddrs?.data?.length && 'You must first create an address for this wallet.'}
              </FormHelperText>
            </FormControl>
          )}

          <FormControl fullWidth sx={{ mb: 2, }} disabled={!queryAddrs.data?.length}>
            <InputLabel id="fromAddress-label">Select Address</InputLabel>
            <Select  {...methods.register('fromAddress')}
              label="Select Address"
              labelId="fromAddress-label"
              value={fromAddress}
              onChange={e => setFromAddress(e.target.value)}
            >
              {queryAddrs?.data?.length && queryAddrs?.data.map(item => (
                <MenuItem key={item.id} value={String(item.id)}>{item.name && `${item.name} - `}{item.address}</MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!formError?.fromAddress}>{formError?.fromAddress?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <Grid container
              spacing={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              <Grid item xs={6}>
                <FormLabel>Select a CSV file</FormLabel>
                <br />
                <FormLabel>It must consist of <b>address</b>,<b>amount</b>.</FormLabel>
              </Grid>
              <Grid item xs={6} textAlign={'right'}>
                <input
                  type={"file"}
                  id={"csvFileInput"}
                  accept={".csv"}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
          </FormControl>

          {csvDataObj.length > 0 && csvDataObj.map((item: IcsvItem, rowIndex) => (
            <FormControl fullWidth sx={{ mb: 2 }} key={`row-${rowIndex}`}>
              <Grid container
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Grid item xs={8}>
                  <TextField
                    {...methods.register(`toAddresses.${rowIndex}.address`)}
                    fullWidth
                    label={`Receive Address ${(rowIndex + 1)}`}
                    value={item.address}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    {...methods.register(`toAddresses.${rowIndex}.amount`, { valueAsNumber: true, setValueAs: v => parseFloat(v) })}
                    defaultValue={Number(item.amount.replace(/\D/g, ''))}
                    fullWidth
                    label={`Amount ${(rowIndex + 1)}`}
                    type='number'
                    inputProps={{
                      min: 0,
                      // max: 10,
                      // inputMode: 'numeric', 
                      // pattern: '[0-9]*',
                    }}
                  />
                </Grid>
                <Grid item xs={1} textAlign={'center'}>
                  <MyIconButtonDark sx={{}} onClick={() => deleteItem(item)}>
                    <Icon>close</Icon>
                  </MyIconButtonDark>
                </Grid>
              </Grid>
            </FormControl>
          ))}

          <Grid container justifyContent="center">
            <LoadingButton
              variant='contained'
              type='submit'
              loading={createItem.isLoading || queryAddrs.isFetching}
              disabled={!currencySymbol}
            >
              Send
            </LoadingButton>
          </Grid>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default AddBulkTransactionForm;