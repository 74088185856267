import React, { Fragment, useEffect, useState } from 'react';

import {
  Box,
  CardHeader,
  Card,
  List,
  ListItem as _ListItem,
  ListItemText as _ListItemText,
  ListItemAvatar as _ListItemAvatar,
  Typography,
  Divider,
  useTheme,
  Grid,
  Avatar,
  Icon,
  styled
} from '@mui/material';

import Text from 'components/Text';
import DataLoader from 'components/Loaders/DataLoader';
import { useFetch } from 'hooks/useFetch';
import { RES_STATUS } from 'models/Generic';
import { DB_Currency } from 'models/db/Currency';
import { DB_User } from 'models/db/User';
import { DB_Address } from 'models/db/Address';
import { DB_Transaction, TX_TYPES } from 'models/db/Transaction';
import { DB_Deposit } from 'models/db/Deposit';
import zionApi from 'api/zionApi';
import config from 'config';
import { formatBalance } from 'helpers/utils';

interface ISummaryResponse {
  status: RES_STATUS;
  assets: { count: number, data?: DB_Currency[] };
  users: { count: number, data?: DB_User[] };
  wallets: { count: number, data?: DB_Address[] };
  deposits: { count: number, data?: DB_Deposit[], total: number };
  commissions: { count: number, data?: DB_Deposit[], total: number };
  sweepers: { count: number, data?: DB_Transaction[], total: number };
  transactions: { count: number, data?: DB_Transaction[], total: number };
}

const ListItem = styled(_ListItem)(({ theme }) => ({
  padding: 18,
  alignItems: 'center',
}));
const ListItemText = styled(_ListItemText)(({ theme }) => ({

}));
const ListItemAvatar = styled(_ListItemAvatar)(({ theme }) => ({
  minWidth: 0,
  marginRight: 18,
  display: 'flex',
  alignItems: 'center',
  color: `${theme.colors.primary.main}`,
  '& .material-icons': {
    fontSize: `${theme.typography.pxToRem(23)}`
  }
}));

const Summary: React.FC<{}> = ({ }) => {
  const theme = useTheme();

  const { data: summary, error, loading, refetch, action, counter } = useFetch<ISummaryResponse>({
    callback: async () => {
      const response = await zionApi.get<ISummaryResponse>('admin/summary');
      return response.data;
    }
  })

  useEffect(() => {
    refetch();
  }, []);

  if (summary === undefined || action === 'fetching') {
    return <DataLoader sx={{ mt: 3 }} />;
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Typography variant='h3'>Summary Data</Typography>
        </Grid>

        <Grid item md={6} xs={12}>
          <Card variant='outlined'>
            <CardHeader disableTypography sx={{ p: 3 }}
              title={<Typography variant="h4" sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}>Summary</Typography>}
            />
            <Divider />

            <List sx={{ py: 0 }}>
              <ListItem>
                <ListItemAvatar>
                  <Icon className='material-icons'>people</Icon>
                </ListItemAvatar>
                <ListItemText primary={<Typography variant="h4">Total Users</Typography>} />
                <Box alignSelf="center">
                  <Typography variant="subtitle2">
                    <Text color="success">{summary.users.count}</Text>
                  </Typography>
                </Box>
              </ListItem>

              <Divider />

              <ListItem>
                <ListItemAvatar>
                  <Icon className='material-icons'>currency_bitcoin</Icon>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="h4">Total Assets</Typography>}
                // secondary={<Typography noWrap variant="subtitle2">Total Assets</Typography>}
                />
                <Box alignSelf="center">
                  <Typography variant="subtitle2">
                    <Text color="success">{summary.assets.count}</Text>
                  </Typography>
                </Box>
              </ListItem>

              <Divider />

              <ListItem>
                <ListItemAvatar>
                  <Icon className='material-icons-outlined'>request_quote</Icon>
                </ListItemAvatar>
                <ListItemText primary={<Typography variant="h4">Total Addresses</Typography>} />
                <Box alignSelf="center">
                  <Typography variant="subtitle2">
                    <Text color="success">{summary.wallets.count}</Text>
                  </Typography>
                </Box>
              </ListItem>

              <Divider />

              <ListItem>
                <ListItemAvatar>
                  <Icon className='material-icons-outlined'>move_down</Icon>
                </ListItemAvatar>
                <ListItemText primary={<Typography variant="h4">Total Deposits ({summary.deposits.count})</Typography>} />
                <Box alignSelf="center">
                  <Typography variant="subtitle2">
                    <Text color="success">{formatBalance(summary.deposits.total)} USDT</Text>
                  </Typography>
                </Box>
              </ListItem>

              <Divider />

              <ListItem>
                <ListItemAvatar>
                  <Icon className='material-icons-outlined'>local_atm</Icon>
                </ListItemAvatar>
                <ListItemText primary={<Typography variant="h4">Total Commissions ({summary.commissions.count})</Typography>} />
                <Box alignSelf="center">
                  <Typography variant="subtitle2">
                    <Text color="success">{formatBalance(summary.commissions.total)} USDT</Text>
                  </Typography>
                </Box>
              </ListItem>

              <Divider />

              <ListItem>
                <ListItemAvatar>
                  <Icon className='material-icons-outlined'>currency_exchange</Icon>
                </ListItemAvatar>
                <ListItemText primary={<Typography variant="h4">Total Transactions ({summary.transactions.count})</Typography>} />
                <Box alignSelf="center">
                  <Typography variant="subtitle2">
                    <Text color="success">{formatBalance(summary.transactions.total)} USDT</Text>
                  </Typography>
                </Box>
              </ListItem>

              <Divider />

              <ListItem>
                <ListItemAvatar>
                  <Icon className='material-icons-outlined'>manage_history</Icon>
                </ListItemAvatar>
                <ListItemText primary={<Typography variant="h4">Total Sweep Rules ({summary.sweepers.count})</Typography>} />
                <Box alignSelf="center">
                  <Typography variant="subtitle2">
                    <Text color="success">{formatBalance(summary.sweepers.total)} USDT</Text>
                  </Typography>
                </Box>
              </ListItem>
            </List>
          </Card>
        </Grid>

        <Grid item md={6} xs={12}>
          <Card variant="outlined">
            <CardHeader disableTypography sx={{ p: 3 }}
              title={<Typography variant="h4" sx={{ fontSize: `${theme.typography.pxToRem(16)}` }}>Recent Transactions</Typography>}
            // action={
            //   <Button
            //     size="small"
            //     variant="text"
            //     endIcon={<ExpandMoreTwoToneIcon />}
            //     color="secondary"
            //     sx={{
            //       backgroundColor: `${theme.colors.secondary.lighter}`,
            //       '&:hover': {
            //         backgroundColor: `${theme.colors.secondary.main}`,
            //         color: `${theme.palette.getContrastText(theme.colors.secondary.main)}`
            //       }
            //     }}
            //   >
            //     Date
            //   </Button>
            // }
            />
            <Divider />

            {summary.transactions?.data?.length && (
              <List sx={{ py: 0 }}>
                {summary.transactions?.data?.map((transaction, txIndex) => (
                  <Fragment key={txIndex}>
                    <ListItem sx={{ py: 0.96 }}>
                      <ListItemAvatar>
                        <Avatar />
                      </ListItemAvatar>

                      <ListItemText
                        primary={<Typography color="text.primary" variant="h5">{transaction.user.name}</Typography>}
                        secondary={<Typography variant="subtitle2" noWrap>{transaction.txType}</Typography>}
                      />

                      <Box display="flex" alignItems="center">
                        <Typography variant="subtitle2" sx={{ pr: 0.5 }}>
                          <Text color="success">{formatBalance(transaction.amount)} {transaction.currency.symbol}</Text>
                        </Typography>
                        <Icon className='material-icons-outlined' sx={{
                          color: `${[TX_TYPES.DEPOSIT, TX_TYPES.COMMISSION].includes(transaction.txType) ? theme.colors.success.main : theme.colors.warning.main}`
                        }}>{[TX_TYPES.DEPOSIT, TX_TYPES.COMMISSION].includes(transaction.txType) ? 'keyboard_double_arrow_up' : 'currency_exchange'}</Icon>
                      </Box>
                    </ListItem>

                    {Number(summary.transactions.data?.length) > (txIndex + 1) && <Divider />}
                  </Fragment>
                ))}
              </List>
            )}

          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Summary;
