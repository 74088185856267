import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getUserAssets, getUserWallets } from 'api/walletApi';

import Message from 'components/Message';
import SuspenseLoader from 'components/SuspenseLoader';

import {
  styled,
  Container,
  Card,
  Grid,
  Avatar,
  Tooltip,
  CardActionArea,
  CardContent,
  Box,
} from '@mui/material';

import { AddTwoTone as AddTwoToneIcon } from '@mui/icons-material';

import PageTitleWrapper from 'components/PageTitleWrapper';
import PageHeader from './PageHeader';
import Wallet from './Wallet';

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[10]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);

const CardAddAction = styled(Card)(
  ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100%;
        color: ${theme.colors.primary.main};
        transition: ${theme.transitions.create(['all'])};
        
        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[70]};
        }
`
);

const Wallets = () => {
  const navigate = useNavigate();

  const wallets = useQuery(['wallets'], () => getUserWallets());
  const assets = useQuery(['assets'], () => getUserAssets());

  if (wallets.isLoading) {
    return <SuspenseLoader />;
  }

  // console.log({ wallets });

  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {wallets.data?.data.length ? wallets.data?.data.map((item) => <Wallet key={item.id} walletItem={item} />) : (
            <Box maxWidth='sm' sx={{ mx: 'auto', py: '5rem' }}>
              <Message type='info' title='Info'>
                No data at the moment
              </Message>
            </Box>
          )}

          {Number(wallets.data?.data.length) < 4 && (
            <Grid xs={12} sm={6} md={3} item>
              <Tooltip arrow title="Click to add a new wallet">
                <CardAddAction onClick={() => navigate('/dashboard/addresses')}>
                  <CardActionArea sx={{ px: 1 }}>
                    <CardContent>
                      <AvatarAddWrapper>
                        <AddTwoToneIcon fontSize="large" />
                      </AvatarAddWrapper>
                    </CardContent>
                  </CardActionArea>
                </CardAddAction>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default Wallets;
