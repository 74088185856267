import { Box, FormControl, FormHelperText, Grid, Icon, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

import { type ChangeEvent, type HTMLInputTypeAttribute, useState } from 'react';
import { type Filter } from 'components/DataTable/filter/Filter';
import {
  type FilterType,
  getFilterType,
  type FormValueType,
  createNewFilter,
  filterValuesLengthForFilterType,
  getValuesForFilterType,
  fieldTypeToFilterTypes,
  FilterParams,
} from 'components/DataTable/filter/FilterGroup';
import { MyIconButton, MyIconButtonDark } from 'components/Buttons/Index';
import { LoadingButton } from 'components/Buttons/LoadingButton';
import { SortTuple } from './filter/FilterGroup';
import { GenericField } from './filter/FilterType';
import DateRangeInput from 'components/DataTable/DateRangeInput';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface FilterTextInputProps {
  index: number;
  icon?: string;
  label?: string;
  type: HTMLInputTypeAttribute;
  className?: string;
  placeholder?: string;
  filterValues: FormValueType[];
  iconHandleClick?: (e: any) => void;
  setFilterValues: (filterValues: FormValueType[]) => void;
}

/**
 * FilterTextInput is a generic input component that can be used for text-input filter types.
 * It calls a parent component's stateful function to update the filter value at the given index.
 */
const FilterTextInput = (props: FilterTextInputProps): React.JSX.Element => {
  return (
    <>
      <FormControl fullWidth variant="outlined">
        {props.label && (<InputLabel size="small">{props.label}</InputLabel>)}
        <OutlinedInput
          size="small"
          type={props.type}
          label={props.placeholder}
          value={props.filterValues[props.index]}
          onChange={(e) => {
            // copy filter values and update the value at the index,
            const newFilterValues = [...props.filterValues];
            newFilterValues[props.index] = e.target.value;
            // then update it in the stateful parent component
            props.setFilterValues(newFilterValues);
          }}
        // endAdornment={props.icon && (
        //   <InputAdornment position="end">
        //     <MyIconButton sx={{ mr: -2 }}
        //       edge="end"
        //       aria-label="remove option"
        //       onClick={(e) => {
        //         if (typeof props.iconHandleClick === 'function') {
        //           props.iconHandleClick(props.icon === 'close' ? null : e);
        //         }
        //       }}
        //     >
        //       <Icon>{props.icon}</Icon>
        //     </MyIconButton>
        //   </InputAdornment>
        // )}
        />
      </FormControl>
    </>
  );
};

interface FilterInputProps<T> {
  filter: Filter;
  filterValues: FormValueType[];
  setFilter?: (filter: Filter | null) => void;
  setFilterValues: (filterValues: FormValueType[]) => void;
  field: GenericField<T> | undefined;
}

/**
 * FilterInput is a generic component that renders the correct input component for a given filter type.
 * Most filter types are rendered by FilterTextInput, but Range and Boolean filters are rendered by their own components.
 */
const FilterInput = <T,>(props: FilterInputProps<T>): React.JSX.Element => {
  const filterType = getFilterType(props.filter);
  switch (filterType) {
    case 'Date': {
      const [value, setValue] = React.useState<Dayjs | null>(dayjs());
      React.useEffect(() => {
        const newFilterValues = [...props.filterValues];
        if (value) newFilterValues[0] = value.toDate();
        props.setFilterValues(newFilterValues);
      }, [value])

      return (
        <Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="column" spacing={1} justifyContent="space-between">
              <MobileDatePicker
                showToolbar={false}
                showDaysOutsideCurrentMonth={true}
                views={["year", "month", "day"]}
                inputFormat="DD/MM/YYYY"
                label={props.field?.title}
                value={value}
                closeOnSelect={true}
                onChange={setValue}
                minDate={dayjs().subtract(5, 'year')}
                maxDate={dayjs()}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    helperText={null}
                    onKeyDown={e => e.preventDefault()}
                    {...params}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Stack>
      )
    }
    case 'DateRange': {
      return (
        <DateRangeInput
          values={[dayjs().subtract(1, "day").toDate(), dayjs().toDate()]}
          onChangeFn={(value) => {
            const newFilterValues = [...props.filterValues];
            if (value[0]) newFilterValues[0] = value[0];
            if (value[1]) newFilterValues[1] = value[1];
            props.setFilterValues(newFilterValues);
          }}
        />
      )
    }
    case 'Range': {
      // Specify strict inequality according to filter type
      props.filterValues[1] = false;
      props.filterValues[3] = false;
      return (
        <>
          <FilterTextInput
            setFilterValues={props.setFilterValues}
            filterValues={props.filterValues}
            index={0}
            type='number'
            label='Lower'
          />
          <FilterTextInput
            setFilterValues={props.setFilterValues}
            filterValues={props.filterValues}
            index={2}
            type='number'
            label='Upper'
          />
        </>
      );
    }
    case 'GreaterThan':
    case 'LessThan':
      // Specify strict inequality according to filter type
      props.filterValues[1] = false;
      return (
        <FilterTextInput
          setFilterValues={props.setFilterValues}
          filterValues={props.filterValues}
          index={0}
          type='number'
        />
      );

    case 'NotEqual':
    case 'Like':
    case 'Equal':
      if (
        props.field?.type === 'select' &&
        getValuesForFilterType(props.filter)[0] === ''
      ) {
        const options = props.field?.selectOptions ?? ['undefined'];
        props.setFilterValues([options[0]]);
      }
      return props.field?.type === 'select' ? (
        // if select, render a select input
        <FormControl fullWidth>
          <Select
            size='small'
            value={props.filterValues[0].toString()}
            onChange={(e) => {
              const newFilterValues = [...props.filterValues];
              newFilterValues[0] = e.target.value;
              props.setFilterValues(newFilterValues);
            }}
          >
            {props.field?.selectOptions?.map((option, i) => (
              <MenuItem key={i} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        // otherwise render a text input
        <FilterTextInput
          index={0}
          type='text'
          label={props.field?.title}
          placeholder={props.field?.title}
          icon='close'
          iconHandleClick={props.setFilter}
          setFilterValues={props.setFilterValues}
          filterValues={props.filterValues}
        />
      );
  }
  return <div></div>;
};

interface FilterEntryProps<T> {
  index: number;
  filter: Filter;
  field: GenericField<T>;
  setFilter: (filter: Filter | null) => void;
}

/**
 * FilterEntry is a component that renders a single filter entry, which consists of a filter type selector and the corresponding filter value inputs (if any).
 * For example, a filter entry for 'Equal' would render a single text box or select input depending on the field.
 * A filter entry for 'True' would render only render the filter type selector and no filter value inputs.
 *
 * The filter is set by calling the setFilter callback in a stateful parent component.
 * It also renders a button to remove the filter.
 */
const FilterEntry = <T,>(props: FilterEntryProps<T>): React.JSX.Element => {
  // console.log({ props });

  const [filterValues, setLocalFilterValues] = useState<FormValueType[]>(
    getValuesForFilterType(props.filter)
  );

  const setFilterValues = (
    filterType: Filter,
    values: FormValueType[]
  ): void => {
    const name = getFilterType(filterType);
    const newFilterType = createNewFilter(name, values);
    setLocalFilterValues(values);
    props.setFilter(newFilterType);
  };

  const changeFilterType = (e: SelectChangeEvent): void => {
    const valueLen =
      filterValuesLengthForFilterType[e.target.value as FilterType];
    const newFilterValues = [];
    while (newFilterValues.length < valueLen) {
      newFilterValues.push('');
    }
    const newFilterType = createNewFilter(
      e.target.value as FilterType,
      newFilterValues
    );
    setLocalFilterValues(newFilterValues);
    props.setFilter(newFilterType);
  };

  return (
    <Grid
      key={props.index}
      container
      display="flex"
      alignItems="center"
      flexDirection="row"
      flexWrap="nowrap"
    >
      <Grid sx={{ flexGrow: 1, position: 'relative' }}>
        <Typography
          sx={{
            top: 9,
            left: -24,
            position: 'absolute',
            display: (props.index > 0 ? '' : 'none'),
          }}>
          OR
        </Typography>

        <Grid container>
          <Grid item xs={4}>
            <FormControl fullWidth sx={{ minWidth: 100 }}>
              <Select
                size='small'
                onChange={changeFilterType}
                value={getFilterType(props.filter)}>
                {fieldTypeToFilterTypes[props.field.type].map((name, i) => (
                  <MenuItem key={i} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={8}>
            <Box sx={{ ml: 1 }}>
              <FilterInput
                filter={props.filter}
                setFilter={props.setFilter}
                field={props.field}
                filterValues={filterValues}
                setFilterValues={(filterValues) => {
                  setFilterValues(props.filter, filterValues);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ ml: 1 }}>
        <MyIconButton sx={{}} onClick={() => { props.setFilter(null); }}>
          <Icon>close</Icon>
        </MyIconButton>
      </Grid>
    </Grid>
  );
};

export interface ColumnFilterProps<T> {
  className?: string;
  field: GenericField<T>;
  columnFilters: Filter[];
  setColumnFilters: (filters: Filter[]) => void;
  filterApply?: (params?: FilterParams<T>) => void;
}

/**
 * ColumnFilter is a component that displays all the filters for a single column.
 *
 * It renders a list of filters (the filter type selector and corresponding data inputs), and a button to add a new filter.
 * The field prop is optional because the parent component may pass in a null field if no column is currently being filtered.
 */
export const ColumnFilter = <T,>(props: ColumnFilterProps<T>): React.JSX.Element => {
  const addFilter = (): void => {
    if (props.field === undefined) {
      return;
    }
    // get the first filter type for the field type and create a new filter with that type
    const firstFilterType = fieldTypeToFilterTypes[props.field.type][0];
    const filterValuesLen = filterValuesLengthForFilterType[firstFilterType];
    props.setColumnFilters([
      ...props.columnFilters,
      createNewFilter(
        firstFilterType,
        Array<FormValueType>(filterValuesLen).fill('')
      ),
    ]);
  };

  return (
    <>
      {props.field !== undefined ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          {props.columnFilters.map((filterType, i) => (
            <Grid
              key={i}
              container
              sx={{ mb: 1, }}
            >
              {/* <Box sx={{ display: (i === 0 ? 'none' : ''), position: 'absolute', top: 10, left: -24 }}>
                OR
              </Box> */}

              <FilterEntry
                key={i}
                index={i}
                filter={filterType}
                field={props.field}
                setFilter={(filterType) => {
                  const newFilters = [...props.columnFilters];
                  if (filterType === null) {
                    newFilters.splice(i, 1);
                  } else {
                    newFilters[i] = filterType;
                  }
                  props.setColumnFilters(newFilters);
                }}
              />
            </Grid>
          ))}

          <Grid container display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
            <MyIconButtonDark onClick={addFilter}>
              <Icon sx={{ fontSize: 24 }}>add</Icon>
            </MyIconButtonDark>

            <Grid>
              {props.filterApply && (
                <>
                  <LoadingButton
                    sx={{ ml: 1 }}
                    variant='contained'
                    loading={false}
                    disabled={false}
                    onClick={(e) => {
                      if (typeof props.filterApply === 'function') {
                        const newFilters = props.columnFilters;
                        newFilters.splice(0)
                        props.setColumnFilters(newFilters);
                        props.filterApply()
                      }
                    }}
                  >
                    CLEAR
                  </LoadingButton>

                  <LoadingButton
                    sx={{ ml: 1 }}
                    variant='contained'
                    loading={false}
                    disabled={false}
                    onClick={(e) => {
                      if (typeof props.filterApply === 'function') {
                        props.filterApply()
                      }
                    }}
                  >
                    APPLY
                  </LoadingButton>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      ) : (<></>)}
    </>
  );
};

interface FilterModalBoxProps<T> {
  field: GenericField<T> | undefined;
  columnFilters: Filter[];
  setColumnFilters: (filters: Filter[]) => void;
}

/**
 * The ColumnFilterModalBox is a modal box wrapper around ColumnFilter that is rendered when a column filter is focused.
 * This is done by passing in a non-undefined field value.
 * Otherwise, the modal box is not rendered.
 */
export const ColumnFilterModalBox = <T,>(
  props: FilterModalBoxProps<T>
): React.JSX.Element => {
  return (
    <>
      {props.field !== undefined && (
        <ColumnFilter
          field={props.field}
          columnFilters={props.columnFilters}
          setColumnFilters={props.setColumnFilters}
        />
      )}
    </>
  );
};

export interface SearchBoxFilterProps<T> {
  field: GenericField<T>;
  columnFilters: Filter[];
  searchValue: string;
  setColumnFilters: (filters: Filter[]) => void;
  filterApply: (params?: FilterParams<T>) => void;
}

export const SearchBoxFilter = <T,>(props: SearchBoxFilterProps<T>): React.JSX.Element => {
  const addFilter = (defaultValue: FormValueType = ''): void => {
    if (props.field === undefined) {
      return;
    }

    // get the first filter type for the field type and create a new filter with that type
    const filterType = 'Like'; // fieldTypeToFilterTypes['text'][0];
    const filterValuesLen = filterValuesLengthForFilterType[filterType];
    props.setColumnFilters([
      ...props.columnFilters,
      createNewFilter(
        filterType,
        Array<FormValueType>(filterValuesLen).fill(defaultValue)
      ),
    ]);
  };

  React.useEffect(() => {
    addFilter(props.searchValue)
  }, [props.searchValue])

  return (
    <>
      {props.field !== undefined ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          {props.columnFilters.map((filterType, i) => (
            <Grid
              key={i}
              container
              sx={{ mb: 1, }}
            >
              {/* <Box sx={{ display: (i === 0 ? 'none' : ''), position: 'absolute', top: 10, left: -24 }}>
                OR
              </Box> */}

              <FilterEntry
                key={i}
                index={i}
                filter={filterType}
                field={props.field}
                setFilter={(filterType) => {
                  const newFilters = [...props.columnFilters];
                  if (filterType === null) {
                    newFilters.splice(i, 1);
                  } else {
                    newFilters[i] = filterType;
                  }
                  props.setColumnFilters(newFilters);
                }}
              />
            </Grid>
          ))}
        </Box>
      ) : (<></>)}
    </>
  );
};