import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { CryptoAvatar as _CryptoAvatar } from 'components/Avatar';
import { styled } from "@mui/system";

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import {
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { getAllItemsFn as getAddress } from 'api/addrApi';
import { DB_Transaction, TX_TYPES } from 'models/db/Transaction';
import { GenericResponse } from 'models/Generic';
import zionApi from 'api/zionApi';
import { CRYPTO_SYMBOL, DB_Currency } from 'models/db/Currency';
import { MyFormHelperText } from 'components/Forms/MyFormHelper';
import { useFetch } from 'hooks/useFetch';
import { getCurrencies } from 'models/api/Currency';
import { formatBalance } from 'helpers/utils';

const CryptoAvatar = styled(_CryptoAvatar)(({ theme }) => ({
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  height: theme.spacing(3.2),
  width: theme.spacing(3.2),
  img: {
    padding: theme.spacing(0),
    height: theme.spacing(3),
    width: theme.spacing(3),
  }
}));

interface ICreateItemProp {
  setRefetch?: Dispatch<SetStateAction<number>>;
  setOpenCrudModal: (openCrudModal: boolean) => void;
  data: {
    price: number;
    amount: number;
    fromCurrency: string;
    toCurrency: string;
    txType: TX_TYPES.BUYING_CRYPTO | TX_TYPES.SELLING_CRYPTO
  };
}

const createItemSchema = z.object({
  amount: z.number(),
  txType: z.union([
    z.literal(TX_TYPES.BUYING_CRYPTO),
    z.literal(TX_TYPES.SELLING_CRYPTO),
  ], {
    required_error: 'TransactionType is not valid',
  }),
  currencySymbol: z.nativeEnum(CRYPTO_SYMBOL),
  targetAddress: z.string().min(1, 'TargetAddress is required'),
  mainAsset: z.string().min(1, 'MainAsset is required'),
  cryptoAsset: z.string().min(1, 'CryptoAsset is required'),
});

export type TCreateItem = z.TypeOf<typeof createItemSchema>;

const ConvertTxForm: FC<ICreateItemProp> = ({ setRefetch, setOpenCrudModal, ...props }) => {
  const [currencySymbol, setCurrencySymbol] = React.useState<CRYPTO_SYMBOL>();
  const [targetAddress, setTargetAddress] = React.useState<string>('');

  const [currencies, setCurrencies] = React.useState<DB_Currency[]>([]);
  const currencyData = useFetch({ callback: async () => await getCurrencies() })

  const currencyWallets = useQuery({
    enabled: false,
    queryKey: ['currencyWallets'],
    queryFn: () => getAddress(currencySymbol),
    select: (result) => result.data,
    onSuccess(data) { }
  });

  // Mutations
  const createItem = useMutation({
    mutationFn: async (inputData: TCreateItem) => {
      const response = await zionApi.post<GenericResponse<DB_Transaction>>(`transactions/convert`, inputData);
      return response.data;
    },
    onSuccess: () => {
      if (typeof setRefetch === 'function') setRefetch(c => ++c)
      notification.success('Transaction created successfully');
      setOpenCrudModal(false);
      methods.reset();
    }
  })

  const methods = useForm<TCreateItem>({ resolver: zodResolver(createItemSchema) });

  const { formState: { errors: formError, isSubmitSuccessful } } = methods;

  if (Object.keys(formError).length) {
    for (const err of Object.entries(formError)) {
      notification.error(`${err[0]}: ${err[1].message}`)
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      // methods.reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<TCreateItem> = (formValues) => {
    console.log('formValues', formValues);
    createItem.mutate(formValues);
  };

  const handleChangeCurrency = async (event: SelectChangeEvent) => {
    setCurrencySymbol(event.target.value as CRYPTO_SYMBOL)
  };

  useEffect(() => {
    setTargetAddress('');
    methods.setValue("targetAddress", '');

    methods.setValue("txType", props.data.txType);
    methods.setValue("amount", props.data.amount);
    methods.setValue("mainAsset", "TRY");
    methods.setValue("cryptoAsset", "USDT");

    if (currencySymbol) {
      ; (async () => await currencyWallets.refetch())();
    }
  }, [currencySymbol])

  useEffect(() => {
    if (currencyData.action === 'ready') {
      currencyData.refetch()
    }

    if (currencyData.data?.data) {
      setCurrencies(currencyData.data.data)
    }
  }, [currencyData.action])

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Convert : {props.data.fromCurrency}-{props.data.toCurrency}
        </Typography>

        <CloseButton loading={createItem.isLoading || currencyWallets.isFetching}
          onClick={() => setOpenCrudModal(false)}>
          <Icon>close</Icon>
        </CloseButton>
      </Box>

      <FormProvider {...methods}>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              disabled
              label={"Quantity to Buy"}
              fullWidth
              value={Number(props.data.price * props.data.amount)}
              type="number"
              InputProps={{
                type: "number",
                endAdornment: <InputAdornment position="end">
                  <CryptoAvatar src={`/icons/${props.data.toCurrency.toLowerCase()}.svg`} />
                </InputAdornment>
              }}
            />

            <FormHelperText error={!!formError?.amount}>{formError?.amount?.message}</FormHelperText>
          </FormControl>

          {currencies.length > 0 && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="currencySymbol-label">Select Wallet</InputLabel>

              <Select {...methods.register('currencySymbol')}
                label="Select Wallet"
                labelId="currencySymbol-label"
                value={currencySymbol}
                onChange={handleChangeCurrency}
              >
                {currencies.map(curr => <MenuItem key={curr.id} value={curr.symbol}>{curr.name} Wallet</MenuItem>)}
              </Select>

              <MyFormHelperText error={!!formError.currencySymbol} message={formError.currencySymbol?.message} />
            </FormControl>
          )}

          <FormControl fullWidth sx={{ mb: 2, }} disabled={!currencyWallets.data?.length}>
            <InputLabel id="targetAddress-label">Select Address</InputLabel>
            <Select  {...methods.register('targetAddress')}
              label="Select Address"
              labelId="targetAddress-label"
              value={targetAddress}
              onChange={e => setTargetAddress(e.target.value)}
              sx={{
                '&>.MuiSelect-select': {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                },
                '& .hide': {
                  display: 'none'
                }
              }}
            >
              <ListSubheader sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
                <Typography fontWeight="bold" sx={{ width: '100px' }}>Name</Typography>
                <Typography fontWeight="bold" sx={{ width: '80%' }}>Address</Typography>
                <Typography fontWeight="bold" sx={{ width: '100px' }}>Balance</Typography>
              </ListSubheader>

              {currencyWallets?.data?.length && currencyWallets?.data.map(wallet => (
                <MenuItem key={wallet.id} value={String(wallet.id)} sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                  <Typography sx={{ width: '100px' }}>{wallet.name?.trim() || '-'}</Typography>
                  <Typography sx={{ width: '80%' }}>{wallet.address}</Typography>
                  <Typography sx={{ width: '100px' }}>
                    {/* .filter(item => item.symbol === currencySymbol) */}
                    {wallet.currencyBalances.map(item => <>{`${formatBalance(item.balance)} ${item.symbol}`}</>)}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!formError?.targetAddress}>{formError?.targetAddress?.message}</FormHelperText>
          </FormControl>

          {/* <FormControl fullWidth sx={{ mb: 2 }}>
            <Controller
              name="amount"
              control={methods.control}
              defaultValue={amount}
              render={({ field: { onChange: fieldOnChange, ref, ...field } }) => (
                <TextField
                  {...field}
                  label='Amount'
                  fullWidth
                  value={amount}
                  type="number"
                  onChange={(e) => {
                    setAmount(Math.abs(parseFloat(e.target.value)))
                    if (!Number.isNaN(e.target.value)) {
                      fieldOnChange(e)
                    }
                  }}
                />
              )}
            />

            <FormHelperText error={!!formError?.amount}>{formError?.amount?.message}</FormHelperText>
          </FormControl> */}

          {/* <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label='Receive Address'
              fullWidth
              {...methods.register('toAddress')}
            />
            <FormHelperText error={!!formError?.toAddress}>{formError?.toAddress?.message}</FormHelperText>
          </FormControl> */}

          <Grid container justifyContent="center">
            <LoadingButton
              variant='contained'
              type='submit'
              loading={createItem.isLoading || currencyWallets.isFetching}
              disabled={!currencySymbol}
            // disabled
            >
              Send
            </LoadingButton>
          </Grid>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default ConvertTxForm;