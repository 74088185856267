import React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { getFilteredSweepers } from "models/api/Sweeper";
import { cols_Sweeper, fields_Sweeper } from "./definitions";
import { nameMapping_Sweeper } from "models/db/Sweeper";

export default function SweepersTab(): React.JSX.Element {
  return (
    <>
      <Helmet><title>All Sweepers</title></Helmet>

      <DataTable
        title='Sweepers'
        dataName='sweeper'
        rowsPerPage={25}
        cols={cols_Sweeper}
        fields={fields_Sweeper}
        settings={{ toolbar: { export: 'excel' } }}
        nameMapping={nameMapping_Sweeper}
        getFilteredRecords={getFilteredSweepers}
      />
    </>
  );
}