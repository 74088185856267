import { Dispatch, FC, SetStateAction, useEffect, useReducer } from 'react';
import { pickBy } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { createUserFn } from 'api/userApi';

import { DB_USER_STATUS, DB_UserPermission, EMemberRoleEnumType } from 'models/db/User';

interface ICreateUserProp {
  setRefetch: Dispatch<SetStateAction<number>>;
  setOpenCrudModal: (openCrudModal: boolean) => void;
}

const createUserSchema = z.object({
  status: z.nativeEnum(DB_USER_STATUS),
  name: z.string().min(1, 'Full name is required').max(50),
  email: z.string()
    .min(1, 'Email address is required')
    .email('Email Address is invalid'),
  password: z.string()
    .min(1, 'Password is required')
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  passwordConfirm: z.string().min(1, 'Please confirm your password'),
  // image: z.instanceof(File),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ['passwordConfirm'],
  message: 'Passwords do not match',
});

export type TCreateUser = z.TypeOf<typeof createUserSchema>;

const MemberAddForm: FC<ICreateUserProp> = ({ setRefetch, setOpenCrudModal }) => {
  const { isLoading, mutate: createUser } = useMutation(
    (item: FormData) => createUserFn(item),
    {
      onSuccess: () => {
        setRefetch(c => ++c)
        notification.success('User created successfully');
        setOpenCrudModal(false);
      }
    }
  );

  const methods = useForm<TCreateUser>({
    resolver: zodResolver(createUserSchema)
  });

  const {
    formState: { errors: formErrors, isSubmitting, isSubmitSuccessful },
  } = methods;

  if (Object.keys(formErrors).length) {
    console.log({ formErrors });
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      // methods.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const [userPermission, setUserPermission] = useReducer((state: DB_UserPermission, newState: Partial<DB_UserPermission>) => {
    return { ...state, ...newState, };
  }, {
    role: EMemberRoleEnumType.VIEWER,
    read: true,
    write: false,
    update: false,
    delete: false,
    readAll: true,
  });

  const onSubmitHandler: SubmitHandler<TCreateUser> = (values) => {
    const formData = new FormData();
    const filteredFormData = pickBy(values, (value) => value !== '' && value !== undefined);
    const { image, ...otherFormData } = filteredFormData;
    // if (image) {
    //   formData.append('image', image);
    // }

    if (userPermission) {
      Object.assign(otherFormData, {
        permissions: {
          role: userPermission.role,
          read: userPermission.read,
          write: userPermission.write,
          update: userPermission.update,
          delete: userPermission.delete,
          readAll: userPermission.readAll,
        }
      })
    }

    formData.append('data', JSON.stringify(otherFormData));
    createUser(formData);
  };

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Add User
        </Typography>

        <CloseButton loading={isLoading} onClick={() => setOpenCrudModal(false)}>
          <Icon>close</Icon>
        </CloseButton>
      </Box>

      <FormProvider {...methods}>
        <Box
          noValidate
          component='form'
          autoComplete='on'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label='Name'
              {...methods.register('name')}
            />
            <FormHelperText error={!!formErrors?.name}>{formErrors?.name?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label='Email'
              {...methods.register('email')}
            />
            <FormHelperText error={!!formErrors?.email}>{formErrors?.email?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField label='Password'
              {...methods.register('password')}
            />
            <FormHelperText error={!!formErrors?.password}>{formErrors?.password?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField label='Password Confirm'
              {...methods.register('passwordConfirm')}
            />
            <FormHelperText error={!!formErrors?.passwordConfirm}>{formErrors?.passwordConfirm?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="status-label">Select Status</InputLabel>
            <Controller
              name="status"
              control={methods.control}
              defaultValue={DB_USER_STATUS['ACTIVE']}
              render={({ field: { onChange: fieldOnChange, ...field } }) => (
                <Select {...field}
                  label="Select Status"
                  labelId="status-label"
                  onChange={e => fieldOnChange(e)}
                >
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="SUSPEND">SUSPEND</MenuItem>
                  {/* <MenuItem value="DELETED">DELETED</MenuItem> */}
                </Select>
              )}
            />

            <FormHelperText error={!!formErrors?.status}>{formErrors?.status?.message}</FormHelperText>
          </FormControl>

          {/* <FormControl fullWidth sx={{ mb: 4 }}>
            <FileUpLoader name='image' />
          </FormControl> */}

          <FormControl fullWidth sx={{ mb: 4 }}>
            <InputLabel id="role-label">Select Role</InputLabel>
            <Select
              label='Select Role'
              labelId='role-label'
              name='role'
              value={userPermission.role}
              onChange={e => setUserPermission({ [e.target.name]: e.target.value })}
            >
              <MenuItem value="viewer">viewer</MenuItem>
              <MenuItem value="modarator">modarator</MenuItem>
            </Select>
          </FormControl>

          {/* <FormControl fullWidth sx={{ mb: 4 }}>
            <FormLabel>User Permission</FormLabel>
            <List>
              <ListItem sx={{ pt: 1, pb: 1 }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                  secondaryTypographyProps={{ variant: 'subtitle1', lineHeight: 1 }}
                  primary="Read"
                  secondary="Read single item"
                />
                <Switch
                  color="primary"
                  checked={userPermission.read}
                  onChange={e => setUserPermission({ [e.target.name]: e.target.checked })}
                  name="read"
                />
              </ListItem>
              <Divider component="li" sx={{ ml: 2, mr: 2 }} />
              <ListItem sx={{ pt: 1, pb: 1 }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                  secondaryTypographyProps={{ variant: 'subtitle1', lineHeight: 1 }}
                  primary="Read All"
                  secondary="Read items"
                />
                <Switch
                  color="primary"
                  checked={userPermission.readAll}
                  onChange={e => setUserPermission({ [e.target.name]: e.target.checked })}
                  name="readAll"
                />
              </ListItem>
              <Divider component="li" sx={{ ml: 2, mr: 2 }} />
              <ListItem sx={{ pt: 1, pb: 1 }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                  secondaryTypographyProps={{ variant: 'subtitle1', lineHeight: 1 }}
                  primary="Write"
                  secondary="Write item"
                />
                <Switch
                  color="primary"
                  checked={userPermission.write}
                  onChange={e => setUserPermission({ [e.target.name]: e.target.checked })}
                  name="write"
                />
              </ListItem>
              <Divider component="li" sx={{ ml: 2, mr: 2 }} />
              <ListItem sx={{ pt: 1, pb: 1 }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                  secondaryTypographyProps={{ variant: 'subtitle1', lineHeight: 1 }}
                  primary="Update"
                  secondary="Update item"
                />
                <Switch
                  color="primary"
                  checked={userPermission.update}
                  onChange={e => setUserPermission({ [e.target.name]: e.target.checked })}
                  name="update"
                />
              </ListItem>
              <Divider component="li" sx={{ ml: 2, mr: 2 }} />
              <ListItem sx={{ pt: 1, pb: 1 }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                  secondaryTypographyProps={{ variant: 'subtitle1', lineHeight: 1 }}
                  primary="Delete"
                  secondary="Delete item"
                />
                <Switch
                  color="primary"
                  checked={userPermission.delete}
                  onChange={e => setUserPermission({ [e.target.name]: e.target.checked })}
                  name="delete"
                />
              </ListItem>
            </List>
          </FormControl> */}

          <LoadingButton
            fullWidth
            type='submit'
            variant='contained'
            loading={isLoading}
          >
            Save User
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default MemberAddForm;