import dayjs from "dayjs";
import { ColumnDefinitionType } from "components/DataTable/Table";
import { DB_User, UserFieldName } from "models/db/User";
import { getStatusLabel } from "components/Label/StatusLabel";
import { DB_STATUS } from "models/Generic";
import { GenericField } from "components/DataTable/filter/FilterType";
import { Icon } from "@mui/material";
import actionsCell from "./actionsCell";

export const cols_User: Array<ColumnDefinitionType<DB_User>> = [
    // { key: 'id', header: 'ID' },
    {
        key: 'name',
        header: 'Name',
    },
    {
        key: 'email',
        header: 'Email',
    },
    {
        key: 'role',
        header: 'Role',
        cell: (item) => item.permission.role,
    },
    {
        key: 'status',
        header: 'Status',
        cell: (item) => getStatusLabel(item.status),
    },
    {
        key: 'verified',
        header: 'Verified',
        cell: (item) => {
            return (
                <Icon color={item.verified ? 'success' : 'warning'}>{item.verified ? 'check_circle' : 'pending'}</Icon>
            )
        },
    },
    {
        key: 'createdAt',
        header: 'Created At',
        cell: (item) => dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
        key: 'actions',
        header: 'Actions',
        cell: (row, setRefetch) => actionsCell(row, setRefetch),
    },
];

export const fields_User: Array<GenericField<DB_User>> = [
    {
        name: 'name',
        title: 'Name',
        type: 'text',
        search: true,
    },
    {
        name: 'email',
        title: 'Email',
        type: 'text',
    },
    {
        name: 'status',
        title: 'Status',
        type: 'select',
        selectOptions: Object.keys(DB_STATUS).filter((v) => isNaN(Number(v)))
    },
    {
        name: 'role',
        title: 'Role',
        type: 'select',
        selectOptions: ['admin', 'user', 'member']
    },
    {
        name: 'verified',
        title: 'Verified',
        type: 'select',
        selectOptions: ['true', 'false']
    },
    {
        name: 'createdAt',
        title: 'Date',
        type: 'date',
    },
];