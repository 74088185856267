import React from 'react';

import { Helmet } from 'react-helmet-async';

import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { MyIconButton } from 'components/Buttons/Index';
import { setItem } from 'helpers/utils';
import { useDataContext } from 'context/data.context';

function PageHeader() {
  const { state, dispatch } = useDataContext();

  const showBalanceHandleClick = () => {
    setItem('showBalance', !state.showBalance)
    dispatch({
      type: 'SET_DATA',
      payload: {
        showBalance: !state.showBalance,
      }
    });
  }

  return (
    <>
      <Helmet>
        <title>Wallet / Assets</title>
      </Helmet>

      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            Wallet / Assets
          </Typography>
          {/* <Typography variant="subtitle2">{subHeading}</Typography> */}
        </Grid>

        <Grid item>
          <MyIconButton onClick={showBalanceHandleClick}>
            {state.showBalance ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
          </MyIconButton>
        </Grid>
      </Grid>
    </>
  );
}

export default PageHeader;
