import { FC } from 'react';
import {
  Icon,
  InputProps,
  InputAdornment,
  InputLabel,
  FormControl,
  FormHelperText,
  Input as _Input,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';

const Input = styled(_Input)(({ theme }) => ({
  padding: '0.2rem 0',
  marginBottom: '0.4rem',
}));

type IFormInputProps = {
  name: string;
  label?: string;
  icon?: string;
} & InputProps;

const FormInput: FC<IFormInputProps> = ({ name, label, icon, ...otherProps }) => {
  const { control, formState: { errors } } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue=''
      name={name}
      render={({ field }) => (
        <>
          <FormControl fullWidth variant="standard" sx={{ mb: 2 }}>
            {label && <InputLabel htmlFor={`label-${name}`}>{label}</InputLabel>}

            <Input
              {...field}
              error={!!errors[name]}
              startAdornment={icon && (
                <InputAdornment position="start" sx={{ backgroundColor: 'transparent' }}>
                  <Icon className='material-icons-outlined'>{icon}</Icon>
                </InputAdornment>
              )}
              {...otherProps}
            />
            <FormHelperText error={!!errors[name]}>
              {errors[name]?.message as string}
            </FormHelperText>
          </FormControl>
        </>
      )}
    />
  );
};

export default FormInput;
