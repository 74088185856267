import Label from ".";
import { PRIMARY_COLOR } from "models/Generic";
import { TX_TYPES } from "models/db/Transaction";

type TX_TYPES_LABEL = {
    [key in keyof typeof TX_TYPES]: {
        text: keyof typeof TX_TYPES;
        color: PRIMARY_COLOR;
    }
}

export const TxTypeLabelMaps: TX_TYPES_LABEL = {
    CUSTOM: {
        text: 'CUSTOM',
        color: 'info',
    },
    DEPOSIT: {
        text: 'DEPOSIT',
        color: 'warning',
    },
    SWEEPER: {
        text: 'SWEEPER',
        color: 'primary',
    },
    COMMISSION: {
        text: 'COMMISSION',
        color: 'success',
    },
    BUYING_WALLET: {
        text: 'BUYING_WALLET',
        color: 'info',
    },
    BUYING_CRYPTO: {
        text: 'BUYING_CRYPTO',
        color: 'info',
    },
    SELLING_CRYPTO: {
        text: 'SELLING_CRYPTO',
        color: 'info',
    },
};

export const getTxTypeLabel = (status: TX_TYPES): React.JSX.Element => {
    const { text, color } = TxTypeLabelMaps[status];
    return <Label color={color}>{text}</Label>;
};