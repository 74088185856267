import Label from ".";
import { PRIMARY_COLOR, RES_STATUS, } from "models/Generic";

type RES_STATUS_LABEL = {
    [key in keyof typeof RES_STATUS]: {
        text: keyof typeof RES_STATUS | string;
        color: PRIMARY_COLOR;
    }
}

export const StatusResLabelMaps: RES_STATUS_LABEL = {
    info: {
        text: 'info',
        color: 'info'
    },
    error: {
        text: 'error',
        color: 'error'
    },
    fail: {
        text: "failed",
        color: "error"
    },
    warning: {
        text: 'warning',
        color: 'warning'
    },
    success: {
        text: 'success',
        color: 'success'
    },
};

export const getStatusResLabel = (status: RES_STATUS): React.JSX.Element => {
    const { text, color } = StatusResLabelMaps[status || 'info'];
    return <Label color={color}>{text.toUpperCase()}</Label>;
};