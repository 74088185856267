import { useOutletContext } from "react-router-dom";
import { DB_User } from "models/db/User";


type State = {
  user: DB_User | null;
  isAuthenticated: boolean;
};

type Action = {
  type: string;
  payload: DB_User | null;
};

type UserContextType = {
  authState: State
  authDispatch: (action: Action) => void,
};

export default () => useOutletContext<UserContextType>();