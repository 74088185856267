import React from 'react';
import 'reflect-metadata';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import AuthMiddleware from './middleware/AuthMiddleware';
import { AuthProvider } from './context/auth.context';
import { DataContextProvider } from './context/data.context';
import { ToastContainer } from 'react-toastify';
import { Container } from '@mui/material';
import CurrencyProvider from 'context/CurrencyContext';
import App from 'App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <Router>
      <AuthProvider>
        <AuthMiddleware>
          <DataContextProvider>
            <CurrencyProvider>
              <App />
            </CurrencyProvider>
          </DataContextProvider>
        </AuthMiddleware>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </Router>
  </QueryClientProvider>
  // </React.StrictMode>
);

ReactDOM.createRoot(document.getElementById('rootModal') as HTMLElement).render(
  <>
    <Container id="todoModal" style={{ zIndex: 8888 }} />
    <ToastContainer style={{ zIndex: 9999 }} />
  </>
)
