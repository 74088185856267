import ReactDom from 'react-dom';
import React, { FC, CSSProperties } from 'react';
import { Container } from '@mui/material';

const OVERLAY_STYLES: CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,.3)',
  zIndex: 1,
};

const MODAL_STYLES: CSSProperties = {
  position: 'fixed',
  top: '10%',
  left: '50%',
  transform: 'translateX(-50%)',
  transition: 'all 300ms ease',
  backgroundColor: 'white',
  overflowY: 'auto',
  zIndex: 1000,
  padding: '2rem 1rem',
  borderRadius: 4,
  maxHeight: '80%',
};

type ICrudModal = {
  openCrudModal: boolean;
  setOpenCrudModal: (openCrudModal: boolean) => void;
  closeable?: boolean;
  children: React.ReactNode;
};

const CrudModal: FC<ICrudModal> = ({
  openCrudModal,
  setOpenCrudModal,
  closeable: closeable = false,
  children,
}) => {
  if (!openCrudModal) return null;
  return ReactDom.createPortal(
    <>
      <div
        style={OVERLAY_STYLES}
        onClick={() => closeable ? setOpenCrudModal(false) : false}
      />
      <Container
        maxWidth='sm'
        style={MODAL_STYLES}
      >
        {children}
      </Container>
    </>,
    document.getElementById('todoModal') as HTMLElement
  );
};

export default CrudModal;
