import React from "react";
import dayjs, { Dayjs } from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
// import localeData from "dayjs/plugin/localeData";

import {
    styled,
    TextField,
    TextFieldProps,
    Stack,
    Chip,
} from "@mui/material";

import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import { FormHelperText, Typography } from "@mui/material";

dayjs.extend(isBetweenPlugin);
// dayjs.extend(localeData);

const errorMessage = "Start date should be before the end date";

interface Errors {
    start?: string;
    end?: string;
}

const isBefore = (start: Date, end: Date): boolean => dayjs(end).isBefore(dayjs(start));

type Props = {
    values: Date[] | null[];
    onChangeFn: (values: Date[] | null[]) => void;
    inputFormat?: string;
    separator?: string | React.ReactNode;
} & TextFieldProps;

interface CustomPickersDayProps extends PickersDayProps<Dayjs> {
    dayIsBetween: boolean;
    isFirstDay?: boolean;
    isLastDay?: boolean;
}

//a custom component for styling the highlighted date range
const RangeHighLightStyle = styled(PickersDay, {
    shouldForwardProp: (prop) => {
        return prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay";
    }
})<CustomPickersDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        "&:hover, &:focus": {
            backgroundColor: theme.palette.primary.dark
        }
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
})) as React.ComponentType<CustomPickersDayProps>;

export default function DateRangeInput({
    values = [],
    onChangeFn,
    inputFormat = "DD/MM/YYYY",
    separator,
}: Props) {
    const [error, setError] = React.useState<Errors | null>(null);

    // state for storing the start and end date of the selected range
    const [startDate, setStartDate] = React.useState<Date>(dayjs(values[0]).toDate());
    const [stopDate, setStopDate] = React.useState<Date>(dayjs(values[1]).toDate());

    // function for highlighting the selected date range
    const rangeHighLight = (
        date: Dayjs,
        selectedDates: Array<Dayjs>,
        pickersDayProps: PickersDayProps<Dayjs>,
    ) => {
        if (!startDate || !stopDate) {
            return <PickersDay {...pickersDayProps} />;
        }

        const dayIsBetween = date.isBetween(startDate, stopDate, null, "[]");
        const isFirstDay = date.isSame(startDate, "day");
        const isLastDay = date.isSame(stopDate, "day");

        return (
            <RangeHighLightStyle
                {...pickersDayProps}
                disableMargin
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        );
    };

    const onKeyDown = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
    };

    React.useEffect(() => {
        onChangeFn([startDate, stopDate])
    }, [startDate, stopDate])


    const handleClickOnClear = () => {
        setStartDate(dayjs().startOf("day").toDate());
        setStopDate(dayjs().add(1, "day").toDate());
    };

    const ActionBar = () => (
        <>
            <Stack
                spacing={1}
                mt={-2}
                pb={2}
                pr={2}
                direction="row"
                alignItems="right"
                justifyContent="right"
            >
                <Chip
                    label="Clear"
                    onClick={handleClickOnClear}
                    variant="outlined"
                    color="error"
                />
            </Stack>
        </>
    );

    return (
        <Stack>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" spacing={2} alignItems="baseline" justifyContent="space-between">
                    <Stack flexGrow={1}>
                        <MobileDatePicker
                            showToolbar={false}
                            showDaysOutsideCurrentMonth={true}
                            views={["year", "month", "day"]}
                            inputFormat={inputFormat}
                            label="From"
                            value={startDate}
                            closeOnSelect={true}
                            onChange={(newValue) => {
                                setStartDate(dayjs(newValue).toDate());
                                setError({ end: isBefore(dayjs(newValue).toDate(), stopDate) ? errorMessage : '' });
                            }}
                            components={{
                                ActionBar: () => <ActionBar />
                            }}
                            minDate={dayjs()}
                            maxDate={dayjs(new Date()).add(3, 'month')}
                            renderDay={rangeHighLight}
                            renderInput={(params) => (
                                <TextField
                                    helperText={null}
                                    onKeyDown={onKeyDown}
                                    error={!!error?.start}
                                    {...params}
                                />
                            )}
                        />
                        <FormHelperText error={!!error?.start}>{error?.start}</FormHelperText>
                    </Stack>
                    <Typography>{separator}</Typography>
                    <Stack flexGrow={1}>
                        <MobileDatePicker
                            showToolbar={false}
                            showDaysOutsideCurrentMonth={true}
                            views={["year", "month", "day"]}
                            inputFormat={inputFormat}
                            label="To"
                            value={stopDate}
                            closeOnSelect={true}
                            onChange={(newValue) => {
                                setStopDate(dayjs(newValue).toDate());
                                setError({ end: isBefore(startDate, dayjs(newValue).toDate()) ? errorMessage : '' });
                            }}
                            components={{
                                ActionBar: () => <ActionBar />
                            }}
                            minDate={dayjs(startDate).add(1, 'day')}
                            maxDate={dayjs().add(3, 'month')}
                            renderDay={rangeHighLight}
                            renderInput={(params) => (
                                <TextField
                                    helperText={null}
                                    onKeyDown={onKeyDown}
                                    error={!!error?.end}
                                    {...params}
                                />
                            )}
                        />
                        <FormHelperText error={!!error?.end}>{error?.end}</FormHelperText>
                    </Stack>
                </Stack>
            </LocalizationProvider>
        </Stack>
    );
}