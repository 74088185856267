import { DB_User } from "./User";
import { CRYPTO_SYMBOL, DB_Currency } from "./Currency";
import { DB_STATUS } from "models/Generic";
import { DB_Network } from "./Network";

export interface WalletCurrencyBalance {
    id: number;
    symbol: CRYPTO_SYMBOL;
    balance: number;
}

export interface DB_WalletBalance {
    id: string;
    balance: number;
    user: DB_User;
    wallet: DB_Address;
    currency: DB_Currency;
    createdAt: string;
    updatedAt: string;
}

export interface DB_Address {
    id: string;
    name: string;
    address: string;
    user: DB_User;
    status: DB_STATUS;
    createdAt: string;
    updatedAt: string;
    network: DB_Network,
    balances: DB_WalletBalance[];
    currencyBalances: WalletCurrencyBalance[];
    // fake fields
    totalUsdBalance: number;
    userName: string | null;
    actions: string | null;
}

export interface DB_Addresses {
    status: string;
    data: DB_Address[];
    totalRows: number;
}

export type AddressFieldName = keyof DB_Address;

export const nameMapping_Address: { [key in keyof DB_Address]: AddressFieldName } = {
    id: "id",
    user: "user",
    status: "status",
    name: "name",
    address: "address",
    network: "network",
    createdAt: "createdAt",
    updatedAt: "updatedAt",
    userName: "userName",
    balances: "balances",
    currencyBalances: "currencyBalances",
    totalUsdBalance: "totalUsdBalance",
    actions: "actions",
};