import axios from "axios";
import { getItem, setItem, validatePath } from 'helpers/utils';
import notification from 'helpers/notification';
import { IRefreshAccessTokenResponse } from 'models/db/User';
import { authRoutes, publicRoutes } from 'router';
import config from "config";

const zionApi = axios.create({
    baseURL: `${config.API_BASE_URL}/api/`,
    withCredentials: true,
});

zionApi.defaults.headers.common['Content-Type'] = 'application/json';

export const refreshAccessTokenFn = async () => {
    const response = await zionApi.get<IRefreshAccessTokenResponse>('auth/refresh');
    return response.data;
};

// Request interceptor for API calls
zionApi.interceptors.request.use(async (config) => {
    config.headers.set('Authorization', `Bearer ${getItem('accessToken')}`)
    config.headers.set('RefreshToken', getItem('refreshToken') as string)
    return config;
});

// Response interceptor for API calls
zionApi.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (
            !validatePath([...authRoutes.children, ...publicRoutes.children]).length &&
            ![''].includes(location.pathname.replace(/^\/([^\/]*).*$/, '$1')) // for home page
        ) {
            notification.error(error)
        }

        const errMessage = error.response?.data?.message || error.message;

        // RefreshAccessToken
        if (getItem('loggedIn') === 'true' && error.response.status === 401 && !originalRequest._retry) {
            console.log('Refetch AccessToken!');

            originalRequest._retry = true;
            const data = await refreshAccessTokenFn();

            if (data.accessToken) {
                console.log('Renewed AccessToken!');
                setItem('accessToken', data.accessToken)
                return zionApi(originalRequest);
            }
        }

        if (getItem('loggedIn') === 'true' && error.response.status === 403 && errMessage.includes('not refresh')) {
            console.error('Force reauthorization!');
            setItem('loggedIn', "false")
        }

        return Promise.reject(error);
    }
);

export default zionApi;