
import React from 'react';
import {
    Stack,
    Icon,
    Typography,
    IconButton,
    Tooltip,
    Box,
} from '@mui/material';
import copy from 'copy-to-clipboard';

const ShortText = ({
    value,
    tooltip = '',
    expandable = false,
}: {
    value: string | number;
    tooltip?: string;
    expandable?: boolean;
}): React.JSX.Element => {
    return (
        <Stack direction="row" alignItems="center" sx={{
            ...(!expandable ? {} : {
                position: 'relative',
                padding: 1,
                ml: -1,
                ':hover': {
                    zIndex: '1',
                    overflow: 'visible',
                    background: '#f5f6fa',
                    borderRadius: 1,
                    '>button': {
                        color: 'inherit'
                    },
                    '>.dots': {
                        display: 'none'
                    },
                    '>.middle': {
                        display: 'inline'
                    }
                }
            })
        }}>
            <IconButton aria-label="copy" size="small" onClick={e => copy(String(value))}>
                <Icon fontSize='inherit'>content_copy</Icon>
            </IconButton>

            {String(value).length <= 10 ? <Box>{String(value)}</Box> : <>
                <Tooltip title={String(tooltip) ?? ''}>
                    <Box>
                        <Typography component='span'>{String(value).slice(0, 10)}</Typography>
                        <Typography component='span' className='dots'>...</Typography>
                        <Typography component='span' className='middle' sx={{ display: 'none' }}>{String(value).slice(10, String(value).length - 10)}</Typography>
                        <Typography component='span'>{String(value).slice(String(value).length - 10, String(value).length)}</Typography>
                    </Box>
                </Tooltip>
            </>}
        </Stack>
    );
}

export default ShortText;