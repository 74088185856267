import { FilterGroup } from "components/DataTable/filter/FilterGroup";

export type Concat<T extends string[]> = T extends [infer F, ...infer R] ? F extends string ? R extends string[] ? `${F}${Concat<R>}` : never : never : '';

export enum DB_STATUS {
    ACTIVE = 'ACTIVE',
    SUSPEND = 'SUSPEND',
    DELETED = 'DELETED',
}

export enum RES_STATUS {
    info = 'info',
    fail = 'fail',
    error = 'error',
    warning = 'warning',
    success = 'success',
}

export enum RESCODE {
    ADDRESS_LIMIT_IS_NOT_ENOUGH = 'ADDRESS_LIMIT_IS_NOT_ENOUGH',
}

export interface GenericResponse<T> {
    status: RES_STATUS;
    message?: string | RESCODE;
    data: T;
}

export interface DTResponse<T> {
    data: T[];
    status: RES_STATUS;
    totalRows: number;
    downloadUrl?: string;
    lastParams?: FilterGroup<keyof T>;
}

export type PRIMARY_COLOR = 'primary' | 'black' | 'secondary' | 'error' | 'warning' | 'success' | 'info';

export type ENUM_LIKE = {
    [k: string]: string | number;
    [nu: number]: string;
};

export type STATUS_LABEL = {
    [key in keyof ENUM_LIKE]: {
        text: keyof ENUM_LIKE,
        color: PRIMARY_COLOR,
    }
}

export const statusOptions = [
    {
        id: 'all',
        name: 'All'
    },
    {
        id: 'ACTIVE',
        name: 'Active'
    },
    {
        id: 'SUSPEND',
        name: 'Suspend'
    },
    {
        id: 'DELETED',
        name: 'Deleted'
    }
];

export const balanceFileds = [
    'amount',
    'balance',
    'userBalance',
    'sweepBalance',
    'totalBalance',
    'totalUsdtBalance',
]