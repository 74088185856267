import { getMeFn } from '../api/authApi';
import { useAuthState } from '../context/auth.context';

import React, { useEffect, useState } from 'react';
import SuspenseLoader from 'components/SuspenseLoader';
import BaseLayout from 'components/Layout/BaseLayout';
import { useMatch, useNavigate } from 'react-router-dom';
import useStore from 'store';

type AuthMiddlewareProps = {
  children: React.ReactElement;
};

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({ children }) => {
  // const [cookies] = useCookies(['logged_in']);
  const userProvider = useAuthState();
  const { authState, dispatch } = userProvider;
  const store = useStore();

  const isDeposit = useMatch("deposit/*");
  const isPrivate = useMatch("dashboard/*");

  // const location = useLocation()
  const navigate = useNavigate()

  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (isDeposit) {
      setIsFetching(false)
      return
    }

    // console.log('AuthMiddleware: fetch start');
    let isMounted = true;

    ; (async () => {
      try {
        console.log('AuthMiddleWare Loader ...')
        const result = await getMeFn({ client: 'auth' });
        // console.log('AuthMiddleware: fetch success', response);
        dispatch({ type: 'SET_USER', payload: result.data });
        store.setRequestLoading(false);
        store.setAuthUser(result.data);

        if (!isMounted) {
          console.debug('AuthMiddleware:Aborted setState on unmounted component')
        } else {
          setIsFetching(false);
          console.log('AuthMiddleWare Loaded!')
        };
      } catch (err) {
        console.error('AuthMiddleware Err:', err);
        store.setRequestLoading(false);

        if (isPrivate) navigate('login');

        setIsFetching(false);
      }
    })();

    return () => { isMounted = false; };
  }, [dispatch])

  if (isFetching) {
    return (
      <BaseLayout>
        <SuspenseLoader />
      </BaseLayout>
    )
  }

  return children;
};

export default AuthMiddleware;
