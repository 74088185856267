import { Box as _Box, CircularProgress, styled } from '@mui/material';

export const Box = styled(_Box)(({
  backgroundColor: 'transparent',
}));

interface LoaderProps {
  sx?: object | {};
}

const DataLoader: React.FC<LoaderProps> = ({ sx }: LoaderProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ ...sx }}
    >
      <CircularProgress
        size={32}
        disableShrink thickness={3}
      />
    </Box>
  );
}

export default DataLoader;
