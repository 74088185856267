import {
    Avatar,
    alpha,
    styled
} from '@mui/material';

export const CryptoAvatar = styled(Avatar)(({ theme }) => ({
    margin: theme.spacing(2, 0, 1, -0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
    borderRadius: '60px',
    height: theme.spacing(5.5),
    width: theme.spacing(5.5),
    background: theme.palette.mode === 'dark'
        ? theme.colors.alpha.trueWhite[30]
        : alpha(theme.colors.alpha.black[100], 0.07),

    img: {
        background: theme.colors.alpha.trueWhite[100],
        padding: theme.spacing(0),
        display: 'block',
        borderRadius: 'inherit',
        height: theme.spacing(4.5),
        width: theme.spacing(4.5),
    }
}));