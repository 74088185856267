export const pablicPageItems = [
    {
        priority: 1,
        path: '/about',
        title: 'About us',
        icon: 'home',
        roles: [],
        visible: true,
        private: false,
        multiple: false,
    },
    {
        priority: 2,
        path: '/enterprise',
        title: 'Enterprise Solution',
        icon: 'api',
        roles: [],
        visible: true,
        private: false,
        multiple: false,
    },
    {
        priority: 3,
        path: '/product',
        title: 'Product Features',
        icon: 'api',
        roles: [],
        visible: true,
        private: false,
        multiple: false,
    },
    {
        priority: 4,
        path: '/fees',
        title: 'Fees',
        icon: 'api',
        roles: [],
        visible: true,
        private: false,
        multiple: false,
    },
    {
        priority: 5,
        path: '/contact',
        title: 'Contact Us',
        icon: 'api',
        roles: [],
        visible: true,
        private: false,
        multiple: false,
    },
];

export const userSettingItems = [
    {
        priority: 1,
        path: '/',
        title: 'Home',
        icon: 'home',
        roles: ['admin', 'user', 'member'],
        visible: true,
        private: true,
        multiple: false,
    },
    {
        priority: 2,
        path: '/dashboard',
        title: 'Dashboard',
        icon: 'dashboard',
        roles: ['admin', 'user', 'member'],
        visible: true,
        private: true,
        multiple: false,
    },
];

const routeItems = [
    // {
    //     path: '/dashboard',
    //     isPrivate: true,
    //     isVisible: true,
    //     multiple: false,
    //     priority: 2,
    //     title: 'Dashboard',
    //     icon: 'dashboard',
    //     roles: ['admin', 'user', 'member'],
    // },
    {
        path: '/dashboard/wallets',
        isPrivate: true,
        isVisible: true,
        multiple: false,
        priority: 7,
        title: 'Wallets',
        icon: 'account_balance_wallet',
        roles: ['admin', 'user', 'member'],
    },
    {
        path: '/dashboard/addresses',
        isPrivate: true,
        isVisible: true,
        multiple: false,
        priority: 7,
        title: 'Addresses',
        icon: 'account_tree',
        roles: ['admin', 'user', 'member'],
    },
    {
        path: '/dashboard/sweepers',
        isPrivate: true,
        isVisible: true,
        multiple: false,
        priority: 7,
        title: 'Sweep Rules',
        icon: 'manage_history',
        roles: ['admin', 'user', 'member'],
    },
    {
        path: '/dashboard/transactions',
        isPrivate: true,
        isVisible: true,
        multiple: false,
        priority: 7,
        title: 'Transaction History',
        icon: 'history',
        roles: ['admin', 'user', 'member'],
    },
    {
        path: '/dashboard/profile',
        isPrivate: true,
        isVisible: true,
        multiple: false,
        priority: 10,
        title: 'Profile',
        icon: 'manage_accounts',
        roles: ['admin', 'user', 'member'],
    },
    {
        path: '/dashboard/admin',
        isPrivate: true,
        isVisible: true,
        multiple: false,
        priority: 101,
        title: 'Admin',
        icon: 'group',
        roles: ['admin'],
    },
    {
        path: '/dashboard/settings',
        isPrivate: true,
        isVisible: true,
        multiple: false,
        priority: 102,
        title: 'Settings',
        icon: 'settings',
        roles: ['admin'],
    },
    {
        path: '/dashboard/apidocs',
        isPrivate: true,
        isVisible: true,
        multiple: false,
        priority: 99,
        title: 'API Docs',
        icon: 'api',
        roles: ['admin', 'user'],
    },
    {
        path: '/signin',
        isPrivate: false,
        isVisible: false,
        multiple: false,
        priority: 2,
        title: 'Giriş',
        icon: 'login',
        roles: ['admin', 'user', 'member'],
    },
    {
        path: '/signup',
        isPrivate: false,
        isVisible: false,
        multiple: false,
        priority: 3,
        title: 'Kayıt',
        icon: 'login',
        roles: ['admin', 'user', 'member'],
    },
    {
        path: '/resetpassword',
        isPrivate: false,
        isVisible: false,
        multiple: false,
        priority: 4,
        title: 'Şifremi Unuttum',
        icon: 'lockreset',
        roles: ['admin', 'user', 'member'],
    },
    {
        path: '/404',
        isPrivate: false,
        isVisible: false,
        multiple: false,
        priority: 1,
        title: false,
        roles: ['admin', 'user', 'member'],
    },
    {
        path: '/',
        isPrivate: true,
        isVisible: false,
        multiple: true,
        priority: 1,
        title: 'Anasayfa',
        icon: 'dashboard',
        roles: ['admin', 'user', 'member'],
    },
];

export default routeItems;