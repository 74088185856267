import {
    styled,
    IconButton as _IconButton,
} from "@mui/material";

export const MyIconButton = styled(_IconButton, {
    // Configure which props should be forwarded on DOM
    // shouldForwardProp: (prop) => prop !== 'color' && prop !== 'variant' && prop !== 'sx',
    // name: 'MyIconButton',
    // slot: 'Root',
    // We are specifying here how the styleOverrides are being applied based on props
    // overridesResolver: (props, styles) => [
    //     styles.root,
    //     props.color === 'primary' && styles.primary,
    //     props.color === 'secondary' && styles.secondary,
    // ],
})(({ theme }) => ({
    color: theme.palette.primary.main,
    '&:hover': {
        background: theme.colors.primary.lighter
    },
}));

export const MyIconButtonDark = styled(_IconButton)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    '&:hover': {
        background: theme.palette.primary.dark
    },
}));