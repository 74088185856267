import React from "react";
import { Link, useMatch, useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import { useAuthState } from 'context/auth.context';

import { styled, CSSObject, Theme } from '@mui/material/styles';

import {
    Box,
    Icon,
    List,
    Toolbar,
    Divider,
    Typography,
    IconButton,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    Drawer as MuiDrawer,
} from '@mui/material';

import {
    Menu as MenuIcon,
} from '@mui/icons-material';

import { logoutUserFn } from '../../../api/authApi';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import navLinks from "../../../router/route.items";

import { LoadingButton } from "../../Buttons/LoadingButton";
// import { checkItem, getItem, setItem } from "helpers/utils";
// import HeaderNotifications from "./Notifications";
import HeaderUserbox from "./Userbox";

import { SidebarContext } from "context/SidebarContext";
// import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
// import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: '#f5f6fa',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: '#f5f6fa',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: '1202',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    backgroundColor: theme.header.background,
    color: 'rgba(0, 0, 0, 0.84)',
    fontWeight: '700',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Header = () => {
    const { sidebarToggle, toggleSidebar } = React.useContext(SidebarContext);

    const userProvider = useAuthState();
    const { authState: { user }, dispatch } = userProvider;

    const navigate = useNavigate();

    const { mutate: logoutUser, isLoading } = useMutation(
        async () => await logoutUserFn(),
        {
            onSuccess: (data) => {
                dispatch({ type: 'SET_USER', payload: null })
                navigate('/login')
            }
        }
    );

    const onLogoutHandler = async () => {
        logoutUser();
    };

    return (
        <>
            <AppBar position="fixed" open={sidebarToggle}>
                <Toolbar sx={{
                    ...(!sidebarToggle && { marginLeft: "56px" }),
                }}>
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
                        {/* <Typography>
                            Do you know the latest update of 2022? A overview of our is now available.
                        </Typography> */}
                    </Box>

                    <Typography sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} />

                    <Box sx={{ flexGrow: 0 }} display="flex" alignItems="center">
                        {user ? (
                            <>
                                <Box sx={{ mx: 1 }} component="span">
                                    {/* <HeaderNotifications /> */}
                                </Box>

                                <Box sx={{ mx: 0.5 }}>
                                    <HeaderUserbox onLogoutHandler={onLogoutHandler} isLoading={isLoading} />
                                </Box>

                                {/* <Box component="span" sx={{ display: { lg: "none" } }}>
                                    <Tooltip arrow title="Toggle Menu">
                                        <IconButton color="primary" onClick={toggleSidebar}>
                                            {!sidebarToggle ? (
                                                <MenuTwoToneIcon fontSize="small" />
                                            ) : (
                                                <CloseTwoToneIcon fontSize="small" />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                </Box> */}
                            </>
                        ) : (
                            <>
                                <LoadingButton
                                    sx={{ mr: 2 }}
                                    onClick={() => navigate('/register')}
                                >
                                    SignUp
                                </LoadingButton>
                                <LoadingButton onClick={() => navigate('/login')}>
                                    Login
                                </LoadingButton>
                            </>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                open={sidebarToggle}>
                <DrawerHeader>
                    <IconButton onClick={toggleSidebar}>
                        <MenuIcon />
                    </IconButton>

                    <Typography
                        noWrap
                        variant="h6"
                        lang="en"
                        sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            ...(!sidebarToggle && { display: 'none' }),
                        }}
                    >
                        <Link to='/dashboard' style={{
                            color: 'inherit',
                            textDecoration: 'none',
                        }}>
                            <img
                                alt="ZionTrader"
                                src="/logo/logo-dark.png"
                            />
                        </Link>
                    </Typography>
                </DrawerHeader>

                {/* <Divider /> */}

                <List component="nav">
                    {user && (
                        <>
                            <ListItemButton key="dashboard" to="dashboard" component={Link}>
                                <ListItemIcon>
                                    <Icon>dashboard</Icon>
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>

                            {navLinks.filter(item => item?.title && item?.isVisible && [...item?.roles].includes(user?.role as string))
                                .sort((a, b) => a.priority - b.priority)
                                .map((route, i) => {
                                    const selected = useMatch(`${route.path}/*`);
                                    return (
                                        <ListItemButton key={i} to={route.path} component={Link} selected={selected !== null}>
                                            <ListItemIcon>
                                                <Icon>{route.icon || 'dashboard'}</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary={route.title} />
                                        </ListItemButton>
                                    )
                                })}
                        </>
                    )}
                </List>
                <Divider />
                <List>
                    {user && (
                        <>
                            <ListItemButton onClick={onLogoutHandler}>
                                <ListItemIcon>
                                    <Icon>logout</Icon>
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItemButton>
                        </>
                    )}
                </List>
            </Drawer>
        </>
    );
};

export default Header;