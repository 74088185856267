import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  FormControl,
  FormHelperText,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import {
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { createBankAccountFn, getBanks } from 'models/api/BankAccount';
import zionApi from 'api/zionApi';
import { GenericResponse } from 'models/Generic';
import { BankCurrencyType, DB_Bank } from 'models/db/BankAccount';

interface ICreateBankAccountProp {
  setRefetch: Dispatch<SetStateAction<number>>;
  setOpenCrudModal: (openCrudModal: boolean) => void;
}

const createBankAccountSchema = z.object({
  iban: z.string().min(1, 'IBAN is required'),
  name: z.string().min(1, 'AccountName is required').max(50),
  bankId: z.number().min(1, 'Bank is required').transform(val => val.toString()),
  currency: z.nativeEnum(BankCurrencyType),
})

export type TCreateBankAccount = z.TypeOf<typeof createBankAccountSchema>;

const AddBankAccount: FC<ICreateBankAccountProp> = ({ setRefetch, setOpenCrudModal }) => {
  const [formData, setFormData] = React.useReducer((state: TCreateBankAccount, newState: Partial<TCreateBankAccount>) => ({ ...state, ...newState, }), {
    iban: '',
    name: '',
    bankId: '',
    currency: '' as BankCurrencyType,
  });

  const banks = useQuery({
    queryKey: ['availableBanks'],
    select: (res) => res.data,
    queryFn: async () => await getBanks(),
  });

  // console.log({ banks: banks.data });

  const { isLoading, mutate: createBankAccount } = useMutation(
    (data: TCreateBankAccount) => createBankAccountFn(data),
    {
      onSuccess: () => {
        setRefetch(c => ++c)
        notification.success('Bank account created successfully');
        setOpenCrudModal(false);
      }
    }
  );

  const methods = useForm<TCreateBankAccount>({
    resolver: zodResolver(createBankAccountSchema)
  });

  const {
    formState: { errors: formErrors, isSubmitting, isSubmitSuccessful },
  } = methods;

  if (Object.keys(formErrors).length) {
    console.log({ formErrors });
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      // methods.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<TCreateBankAccount> = (formValues) => {
    createBankAccount(formValues);
  };

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Add Bank Account
        </Typography>

        <CloseButton loading={isLoading} onClick={() => setOpenCrudModal(false)}>
          <Icon>close</Icon>
        </CloseButton>
      </Box>

      <FormProvider {...methods}>
        <Box
          noValidate
          component='form'
          autoComplete='on'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              {...methods.register('name')}
              label='Account Name' />
            <FormHelperText error={!!formErrors?.name}>{formErrors?.name?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="bankId-label">Bank Name</InputLabel>
            <Select  {...methods.register('bankId')}
              label="Bank Name"
              labelId="bankId-label"
              value={formData.bankId}
              onChange={e => setFormData({ [e.target.name]: e.target.value })}
            >
              {banks?.data?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
            </Select>
            <FormHelperText error={!!formErrors?.bankId}>{formErrors?.bankId?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="currency-label">Account Currency Type</InputLabel>
            <Select  {...methods.register('currency')}
              label="Account Currency Type"
              labelId="currency-label"
              value={formData.currency}
              onChange={e => setFormData({ [e.target.name]: e.target.value })}
            >
              {Object.keys(BankCurrencyType).filter((v) => isNaN(Number(v))).map(
                item => <MenuItem key={item} value={item}>{item}</MenuItem>
              )}
            </Select>

            <FormHelperText error={!!formErrors?.currency}>{formErrors?.currency?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label='IBAN'
              {...methods.register('iban')}
            />
            <FormHelperText error={!!formErrors?.iban}>{formErrors?.iban?.message}</FormHelperText>
          </FormControl>

          <LoadingButton
            fullWidth
            type='submit'
            variant='contained'
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default AddBankAccount;