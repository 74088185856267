import { styled } from "@mui/material";
import { LoadingButton as _LoadingButton } from '@mui/lab';

export const LoadingButton = styled(_LoadingButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  background: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.primary.contrastText,
    background: theme.colors.primary.dark,
  },
  // "&:disabled": {
  //   backgroundColor: theme.palette.primary.contrastText
  // }
}));

export const LoadingIconButton = styled(_LoadingButton)(({ theme }) => ({
  padding: '0.4rem',
  fontWeight: 'inherit',
  color: theme.colors.primary.light,
  // background: theme.palette.primary.main,
  // '&:hover': {
  //   backgroundColor: '#f5f6fa'
  // },
  minWidth: 'auto',
  marginLeft: '0.1rem',
  marginRight: '0.1rem',
}));

export const CloseButton = styled(_LoadingButton)(({
  padding: 0,
  margin: 0,
  top: -4,
  width: 'initial',
  minWidth: 'initial',
  fontWeight: 'inherit',
  color: 'inherit',
  '&:hover': {
    backgroundColor: 'transparent'
  },
}));