import { Link } from "react-router-dom";
import { styled } from "@mui/material";

export const LinkItem = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.colors.alpha.black[100],
  fontWeight:'bold',
  '&:hover': {
    textDecoration: "underline"
  },
}));