import Label from ".";
import { ENUM_LIKE, PRIMARY_COLOR, STATUS_LABEL } from "models/Generic";

export const StatusLabelMaps: STATUS_LABEL = {
    ACTIVE: {
        text: 'ACTIVE',
        color: 'success'
    },
    SUSPEND: {
        text: 'SUSPEND',
        color: 'warning'
    },
    DELETED: {
        text: 'DELETED',
        color: 'error'
    },
};

export const getStatusLabel = (value: keyof ENUM_LIKE): React.JSX.Element => {
    value = value.toString().toUpperCase();

    const { text, color } = StatusLabelMaps.hasOwnProperty(value) ? StatusLabelMaps[value] : { text: value, color: 'error' };

    return <Label color={color as PRIMARY_COLOR}>{text}</Label>;
};