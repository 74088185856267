import { type Filter } from 'components/DataTable/filter/Filter';
import { type Order } from 'components/DataTable/filter/Order';
import { FieldType } from './FilterType';
import dayjs from 'dayjs';

export type SortTuple<T> = [keyof T, Order];

export interface FilterParams<T> {
  sortType?: SortTuple<T>;
  download?: 'link' | 'export' | undefined;
}

export type FilterTuple<T> = [T, Filter];
/**
 * A generic filter for interacting with the database
 * The type you provide should be an autogenerated DB field name enum
 *
 */
export interface FilterGroup<T> {
  /**
   * @overview Specify dynamic filtering of a DB table
   *
   * @description The nested array structure allows AND / OR statements
   * To create chained OR statements: add tuples in the INNER-MOST arrays
   * To create chained AND statements: add multiple arrays with a single tuple in each
   * To create mixed AND / OR statements: any combination of the above 2 lines
   *
   * -----------------------------------------------
   *
   * @example
   * [
   *   [ [field1, val1], [field2, val2], [field3, val3] ]
   * ]
   *
   * ^ would generate: WHERE (field1 === val1 OR field2 === val2 OR field3 === val3);
   *
   * -----------------------------------------------
   *
   * @example
   * [
   *   [ [field1, val1] ],
   *   [ [field1, val2] ],
   *   [ [field2, val3] ],
   * ]
   *
   * ^ would generate: WHERE (field1 === val1) AND (field1 === val2) AND (field2 === val3);
   *
   * -----------------------------------------------
   *
   * @example
   * [
   *   [ [field1, val1], [field2, val2], [field3, val3] ],
   *   [ [field1, val1], [field4, val4] ],
   *   [ [field5, val5] ],
   * ]
   *
   * ^ would generate: WHERE (field1 === val1 OR field2 === val2 OR field3 === OR val3) AND
   *                         (field1 === val1 OR field4 === val4) AND
   *                         (field5 === val5);
   */
  filters: Array<Array<FilterTuple<T>>>;
  /**
   * @overview Allows ordering based on fieldNames
   * @example
   * [field1, field2] would generate the the following statement:
   *
   * <select query> ... ORDER BY field1, field2;
   */
  orderBy: Array<[T, Order]>;
  limit?: number;
  offset?: number;
  download?: 'link' | 'export' | undefined;
}

/**
 * Representation for backend booleean type
 */
type dbBool = 'True' | 'False';

/**
 * @param val frontend boolean
 * @returns backend boolean conversion
 */
export const getDbBoolean = (val: boolean): dbBool => {
  return val ? 'True' : 'False';
};

/**
 * Given an array of db records (or db_Error), returns the first element
 * @param T type of the db_table you are querying
 * @param response response from db invokation
 * @param error optional error message you would like to display if there are no records
 * @returns
 */
export const getSingleRecordOrThrow = <T>(
  response: T[],
  error = 'unable to get a single record from db'
): T => {
  try {
    return response[0];
  } catch {
    throw Error(error);
  }
};

export const getValuesForFilterType = (filter: Filter): FormValueType[] => {
  const filterName = getFilterType(filter);
  switch (filterName) {
    case 'LessThan':
    case 'GreaterThan':
    case 'Range':
      return Object.values(filter)[0] as FormValueType[];
    case 'Date':
      return [Object.values(filter)[0] as Date];
    case 'DateRange':
      return Object.values(filter)[0] as FormValueType[];
    case 'Equal':
    case 'NotEqual':
    case 'Like':
      return [Object.values(filter)[0] as string];
  }
  return [];
};

/**
 * fieldTypeToFilterTypes maps a field type to the filter types that can be applied to that field type.
 * This is used to determine which filter types are available for a given column.
 */
export const fieldTypeToFilterTypes: {
  [key in FieldType]: FilterType[];
} = {
  text: ['Equal', 'NotEqual', 'Null', 'NotNull', 'Like'],
  number: [
    'Equal',
    'NotEqual',
    'Null',
    'NotNull',
    'Range',
    'GreaterThan',
    'LessThan',
  ],
  date: [
    'Date',
    'DateRange',
  ],
  boolean: ['True', 'False', 'Null', 'NotNull'],
  select: ['Equal', 'NotEqual', 'Null', 'NotNull'],
};

/**
 * @overview This is used to store the number of values a filter has
 */
export const filterValuesLengthForFilterType: {
  [key in FilterType]: number;
} = {
  Null: 0,
  NotNull: 0,
  True: 0,
  False: 0,
  Equal: 1,
  NotEqual: 1,
  Like: 1,
  LessThan: 2,
  GreaterThan: 2,
  Range: 4,
  Date: 1,
  DateRange: 2,
};

export type FilterType =
  | 'Range'
  | 'LessThan'
  | 'GreaterThan'
  | 'Date'
  | 'DateRange'
  | 'Equal'
  | 'NotEqual'
  | 'Like'
  | 'Null'
  | 'NotNull'
  | 'True'
  | 'False';

/**
 * @overview List of filter types.
 * This is useful in select menus, and is necessary since the filter type doesn't actual enumerate its options
 */
export const filterTypes: FilterType[] = [
  'Range',
  'LessThan',
  'GreaterThan',
  'Date',
  'DateRange',
  'Equal',
  'NotEqual',
  'Like',
  'Null',
  'NotNull',
  'True',
  'False',
];

/**
 * @overview Converts a filter to its filter type (e.g. Range, LessThan, etc.)
 * @param filter
 * @returns filterType
 */
export const getFilterType = (filter: Filter): FilterType => {
  if (filter === 'Null') {
    return 'Null';
  } else if (filter === 'NotNull') {
    return 'NotNull';
  } else if (filter === 'True') {
    return 'True';
  } else if (filter === 'False') {
    return 'False';
  } else {
    return Object.keys(filter)[0] as FilterType;
  }
};

export type FormValueType = string | number | boolean | Date;

/**
 * @param type The type of filter you want to create
 * @param values The values you want to use for the filter, this will be different lengths for different filter types
 * @returns Filter of given type filled with the values you provide
 */
export const createNewFilter = (
  type: FilterType,
  values: FormValueType[]
): Filter => {
  switch (type) {
    case 'Range':
      return {
        Range: [
          values[0].toString(),
          values[1] as boolean,
          values[2].toString(),
          values[3] as boolean,
        ],
      };
    case 'LessThan':
      return { LessThan: [values[0].toString(), values[1] as boolean] };
    case 'GreaterThan':
      return { GreaterThan: [values[0].toString(), values[1] as boolean] };
    case 'Date':
      return { Date: dayjs(values[0] as string).toDate() };
    case 'DateRange':
      return {
        DateRange: [
          dayjs(values[0] as string).toDate(),
          dayjs(values[1] as string).toDate(),
        ],
      };
    case 'Equal':
      return { Equal: values[0].toString() };
    case 'NotEqual':
      return { NotEqual: values[0].toString() };
    case 'Like':
      return { Like: values[0].toString() };
    case 'Null':
      return 'Null';
    case 'NotNull':
      return 'NotNull';
    case 'True':
      return 'True';
    case 'False':
      return 'False';
  }
};
