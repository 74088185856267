import { useOutletContext } from "react-router-dom";

type ContextType = {
  headerDimensions: {
    width: number,
    height: number,
  },
  footerDimensions: {
    width: number,
    height: number,
  }
};

export default () => useOutletContext<ContextType>();