import { ColumnDefinitionType } from "components/DataTable/Table";
import { GenericField } from "components/DataTable/filter/FilterType";
import { DB_Commission } from "models/db/Commission";
import actionsCell from "./actionsCell";

export const cols_Commission: Array<ColumnDefinitionType<DB_Commission>> = [
    // { key: 'id', header: 'ID' },
    {
        key: 'name',
        header: 'Name',
    },
    {
        key: 'value',
        header: 'Value',
    },
    {
        key: 'actions',
        header: 'Actions',
        cell: (row, setRefetch) => actionsCell(row, setRefetch),
    },
];

export const fields_Commission: Array<GenericField<DB_Commission>> = [];