import React from 'react';
import { formatBalance } from 'helpers/utils';
import { List, ListItem, ListItemText } from '@mui/material';
import { WalletCurrencyBalance } from 'models/db/Address';

interface IBalanceParser {
    balances: WalletCurrencyBalance[];
}
const balanceParser: React.FC<IBalanceParser> = ({ balances }) => {
    let elements: JSX.Element[] = [
        <ListItem key={`litem-0`} sx={{ m: 0, p: 0 }}>
            <ListItemText primary={`0`} />
        </ListItem>
    ]

    if (balances.length > 0) {
        elements = balances.reduce<JSX.Element[]>((acc, item, index) => {
            const element: JSX.Element = (
                <ListItem key={`litem-${item.id}`} sx={{ m: 0, p: 0 }}>
                    <ListItemText primary={`${formatBalance(item.balance)} ${item.symbol}`} />
                </ListItem>
            );
            return [...acc, element];
        }, []);
    }

    return <List>{elements}</List>;
};

export default balanceParser;
