import React from 'react';
import { Button, Container, Grid } from '@mui/material';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { LinkItem } from 'components/Buttons/LinkItem';
import config from 'config';

import remarkGfm from 'remark-gfm'
import ReactMarkdown from "react-markdown";

import PageTitleWrapper from 'components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { toLower } from 'lodash';
const files = import.meta.glob(`./markdown/*.md`, { eager: true, as: "raw" });

const ApiDoc = () => {
  const location = useLocation();
  const lastHash = React.useRef('');

  const [content, setContent] = React.useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [file, setFile] = React.useState(searchParams.get('file') || 'readme.md');

  React.useEffect(() => {
    setFile(searchParams.get('file') || 'readme')
  }, [searchParams.get('file')]);

  React.useEffect(() => {
    setContent(files[`./markdown/${file}.md`])
  }, [file]);


  React.useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={0}
        >
          <Grid item xs={12}>
            <ReactMarkdown
              skipHtml
              remarkPlugins={[remarkGfm]}
              components={{
                h2: props => {
                  return <h2 id={String(props.children).replace(/\s+/g, '-').toLowerCase()}>{props.children}</h2>
                },
                a: props => {
                  // console.log({ props });
                  return props.href?.startsWith('http') ? (
                    <LinkItem to={props.href} target='_blank'>{props.children}</LinkItem>
                  ) : (
                    <Button size='small' onClick={e => {
                      const href = String(props.href)?.split('#')

                      if (href[0].endsWith('.md')) {
                        setSearchParams({ 'file': href[0].replace('.md', '') })
                      }

                      if (href[1]) {
                        setTimeout(() => {
                          window.location.hash = href[1]
                        }, 100)
                      }
                    }}>{props.children}</Button>
                  )
                }
              }}
            >{content}</ReactMarkdown>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ApiDoc;
