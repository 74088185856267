import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import PageHeader from './PageHeader';
import { Container, Tab, Grid, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import useUserContext from 'hooks/useUserContext';

import ProfileTab from './ProfileTab';
import SecurityTab from './SecurityTab';
import ApiKeysTab from './ApiKeysTab';
import UsersTab from './UsersTab';
import BankAccountsTab from './BankAccountsTab';
import { TabContext, TabList, TabPanel as TabPanel_ } from '@mui/lab';
// import NotificationsTab from './NotificationsTab';

const TabPanel = styled(TabPanel_)(({ theme }) => ({
  padding: 0,
  scrollableX: {
    overflowX: "auto !important"
  }
}));

export default function UserProfile() {
  const { authState } = useUserContext()

  const [tabs, setTabs] = useState<{ value: string; label: string; }[]>([
    { value: 'profile', label: 'Profile' },
    { value: 'security', label: 'Security' },
    // { value: 'notifications', label: 'Notifications' },
  ])

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<string>(searchParams.get('tab') || tabs[0].value);

  useEffect(() => {
    if (currentTab !== searchParams.get('tab')) {
      setCurrentTab(searchParams.get('tab') || tabs[0].value)
    }
  }, [searchParams.get('tab')])

  useEffect(() => {
    if (authState.user?.role !== 'member') {
      setTabs(values => [
        ...values,
        { value: 'apikey', label: 'Api Keys' },
        { value: 'bank_accounts', label: 'Bank Accounts' },
        { value: 'users', label: 'User Management' }
      ])
    }
  }, [authState.user?.role])

  return (
    <>
      <TabContext value={currentTab}>
        <Container maxWidth={false}>
          <Grid sx={{ mb: 3 }}>
            <PageHeader />
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <TabList onChange={(event: React.SyntheticEvent, value: string): void => setCurrentTab(value)}>
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} onClick={e => setSearchParams({ 'tab': tab.value })} />
                ))}
              </TabList>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TabPanel value="profile"><ProfileTab /></TabPanel>
              <TabPanel value="security"><SecurityTab /></TabPanel>
              {/* <TabPanel value="notifications"><NotificationsTab /></TabPanel> */}
              {(authState.user?.role !== 'member') && (
                <>
                  <TabPanel value="apikey"><ApiKeysTab /></TabPanel>
                  <TabPanel value="bank_accounts"><BankAccountsTab /></TabPanel>
                  <TabPanel value="users"><UsersTab /></TabPanel>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </TabContext>
    </>
  );
}
