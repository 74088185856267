import { useQuery } from '@tanstack/react-query';
import { Navigate, Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { getMeFn } from '../api/authApi';
import { useAuthState } from '../context/auth.context';

import SuspenseLoader from 'components/SuspenseLoader';
import { checkItem } from 'helpers/utils';
import useStore from 'store';

const RequireUser = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const userProvider = useAuthState()
  const isPrivate = useMatch("dashboard/*");
  const store = useStore();

  const requireUser = useQuery(['user'], () => getMeFn({ client: 'require' }), {
    enabled: false,
    retry: 1,
    select: (result) => result.data,
    onSuccess: (user) => {
      console.log('RequireUser >> data >>', user);
      userProvider.dispatch({ type: 'SET_USER', payload: user });
      store.setRequestLoading(false);
      store.setAuthUser(user);
    },
    onError(err) {
      console.error('RequireUser >> err >>', err);
      userProvider.dispatch({ type: 'SET_USER', payload: null });
      store.setRequestLoading(false);

      if (isPrivate && !checkItem('loggedIn', 'true')) {
        navigate('login');
      }
    },
  });

  if (!requireUser.isFetching && !checkItem('loggedIn', 'true')) {
    requireUser.refetch()
  }

  if (requireUser.isFetching) {
    console.log('RequireUser SuspenseLoader...')
    return <SuspenseLoader />
  }

  if (!userProvider.authState.user) {
    console.log('RequireUser Navigate to LoginPage')
    return <Navigate to='/login' state={{ from: location }} replace />
  }

  if (userProvider.authState.user && allowedRoles.includes(userProvider.authState.user?.role)) {
    // console.log('RequireUser Outlet rendered');
    return <Outlet context={{
      authState: userProvider.authState,
      authDispatch: userProvider.dispatch,
    }} />
  }

  console.log('RequireUser Navigate UnauthorizedPage');
  return <Navigate to='/unauthorized' state={{ from: location }} replace />
};

export default RequireUser;