import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';
import DataTable from 'components/DataTable/DataTable';
import { cols_Commission, fields_Commission } from './definitions';
import { nameMapping_Commission } from 'models/db/Commission';
import { getFilteredCommissions } from 'models/api/Commission';
import AddForm from './forms/AddForm';
import useUserContext from 'hooks/useUserContext';

interface IUpdateUserProp { }

const CommissionsTab: React.FC<IUpdateUserProp> = ({ }) => {
  const { authState } = useUserContext()
  const [refetchTable, setRefetchTable] = React.useState<number>(0);

  return (
    <>
      <Helmet><title>Commission Settings</title></Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={8} md={8}>
          <DataTable
            title='Commissions'
            dataName='commission'
            rowsPerPage={25}
            cols={cols_Commission}
            fields={fields_Commission}
            nameMapping={nameMapping_Commission}
            fetchCounter={[refetchTable, setRefetchTable]}
            getFilteredRecords={getFilteredCommissions}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <AddForm setRefetch={setRefetchTable} />
        </Grid>
      </Grid>
    </>
  );
};

export default CommissionsTab;