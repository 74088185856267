import React, { Dispatch, SetStateAction } from 'react';

import { styled, Box, Grid, IconButton, Paper, Toolbar, Tooltip, Typography, FormControl, TextField, InputLabel, Select, MenuItem, SelectChangeEvent, Icon } from "@mui/material";

import FilterListIcon from '@mui/icons-material/FilterList';
import { FilterableItems, useDataContext } from 'context/data.context';
import { setItem } from 'helpers/utils';
import { ColumnFilter, SearchBoxFilter } from './ColumnFilter';
import { Filter } from './filter/Filter';
import { FilterParams, SortTuple } from './filter/FilterGroup';
import { SearchBar } from './SearchBar';
import { MyIconButton, MyIconButtonDark } from 'components/Buttons/Index';
import { FormValueType, createNewFilter, fieldTypeToFilterTypes, filterValuesLengthForFilterType } from './filter/FilterGroup';
import { GenericField } from './filter/FilterType';
import { DB_STATUS, } from 'models/Generic';
import { StatusLabelMaps } from 'components/Label/StatusLabel';
import { TxStatusLabelMaps } from 'components/Label/TxStatusLabel';
import { TxTypeLabelMaps } from 'components/Label/TxTypeLabel';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
}));

interface TableToolbarProps<T, K> {
    title: string;
    dataName: string;
    fields: Array<GenericField<T>>;
    filterMap: Map<keyof T, Filter[]>;
    settings?: {
        export?: 'excel';
    };
    actions?: Array<{
        icon: string;
        title: string;
        event: Dispatch<SetStateAction<boolean>>;
    }>;
    filterApply: (params?: FilterParams<T>) => void;
    setFilterMapItem: (key: keyof T, value: Filter[]) => void;
}

const addStatusFilter = <T, K>(props: TableToolbarProps<T, K>, targetField: GenericField<T> | undefined, value: FormValueType = ''): void => {
    if (!targetField || !props.fields.length) {
        console.error('targetField or props.fields err');
        return;
    }

    // get the first filter type for the field type and create a new filter with that type
    const firstFilterType = fieldTypeToFilterTypes[targetField.type][0];
    const filterValuesLen = filterValuesLengthForFilterType[firstFilterType];
    props.setFilterMapItem(targetField.name, [
        ...(props.filterMap.get(targetField.name) ?? []),
        createNewFilter(
            firstFilterType,
            Array<FormValueType>(filterValuesLen).fill(value)
        ),
    ]);
};

export const TableToolbar = <T, K>(props: TableToolbarProps<T, K>): React.JSX.Element => {
    const { state, dispatch: dispatchData } = useDataContext();
    const filterToggleHandle = () => {
        setItem('filterType', props.dataName)
        setItem('filterStatus', !state.filterData?.status)
        dispatchData({
            type: 'SET_DATA',
            payload: {
                ...state,
                filterData: {
                    type: props.dataName as FilterableItems,
                    status: !state.filterData?.status,
                }
            }
        });
    }

    const [focusedFilterField, setFocusedFilterField] = React.useState<GenericField<T> | undefined>(undefined);

    const [statusField, setStatusField] = React.useState<GenericField<T> | undefined>(undefined);
    const [filterStatus, setFilterStatus] = React.useState<DB_STATUS | 'all'>('all');

    const [statusFieldMapLength, setStatusFieldMapLength] = React.useState<number | undefined>(undefined);

    // const [searchField, setTargetField] = React.useState<GenericField<T>>({ name: 'name' as keyof T, title: 'Name', type: 'text' });
    const [searchField, setSearchField] = React.useState<GenericField<T> | undefined>(props.fields.find((f) => f.search === true));
    const [filterMapLength, setFilterMapLength] = React.useState<number | undefined>(undefined);

    const [searchValue, setSearchValue] = React.useState("");
    const searchTable = (newSearchValue: string) => {
        setSearchValue(newSearchValue)
    };

    React.useEffect(() => {
        // console.log({ filterMapLength });
        if (filterMapLength === 0) {
            setSearchValue('')
        }

        if (filterMapLength && searchField?.search) {
            setFocusedFilterField(searchField)
        }
    }, [filterMapLength])

    React.useEffect(() => {
        if (searchField?.search) {
            setFilterMapLength(preValue => {
                if (preValue !== undefined && searchValue) {
                    props.filterApply()
                }

                return props.filterMap.get(searchField.name)?.length ?? 0;
            })
        }
    }, [props.filterMap.get(searchField?.name as keyof T)?.length])

    React.useEffect(() => {
        setStatusField(props.fields.find((f) => ['status', 'txType', 'txStatus'].includes(f.name as string)))
    }, [])

    React.useEffect(() => {
        if (statusField?.type === 'select') {
            // setFocusedFilterField(statusField)

            if (filterStatus === 'all' || statusFieldMapLength) {
                props.filterApply()
            }
        }

    }, [statusFieldMapLength])

    React.useEffect(() => {
        if ((statusField?.type === 'select')) {
            setStatusFieldMapLength(props.filterMap.get(statusField.name)?.length ?? 0)
        }
    }, [props.filterMap.get(statusField?.name as keyof T)?.length])

    const handleStatusChange = (e: SelectChangeEvent): void => {
        const statusValue = e.target.value ?? 'all';

        setFilterStatus(statusValue as DB_STATUS);

        // console.log({ statusField, statusValue });

        if (statusField?.type === 'select') {
            const newFilters = props.filterMap.get(statusField.name) ?? []
            newFilters.splice(0)
            props.setFilterMapItem(statusField.name, newFilters);

            if (statusValue === 'all') {
                return;
            }

            setTimeout(() => addStatusFilter(props, statusField, statusValue), 0);
        }
    };

    let statusOptions = StatusLabelMaps;
    switch (statusField?.name) {
        case 'status':
            statusOptions = StatusLabelMaps;
            break;
        case 'txType':
            statusOptions = TxTypeLabelMaps;
            break;
        case 'txStatus':
            statusOptions = TxStatusLabelMaps;
            break;
        default:
            break;
    }

    return (
        <>
            <Grid container>
                <Grid container display="flex" flexDirection="column">
                    <Toolbar sx={{ pl: { xs: 0 }, pr: { xs: 0 }, mb: 1 }}>
                        <Typography
                            id="tableTitle"
                            sx={{ flex: '1 1 100%' }}
                            variant="h3"
                            component="h3"
                            fontSize={20}
                        >
                            {props.title}
                        </Typography>

                        {props?.actions !== undefined && props?.actions.map((action, actionIndex) => (
                            <Tooltip key={`action${actionIndex}`} title={action.title.toUpperCase()}>
                                <MyIconButton onClick={() => action.event(true)}>
                                    <Icon>{action.icon}</Icon>
                                </MyIconButton>
                            </Tooltip>
                        ))}

                        {props.settings?.export && (
                            <Tooltip title="Export Data">
                                <MyIconButton onClick={e => props.filterApply({ download: 'link' })}>
                                    <Icon className='material-icons-outlined'>file_download</Icon>
                                </MyIconButton>
                            </Tooltip>
                        )}

                        {props.fields.length > 0 && (
                            <Tooltip title="Apply Filter">
                                <MyIconButton onClick={filterToggleHandle}>
                                    <Icon>{state.filterData?.status ? 'filter_list' : 'filter_list_off'}</Icon>
                                </MyIconButton>
                            </Tooltip>
                        )}
                    </Toolbar>
                </Grid>

                {(state.filterData?.status && props.fields.length > 0) && (
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4}>
                            <Item>
                                {!searchValue ? <SearchBar searchTable={searchTable} enable={Boolean(searchField?.search)} /> : !searchField?.search ? (<></>) : (
                                    <SearchBoxFilter
                                        field={searchField}
                                        searchValue={searchValue}
                                        filterApply={props.filterApply}
                                        columnFilters={props.filterMap.get(searchField.name) ?? []}
                                        setColumnFilters={(filters) => {
                                            props.setFilterMapItem(searchField.name, filters);
                                        }}
                                    />
                                )}
                            </Item>
                        </Grid>

                        <Grid item xs={2} sm={4} md={4}>
                            <Item>
                                <FormControl fullWidth>
                                    <InputLabel size='small' id="filterStatus-label">Status</InputLabel>
                                    <Select
                                        size='small'
                                        label="Status"
                                        id="filterStatus"
                                        labelId="filterStatus-label"
                                        value={filterStatus || 'all'}
                                        onChange={handleStatusChange}
                                    >
                                        <MenuItem key="all" value="all">ALL</MenuItem>
                                        {Object.keys(statusOptions).length && Object.entries(statusOptions).map((item) => (
                                            <MenuItem key={item[0]} value={item[0]}>
                                                {item[1].text}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Item>
                        </Grid>

                        <Grid item xs={2} sm={4} md={4}>
                            <Item>
                                <FormControl fullWidth>
                                    <InputLabel size='small' id="focusedFilterField-label">Add Filter</InputLabel>
                                    <Select
                                        size='small'
                                        label="Add Filter"
                                        id="focusedFilterField"
                                        labelId="focusedFilterField-label"
                                        value={focusedFilterField?.name || ''}
                                        onChange={(e) => {
                                            setFocusedFilterField(props.fields.find((f) => f.name === e.target.value as keyof T))
                                        }}
                                    >
                                        {props.fields.length && props.fields.map((option) => (
                                            <MenuItem key={`opt-${option.name as string}`} value={option.name as string}>
                                                {option.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Item>
                            <Item sx={{ mt: 2 }}>
                                {focusedFilterField !== undefined && (
                                    <ColumnFilter
                                        field={focusedFilterField}
                                        filterApply={props.filterApply}
                                        columnFilters={props.filterMap.get(focusedFilterField.name) ?? []}
                                        setColumnFilters={(filter) => {
                                            props.setFilterMapItem(focusedFilterField.name, filter);
                                        }}
                                    />
                                )}
                            </Item>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
}