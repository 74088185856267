import { Box as _Box, CircularProgress, styled } from '@mui/material';

export const Box = styled(_Box)(({
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
}));

interface LoaderProps {
  sx?: object | {};
}

const SuspenseLoader: React.FC<LoaderProps> = ({ sx }: LoaderProps) => {
  return (
    <Box
      display="flex"
      alignItems="end"
      justifyContent="end"
      sx={{ ...sx }}
    >
      <CircularProgress
        size={32}
        disableShrink thickness={3}
        sx={{ marginRight: 3, marginBottom: 3 }} />
    </Box>
  );
}

export default SuspenseLoader;
