import { Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';

export const AboutPage = () => {
  return (
    <Container disableGutters maxWidth={'md'}>
      <Grid container sx={{ display: 'flex' }}>
        <Grid item xs={12} my={3}>
          <Card
            sx={{
              // maxWidth: 460,
              boxShadow: 'none',
              backgroundColor: 'transparent'
            }}
          >
            <CardMedia
              // height="140"
              alt="about"
              component="img"
              image="/images/about_us.png"
            />
            <CardContent sx={{ px: 0 }}>
              <Typography variant="h1" sx={{ pb: 1 }} align='center'>
                About Us
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 1 }}>
                Zion Trader is a leading provider of reliable and comprehensive wallet services for institutional solutions. Our state-of-the-art solution simplifies cryptocurrency management, allowing businesses practical access, management, and transactions with various digital assets. As a trusted name in the industry, we offer a highly secure and user-friendly wallet platform that supports major cryptocurrencies.
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ pb: 1 }}>
                We utilize the latest security measures and technologies to empower users with a robust tool for safeguarding and transacting with their digital assets. Positioned to understand our customers' needs and provide tailored solutions, we are here to help you manage your digital assets effectively.
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Collaborate with us to optimize your digital asset strategies under Zion Trader's leadership and step confidently into the financial future, well-prepared and secure.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};