import { object, string, TypeOf } from "zod";
import { useEffect } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useStore from "store";
import zionApi from "api/zionApi";
import { LoadingButton } from "components/Buttons/LoadingButton";
import { Box, Container, FormControl, Grid, TextField, Typography } from "@mui/material";
import useUserContext from "hooks/useUserContext";
import FormInput from "components/FormInput";
import { LinkItem } from "components/Buttons/LinkItem";
import { AboutCard } from "./about.card";
import { MyFormHelperText } from "components/Forms/MyFormHelper";
import { useQuery } from "@tanstack/react-query";
import { getMeFn } from "api/authApi";
import { setItem } from "helpers/utils";
import { ILoginResponse, IValidateOTP } from "models/db/User";
import notification from "helpers/notification";

const validate2faSchema = object({
  token: string().min(1, "Authentication code is required"),
});

export type Validate2faInput = TypeOf<typeof validate2faSchema>;

const Validate2faPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const store = useStore();
  const { authState, authDispatch } = useUserContext()

  const from = ((location.state as any)?.from.pathname as string) || '/dashboard';

  const methods = useForm<Validate2faInput>({
    resolver: zodResolver(validate2faSchema),
  });

  // API Get Current Logged-in user
  const userQuery = useQuery(['user'], () => getMeFn({ client: 'otp' }), {
    retry: 1,
    enabled: false,
    select: (result) => result.data,
    onSuccess: (user) => {
      authDispatch({ type: 'SET_USER', payload: user });
      store.setRequestLoading(false);
      store.setAuthUser(user);
      navigate(from);
    },
    onError: () => {
      store.setRequestLoading(false);
    }
  });

  const validate2fa = async (token: string) => {
    try {
      store.setRequestLoading(true);
      const { data } = await zionApi.post<IValidateOTP>("/auth/otp/validate", {
        validate: {
          token, userId: store.authUser?.id
        }
      });
      store.setRequestLoading(false);

      if (!data.otpValid) {
        throw new Error('Not valid your authentication code')
      }

      setItem('accessToken', data.accessToken)
      setItem('refreshToken', data.refreshToken)
      store.setRequestLoading(true);
      userQuery.refetch();
      notification.success('You successfully logged in');
    } catch (error: any) {
      store.setRequestLoading(false);
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      toast.error(resMessage, {
        position: "top-right",
      });
    }
  };

  const onSubmitHandler: SubmitHandler<Validate2faInput> = (values) => {
    validate2fa(values.token);
  };

  useEffect(() => {
    methods.setFocus("token");
  }, [methods.setFocus]);

  useEffect(() => {
    if (!store.authUser) {
      navigate("/login");
    }
  }, []);

  return (
    <Container disableGutters maxWidth={authState.user ? 'md' : false}>
      <Grid container sx={{ display: 'flex' }}>

        {!authState.user && (
          <Grid item xs={12} md={5} my={3}>
            <FormProvider {...methods}>
              <Box
                component='form'
                onSubmit={methods.handleSubmit(onSubmitHandler)}
                noValidate
                // autoComplete='off'
                width='100%'
                sx={{
                  backgroundColor: '#fff',
                  p: { xs: '1rem', sm: '2rem' },
                  borderRadius: 2,
                  maxWidth: { xs: '100%', md: '28rem' }
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h1" component="h1" sx={{ mb: 1 }}>Sign in</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h4" component="h4">
                      Welcome Back!
                    </Typography>
                    <Typography variant="subtitle2">
                      Two-Factor Authentication
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle2">
                    Open the two-step verification app on your mobile device to get your verification code.
                  </Typography>
                </Box>

                {/* <FormInput {...methods.register('token')} icon='phonelink_lock' label='Authentication Code' placeholder='Authentication Code' /> */}

                <FormControl fullWidth sx={{ mt: 1 }}>
                  <TextField fullWidth
                    label='Authentication Code'
                    {...methods.register('token')}
                  />
                  <MyFormHelperText error={!!methods.formState.errors.token} message={methods.formState.errors.token?.message} />
                </FormControl>

                <LoadingButton
                  fullWidth
                  type='submit'
                  variant='contained'
                  disableElevation
                  loading={store.requestLoading}
                  sx={{ mt: 1, mb: 3 }}
                >
                  Login
                </LoadingButton>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography>
                      Need an account? <LinkItem to='/register'>Sign Up Here</LinkItem>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      <LinkItem to='/forgotpassword'>
                        Forgot Password?
                      </LinkItem>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </FormProvider>
          </Grid>
        )}

        <Grid item xs={12} md={authState.user ? 12 : 7} my={3}>
          <AboutCard />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Validate2faPage;
