import zionApi from 'api/zionApi';

import { FilterGroup } from 'components/DataTable/filter/FilterGroup';
import { DTResponse } from 'models/Generic';
import { DB_Transaction, TransactionFieldName } from 'models/db/Transaction';

export const getFilteredTransactions = async (
  filterParams: FilterGroup<TransactionFieldName>
): Promise<DTResponse<DB_Transaction>> => {
  const response = await zionApi.get<DTResponse<DB_Transaction>>(`admin/transactions`, {
    params: { filterParams }
  });

  response.data.lastParams = filterParams;
  return response.data;
};