import { DB_STATUS } from "models/Generic";

export interface DB_Todo {
    id: number;
    title: string;
    content: string;
    category: string;
    image: string | null;
    createdAt: string;
    status: DB_STATUS;
    actions: string | null;
}

export interface DB_Todos {
    status: string;
    data: DB_Todo[];
    totalRows: number;
}

export type TodoFieldName = keyof DB_Todo;

export const nameMapping_Todo: { [key in keyof DB_Todo]: TodoFieldName } = {
    id: "id",
    title: 'title',
    content: 'content',
    category: 'category',
    image: 'image',
    status: "status",
    createdAt: "createdAt",
    actions: "actions",
};