import { Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';

export const AML_KYCPage = () => {
  return (
    <Container disableGutters maxWidth={'md'}>
      <Grid container sx={{ display: 'flex' }}>
        <Grid item xs={12} my={3}>
          <Card
            sx={{
              boxShadow: 'none',
              backgroundColor:'transparent'
            }}
          >
            <CardContent sx={{ px: 0 }}>
              <Typography variant="h1" sx={{ pb: 1 }} align='center'>
                AML & KYC (Know Your Customer)
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                KYC is a regulatory framework implemented by financial institutions and other businesses to verify and gather essential information about their customers. The aim is to prevent money laundering, terrorist financing, fraud, and other illicit activities.
              </Typography>
              
              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Key elements of KYC policies include:
              </Typography>
                
              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Customer Identification: Financial institutions are required to establish the identity of their customers through reliable and independent documentation. This typically includes obtaining a valid identification document such as a passport, national identity card, or driver's license.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Verification of Identity: Institutions must verify the authenticity of the provided identification documents by conducting appropriate checks. This may involve contacting relevant authorities, using electronic identity verification systems, or employing other reliable methods.
              </Typography>
              
              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Beneficial Ownership: Institutions need to identify the beneficial owners of legal entities, such as companies or trusts, with whom they establish a business relationship. They should gather information on individuals who ultimately own or control these entities.
              </Typography>
              
              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Risk Assessment: Institutions must assess the risk associated with each customer based on factors such as their location, business activities, source of funds, and reputation. Higher-risk customers require enhanced due diligence procedures.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Anti-Money Laundering (AML): AML refers to the measures taken to detect, prevent, and deter money laundering and terrorist financing. 
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Key aspects of AML policies include:
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Suspicious Transaction Reporting: Financial institutions are obligated to report any transactions or activities that appear suspicious or potentially related to money laundering or terrorist financing. They have a duty to monitor customer transactions and promptly notify the relevant authorities of any suspicions.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Customer Due Diligence (CDD): CDD procedures involve assessing the risk associated with a customer, verifying their identity, and understanding the nature of their transactions. This helps institutions determine the level of scrutiny required and ensures compliance with AML regulations.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Record Keeping: Institutions must maintain adequate records of customer transactions, identity verification, and due diligence measures for a specified period. These records should be available for inspection by regulatory authorities.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Documentation: The required documentation for KYC and AML compliance typically includes:
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Customer Identification Documents: Valid identification documents, such as passports, national identity cards, or driver's licenses.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Proof of Address: Documents that verify the customer's residential or business address, such as utility bills, bank statements, or official correspondence.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Business Documents: In the case of legal entities, documentation pertaining to their incorporation, ownership structure, and authorized signatories may be required.              
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Transaction Records: Financial institutions are expected to maintain records of customer transactions, including details of the amounts involved, parties involved, and the purpose of the transactions.             
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                It's important to note that the specific requirements and documentation may vary based on the type of institution, the nature of the business relationship, and the level of risk associated with the customer.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};