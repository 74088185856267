import { useState, ChangeEvent, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';

import { styled } from '@mui/material/styles';
import { Tabs, Tab, Grid, Divider, Card } from '@mui/material';
import SuspenseLoader from 'components/SuspenseLoader';

import { useParams } from 'react-router-dom';

import { getUser } from 'models/api/User';
import { DB_User } from 'models/db/User';
import { useFetch } from 'hooks/useFetch';

import UserProfileTab from './ProfileTab';
import MemberLogsTab from './LogsTab';

const TabsWrapper = styled(Tabs)(({ theme }) => ({
  scrollableX: {
    overflowX: "auto !important"
  }
}));

function MemberDetail() {
  const params = useParams()

  const [currentTab, setCurrentTab] = useState<string>('profile');
  const tabs = [
    { value: 'profile', label: 'Profile' },
    { value: 'logs', label: 'User Logs' },
  ];

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const userId = params.userId;

  // const { data: targetUser, error, loading } = useFetch()
  const { data: targetUser, error, loading, refetch } = useFetch<DB_User>({
    qkey: `users/${userId}`,
    callback: async () => await getUser(String(userId))
  })

  useEffect(() => {
    refetch()
  }, [])

  if (loading) return <SuspenseLoader />
  if (error) {
    console.error(error);
    return <>There is an error!</>
  }
  if (!targetUser?.id) return <></>

  return (
    <>
      <Helmet><title>Users</title></Helmet>

      <Grid sx={{ mb: 3 }}>
        <PageHeader targetUser={targetUser} />
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <TabsWrapper
            onChange={handleTabsChange}
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </TabsWrapper>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          {currentTab === 'profile' && <UserProfileTab targetUser={targetUser} />}
          {currentTab === 'logs' && <MemberLogsTab targetUser={targetUser} />}
        </Grid>
      </Grid>
    </>
  );
}

export default MemberDetail;
