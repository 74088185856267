import { RES_STATUS } from "models/Generic";

export interface DB_UserLog {
    id: string,
    level: string,
    method: string | null,
    request: {
        path: [],
        query: {},
        body: {},
        params: {},
    },
    status: RES_STATUS,
    statusCode: string | null,
    message: string | null;
    osName: string | null;
    osVersion: string | null;
    browserName: string | null;
    browserVersion: string | null;
    responseTime: string | null;
    httpVersion: string | null;
    remoteFamily: string | null;
    remoteAddress: string | null;
    timestamp: string | null;
}

export interface DB_UserLogs {
    status: string;
    data: DB_UserLog[];
    totalRows: number;
}

export type UserLogFieldName = keyof DB_UserLog;

export const nameMapping_UserLog: { [key in keyof DB_UserLog]: UserLogFieldName } = {
    id: "id",
    level: "level",
    method: "method",
    request: "request",
    status: "status",
    statusCode: "statusCode",
    message: "message",
    osName: "osName",
    osVersion: "osVersion",
    browserName: "browserName",
    browserVersion: "browserVersion",
    responseTime: "responseTime",
    httpVersion: "httpVersion",
    remoteFamily: "remoteFamily",
    remoteAddress: "remoteAddress",
    timestamp: "timestamp",
};