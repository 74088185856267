import React from "react";

interface Props {
    time: number;
}

interface State {
    init: boolean;
    time: number;
    minute: number;
    seconds: number;
}

export default ({ time }: Props): State => {
    const [state, setState] = React.useState<State>({
        init: false,
        time,
        minute: 0, // time - Math.floor((time - 1) / 60) * 60 - 1,
        seconds: 0, // Math.floor((time - 1) / 60),
    })

    React.useEffect(() => {
        setTimeout(() => {
            if (state.time === 0) {
                return;
            }

            setState({
                init: true,
                time: state.time - 1,
                minute: Math.floor((state.time - 1) / 60),
                seconds: state.time - Math.floor((state.time - 1) / 60) * 60 - 1,
            });
        }, 1000);
    }, [state.time]);


    return state;
}