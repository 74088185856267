import React, { Dispatch, SetStateAction } from 'react';
import { useMutation } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { updateItemFn } from 'api/sweeperApi';

import dayjs from 'dayjs';
import MyDateRangePicker from 'components/Date/MyDateRangePicker';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MyFormHelperText } from 'components/Forms/MyFormHelper';
import { DB_STATUS } from 'models/Generic';
import { DB_FREQUENCY, DB_Sweeper } from 'models/db/Sweeper';

interface IUpdateItemProp {
  item: DB_Sweeper;
  setRefetch: Dispatch<SetStateAction<number>>;
  setOpenCrudModal: (openCrudModal: boolean) => void;
}

const updateItemSchema = z.object({
  status: z.nativeEnum(DB_STATUS),
  amount: z.number(),
  frequency: z.nativeEnum(DB_FREQUENCY),
  dateRange: z.array(z.date()).optional(),
  startDate: z.date(),
  stopDate: z.date(),
  startTime: z.date(),
});

export type TUpdateItem = z.TypeOf<typeof updateItemSchema>;

const EditForm: React.FC<IUpdateItemProp> = ({ item, setRefetch, setOpenCrudModal }) => {
  const defaultValues: TUpdateItem = {
    status: item.status,
    amount: item.amount,
    frequency: item.frequency as DB_FREQUENCY,
    dateRange: [dayjs(item.startDate).toDate(), dayjs(item.stopDate).toDate()],
    startDate: dayjs(item.startDate).toDate(),
    stopDate: dayjs(item.stopDate).toDate(),
    startTime: dayjs(item.startTime).toDate(),
  }

  const [rules, updateRules] = React.useReducer(
    (state: TUpdateItem, newState: Partial<TUpdateItem>) => ({ ...state, ...newState, }), { ...defaultValues }
  );

  const [dateRange, setDateRange] = React.useState<Date[] | null[]>([rules.startDate as Date, rules.stopDate as Date]);

  const queryUpdateSweeper = useMutation(({ id, inputData }: { id: string; inputData: TUpdateItem }) => updateItemFn({ id, inputData }), {
    onSuccess: () => {
      setRefetch(c => ++c)
      notification.success('Sweeper updated successfully');
      setOpenCrudModal(false);
      methods.reset({ ...rules });
    }
  });

  const methods = useForm<TUpdateItem>({ resolver: zodResolver(updateItemSchema) });

  const { formState: { errors: formErrors, isSubmitted }, } = methods;

  if (isSubmitted && Object.keys(formErrors).length) {
    console.log({ formErrors, values: methods.getValues() });
    Object.values(formErrors).map((err) => notification.error(err?.message as string));
  }

  const onSubmitHandler: SubmitHandler<TUpdateItem> = (formValues) => {
    queryUpdateSweeper.mutate({ id: item.id, inputData: formValues })
  };

  React.useEffect(() => {
    // console.log('dateRange', dateRange);
    if (dateRange[0] && dateRange[1]) {
      updateRules({ "startDate": dateRange[0] })
      updateRules({ "stopDate": dateRange[1] })
    }
  }, [dateRange])


  React.useEffect(() => {
    // console.log('startDate', rules.startDate, rules.stopDate);
    if (rules.startDate && rules.stopDate) {
      methods.setValue('startDate', rules.startDate, { shouldValidate: true })
      methods.setValue('stopDate', rules.stopDate, { shouldValidate: true })
    }
  }, [rules.startDate, rules.stopDate])

  React.useEffect(() => {
    console.log('startTime', rules.startTime);
  }, [rules.startTime])

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Edit Sweep Rule Id#{item.id}
        </Typography>

        <CloseButton loading={queryUpdateSweeper.isLoading}
          onClick={() => setOpenCrudModal(false)}>
          <Icon>close</Icon>
        </CloseButton>
      </Box>

      <FormProvider {...methods}>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="status-label">Select Status</InputLabel>
            <Controller
              name="status"
              control={methods.control}
              defaultValue={rules.status}
              render={({ field: { onChange: fieldOnChange, ...field } }) => (
                <Select {...field}
                  label="Select Status"
                  labelId="status-label"
                  onChange={event => {
                    fieldOnChange(event)
                  }}
                >
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="SUSPEND">SUSPEND</MenuItem>
                  {/* <MenuItem value="DELETED">DELETED</MenuItem> */}
                </Select>
              )}
            />

            <MyFormHelperText error={!!formErrors?.status} message={formErrors?.status?.message} />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              {...methods.register('amount', { valueAsNumber: true, setValueAs: v => parseFloat(v) })}
              label='Amount'
              fullWidth
              value={rules.amount}
              type="number"
              inputProps={{ min: 0 }}
              onChange={(e) => updateRules({ "amount": parseFloat(e.target.value) })}
            />

            <MyFormHelperText error={!!formErrors?.amount} message={formErrors?.amount?.message} />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="frequency-label">Choose Frequency</InputLabel>

            <Select {...methods.register('frequency')}
              label="Choose Frequency"
              labelId="frequency-label"
              value={rules.frequency}
              onChange={(e) => updateRules({ "frequency": e.target.value as DB_FREQUENCY })}
            >
              {Object.values(DB_FREQUENCY).map((fval, i) => (
                <MenuItem key={i} value={fval}>{fval.toUpperCase()}</MenuItem>
              ))}
            </Select>

            <MyFormHelperText error={!!formErrors?.frequency} message={formErrors?.frequency?.message} />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel sx={{ mb: 2 }}>
              Start - End Date
            </FormLabel>

            <Controller
              name="dateRange"
              control={methods.control}
              defaultValue={[dayjs(rules.startDate).toDate(), dayjs(rules.stopDate).toDate()]}
              render={({ field: { onChange, value } }) => (
                <MyDateRangePicker
                  separator="to"
                  values={value || []}
                  onChangeFn={value => setDateRange(value)}
                />
              )}
            />

            {/* <MyFormHelperText error={!!formErrors?.startDate} message={formErrors?.startDate?.message} />
            <MyFormHelperText error={!!formErrors?.stopDate} message={formErrors?.stopDate?.message} /> */}
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <Controller
              name="startTime"
              control={methods.control}
              defaultValue={dayjs(rules.startTime).toDate()}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <TimePicker
                      renderInput={(params) => <TextField {...params} />}
                      label="Start Time"
                      value={field.value}
                      onChange={(newValue) => {
                        updateRules({ "startTime": dayjs(newValue).toDate() })
                        field.onChange(dayjs(newValue).toDate())
                      }}
                      shouldDisableTime={(timeValue, clockType) => {
                        if (clockType === 'minutes' && timeValue % 15) {
                          return true
                        }

                        return false
                      }}
                    />
                  </Stack>
                </LocalizationProvider>
              )}
            />

            <MyFormHelperText error={!!formErrors?.startTime} message={formErrors?.startTime?.message} />
          </FormControl>

          <Grid container justifyContent="center" >
            <LoadingButton
              variant='contained'
              type='submit'
              loading={queryUpdateSweeper.isLoading}
            >
              Save
            </LoadingButton>
          </Grid>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default EditForm;