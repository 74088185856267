import dayjs from "dayjs";
import { ColumnDefinitionType } from "components/DataTable/Table";
import { DB_UserLog, UserLogFieldName } from "models/db/UserLog";
import { GenericField } from "components/DataTable/filter/FilterType";
import { getStatusResLabel } from "components/Label/StatusResLabel";

export const cols_UserLog: Array<ColumnDefinitionType<DB_UserLog>> = [
    {
        key: 'level',
        header: 'Log',
        cell: (item) => {
            return item.request?.path ? JSON.stringify(item.request.path) : '';
        },
    },
    // {
    //     key: 'responseTime',
    //     header: 'Time',
    // },
    // {
    //     key: 'method',
    //     header: 'Method',
    // },
    // {
    //     key: 'request',
    //     header: 'Path',
    //     cell: (item) => {
    //         return item.request?.path ? JSON.stringify(item.request.path) : '';
    //     },
    // },
    {
        key: 'status',
        header: 'Status',
        cell: (item) => getStatusResLabel(item.status || 'info'),
    },
    // {
    //     key: 'statusCode',
    //     header: 'Code',
    // },
    // {
    //     key: 'message',
    //     header: 'message',
    // },
    {
        key: 'osName',
        header: 'Os.Name',
    },
    {
        key: 'osVersion',
        header: 'Os.Version',
    },
    {
        key: 'browserName',
        header: 'Browser',
        cell: (item) => `${item.browserName} (${item.browserVersion})`,
    },
    // {
    //     key: 'httpVersion',
    //     header: 'http',
    // },
    {
        key: 'remoteAddress',
        header: 'Ip',
        cell: (item) => `${item.remoteAddress}`,
    },
    {
        key: 'timestamp',
        header: 'Timestamp',
        cell: (item) => dayjs(item.timestamp).format('YYYY-MM-DD HH:mm:ss'),
    },

];

export const fields_UserLog: Array<GenericField<DB_UserLog>> = [
    {
        name: 'level',
        title: 'Log Level',
        type: 'select',
        selectOptions: ['info', 'error']
    },
    {
        name: 'timestamp',
        title: 'Date',
        type: 'date',
    },
];