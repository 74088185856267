import Label from ".";
import { PRIMARY_COLOR } from "models/Generic";
import { TX_STATUS } from "models/db/Transaction";

type TX_STATUS_LABEL = {
    [key in keyof typeof TX_STATUS]: {
        text: keyof typeof TX_STATUS;
        color: PRIMARY_COLOR;
    }
}

export const TxStatusLabelMaps: TX_STATUS_LABEL = {
    ERROR: {
        text: 'ERROR',
        color: 'error',
    },
    FAILED: {
        text: 'FAILED',
        color: 'warning',
    },
    SUCCESS: {
        text: 'SUCCESS',
        color: 'success',
    },
    PENDING: {
        text: 'PENDING',
        color: 'primary',
    },
    CANCELED: {
        text: "CANCELED",
        color: "black"
    },
    PROCESSING: {
        text: 'PROCESSING',
        color: 'secondary',
    },
};

export const getTxStatusLabel = (status: TX_STATUS): React.JSX.Element => {
    const { text, color } = TxStatusLabelMaps[status];
    return <Label color={color}>{text}</Label>;
};