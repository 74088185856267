import { DB_Transaction, DB_Transactions } from 'models/db/Transaction';
import zionApi from './zionApi';
import { GenericResponse } from 'models/Generic';

// import { TCreateItem } from 'pages/Transaction/forms/AddForm';
// import { TUpdateItem } from 'pages/Transaction/forms/EditForm';

export const getAllItemsFn = async (params: object = {}) => {
  const response = await zionApi.get<DB_Transactions>(`transactions` + (Object.keys(params).length ? `?${new URLSearchParams(Object.entries(params)).toString()}` : ''));
  return response.data;
};

export const getItemFn = async (id: string) => {
  const response = await zionApi.get<GenericResponse<DB_Transaction>>(`transactions/${id}`);
  return response.data;
};

export const createItemFn = async (inputData: object) => {
  const response = await zionApi.post<GenericResponse<DB_Transaction>>(`transactions`, inputData);
  return response.data;
};

export const updateItemFn = async ({ id, inputData }: {
  id: string;
  inputData: object;
}) => {
  const response = await zionApi.patch<GenericResponse<DB_Transaction>>(`transactions/${id}`, inputData);
  return response.data;
};

export const deleteItemFn = async (id: string) => {
  const response = await zionApi.delete<GenericResponse<DB_Transaction>>(`transactions/${id}`);
  return response.data;
};
