import React from "react";
import { useSearchParams } from "react-router-dom";

import {
  Box,
  Typography,
  Container,
  Avatar,
  Stack,
  IconButton,
  Icon,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Alert,
  CircularProgress,
} from "@mui/material";

import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import DataLoader from "components/Loaders/DataLoader";
import { useMutation } from "@tanstack/react-query";
import { getQrCodeFn } from "api/addrApi";
import copy from "copy-to-clipboard";
import { DB_DepositPay } from "models/db/DepositPay";
import { getDeposit } from "models/api/Deposit";
import { format, parseISO } from 'date-fns';
import { ceil, formatBalance } from "helpers/utils";
import useTimer from "hooks/useTimer";
import { useFetch } from "hooks/useFetch";
import { GenericResponse } from "models/Generic";

const MainContent = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flex: "1",
  overflow: "auto",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export default (): React.JSX.Element => {
  const timer = useTimer({ time: 0 });
  const [params] = useSearchParams();
  const [token] = React.useState<string>(params.get("token") as string);
  const [payment, setPayment] = React.useState<DB_DepositPay | null>(null);
  const [addressQr, setAddressQr] = React.useState<string>();

  const [tooltipText, setToolTipText] = React.useState("copy me");
  const copyToClipboard = (text: string) => {
    copy(text);
    setToolTipText("👍 Copied!");
  };

  const deposit = useFetch<GenericResponse<DB_DepositPay>>({
    callback: async (props: { token: string }) => await getDeposit(props.token)
  })

  const qrCode = useFetch<string>({
    callback: async (address: string) => await getQrCodeFn({ data: address })
  })

  React.useEffect(() => {
    if (payment) {
      if (!addressQr) {
        qrCode.refetch(payment.wallet?.address as string)
      }
    }
  }, [payment])

  React.useEffect(() => deposit.refetch({ token }), [token])

  React.useEffect(() => {
    if (deposit.action === 'fetched') {
      if (deposit.data) {
        const { data, status } = deposit.data;
        setPayment(data)

        if (deposit.counter === 1) {
          timer.time = data.timeLeft;
        }
      }
    }
  }, [deposit.action])

  React.useEffect(() => {
    if (qrCode.action === 'fetched' && qrCode.data) {
      setAddressQr(qrCode.data)
    }
  }, [qrCode.action])

  React.useEffect(() => {
    if (timer.init && timer.time % 30 === 0) {
      deposit.refetch({ token })
    }
  }, [timer.time])

  return (
    <>
      <Helmet><title>Deposit</title></Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box>
            <Stack direction="column" alignItems="center">
              {!payment || !addressQr ? <DataLoader /> : (
                <>
                  <Typography variant="h3" sx={{ my: 2 }}>
                    Your Deposit Details
                  </Typography>

                  <Stack direction="row">
                    <Box>
                      <Avatar src={addressQr} alt="QR" variant="square" sx={{ width: 250, height: 250 }} />
                    </Box>

                    <Stack direction="column">
                      <Box sx={{ ml: 2 }}>
                        <Typography>Recepient"s wallet address</Typography>
                        <Stack direction="row">
                          <Box>
                            <Typography component="span">{payment.wallet?.address}</Typography>
                          </Box>
                          <Tooltip arrow title={tooltipText} TransitionProps={{ onExited: () => setToolTipText("copy me") }}>
                            <IconButton aria-label="copy" size="small" onClick={e => copyToClipboard(payment.wallet?.address as string)}>
                              <Icon fontSize="inherit">content_copy</Icon>
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </Box>

                      <Box sx={{ ml: 2, mt: 2 }}>
                        <Table sx={{ "td,th": { border: 'none', p: 0 }, width: 'auto', }}>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th">Amount</TableCell>
                              <TableCell style={{ padding: "0 10px 0 10px" }}>:</TableCell>
                              <TableCell>{payment.amount} {payment.currency?.toUpperCase()}</TableCell>
                              <TableCell style={{ padding: "0 10px 0 10px" }}>≈</TableCell>
                              <TableCell>{ceil(payment.toAmount, 12)} {payment.toCurrency?.toUpperCase()}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell component="th">Balance (USDT)</TableCell>
                              <TableCell style={{ padding: "0 10px 0 10px" }}>:</TableCell>
                              <TableCell colSpan={3}>{formatBalance(payment.balance, 4)} {payment.toCurrency}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell component="th" colSpan={3}>
                                <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                                  Deposits
                                </Typography>
                              </TableCell>

                              <TableCell component="th" colSpan={2} sx={{ textAlign: 'right' }}>
                                <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                                  {timer.minute >= 10 ? timer.minute : `0${timer.minute}`}
                                  :
                                  {timer.seconds >= 10 ? timer.seconds : `0${timer.seconds}`}
                                </Typography>
                              </TableCell>
                            </TableRow>

                            {Number(payment.deposits?.length) > 0 && payment.deposits?.map((deposit, i) => (
                              <TableRow key={deposit.id}>
                                <TableCell component="th">Deposit ({deposit?.currency?.symbol})</TableCell>
                                <TableCell style={{ padding: "0 10px 0 10px" }}>:</TableCell>
                                <TableCell>{deposit.amount} {deposit?.currency?.symbol}</TableCell>
                                <TableCell style={{ padding: "0 10px 0 10px" }}>/</TableCell>
                                <TableCell>{format(parseISO(deposit.createdAt), 'PPpp')}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>

                      <Box sx={{ ml: 2, mt: 2, flexGrow: 1, display: 'flex' }}>
                        <Box sx={{ alignSelf: 'flex-end' }}>
                          {Number(payment?.balance) >= Number(payment?.toAmount) ? (
                            <Alert severity="success">
                              Congratulations, your deposit is complete.
                            </Alert>
                          ) : (payment.timeLeft > 0) ? (
                            <Alert severity="info" icon={<CircularProgress color="success" size={22} />}>
                              Awaiting Payment From You
                            </Alert>
                          ) : (
                            <Alert severity="error" icon={<Icon fontSize='inherit'>close</Icon>}>
                              Timeout!
                            </Alert>
                          )}
                        </Box>
                      </Box>
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>
          </Box>
        </Container>
      </MainContent>
    </>
  );
}