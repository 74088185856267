import { Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';

export const EnterprisePage = () => {
  return (
    <Container disableGutters maxWidth={'md'}>
      <Grid container sx={{ display: 'flex' }}>
        <Grid item xs={12} my={3}>
          <Card
            sx={{
              boxShadow: 'none',
              backgroundColor: 'transparent'
            }}
          >
            <CardMedia
              alt="about"
              component="img"
              image="/images/enterprise.png"
            />
            <CardContent sx={{ px: 0 }}>
              <Typography variant="h1" sx={{ pb: 1 }} align='center'>
                Enterprise Solution
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 1 }}>
                Enhance your blockchain experience and streamline the process of managing your digital assets with our comprehensive wallet solution. Now, you can seamlessly access different blockchains through a single API without the need to deal with integrations separately. This makes managing your digital asset portfolio more efficient and saves you time and effort, allowing you to focus on your business.
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ pb: 1 }}>
                Our wallet service not only provides access to your crypto assets but also enables you to transact with cryptocurrencies. Enjoy advanced features like sweep rules for regular transfers, making it easier to deposit, send, withdraw, and exchange digital assets effectively.
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Experience the convenience of accessing all these capabilities in one place. Transitioning between blockchains, exchanging different cryptocurrencies, or monitoring the performance of your digital assets is now more straightforward. Simplify your crypto transactions with Zion Trader and enjoy being ahead in the financial world of the future.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};