import { Concat, DB_STATUS } from "models/Generic";

export enum MAIN_CURRENCY {
    TRY = 'TRY',
    USD = 'USD',
    EURO = 'EURO',
}

export enum CRYPTO_SYMBOL {
    BTC = 'BTC',
    ETH = 'ETH',
    TRX = 'TRX',
    USDT = 'USDT',
    SOL = 'SOL',
}

export enum CRYPTO_NAME {
    Bitcoin = 'Bitcoin',
    Ethereum = 'Ethereum',
    Tron = 'Tron',
    Tether = 'Tether',
    Solana = 'Solana',
}

export type CRYPTO_NAMES = {
    [key in keyof typeof CRYPTO_SYMBOL]: keyof typeof CRYPTO_NAME
}

export const cryproNames: CRYPTO_NAMES = {
    BTC: "Bitcoin",
    ETH: "Ethereum",
    TRX: "Tron",
    USDT: "Tether",
    SOL: "Solana",
}

export type IBaseCurrency = 'USDT' | 'TRY';
export type IMainCurrency = 'BTC' | 'ETH' | 'TRX' | 'USDT' | 'SOL';
export type ICurrencyPair = Concat<[IMainCurrency, IBaseCurrency]>;

export interface ISparklineData {
    times: number[];
    labels: string[];
    highest: number[];
}

export interface DB_Currency {
    id: string;
    name: CRYPTO_NAME;
    symbol: CRYPTO_SYMBOL;
    checkUrl: string;
    description: string;
    depositStatus: string;
    withdrawStatus: string;
    status: DB_STATUS;
    createdAt: string;
    updatedAt: string;
    totalBalance: number;
    totalUsdtBalance: number;
    marketData?: {
        pair: ICurrencyPair;
        price: string;
        change: string;
        volume24h: string;
        increase: '+' | '-';
        sparkline?: ISparklineData;
    }
}

export interface DB_Currencies {
    status: string;
    data: DB_Currency[];
    totalRows: number;
}