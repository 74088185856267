import React, { Dispatch, SetStateAction } from "react";
import { useMutation } from "@tanstack/react-query";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide, Tooltip } from "@mui/material";
import {
  EditTwoTone as EditTwoToneIcon,
  DeleteTwoTone as DeleteTwoToneIcon,
} from '@mui/icons-material';

import notification from "helpers/notification";
import CrudModal from "components/Modals/crud.modal";
import EditForm from "./forms/EditForm";
import { LoadingIconButton } from "components/Buttons/LoadingButton";
import { LoadingButton } from "@mui/lab";
import { TransitionProps } from "@mui/material/transitions";
import { DB_Commission } from "models/db/Commission";
import zionApi from "api/zionApi";
import { GenericResponse } from "models/Generic";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function actionsCell(row: DB_Commission, setRefetch: Dispatch<SetStateAction<number>>): React.JSX.Element {
  const [openCrudModal, setOpenCrudModal] = React.useState(false);
  const [deleteConfirm, setDeleteConfirm] = React.useState(false);

  const deleteItem = useMutation(
    async (id: string) => {
      const response = await zionApi.delete<GenericResponse<DB_Commission>>(`admin/commissions/${id}`);
      return response.data;
    }, {
    onSuccess: (data) => notification.success(`Commission deleted successfully`)
  });

  React.useEffect(() => {
    if ([deleteItem.status].includes('success')) {
      setDeleteConfirm(false)
      setTimeout(() => setRefetch(c => ++c), 500);
    };

  }, [deleteItem.status])

  return <>
    <Grid display="flex" flexDirection="row">
      <Tooltip title="Edit Item" arrow onClick={() => setOpenCrudModal(true)}>
        <LoadingIconButton>
          <EditTwoToneIcon fontSize="small" />
        </LoadingIconButton>
      </Tooltip>

      <Tooltip title="Delete Item" arrow onClick={() => setDeleteConfirm(true)}>
        <LoadingIconButton loading={deleteItem.isLoading}>
          <DeleteTwoToneIcon fontSize="small" />
        </LoadingIconButton>
      </Tooltip>
    </Grid>

    <CrudModal openCrudModal={openCrudModal} setOpenCrudModal={setOpenCrudModal}>
      <EditForm setOpenCrudModal={setOpenCrudModal} item={row} setRefetch={setRefetch} />
    </CrudModal>

    <Dialog
      open={deleteConfirm}
      onClose={e => setDeleteConfirm(false)}
      TransitionComponent={Transition}
      PaperProps={{ sx: { px: 1, py: 1 } }}
    >
      <DialogTitle variant="h4">Are You Sure?</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Do you want to <strong>DELETE</strong> this item?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', mx: 1 }}>
        <LoadingButton size="small" variant='outlined' onClick={e => setDeleteConfirm(false)}>
          Close
        </LoadingButton>
        <LoadingButton size="small" variant='contained' loading={deleteItem.isLoading} onClick={(e) => deleteItem.mutate(row.id)}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  </>
}