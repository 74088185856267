import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { createItemFn } from 'api/transactionApi';
import { getAllItemsFn as getAddress } from 'api/addrApi';
import { CRYPTO_SYMBOL, DB_Currency } from 'models/db/Currency';
import { useFetch } from 'hooks/useFetch';
import { getCurrencies } from 'models/api/Currency';
import { MyFormHelperText } from 'components/Forms/MyFormHelper';
import { formatBalance } from 'helpers/utils';

interface ICreateItemProp {
  setRefetch?: Dispatch<SetStateAction<number>>;
  setOpenCrudModal: (openCrudModal: boolean) => void;
}

const createItemSchema = z.object({
  currencySymbol: z.nativeEnum(CRYPTO_SYMBOL),
  fromAddress: z.string().min(1, 'Sender Address is required'),
  toAddress: z.string().min(1, 'Receive Address is required'),
  amount: z.number(),
  txType: z.string().min(1, 'Transaction Type is required'),
});

export type TCreateItem = z.TypeOf<typeof createItemSchema>;

const AddTransactionForm: FC<ICreateItemProp> = ({ setRefetch, setOpenCrudModal }) => {
  const [amount, setAmount] = React.useState<number>(0.001);
  const [currencySymbol, setCurrencySymbol] = React.useState<CRYPTO_SYMBOL>();
  const [fromAddress, setFromAddress] = React.useState<string>('');

  const [currencies, setCurrencies] = React.useState<DB_Currency[]>([]);
  const currencyData = useFetch({ callback: async () => await getCurrencies() })

  const currencyWallets = useQuery({
    enabled: false,
    queryKey: ['currencyWallets'],
    queryFn: () => getAddress(currencySymbol),
    select: (result) => result.data,
    onSuccess(data) { }
  });

  // Mutations
  const createItem = useMutation({
    mutationFn: (inputData: TCreateItem) => createItemFn(inputData),
    onSuccess: () => {
      if (typeof setRefetch === 'function') setRefetch(c => ++c)
      notification.success('Transaction created successfully');
      setOpenCrudModal(false);
      methods.reset();
    }
  })

  const methods = useForm<TCreateItem>({ resolver: zodResolver(createItemSchema) });

  const { formState: { errors: formError, isSubmitSuccessful } } = methods;

  if (Object.keys(formError).length) {
    console.log('formError', formError);
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      // methods.reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<TCreateItem> = (formValues) => {
    console.log('formValues', formValues);
    createItem.mutate(formValues);
  };

  const handleChangeCurrency = async (event: SelectChangeEvent) => {
    setCurrencySymbol(event.target.value as CRYPTO_SYMBOL)
  };

  useEffect(() => {
    setFromAddress('');
    methods.setValue("fromAddress", '');

    methods.setValue("txType", "CUSTOM");

    if (currencySymbol) {
      ; (async () => await currencyWallets.refetch())();
    }
  }, [currencySymbol])

  useEffect(() => {
    if (currencyData.action === 'ready') { currencyData.refetch(); }
    if (currencyData.data?.data) {
      setCurrencies(currencyData.data.data)
    }
  }, [currencyData.action])

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Add Transaction
        </Typography>

        <CloseButton loading={createItem.isLoading || currencyWallets.isFetching}
          onClick={() => setOpenCrudModal(false)}>
          <Icon>close</Icon>
        </CloseButton>
      </Box>

      <FormProvider {...methods}>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          {currencies.length > 0 && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="currencySymbol-label">Select Wallet</InputLabel>

              <Select {...methods.register('currencySymbol')}
                label="Select Wallet"
                labelId="currencySymbol-label"
                value={currencySymbol}
                onChange={handleChangeCurrency}
              >
                {currencies.map(curr => <MenuItem key={curr.id} value={curr.symbol}>{curr.name} Wallet</MenuItem>)}
              </Select>

              <MyFormHelperText error={!!formError.currencySymbol} message={formError.currencySymbol?.message} />
              <FormHelperText error={!currencyWallets?.data?.length}>
                {currencySymbol && !currencyWallets?.data?.length && 'You must first create an address for this wallet.'}
              </FormHelperText>
            </FormControl>
          )}

          <FormControl fullWidth sx={{ mb: 2, }} disabled={!currencyWallets.data?.length}>
            <InputLabel id="fromAddress-label">Select Address</InputLabel>
            <Select  {...methods.register('fromAddress')}
              label="Select Address"
              labelId="fromAddress-label"
              value={fromAddress}
              onChange={e => setFromAddress(e.target.value)}
              sx={{
                '&>.MuiSelect-select': {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                },
                '& .hide': {
                  display: 'none'
                }
              }}
            >
              <ListSubheader sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
                <Typography fontWeight="bold" sx={{ width: '100px' }}>Name</Typography>
                <Typography fontWeight="bold" sx={{ width: '80%' }}>Address</Typography>
                <Typography fontWeight="bold" sx={{ width: '100px' }}>Balance</Typography>
              </ListSubheader>

              {currencyWallets?.data?.length && currencyWallets?.data.map(wallet => (
                <MenuItem key={wallet.id} value={String(wallet.id)} sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                  <Typography sx={{ width: '100px' }}>{wallet.name?.trim() || '-'}</Typography>
                  <Typography sx={{ width: '80%' }}>{wallet.address}</Typography>
                  <Typography sx={{ width: '100px' }}>
                    {wallet.currencyBalances.map(item => <>{`${formatBalance(item.balance)} ${item.symbol}`}</>)}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!formError?.fromAddress}>{formError?.fromAddress?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              {...methods.register('amount', { valueAsNumber: true, setValueAs: v => parseFloat(v) })}
              label='Amount'
              fullWidth
              value={amount}
              type="number"
              inputProps={{
                min: 0,
                // max: 10,
                // inputMode: 'numeric', 
                // pattern: '[0-9]*',
              }}
              onChange={(e) => setAmount(parseFloat(e.target.value))}
            />

            <FormHelperText error={!!formError?.amount}>{formError?.amount?.message}</FormHelperText>
          </FormControl>

          {/* <FormControl fullWidth sx={{ mb: 2 }}>
            <Controller
              name="amount"
              control={methods.control}
              defaultValue={amount}
              render={({ field: { onChange: fieldOnChange, ref, ...field } }) => (
                <TextField
                  {...field}
                  label='Amount'
                  fullWidth
                  value={amount}
                  type="number"
                  onChange={(e) => {
                    setAmount(Math.abs(parseFloat(e.target.value)))
                    if (!Number.isNaN(e.target.value)) {
                      fieldOnChange(e)
                    }
                  }}
                />
              )}
            />

            <FormHelperText error={!!formError?.amount}>{formError?.amount?.message}</FormHelperText>
          </FormControl> */}

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label='Receive Address'
              fullWidth
              {...methods.register('toAddress')}
            />
            <FormHelperText error={!!formError?.toAddress}>{formError?.toAddress?.message}</FormHelperText>
          </FormControl>

          <Grid container justifyContent="center">
            <LoadingButton
              variant='contained'
              type='submit'
              loading={createItem.isLoading || currencyWallets.isFetching}
              disabled={!currencySymbol}
            >
              Send
            </LoadingButton>
          </Grid>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default AddTransactionForm;