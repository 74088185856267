import { DB_ApiKey, DB_ApiKeys, ICreateApiKey } from 'models/db/ApiKey';
import zionApi from './zionApi';
import { DB_User } from 'models/db/User';
import { GenericResponse } from 'models/Generic';
import { TCreateApiKey } from 'pages/UserProfile/ApiKeysTab/PageHeader';
import { TUpdateItem } from 'pages/UserProfile/ApiKeysTab/forms/EditForm';

const config = {
  headers: { 'Content-Type': 'multipart/form-data' }
};

export const getUserFn = async (id: string) => {
  const response = await zionApi.get<GenericResponse<DB_User>>(`users/${id}`);
  return response.data;
};

export const createUserFn = async (formData: FormData) => {
  const response = await zionApi.post<GenericResponse<DB_User>>(`users`, formData, config);
  return response.data;
};

export const updateUserFn = async ({ id, formData }: {
  id: string;
  formData: FormData;
}) => {
  const response = await zionApi.patch<GenericResponse<DB_User>>(`users/${id}`, formData, config);
  return response.data;
};

export const deleteUserFn = async (id: string) => {
  const response = await zionApi.delete<GenericResponse<DB_User>>(`users/${id}`);
  return response.data;
};

export const getUsersKeysFn = async () => {
  const response = await zionApi.get<DB_ApiKeys>(`users/apis`);
  return response.data;
};

export const createUserKeyFn = async (inputData: TCreateApiKey) => {
  const response = await zionApi.post<GenericResponse<ICreateApiKey>>(`users/apis`, inputData);
  return response.data;
};

export const updateUserKeyFn = async ({ id, inputData }: { id: number; inputData: TUpdateItem; }) => {
  const response = await zionApi.patch<GenericResponse<DB_ApiKey>>(`users/apis/${id}`, inputData);
  return response.data;
};