import React from "react";
import { Container, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { getFilteredUsers } from "models/api/User";
import { cols_User, fields_User } from "./definitions";
import { nameMapping_User } from "models/db/User";

export default function UsersTab(): React.JSX.Element {
  return (
    <>
      <Helmet><title>All Users</title></Helmet>

      <DataTable
        title='Users'
        dataName='users'
        rowsPerPage={100}
        cols={cols_User}
        fields={fields_User}
        nameMapping={nameMapping_User}
        getFilteredRecords={getFilteredUsers}
        settings={{ toolbar: { export: 'excel' } }}
      />
    </>
  );
}