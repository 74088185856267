import axios from 'axios';
import zionApi from './zionApi';

import { TCreateItem } from '../pages/Addrs/forms/AddForm';
import { TUpdateItem } from '../pages/Addrs/forms/EditForm';
import { GenericResponse } from 'models/Generic';
import { DB_Address, DB_Addresses } from 'models/db/Address';
import { CRYPTO_SYMBOL } from 'models/db/Currency';

export const getAllItemsFn = async (currencySymbol?: CRYPTO_SYMBOL) => {
  const response = await zionApi.get<DB_Addresses>(`addresses` + (currencySymbol ? `?currencySymbol=${currencySymbol}` : ''));
  return response.data;
};

export const getItemFn = async (id: string) => {
  const response = await zionApi.get<GenericResponse<DB_Address>>(`addresses/${id}`);
  return response.data;
};

export const createItemFn = async (inputData: TCreateItem) => {
  const response = await zionApi.post<GenericResponse<DB_Address>>(`addresses`, inputData);
  return response.data;
};

export const updateItemFn = async ({ id, inputData }: {
  id: string;
  inputData: TUpdateItem;
}) => {
  const response = await zionApi.patch<GenericResponse<DB_Address>>(`addresses/${id}`, inputData);
  return response.data;
};

export const deleteItemFn = async (id: string) => {
  const response = await zionApi.delete<GenericResponse<DB_Address>>(`addresses/${id}`);
  return response.data;
};

export const getQrCodeFn = async ({ data }: { data: string }) => {
  const apiUrl = `https://api.qrserver.com/v1/create-qr-code`;
  const options = { size: '300x300', data: data };
  const response = await axios.get<any>(`${apiUrl}?${new URLSearchParams(Object.entries({ ...options })).toString()}`, {
    responseType: 'arraybuffer'
  }).then((response) => {
    let image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    return `data:${response.headers['content-type'].toLowerCase()};charset=utf-8;base64,${image}`;
  });

  return response;
};