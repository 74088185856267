import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { getUserAssets, getUserWallets } from 'api/walletApi';
import SuspenseLoader from 'components/SuspenseLoader';

import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'components/PageTitleWrapper';
import { Container, Grid } from '@mui/material';

import Wallets from './Wallets';
import WatchList from './WatchList';
import useUserContext from 'hooks/useUserContext';
import Summary from './Summary';
import CurrencyConverter from './CurrencyConverter';

function DashboardCrypto() {
  // const navigate = useNavigate();
  const { authState: { user } } = useUserContext()

  const wallets = useQuery(['wallets'], () => getUserWallets());
  const assets = useQuery(['assets'], () => getUserAssets());

  if (wallets.isLoading) {
    return <SuspenseLoader />;
  }

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          {/* <Grid item xs={12}>
            <AccountBalance wallets={wallets} />
          </Grid> */}
          <Grid item lg={12} xs={12}>
            <Wallets wallets={wallets.data?.data ?? []} assets={assets?.data?.data ?? []} />
          </Grid>

          <Grid item xs={12}>
            <CurrencyConverter />
          </Grid>

          {user?.role === 'admin' && (
            <Grid item xs={12}>
              <Summary />
            </Grid>
          )}

          <Grid item xs={12}>
            <WatchList />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default DashboardCrypto;
