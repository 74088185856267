import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageTitle from 'components/PageTitle';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { createUserKeyFn } from 'api/userApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import notification from 'helpers/notification';
import { LoadingButton } from 'components/Buttons/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

import {
  useForm,
  FormProvider,
  SubmitHandler,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { Alert, AlertTitle, Box, FormControl, Grid, Icon, IconButton, TextField, Typography } from '@mui/material';
import { MyFormHelperText } from 'components/Forms/MyFormHelper';
import { MyIconButton } from 'components/Buttons/Index';
import copy from 'copy-to-clipboard';
import { ICreateApiKey } from 'models/db/ApiKey';
import config from 'config';

const createApiKeySchema = z.object({
  clientIp: z.string().min(1, 'Ip is required'),
});

export type TCreateApiKey = z.TypeOf<typeof createApiKeySchema>;

function PageHeader() {
  const theme = useTheme();
  const queryClient = useQueryClient();

  const [open, setOpen] = React.useState(false);
  const [openSecret, setOpenSecret] = React.useState(false);
  const [newApi, setNewApi] = React.useState<ICreateApiKey | null>(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);
  const handleCloseSecret = (event: object, reason: string) => {
    if (reason && ['escapeKeyDown', 'backdropClick'].includes(reason)) return;
    setOpenSecret(false);
  }
  const handleClickOpenSecret = () => setOpenSecret(true);

  // Mutations
  const createApiKey = useMutation({
    mutationFn: (inputData: TCreateApiKey) => createUserKeyFn(inputData),
    onSuccess: async (response) => {
      queryClient.invalidateQueries(['user_api_keys']);
      notification.success('ApiKey created successfully');
      methods.reset();
      handleClose();

      console.log({ response });
      if (response.status !== 'success') return;

      setNewApi(response.data)
      handleClickOpenSecret()
    }
  })

  const methods = useForm<TCreateApiKey>({ resolver: zodResolver(createApiKeySchema) });

  const { formState: { errors: formErrors } } = methods;

  if (Object.keys(formErrors).length) {
    console.log('formErrors', formErrors);
  }

  const onSubmitHandler: SubmitHandler<TCreateApiKey> = (formValues) => {
    if (Object.keys(formErrors).length) {
      Object.values(formErrors).map((item) => notification.error(item?.message as string));
      return;
    }
    // console.log('formValues', formValues);
    createApiKey.mutate(formValues);
  };

  return (
    <>
      <Helmet>
        <title>User api keys</title>
      </Helmet>

      <PageTitle
        docs={{ text: 'API Documents', href: `${config.API_BASE_URL}/apidocs/` }}
        crud={{ add: true }}
        setOpenCrudModal={handleClickOpen}
      />

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Do you want to generate API Key?"}

          <MyIconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </MyIconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ mb: 4 }}>
            <ul style={{ padding: '0 0 0 16px', margin: 0 }}>
              <li>Each account can create up to 5 API Keys.</li>
              <li>Do not disclose your API Key to anyone to avoid asset losses. It is recommended to bind IP for API Key to increase your account security.</li>
              <li>Be aware that your API Key may be disclosed by authorizing it to a third-party platform.</li>
            </ul>
          </DialogContentText>

          <FormProvider {...methods}>
            <Box
              component='form'
              noValidate
              autoComplete='off'
              onSubmit={methods.handleSubmit(onSubmitHandler)}
            >
              <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                  label='Ip'
                  fullWidth
                  {...methods.register('clientIp')}
                />

                <MyFormHelperText error={!!formErrors?.clientIp} message={formErrors?.clientIp?.message} />
              </FormControl>

              <Grid container justifyContent="center" >
                <LoadingButton
                  variant='contained'
                  type='submit'
                  loading={createApiKey.isLoading}
                  disabled={createApiKey.isLoading}
                >
                  Generate API KEY
                </LoadingButton>
              </Grid>
            </Box>
          </FormProvider>
        </DialogContent>

        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>Cancel</Button>
          <LoadingButton
            variant='contained'
            type='submit'
            loading={createApiKey.isLoading}
            disabled={createApiKey.isLoading}
            onClick={(e) => createItem()}
          >
            Approve
          </LoadingButton>
        </DialogActions> */}
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={openSecret}
        onClose={handleCloseSecret}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"API Info"}

          <MyIconButton
            aria-label="close"
            onClick={e => setOpenSecret(false)}
            sx={{ right: 8, top: 8, position: 'absolute', color: (theme) => theme.palette.grey[500], }}
          >
            <CloseIcon />
          </MyIconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Alert severity="warning" sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <AlertTitle>Important</AlertTitle>
              <Typography>Make sure to copy your personal access token now. You won't be able to see it again!</Typography>
            </Alert>

            {newApi?.apiKey && newApi.apiKey && (
              <Box>
                <Alert severity="success" sx={{ mb: 2 }}>
                  <AlertTitle>Api Key</AlertTitle>
                  <Typography sx={{ whiteSpace:'nowrap' }}>
                    <IconButton aria-label="copy" size="small" color='success' onClick={e => copy(newApi.apiKey)}>
                      <Icon fontSize='inherit'>content_copy</Icon>
                    </IconButton>
                    {newApi.apiKey}
                  </Typography>
                </Alert>

                <Alert severity="info" sx={{ mb: 2 }}>
                  <AlertTitle>Api Secret</AlertTitle>
                  <Typography sx={{ whiteSpace:'nowrap' }}>
                    <IconButton aria-label="copy" size="small" color='info' onClick={e => copy(newApi.apiSecret)}>
                      <Icon fontSize='inherit'>content_copy</Icon>
                    </IconButton>
                    {newApi.apiSecret}
                  </Typography>
                </Alert>
              </Box>
            )}
          </DialogContentText>

          <Grid container justifyContent="center">
            <LoadingButton variant='contained'
              onClick={e => setOpenSecret(false)}
              loading={createApiKey.isLoading}
              disabled={createApiKey.isLoading}>
              Close
            </LoadingButton>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PageHeader;
