import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import notification from 'helpers/notification';
import { useMutation } from '@tanstack/react-query';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import {
  Box,
  Grid,
  Container,
  Typography,
  Input as _Input,
  Divider,
} from '@mui/material';

import { SxProps, Theme } from '@mui/material/styles';
import { LoadingButton } from 'components/Buttons/LoadingButton';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FormInput from '../../components/FormInput';
import { GenericResponse } from 'models/Generic';
import zionApi from 'api/zionApi';
import { useFetch } from 'hooks/useFetch';
import { LinkItem } from 'components/Buttons/LinkItem';

const SxInput: SxProps<Theme> = (theme) => ({
  borderRadius: '0.4rem',
  padding: '0.2rem 0.5rem',
  marginBottom: '0.5rem',
  border: '1px solid ' + theme.colors.alpha.black[30],
});

const contactSchema = object({
  firstName: string().min(1, 'First name is required').max(15),
  lastName: string().min(1, 'Last name is required').max(15),
  email: string().min(1, 'Email address is required').email('Email Address is invalid'),
  subject: string().min(1, 'Subject is required').max(150),
  message: string().min(3, 'Message is required').max(800),
});

export type ContactInput = TypeOf<typeof contactSchema>;

const ContactPage = () => {
  const navigate = useNavigate();

  const methods = useForm<ContactInput>({
    resolver: zodResolver(contactSchema),
  });

  // 👇 Calling the Contact Mutation
  const { data, error, loading, refetch, action } = useFetch<GenericResponse<{}>>({
    callback: async (data: ContactInput) => (await zionApi.post<GenericResponse<{}>>('contact', data)).data
  })

  useEffect(() => {
    // console.log({ data, error, loading });
    if (action !== 'fetching') {
      if (error) notification.error(error);
      if (data?.message) notification.success(data?.message);
    }
    // navigate('/contact-success');
  }, [loading])

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful]);

  // 👇 Execute the Mutation
  const onSubmitHandler: SubmitHandler<ContactInput> = (values) => refetch(values);

  return (
    <Container disableGutters maxWidth={false}>
      <Grid container sx={{ display: 'flex' }} my={{ xs: 2, md: 4 }}>
        <Grid item xs={12} md={5} textAlign='center'>
          <Typography variant="h1" component="h1" sx={{ my: { xs: 2, md: 4 } }}>Contact Us</Typography>
          <Typography variant="subtitle2">
            Do not hesitate to reach out. Just fill in the contact from here and we’ll be sure to reply as fast as possible.
          </Typography>

          <Divider sx={{ mb: { xs: 2, md: 6 }, visibility: 'hidden' }} />

          <Typography variant="h4" component="h4">Message us</Typography>
          <Typography variant="h6" component="h6" sx={{ mt:1 }}>
            info@ziontrader.com
          </Typography>
          <Typography sx={{ mt:1 }}>
            <Link to="https://www.youtube.com/@ziontradercom" target='_blank'>
              <YouTubeIcon sx={{ fontSize: '2rem', color: '#e13426' }}>youtube</YouTubeIcon>
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12} md={7}>
          <FormProvider {...methods}>
            <Box
              component='form'
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
              autoComplete='off'
              width='100%'
              sx={{
                // backgroundColor: '#fff',
                p: { xs: '1rem', sm: '2rem' },
                borderRadius: 2,
                maxWidth: { xs: '100%' }
              }}
            >
              <Grid container columnSpacing={2}>
                <Grid item xs={12} md={6}>
                  <FormInput name='firstName' type='text' icon='person_outline' placeholder='First Name' sx={SxInput} disableUnderline />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput name='lastName' type='text' icon='person_outline' placeholder='Last Name' sx={SxInput} disableUnderline />
                </Grid>

                <Grid item xs={12}>
                  <FormInput name='email' type='email' icon='email' placeholder='Email Address' sx={SxInput} disableUnderline />
                </Grid>
                <Grid item xs={12}>
                  <FormInput name='subject' type='text' icon='edit' placeholder='Subject' sx={SxInput} disableUnderline />
                </Grid>
                <Grid item xs={12}>
                  <FormInput name='message' type='text' rows={4} placeholder='Message' multiline sx={SxInput} disableUnderline />
                </Grid>

                <Grid item xs={12} textAlign="center">
                  <LoadingButton
                    type='submit'
                    loading={loading}
                    variant='contained'
                  >
                    Send Message
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactPage;
