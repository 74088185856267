import zionApi from "api/zionApi";
import { GenericResponse } from "models/Generic";
import { DB_Currencies, DB_Currency } from "models/db/Currency";

export const getCurrency = async ({ currencyId }: { currencyId: string }) => {
    const response = await zionApi.get<GenericResponse<DB_Currency>>(`currencies/${currencyId}`);
    return response.data;
};

export const getCurrencies = async () => {
    const response = await zionApi.get<DB_Currencies>(`currencies`);
    return response.data;
};