import React from "react";
import { Box, FormControl, Icon, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { MyIconButton } from "components/Buttons/Index";

interface ISearchBarProps {
    enable?: boolean;
    searchTable: (searchValue: string) => void
}

export const SearchBar: React.FC<ISearchBarProps> = ({ enable = true, searchTable }) => {
    const [searchValue, setSearchValue] = React.useState("");
    const submitForm = (e: React.FormEvent) => {
        e.preventDefault();
        searchTable(searchValue.trim());
        // setSearchValue('' )
    };

    return (
        <Box noValidate
            component='form'
            autoComplete='on'
            onSubmit={submitForm}>
            <FormControl fullWidth variant="outlined" sx={{ mb: 1 }} disabled={!enable}>
                <InputLabel size="small">{!enable ? 'There are not searchable fields.': `Search`}</InputLabel>
                <OutlinedInput
                    size="small"
                    type="text"
                    label="Search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    endAdornment={(
                        <InputAdornment position="end">
                            <MyIconButton sx={{ color: 'initial', mr: -2 }}
                                edge="end"
                                aria-label="remove option"
                                onClick={(e) => searchTable(searchValue.trim())}
                            >
                                <Icon>manage_search</Icon>
                            </MyIconButton>
                        </InputAdornment>
                    )}
                />
            </FormControl>
        </Box>
    );
};