import React from 'react';
import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import routes from './router';

import { ModalContextProvider } from './context/modal.context';
import { HelmetProvider } from 'react-helmet-async';

import ThemeProvider from './theme/ThemeProvider';
import { SidebarProvider } from './context/SidebarContext';

const App = (): React.JSX.Element => {
  const content = useRoutes(routes)
  // const location = useLocation()
  // const pageRoutes = matchRoutes(routes, location)
  // const targetPage = [...(pageRoutes || [])].pop();
  // console.log('targetRoute', targetPage?.route?.meta);

  // const { state } = useDataContext();
  // React.useEffect(() => console.log(state), [state]);

  return (
    <>
      <HelmetProvider>
        <SidebarProvider>
          <ThemeProvider>
            <CssBaseline />
            <ModalContextProvider>
              {content}
            </ModalContextProvider>
          </ThemeProvider>
        </SidebarProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
