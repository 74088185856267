import React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { getFilteredTransactions } from "models/api/Transaction";
import { cols_Transaction, fields_Transaction } from "./definitions";
import { nameMapping_Transaction } from "models/db/Transaction";

export default function TransactionsTab(): React.JSX.Element {
  return (
    <>
      <Helmet><title>All Transactions</title></Helmet>

      <DataTable
        title='Transactions'
        dataName='transaction'
        rowsPerPage={25}
        cols={cols_Transaction}
        fields={fields_Transaction}
        nameMapping={nameMapping_Transaction}
        settings={{ toolbar: { export: 'excel' } }}
        getFilteredRecords={getFilteredTransactions}
      />
    </>
  );
}