import React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { getFilteredAddresses, updateAddress } from "models/api/Address";
import { cols_Address, fields_Address } from "./definitions";
import { DB_User } from "models/db/User";
import { AddressFieldName, nameMapping_Address } from "models/db/Address";
import { FilterGroup } from "components/DataTable/filter/FilterGroup";

export default function UserAddressesTab({ targetUser }: { targetUser: DB_User }): React.JSX.Element {

  if (!targetUser.id) {
    return <>Require UserId</>;
  }

  return (
    <>
      <Helmet><title>All Addresses</title></Helmet>

      <DataTable
        title='Addresses'
        dataName='address'
        rowsPerPage={25}
        cols={cols_Address}
        fields={fields_Address}
        nameMapping={nameMapping_Address}
        settings={{ toolbar: { export: 'excel' } }}
        getFilteredRecords={async (params: FilterGroup<AddressFieldName>) => {
          const userParams: FilterGroup<AddressFieldName> = {
            ...params,
            filters: [[['user', { Equal: String(targetUser.id) }]], ...params.filters],
          };
          return await getFilteredAddresses(userParams)
        }}
        updateRecord={async (row, column, value) => {
          const newRow = structuredClone(row);
          newRow[column.key] = value as never;
          return await updateAddress(row.id, newRow);
        }}
      />
    </>
  );
}