import { DB_User } from "./User";
import { DB_Address } from "./Address";
import { DB_Currency } from "./Currency";

export enum TX_TYPES {
    CUSTOM = 'CUSTOM',
    SWEEPER = 'SWEEPER',
    DEPOSIT = 'DEPOSIT',
    COMMISSION = 'COMMISSION',
    BUYING_WALLET = 'BUYING_WALLET',
    BUYING_CRYPTO = 'BUYING_CRYPTO',
    SELLING_CRYPTO = 'SELLING_CRYPTO',
}

export enum TX_STATUS {
    ERROR = 'ERROR',
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS',
    PENDING = 'PENDING',
    CANCELED = 'CANCELED',
    PROCESSING = 'PROCESSING',
}

export interface DB_Transaction {
    id: string;
    createdAt: string;
    updatedAt: string;
    currency: DB_Currency;
    fromAddress: DB_Address;
    toAddress: string;
    amount: number;
    user: DB_User;
    txHash: string;
    txType: TX_TYPES;
    txStatus: TX_STATUS;
    // fake fields
    userName: string | null;
    actions: string | null;
}

export interface DB_Transactions {
    status: string;
    data: DB_Transaction[];
    totalRows: number;
}

export type TransactionFieldName = keyof DB_Transaction;

export const nameMapping_Transaction: { [key in keyof DB_Transaction]: TransactionFieldName } = {
    id: 'id',
    amount: 'amount',
    currency: 'currency',
    fromAddress: 'fromAddress',
    toAddress: 'toAddress',
    createdAt: "createdAt",
    updatedAt: "updatedAt",
    user: "user",
    txHash: "txHash",
    txType: "txType",
    txStatus: "txStatus",
    userName: "userName",
    actions: "actions"
};