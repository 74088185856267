import { ColumnDefinitionType } from "components/DataTable/Table";
import { DB_User, UserFieldName } from "models/db/User";
import { getStatusLabel } from "components/Label/StatusLabel";
import { DB_STATUS } from "models/Generic";
import dayjs from "dayjs";
import { Icon, Tooltip } from "@mui/material";
import { LoadingIconButton } from "components/Buttons/LoadingButton";
import { InfoTwoTone as InfoTwoToneIcon } from '@mui/icons-material';

import { useNavigate } from "react-router-dom";
import { GenericField } from "components/DataTable/filter/FilterType";
import { formatBalance } from "helpers/utils";

export const cols_User: Array<ColumnDefinitionType<DB_User>> = [
    {
        key: 'id',
        header: 'ID',
        hideFilter: true,
        sx: {
            width: 80,
            minWidth: 50,
        }
    },
    {
        key: 'name',
        header: 'Name',
        wordWrap: 'break-word',
    },
    {
        key: 'email',
        header: 'Email',
        wordWrap: 'break-word',
    },
    {
        key: 'userBalance',
        header: 'Balance',
        hideFilter: true,
        cell: (user) => `${formatBalance(user.userBalance)} USDT`,
    },
    {
        key: 'role',
        header: 'Role',
        cell: (item) => item.role,
    },
    {
        key: 'commission',
        header: 'Customer Group',
        hideFilter: true,
        cell: (item) => item.commissionGroup,
    },
    {
        key: 'status',
        header: 'Status',
        cell: (item) => getStatusLabel(item.status),
    },
    {
        key: 'verified',
        header: 'Verified',
        cell: (item) => {
            return (
                <Icon color={item.verified ? 'success' : 'warning'}>{item.verified ? 'check_circle' : 'pending'}</Icon>
            )
        },
    },
    {
        key: 'createdAt',
        header: 'Created At',
        cell: (item) => dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
        key: 'actions',
        header: 'Actions',
        cell: (item) => {
            const navigate = useNavigate();

            return (
                <>
                    <Tooltip
                        title="Detail"
                        onClick={async () => {
                            navigate(`/dashboard/admin/users/${item.id}`)
                        }}
                    >
                        <LoadingIconButton loading={false}>
                            <InfoTwoToneIcon fontSize="small" />
                        </LoadingIconButton>
                    </Tooltip>
                </>
            )
        },
    },

];

export const fields_User: Array<GenericField<DB_User>> = [
    {
        name: 'name',
        title: 'Name',
        type: 'text',
        search: true,
    },
    {
        name: 'email',
        title: 'Email',
        type: 'text',
    },
    {
        name: 'status',
        title: 'Status',
        type: 'select',
        selectOptions: Object.keys(DB_STATUS).filter((v) => isNaN(Number(v)))
    },
    {
        name: 'role',
        title: 'Role',
        type: 'select',
        selectOptions: ['admin', 'user', 'member']
    },
    {
        name: 'commission',
        title: 'User Group',
        type: 'select',
        // selectOptions: ['Zero', '% 1']
    },
    {
        name: 'verified',
        title: 'Verified',
        type: 'select',
        selectOptions: ['true', 'false']
    },
    {
        name: 'createdAt',
        title: 'Date',
        type: 'date',
    },
];