import React, { Dispatch, SetStateAction } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { createItemFn } from 'api/sweeperApi';
import { getAllItemsFn as getAddress } from 'api/addrApi';

import dayjs from 'dayjs';
import MyDateRangePicker from 'components/Date/MyDateRangePicker';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MyFormHelperText } from 'components/Forms/MyFormHelper';
import { DB_STATUS } from 'models/Generic';
import { DB_FREQUENCY } from 'models/db/Sweeper';
import { CRYPTO_SYMBOL, DB_Currency } from 'models/db/Currency';
import { useFetch } from 'hooks/useFetch';
import { getCurrencies } from 'models/api/Currency';

interface ICreateItemProp {
  setRefetch: Dispatch<SetStateAction<number>>;
  setOpenCrudModal: (openCrudModal: boolean) => void;
}

const createItemSchema = z.object({
  amount: z.number(),
  currencySymbol: z.nativeEnum(CRYPTO_SYMBOL),
  fromAddress: z.string().min(1, 'Sender Address is required'),
  toAddress: z.string().min(1, 'Receive Address is required'),
  frequency: z.nativeEnum(DB_FREQUENCY),
  dateRange: z.array(z.date()).optional(),
  startDate: z.date(),
  stopDate: z.date(),
  startTime: z.date(),
});

export type TCreateItem = z.TypeOf<typeof createItemSchema>;

const AddForm: React.FC<ICreateItemProp> = ({ setRefetch, setOpenCrudModal }) => {
  const [currencies, setCurrencies] = React.useState<DB_Currency[]>([]);
  const currencyData = useFetch({ callback: async () => await getCurrencies() })

  const defaultValues: TCreateItem = Object.assign({
    amount: 0,
    currencySymbol: '',
    fromAddress: '',
    toAddress: '',
    status: DB_STATUS.ACTIVE,
    frequency: DB_FREQUENCY.DAILY,
    dateRange: [null, null],
    startDate: dayjs().toDate(),
    stopDate: dayjs().add(1, "day").toDate(),
    startTime: dayjs().add(1, "hour").minute(0).toDate(),
  })

  const [rules, updateRules] = React.useReducer(
    (state: TCreateItem, newState: Partial<TCreateItem>) => ({ ...state, ...newState, }), { ...defaultValues }
  );

  const [dateRange, setDateRange] = React.useState<Date[] | null[]>([rules.startDate as Date, rules.stopDate as Date]);

  const queryGetAddress = useQuery({
    enabled: false,
    queryKey: ['currencyWallets'],
    queryFn: () => getAddress(rules.currencySymbol),
    select: (result) => result.data,
    onSuccess(data) { }
  });

  const queryCreateSweeper = useMutation({
    mutationFn: (inputData: TCreateItem) => createItemFn(inputData),
    onSuccess: () => {
      setRefetch(c => ++c)
      notification.success('Sweeper created successfully');
      setOpenCrudModal(false);
      methods.reset();
    }
  })

  const methods = useForm<TCreateItem>({ resolver: zodResolver(createItemSchema) });

  const { formState: { errors: formErrors, isSubmitSuccessful } } = methods;

  if (Object.keys(formErrors).length) {
    console.log('formErrors', formErrors);
  }

  const onSubmitHandler: SubmitHandler<TCreateItem> = (formValues) => {
    if (Object.keys(formErrors).length) {
      Object.values(formErrors).map((item) => notification.error(item?.message as string));
      return;
    }

    queryCreateSweeper.mutate(formValues);
  };

  const handleChangeCurrency = async (event: SelectChangeEvent) => {
    updateRules({ "currencySymbol": event.target.value as CRYPTO_SYMBOL })
  };

  const handleChangeFromAddress = (event: SelectChangeEvent) => {
    updateRules({ "fromAddress": String(event.target.value) })
  };

  React.useEffect(() => {
    // console.log('dateRange', dateRange);
    if (dateRange[0] && dateRange[1]) {
      updateRules({ "startDate": dateRange[0] })
      updateRules({ "stopDate": dateRange[1] })
    }
  }, [dateRange])

  React.useEffect(() => {
    // console.log('startDate', rules.startDate, rules.stopDate);
    if (rules.startDate && rules.stopDate) {
      methods.setValue('startDate', rules.startDate, { shouldValidate: true })
      methods.setValue('stopDate', rules.stopDate, { shouldValidate: true })
    }
  }, [rules.startDate, rules.stopDate])

  React.useEffect(() => {
    console.log('startTime', rules.startTime);
  }, [rules.startTime])

  React.useEffect(() => {
    updateRules({ "fromAddress": "" })
    methods.setValue("fromAddress", "");

    if (rules.currencySymbol) {
      ; (async () => await queryGetAddress.refetch())();
    }
  }, [rules.currencySymbol])

  React.useEffect(() => {
    if (currencyData.action === 'ready') { currencyData.refetch(); }
    if (currencyData.data?.data) {
      setCurrencies(currencyData.data.data)
    }
  }, [currencyData.action])

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Add Sweep Rule
        </Typography>

        <CloseButton loading={queryCreateSweeper.isLoading || queryGetAddress.isFetching}
          onClick={() => setOpenCrudModal(false)}>
          <Icon>close</Icon>
        </CloseButton>
      </Box>

      <FormProvider {...methods}>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          {currencies.length > 0 && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="currencySymbol-label">Select Wallet</InputLabel>

              <Select {...methods.register('currencySymbol')}
                label="Select Wallet"
                labelId="currencySymbol-label"
                value={rules.currencySymbol}
                onChange={handleChangeCurrency}
              >
                {currencies.map(curr => <MenuItem key={curr.id} value={curr.symbol}>{curr.name} Wallet</MenuItem>)}
              </Select>

              <MyFormHelperText error={!!formErrors?.currencySymbol} message={formErrors?.currencySymbol?.message} />
              <FormHelperText error={!queryGetAddress?.data?.length}>
                {rules.currencySymbol && !queryGetAddress?.data?.length && 'You must first create an address for this wallet.'}
              </FormHelperText>
            </FormControl>
          )}

          <FormControl fullWidth sx={{ mb: 2, }} disabled={!queryGetAddress.data?.length}>
            <InputLabel id="fromAddress-label">Sender Address</InputLabel>
            <Select  {...methods.register('fromAddress')}
              label="Sender Address"
              labelId="fromAddress-label"
              value={rules.fromAddress}
              onChange={handleChangeFromAddress}
            >
              <MenuItem key="everything0" value="everything">Sweep everything connected to my account</MenuItem>
              {queryGetAddress?.data?.length && queryGetAddress?.data.map(item => (
                <MenuItem key={item.id} value={item.id}>{item.name && `${item.name} - `}{item.address}</MenuItem>
              ))}
            </Select>

            <MyFormHelperText error={!!formErrors?.fromAddress} message={formErrors?.fromAddress?.message} />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label='Receive Address'
              fullWidth
              {...methods.register('toAddress')}
            />

            <MyFormHelperText error={!!formErrors?.toAddress} message={formErrors?.toAddress?.message} />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              {...methods.register('amount', { valueAsNumber: true, setValueAs: v => parseFloat(v) })}
              label='Amount'
              fullWidth
              value={rules.amount}
              type="number"
              inputProps={{ min: 0 }}
              onChange={(e) => updateRules({ "amount": parseFloat(e.target.value) })}
            />

            <MyFormHelperText error={!!formErrors?.amount} message={formErrors?.amount?.message} />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="frequency-label">Choose Frequency</InputLabel>

            <Select {...methods.register('frequency')}
              label="Choose Frequency"
              labelId="frequency-label"
              value={rules.frequency}
              onChange={(e) => updateRules({ "frequency": e.target.value as DB_FREQUENCY })}
            >
              {Object.values(DB_FREQUENCY).map((fval, i) => (
                <MenuItem key={i} value={fval}>{fval.toUpperCase()}</MenuItem>
              ))}
            </Select>

            <MyFormHelperText error={!!formErrors?.frequency} message={formErrors?.frequency?.message} />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel sx={{ mb: 2 }}>
              Start - End Date
            </FormLabel>

            <Controller
              name="dateRange"
              control={methods.control}
              defaultValue={[dayjs(rules.startDate).toDate(), dayjs(rules.stopDate).toDate()]}
              render={({ field: { onChange, value } }) => (
                <MyDateRangePicker
                  separator="to"
                  values={value || []}
                  onChangeFn={value => setDateRange(value)}
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <Controller
              name="startTime"
              control={methods.control}
              defaultValue={dayjs(rules.startTime).toDate()}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <TimePicker
                      renderInput={(params) => <TextField {...params} />}
                      label="Start Time"
                      value={field.value}
                      onChange={(newValue) => {
                        updateRules({ "startTime": dayjs(newValue).toDate() })
                        field.onChange(dayjs(newValue).toDate())
                      }}
                      shouldDisableTime={(timeValue, clockType) => {
                        if (clockType === 'minutes' && timeValue % 15) {
                          return true
                        }

                        return false
                      }}
                    />
                  </Stack>
                </LocalizationProvider>
              )}
            />

            <MyFormHelperText error={!!formErrors?.startTime} message={formErrors?.startTime?.message} />
          </FormControl>

          <Grid container justifyContent="center" >
            <LoadingButton
              variant='contained'
              type='submit'
              loading={queryCreateSweeper.isLoading || queryGetAddress.isFetching}
              disabled={!rules.currencySymbol}
            >
              Save
            </LoadingButton>
          </Grid>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default AddForm;