import React, { useState, ChangeEvent } from 'react';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';
import { Tabs, Tab, Grid, Divider, Container } from '@mui/material';

import PurchasesTab from './purchases/Index';
import CommissionsTab from './commissions/Index';

const TabsWrapper = styled(Tabs)(({ theme }) => ({
  scrollableX: {
    overflowX: "auto !important"
  }
}));

export default function Settings() {
  const [currentTab, setCurrentTab] = useState<string>('commissions');
  const tabs = [
    { value: 'commissions', label: 'Commissions' },
    { value: 'purchases', label: 'Purchases' },
  ];

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  return (
    <>
      <Helmet><title>Settings</title></Helmet>

      <Container maxWidth={false}>
        <Grid sx={{ mb: 3 }}>
          <TabsWrapper
            onChange={handleTabsChange}
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </TabsWrapper>
          <Divider />
        </Grid>
      </Container>

      <Container maxWidth={false}>
        <Grid>
          {currentTab === 'commissions' && <CommissionsTab />}
          {currentTab === 'purchases' && <PurchasesTab />}
        </Grid>
      </Container>

    </>
  );
}
