import React from 'react';

import {
  Card,
  Box,
  Typography,
  Grid,
  useTheme,
} from '@mui/material';
import Text from 'components/Text';
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import TrendingDownTwoToneIcon from '@mui/icons-material/TrendingDownTwoTone';
import TrendingUpTwoToneIcon from '@mui/icons-material/TrendingUpTwoTone';
import { CryptoAvatar } from 'components/Avatar';
import { DB_Currency } from 'models/db/Currency';
import config from 'config';
import { formatBalance } from 'helpers/utils';

interface IWatchListColumnProps {
  currencies?: DB_Currency[];
}

interface ChartSerie {
  name?: string;
  data?: number[];
}

const WatchListColumn: React.FC<IWatchListColumnProps> = ({ currencies = [] }) => {
  const theme = useTheme();

  // const chartLabels: string[] = [];
  // const chartSeries: ChartSerie[] = [];
  // currencies.map((item, i) => {
  //   chartLabels.push(item.name);
  //   chartSeries.push({
  //     name: item.name,
  //     data: item.marketData?.sparkline || [],
  //   })
  // });

  const chartOptions: ApexOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      },
      zoom: {
        enabled: false
      }
    },
    fill: {
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    colors: [theme.colors.primary.main],
    dataLabels: {
      enabled: false
    },
    theme: {
      mode: theme.palette.mode
    },
    stroke: {
      show: true,
      colors: [theme.colors.primary.main],
      width: 3
    },
    legend: {
      show: false
    },
    labels: currencies.length ? currencies[0].marketData?.sparkline?.labels : [],
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false,
      tickAmount: 5
    },
    tooltip: {
      x: {
        show: true
      },
      y: {
        title: {
          formatter: function () {
            return 'Price: $';
          }
        }
      },
      marker: {
        show: false
      }
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      {currencies.length && currencies.map((currencyItem, i) =>
        <Grid item xl={3} md={6} xs={12} key={currencyItem.id}>
          <Card sx={{ overflow: 'visible' }}>
            <Box sx={{ p: 3 }}>
              <Box display="flex" alignItems="center">
                <CryptoAvatar
                  sx={{ margin: theme.spacing(0, 0, 1, -0.5) }}
                  src={`/icons/${currencyItem.symbol.toLowerCase()}.svg`} />

                <Box>
                  <Typography variant="h4" noWrap>
                    {currencyItem.name}
                  </Typography>
                  <Typography variant="subtitle1" noWrap>
                    {currencyItem.marketData?.pair.toUpperCase()}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', pt: 3 }}>
                <Typography variant="h2" sx={{ pr: 1, mb: 1 }}>
                  {currencyItem.symbol !== 'USDT' ? '$' : '₺'}
                  {formatBalance(Number(currencyItem?.marketData?.price))}
                </Typography>
                <Text color={currencyItem?.marketData?.increase === '+' ? 'success' : 'error'}>
                  <b>{currencyItem?.marketData?.increase}{Math.abs(Number(currencyItem?.marketData?.change))}%</b>
                </Text>

                {currencyItem?.marketData?.increase === '+' ? (
                  <TrendingUpTwoToneIcon sx={{ color: `${theme.colors.success.main}` }} />
                ) : (
                  <TrendingDownTwoToneIcon sx={{ color: `${theme.colors.error.main}` }} />
                )}
              </Box>

              {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Label color={currencyItem?.marketData?.increase === '+' ? 'success' : 'error'}>+$500</Label>
                <Typography variant="body2" color="text.secondary" sx={{ pl: 1 }}>
                  last 24h
                </Typography>
              </Box> */}
            </Box>
            <Box pt={0}>
              {currencyItem.marketData?.sparkline?.times.length && (
                <Chart
                  options={chartOptions}
                  series={[{
                    name: currencyItem.name,
                    data: currencyItem.marketData?.sparkline?.highest || [],
                  }]}
                  type="area"
                  height={200}
                />
              )}
            </Box>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}

export default WatchListColumn;
