import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  Grid,
  Icon,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  FormControl,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import {
  useForm,
  FormProvider,
  SubmitHandler,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { createItemFn } from 'api/addrApi';
import { RESCODE } from 'models/Generic';
import { CRYPTO_SYMBOL, DB_Currency } from 'models/db/Currency';
import { MyFormHelperText } from 'components/Forms/MyFormHelper';
import { CRYPTO_NETWORK, DB_Network } from 'models/db/Network';
import { useFetch } from 'hooks/useFetch';
import { getNetworks } from 'models/api/Network';
import { getCurrencies } from 'models/api/Currency';
import DataLoader from 'components/Loaders/DataLoader';

interface ICreateItemProp {
  setRefetch: Dispatch<SetStateAction<number>>;
  setOpenCrudModal: Dispatch<SetStateAction<boolean>>;
  setPurchaseConfirm: Dispatch<SetStateAction<boolean>>;
}

const createItemSchema = z.object({
  network: z.nativeEnum(CRYPTO_NETWORK, {
    required_error: 'Network is required',
  }),
  currency: z.nativeEnum(CRYPTO_SYMBOL, {
    required_error: 'Currency is required',
  }),
});

export type TCreateItem = z.TypeOf<typeof createItemSchema>;

const AddForm: FC<ICreateItemProp> = ({ setRefetch, setOpenCrudModal, setPurchaseConfirm }) => {
  const { isLoading, status, isError, error, mutate: createItem } = useMutation({
    mutationFn: (inputData: TCreateItem) => createItemFn(inputData),
    onSuccess: () => {
      setRefetch(c => ++c)
      notification.success('Address created successfully');
      setOpenCrudModal(false);
    },
    onError: (error: any, variables, context) => {
      setOpenCrudModal(false);

      const errMessage = error?.response?.data?.message;
      //! network address limit is enough 
      if (errMessage.trim() === RESCODE.ADDRESS_LIMIT_IS_NOT_ENOUGH) {
        setPurchaseConfirm(true)
      }
    },
  })

  if (!isLoading && isError) {
    console.log(status, error, status);
  }

  const [networks, setNetworks] = React.useState<DB_Network[]>([]);
  const [currencies, setCurrencies] = React.useState<DB_Currency[]>([]);
  const networkData = useFetch({ callback: async () => await getNetworks() })
  const currencyData = useFetch({ callback: async () => await getCurrencies() })

  const methods = useForm<TCreateItem>({ resolver: zodResolver(createItemSchema) });

  const { formState: { errors: formError, isSubmitSuccessful } } = methods;

  if (Object.keys(formError).length) {
    console.log('formError', formError);
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      methods.reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<TCreateItem> = (formValues) => {
    console.log('formValues', formValues);
    createItem(formValues);
  };

  const [network, setNetwork] = React.useState<CRYPTO_NETWORK>(CRYPTO_NETWORK.TRC20);
  const [currency, setCurrency] = React.useState<CRYPTO_SYMBOL>(CRYPTO_SYMBOL.USDT);

  const handleChangeNetwork = (event: SelectChangeEvent) => setNetwork(event.target.value as CRYPTO_NETWORK);
  const handleChangeCurrency = (event: SelectChangeEvent) => setCurrency(event.target.value as CRYPTO_SYMBOL);

  useEffect(() => {
    console.log({ counter: networkData.counter })

    if (networkData.action === 'ready') {
      networkData.refetch()
    }

    if (networkData.data?.data) {
      console.log({ data: networkData.data.data })
      setNetworks(networkData.data.data)
    }
  }, [networkData.action])

  useEffect(() => {
    console.log({ counter: currencyData.counter })

    if (currencyData.action === 'ready') {
      currencyData.refetch()
    }

    if (currencyData.data?.data) {
      console.log({ data: currencyData.data.data })
      setCurrencies(currencyData.data.data)
    }
  }, [currencyData.action])

  if (networkData.loading || currencyData.loading) {
    return <DataLoader />
  }

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Add Address
        </Typography>

        <CloseButton loading={isLoading}
          onClick={() => setOpenCrudModal(false)}>
          <Icon>close</Icon>
        </CloseButton>
      </Box>

      <FormProvider {...methods}>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          {currencies.length > 0 && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="currency-label">Select Wallet</InputLabel>

              <Select {...methods.register('currency')}
                label="Select Wallet"
                labelId="currency-label"
                value={currency}
                onChange={handleChangeCurrency}
              >
                {currencies.map(curr => <MenuItem key={curr.id} value={curr.symbol}>{curr.name} Wallet</MenuItem>)}
              </Select>

              <MyFormHelperText error={!!formError.currency} message={formError.currency?.message} />
            </FormControl>
          )}

          {networks.length > 0 && (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="network-label">Network</InputLabel>
              <Select  {...methods.register('network')}
                value={network}
                label="Network"
                labelId="network-label"
                onChange={handleChangeNetwork}
              >
                {networks.map(network => <MenuItem key={network.id} value={network.symbol}>{network.name}</MenuItem>)}
              </Select>

              <MyFormHelperText error={!!formError.network} message={formError.network?.message} />
            </FormControl>
          )}

          <Grid container justifyContent="center">
            <LoadingButton
              variant='contained'
              type='submit'
              loading={isLoading}
              disabled={!currency || !network}
            >
              Generate
            </LoadingButton>
          </Grid>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default AddForm;