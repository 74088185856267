import { FC } from 'react';

import { Typography, Button, Grid, IconButton as _IconButton, Icon, styled } from '@mui/material';
import {
  AddTwoTone as AddTwoToneIcon,
  OpenInNewTwoTone as OpenInNewTwoToneIcon
} from '@mui/icons-material';

interface PageTitleProps {
  heading?: string;
  subHeading?: string;
  docs?: { text: string, href: string };
  crud?: { text?: string, add?: boolean };
  setOpenCrudModal?: (openModal: boolean) => void;
  sx?: object;
}

const IconButton = styled(_IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  background: theme.palette.primary.main,
  '&:hover': { background: theme.palette.primary.dark },
}));

const PageTitle: FC<PageTitleProps> = ({
  heading = '',
  subHeading = '',
  docs = {
    text: '',
    href: ''
  },
  crud = {
    text: '',
    add: false
  },
  setOpenCrudModal,
  ...rest
}) => {
  const handleCrudModal = (open: boolean) => {
    if (typeof setOpenCrudModal === 'function') {
      setOpenCrudModal(open)
    }
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {heading}
        </Typography>
        <Typography variant="subtitle2">{subHeading}</Typography>
      </Grid>
      <Grid item>
        {docs.text && (
          <Button
            href={docs.href}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mr: { xs: 1, md: 2 } }}
            variant="contained"
            endIcon={<OpenInNewTwoToneIcon fontSize="small" />}
          >
            {docs.text}
          </Button>
        )}

        {crud.add && (
          <IconButton onClick={() => handleCrudModal(true)}
            size="medium">
            {crud.text}
            <Icon fontSize='inherit'>add</Icon>
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default PageTitle;
