import { Autocomplete, Box, Grid, Skeleton, TextField } from "@mui/material"
// import zionApi from "api/zionApi";
// import { useFetch } from "hooks/useFetch";
// import { GenericResponse } from "models/Generic";
// import { DB_Asset } from "models/db/Asset";
// import { useEffect } from "react";

interface SelectCurrencyProps {
  label: any;
  value: any;
  setValue: any;
  hiddens: string[];
}

const SelectCurrency = (props: SelectCurrencyProps): React.JSX.Element => {
  const { value, setValue, label, hiddens } = props;

  // const { data, error, loading, refetch, action, counter } = useFetch<GenericResponse<DB_Asset[]>>({
  //   callback: async () => {
  //     const response = await zionApi.get<GenericResponse<DB_Asset[]>>('assets');
  //     return response.data;
  //   }
  // })

  // useEffect(() => {
  //   refetch();
  // }, [value]);

  // if (!data && !error) {
  //   return (
  //     <Grid item xs={12} md={3}>
  //       <Skeleton variant="rounded" height={60} />
  //     </Grid>
  //   )
  // }
  // if (error) {
  //   return <>Something went wrong!</>
  // }

  // const dataFilter = (data?.status === 'success' ? data.data : [])?.filter((item: DB_Asset) => item);
  // const assetOptions = dataFilter.map((item: DB_Asset) => `${item.symbol.toUpperCase()}`);

  const assetOptions = ['TRY', 'USDT'];

  return (
    <Box>
      <Autocomplete
        value={value}
        disableClearable
        options={hiddens.filter((val) => !assetOptions.includes(val)) ?? []}
        onChange={(e, val) => setValue(val)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            sx={{ minWidth: 70 }}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
          />
        )}
      />
    </Box>
  )
}

export default SelectCurrency