import { DB_Address } from "./Address";
import { DB_BankAccount } from "./BankAccount";

export interface ILoginResponse {
    status: string;
    accessToken: string;
    refreshToken: string;
    user: Partial<DB_User>;
    otpValid: boolean;
}

export interface IValidateOTP {
    status: string;
    otpValid: boolean;
    accessToken: string;
    refreshToken: string;
}

export interface IRefreshAccessTokenResponse {
    status: string;
    accessToken: string;
}

export interface IResetPasswordRequest {
    resetCode: string;
    password: string;
    passwordConfirm: string;
}

export enum EMemberRoleEnumType {
    VIEWER = 'viewer',
    MODARATOR = 'modarator',
}

export enum DB_USER_STATUS {
    ACTIVE = 'ACTIVE',
    SUSPEND = 'SUSPEND',
    DELETED = 'DELETED',
}

export interface DB_User_Commission {
    id: string;
    name: string;
    value: string;
}

export interface DB_UserPermission {
    role: EMemberRoleEnumType,
    read: boolean,
    readAll: boolean,
    write: boolean,
    update: boolean,
    delete: boolean,
}

export interface DB_User {
    id: string;
    name: string;
    email: string;
    role: string;
    image: string;
    verified: string;
    otp_enabled: boolean;
    status: DB_USER_STATUS;
    permission: DB_UserPermission;
    createdAt: string;
    updatedAt: string;
    lastLogin: Date;
    addresses: DB_Address[];
    commission: DB_User_Commission;
    bankAccounts: DB_BankAccount[];

    // fake fields
    commissionGroup: string;
    // commissionPercent: string;
    userBalance: number;
    actions: string | null;
}

export interface DB_Users {
    status: string;
    data: DB_User[];
    totalRows: number;
}

export type UserFieldName = keyof DB_User;

export const nameMapping_User: { [key in keyof DB_User]: UserFieldName } = {
    id: "id",
    name: "name",
    email: "email",
    role: "role",
    image: "image",
    verified: "verified",
    otp_enabled: "otp_enabled",
    status: "status",
    permission: "permission",
    createdAt: "createdAt",
    updatedAt: "updatedAt",
    lastLogin: "lastLogin",
    addresses: "addresses",
    commission: "commission",
    bankAccounts: "bankAccounts",
    commissionGroup: "commissionGroup",
    // commissionPercent: "commissionPercent",
    userBalance: "userBalance",
    actions: 'actions',
};