import React, { Dispatch, SetStateAction } from 'react';
import { useMutation } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import {
  LoadingButton
} from "components/Buttons/LoadingButton";

import {
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { MyFormHelperText } from 'components/Forms/MyFormHelper';
import { GenericResponse } from 'models/Generic';
import { DB_Commission } from 'models/db/Commission';
import zionApi from 'api/zionApi';

interface ICreateItemProp {
  setRefetch: Dispatch<SetStateAction<number>>;
}

const createItemSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  value: z.number(),
});

type TypeOfCreate = z.TypeOf<typeof createItemSchema>;

const AddForm: React.FC<ICreateItemProp> = ({ setRefetch }) => {
  const defaultValues: TypeOfCreate = Object.assign({
    name: '',
    value: 0,
  })

  const [formData, updateFormData] = React.useReducer(
    (state: TypeOfCreate, newState: Partial<TypeOfCreate>) => ({ ...state, ...newState, }), { ...defaultValues }
  );

  const queryCreateItem = useMutation({
    mutationFn: async (inputData: TypeOfCreate) => {
      const response = await zionApi.post<GenericResponse<DB_Commission>>(`admin/commissions`, inputData);
      return response.data;
    },
    onSuccess: () => {
      setRefetch(c => ++c)
      notification.success('Commission created successfully');
      methods.reset();
    }
  })

  const methods = useForm<TypeOfCreate>({ resolver: zodResolver(createItemSchema) });

  const { formState: { errors: formErrors, isSubmitSuccessful } } = methods;

  const onSubmitHandler: SubmitHandler<TypeOfCreate> = (formValues) => {
    if (Object.keys(formErrors).length) {
      Object.values(formErrors).map((item) => notification.error(item?.message as string));
      return;
    }

    queryCreateItem.mutate(formValues);
  };

  return (
    <>
      <Card sx={{ mt: 8 }}>
        <Box
          p={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h4">Add Commission</Typography>
          </Box>
        </Box>
        <Divider />

        <CardContent sx={{ p: 3 }}>
          <Grid container spacing={0} sx={{ mb: 2, xs: { mb: 1 } }}>
            <Grid item xs={12}>
              <FormProvider {...methods}>
                <Box
                  noValidate
                  component='form'
                  autoComplete='off'
                  onSubmit={methods.handleSubmit(onSubmitHandler)}
                >
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField label='Name'
                      {...methods.register('name')}
                      value={formData.name}
                      onChange={(e) => updateFormData({ name: e.target.value })}
                    />
                    <MyFormHelperText error={!!formErrors?.name} message={formErrors?.name?.message} />
                  </FormControl>

                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField label='Value'
                      {...methods.register('value', { valueAsNumber: true, setValueAs: v => parseFloat(v) })}
                      type="number"
                      inputProps={{ min: 0 }}
                      value={formData.value}
                      onChange={(e) => updateFormData({ value: parseFloat(e.target.value) })}
                    />
                    <MyFormHelperText error={!!formErrors?.value} message={formErrors?.value?.message} />
                  </FormControl>

                  <Grid container justifyContent="center" >
                    <LoadingButton
                      variant='contained'
                      type='submit'
                      loading={queryCreateItem.isLoading}
                      disabled={!formData.name && !formData.value}
                    >
                      Create
                    </LoadingButton>
                  </Grid>
                </Box>
              </FormProvider>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default AddForm;