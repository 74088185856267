import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';
import { Tab, Grid, Divider, Container } from '@mui/material';
import { TabContext, TabList, TabPanel as TabPanel_ } from '@mui/lab';
import SuspenseLoader from 'components/SuspenseLoader';

import PageHeader from './PageHeader';
import UserTransactionsTab from './transactions';
import { DB_User } from 'models/db/User';
import { useFetch } from 'hooks/useFetch';

import UserProfileTab from './profile/Index';
import UserSweepersTab from './sweepers/Index';
import UserAddressesTab from './addresses/Index';
import UserLogsTab from './accesslogs/Index';
import { getUserFn } from 'api/userApi';
import UserBankAccountsTab from './bank_accounts';

const TabPanel = styled(TabPanel_)(({ theme }) => ({
  padding: 0,
  scrollableX: {
    overflowX: "auto !important"
  }
}));

function UserDetail() {
  const params = useParams()

  const [tabs, setTabs] = useState<{ value: string; label: string; }[]>([
    { value: 'profile', label: 'Profile' },
    { value: 'logs', label: 'User Logs' },
    { value: 'transactions', label: 'Transactions' },
    { value: 'sweepers', label: 'Sweepers' },
    { value: 'addresses', label: 'Addresses' },
  ])

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<string>(searchParams.get('tab') || tabs[0].value);

  useEffect(() => {
    if (currentTab !== searchParams.get('tab')) {
      setCurrentTab(searchParams.get('tab') || tabs[0].value)
    }
  }, [searchParams.get('tab')])

  const userId = params.userId;

  // const { data: targetUser, error, loading } = useFetch()
  const { data: targetUser, error, loading, refetch } = useFetch<DB_User>({
    qkey: `users/${userId}`,
    callback: async () => (await getUserFn(String(userId))).data
  })

  useEffect(() => refetch(), [])

  useEffect(() => {
    if (targetUser?.id && targetUser?.role !== 'member' && targetUser?.bankAccounts.length > 0) {
      setTabs(values => [...values, { value: 'bank_accounts', label: 'Bank Accounts' }])
    }
  }, [targetUser?.id])

  if (loading || !targetUser?.id) return <SuspenseLoader />

  return (
    <>
      <Helmet><title>Users</title></Helmet>

      <TabContext value={currentTab}>
        <Container maxWidth={false} disableGutters>
          <Container maxWidth={false}>
            <Grid sx={{ mb: 3 }}>
              <PageHeader targetUser={targetUser} />
            </Grid>
          </Container>
        </Container>

        <Container maxWidth={false}>
          <Grid sx={{ mb: 3 }}>
            <TabList onChange={(event: React.SyntheticEvent, value: string): void => setCurrentTab(value)}>
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} onClick={e => setSearchParams({ 'tab': tab.value })} />
              ))}
            </TabList>
            <Divider />
          </Grid>
        </Container>

        <Container maxWidth={false}>
          <Grid>
            <TabPanel value="profile"><UserProfileTab setRefetch={refetch} targetUser={targetUser} /></TabPanel>
            <TabPanel value="logs"><UserLogsTab targetUser={targetUser} /></TabPanel>
            <TabPanel value="transactions"><UserTransactionsTab targetUser={targetUser} /></TabPanel>
            <TabPanel value="sweepers"><UserSweepersTab targetUser={targetUser} /></TabPanel>
            <TabPanel value="addresses"><UserAddressesTab targetUser={targetUser} /></TabPanel>
            {(targetUser?.role !== 'member' && targetUser?.bankAccounts.length > 0) && (
              <>
                <TabPanel value="bank_accounts"><UserBankAccountsTab bankAccounts={targetUser.bankAccounts} /></TabPanel>
              </>
            )}
          </Grid>
        </Container>
      </TabContext>
    </>
  );
}

export default UserDetail;
