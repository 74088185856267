import { DB_User } from "./User";
import { DB_Address } from "./Address";
import { DB_STATUS } from "models/Generic";
import { DB_Currency } from "./Currency";

export enum DB_FREQUENCY {
    HOURLY = 'HOURLY',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
}

export interface DB_Sweeper {
    id: string;
    fromAddress: DB_Address;
    toAddress: string;
    amount: number;
    user: DB_User;
    status: DB_STATUS;
    frequency: DB_FREQUENCY;
    createdAt: string;
    updatedAt: string;
    startDate: Date | null;
    stopDate: Date | null;
    startTime: Date | null;
    lastRun: Date;
    currency: DB_Currency;

    // fake fields
    userName: string | null;
    sweepBalance: string | null;
    actions: string | null;
}

export interface DB_Sweepers {
    status: string;
    data: DB_Sweeper[];
    totalRows: number;
}

export type SweeperFieldName = keyof DB_Sweeper;

export const nameMapping_Sweeper: { [key in keyof DB_Sweeper]: SweeperFieldName } = {
    id: "id",
    createdAt: "createdAt",
    updatedAt: "updatedAt",
    fromAddress: "fromAddress",
    currency: "currency",
    toAddress: "toAddress",
    amount: "amount",
    user: "user",
    status: "status",
    frequency: "frequency",
    startDate: "startDate",
    stopDate: "stopDate",
    startTime: "startTime",
    lastRun: "lastRun",
    userName: "userName",
    sweepBalance: "sweepBalance",
    actions: "actions",
};