import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Card,
  Box,
  CardActions,
  Typography,
  Stack,
  Divider,
  useTheme
} from '@mui/material';
import Text from 'components/Text';
import Label from 'components/Label';
import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import TrendingDownTwoToneIcon from '@mui/icons-material/TrendingDownTwoTone';
import TrendingUpTwoToneIcon from '@mui/icons-material/TrendingUpTwoTone';
import { CryptoAvatar } from 'components/Avatar';
import { DB_Currency } from 'models/db/Currency';
import config from 'config';
import { formatBalance } from 'helpers/utils';

interface IWatchListRowProps {
  currencies?: DB_Currency[];
}

const WatchListRow: React.FC<IWatchListRowProps> = ({ currencies = [] }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const Box1Options: ApexOptions = {
    chart: {
      animations: {
        enabled: false
      },
      background: 'transparent',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      },
      zoom: {
        enabled: false
      }
    },
    labels: currencies.length ? currencies[0].marketData?.sparkline?.labels : [],
    stroke: {
      curve: 'smooth',
      colors: [theme.colors.primary.main],
      width: 2
    },
    yaxis: {
      show: false
    },
    colors: [theme.colors.primary.main],
    grid: {
      padding: {
        top: 10,
        right: 5,
        bottom: 10,
        left: 5
      }
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      fixed: {
        enabled: true
      },
      x: {
        show: true
      },
      y: {
        title: {
          formatter: function () {
            return 'Price: $';
          }
        }
      },
      marker: {
        show: false
      }
    }
  };

  return (
    <Card>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={0}
      >
        {currencies.length && currencies.map((currencyItem, i) =>
          <Box sx={{ width: '100%', p: 3 }} key={currencyItem.id}>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <CryptoAvatar
                  sx={{ margin: theme.spacing(0, 0, 1, -0.5) }}
                  src={`/icons/${currencyItem.symbol.toLowerCase()}.svg`} />
                <Box>
                  <Typography variant="h4" noWrap>
                    {currencyItem.name}
                  </Typography>
                  <Typography variant="subtitle1" noWrap>
                    {currencyItem.marketData?.pair.toUpperCase()}
                  </Typography>
                </Box>
              </Box>
              <Label color="secondary">24h</Label>
            </Box>
            <Box mt={3} display="flex" alignItems="center" justifyContent="space-between">
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Typography variant="h2" sx={{ pr: 1 }}>
                  {currencyItem.symbol !== 'USDT' ? '$' : '₺'}
                  {formatBalance(Number(currencyItem?.marketData?.price))}
                </Typography>
                <Text color={currencyItem?.marketData?.increase === '+' ? 'success' : 'error'}>
                  <b>{currencyItem?.marketData?.increase}{Math.abs(Number(currencyItem?.marketData?.change))}%</b>
                </Text>
              </Box>

              {currencyItem?.marketData?.increase === '+' ? (
                <TrendingUpTwoToneIcon sx={{ color: `${theme.colors.success.main}` }} />
              ) : (
                <TrendingDownTwoToneIcon sx={{ color: `${theme.colors.error.main}` }} />
              )}

            </Box>
            <Box pt={2}>
              {currencyItem.marketData?.sparkline?.times.length && (
                <Chart
                  options={Box1Options}
                  series={[{
                    name: currencyItem.name,
                    data: currencyItem.marketData?.sparkline?.highest || [],
                  }]}
                  type="line"
                  height={100}
                />
              )}
            </Box>
          </Box>
        )}
      </Stack>
      <Divider />
      <CardActions
        disableSpacing
        sx={{
          p: 3,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Button variant="outlined" onClick={e => navigate('/dashboard/wallets')}>View more assets</Button>
      </CardActions>
    </Card>
  );
}

export default WatchListRow;
