import dayjs from "dayjs";
import { ColumnDefinitionType } from "components/DataTable/Table";
import { DB_BankAccount } from "models/db/BankAccount";
import { StatusLabelMaps, getStatusLabel } from "components/Label/StatusLabel";
import { DB_STATUS } from "models/Generic";
import { GenericField } from "components/DataTable/filter/FilterType";
// import actionsCell from "./actionsCell";

export const cols_BankAccount: Array<ColumnDefinitionType<DB_BankAccount>> = [
    // { key: 'id', header: 'ID' },
    {
        key: 'name',
        header: 'Name',
        default: '',
    },
    {
        key: 'bank',
        header: 'Bank',
        cell: (item) => item.bank.name,
    },
    {
        key: 'iban',
        header: 'IBAN',
    },
    {
        key: 'currency',
        header: 'Currency',
    },
    {
        key: 'status',
        header: 'Status',
        cell: (item) => getStatusLabel(item.status),
        default: {
            ACTIVE: {
                text: 'ACTIVE',
                color: 'success'
            },
            SUSPEND: {
                text: 'SUSPEND',
                color: 'warning'
            },
        }
    },
    {
        key: 'createdAt',
        header: 'Created At',
        cell: (item) => dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    // {
    //     key: 'actions',
    //     header: 'Actions',
    //     cell: (row, setRefetch) => actionsCell(row, setRefetch),
    // },
];

export const fields_BankAccount: Array<GenericField<DB_BankAccount>> = [
    {
        name: 'name',
        title: 'Name',
        type: 'text',
        search: true,
    },
    {
        name: 'iban',
        title: 'IBAN',
        type: 'text',
        search: true,
    },
    {
        name: 'status',
        title: 'Status',
        type: 'select',
        selectOptions: [DB_STATUS.ACTIVE, DB_STATUS.SUSPEND]
    },
    {
        name: 'createdAt',
        title: 'Date',
        type: 'date',
    },
];