import { FC, ChangeEvent, useState } from 'react';

import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Checkbox,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  MenuItem,
  Card as _Card,
  CardHeader,
  styled,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import DataTableRow from './DataTableRow';

import TableHeader from './DataTableHeader';
import { DB_STATUS, statusOptions } from 'models/Generic';
import { DB_ApiKey } from 'models/db/ApiKey';

const Card = styled(_Card)(({ theme }) => ({
  borderRadius: 4,
  border: '1px solid #dbdfea',
  boxShadow: 'none',
  backgroundColor: 'transparent',
}));

interface IDataTableProps {
  items: DB_ApiKey[];
}

interface Filters {
  status: DB_STATUS | null;
}

const applyFilters = (
  items: DB_ApiKey[],
  filters: Filters
): DB_ApiKey[] => {
  return items.filter((item) => {
    let matches = true;

    if (filters.status && item.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (
  items: DB_ApiKey[],
  page: number,
  limit: number
): DB_ApiKey[] => {
  return items.slice(page * limit, page * limit + limit);
};

const DataTable: FC<IDataTableProps> = ({ items }) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const selectedBulkActions = selectedItems.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({ status: null });

  const handleStatusChange = (e: SelectChangeEvent): void => {
    let value: any = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleSelectAllItems = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedItems(
      event.target.checked ? items.map((item) => item.id) : []
    );
  };

  const handleSelectOneItem = (
    event: ChangeEvent<HTMLInputElement>,
    itemId: number
  ): void => {
    if (!selectedItems.includes(itemId)) {
      setSelectedItems((prevSelected) => [
        ...prevSelected,
        itemId
      ]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((id) => id !== itemId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => setPage(newPage);
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => setLimit(parseInt(event.target.value));

  const filteredItems = applyFilters(items, filters);
  const paginatedItems = applyPagination(filteredItems, page, limit);

  const selectedAllItems = selectedItems.length === items.length;
  const selectedSomeItems = selectedItems.length > 0 && selectedItems.length < items.length;

  return (
    <Card>
      {selectedBulkActions && (
        <Box flex={1} sx={{ padding: 2 }}>
          <TableHeader />
        </Box>
      )}
      {!selectedBulkActions && (
        <CardHeader
          sx={{ padding: 2 }}
          action={
            <Box width={150}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status || 'all'}
                  onChange={handleStatusChange}
                  label="Status"
                  autoWidth
                >
                  {statusOptions.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
          title="Items"
        />
      )}
      <Divider />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={selectedAllItems}
                  indeterminate={selectedSomeItems}
                  onChange={handleSelectAllItems}
                />
              </TableCell>
              <TableCell>Id</TableCell>
              <TableCell>ApiKey</TableCell>
              <TableCell>Client IP</TableCell>
              <TableCell>Callback Url</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedItems.map((item) =>
              <DataTableRow
                key={item.id}
                item={item}
                isItemSelected={selectedItems.includes(item.id)}
                handleSelectOneItem={handleSelectOneItem}
              />)}
          </TableBody>
        </Table>
      </TableContainer>

      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredItems.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default DataTable;