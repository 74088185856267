import { DB_User } from "./User";
import { DB_STATUS } from "models/Generic";

export enum BankCurrencyType {
    TRY = 'TRY',
    USD = 'USD',
    EURO = 'EURO',
}

export interface DB_Bank {
    id: string;
    name: string;
    code: string;
    status: DB_STATUS;
    createdAt: string;
    updatedAt: string;
}

export interface DB_BankAccount {
    id: string;
    name: string;
    iban: string;
    user: DB_User;
    status: DB_STATUS;
    bank: DB_Bank;
    currency: BankCurrencyType;
    createdAt: string;
    updatedAt: string;
    actions: string | null;
}

export interface DB_BankAccounts {
    status: string;
    data: DB_BankAccount[];
    totalRows: number;
}

export type BankAccountFieldName = keyof DB_BankAccount;

export const nameMapping_BankAccount: { [key in keyof DB_BankAccount]: BankAccountFieldName } = {
    id: "id",
    user: "user",
    status: "status",
    name: "name",
    iban: "iban",
    bank: "bank",
    currency: "currency",
    createdAt: "createdAt",
    updatedAt: "updatedAt",
    actions: "actions"
};