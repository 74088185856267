import { ColumnDefinitionType } from "components/DataTable/Table";
import { DB_Address } from "models/db/Address";
import { getStatusLabel } from "components/Label/StatusLabel";
import { DB_STATUS } from "models/Generic";
import dayjs from "dayjs";
import { GenericField } from "components/DataTable/filter/FilterType";
import AddressCell from "components/Table/AddressCell";
import balanceParser from "components/Helpers/balanceParser";

export const cols_Address: Array<ColumnDefinitionType<DB_Address>> = [
    // {
    //     key: 'id',
    //     header: 'ID',
    //     hideFilter: true,
    //     sx: {
    //         width: 80,
    //         minWidth: 50,
    //     }
    // },
    {
        key: 'name',
        header: 'Name',
        cell: (item) => item.name ?? '-',
        default: '',
    },
    {
        width: 250,
        key: 'address',
        header: 'Address',
        cell: (item) => <AddressCell value={item.address} />,
    },
    {
        key: 'balances',
        header: 'Balance',
        hideSort: true,
        hideFilter: true,
        cell: (row) => balanceParser({ balances: row.currencyBalances }),
    },
    {
        key: 'status',
        header: 'Status',
        cell: (item) => getStatusLabel(item.status),
        default: {
            ACTIVE: {
                text: 'ACTIVE',
                color: 'success'
            },
            SUSPEND: {
                text: 'SUSPEND',
                color: 'warning'
            },
        }
    },
    {
        key: 'createdAt',
        header: 'Created At',
        cell: (item) => dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
];

export const fields_Address: Array<GenericField<DB_Address>> = [
    {
        name: 'address',
        title: 'Address',
        type: 'text',
        search: true,
    },
    {
        name: 'name',
        title: 'Address Name',
        type: 'text',
    },
    {
        name: 'balances',
        title: 'Balance',
        type: 'text',
    },
    {
        name: 'status',
        title: 'Status',
        type: 'select',
        selectOptions: Object.keys(DB_STATUS).filter((v) => isNaN(Number(v)))
    },
    {
        name: 'createdAt',
        title: 'Date',
        type: 'date',
    },
];