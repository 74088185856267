import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import { Grid, Tooltip } from "@mui/material";
import {
  InfoTwoTone as InfoTwoToneIcon,
  EditTwoTone as EditTwoToneIcon,
  DeleteTwoTone as DeleteTwoToneIcon,
  StopCircleTwoTone as SuspendUserOffIcon,
  PlayCircleTwoTone as SuspendUserOnIcon,
} from '@mui/icons-material';

import notification from "helpers/notification";
import CrudModal from "components/Modals/crud.modal";
import MemberEditForm from "./forms/MemberEditForm";
import { LoadingIconButton } from "components/Buttons/LoadingButton";
import { DB_USER_STATUS, DB_User } from "models/db/User";
import { suspendUserFn } from "models/api/User";

export default function actionsCell(row: DB_User, setRefetch: Dispatch<SetStateAction<number>>): React.JSX.Element {
  const navigate = useNavigate()
  const [openCrudModal, setOpenCrudModal] = React.useState(false);

  const suspendUser = useMutation(
    (id: string) => suspendUserFn(id), {
    onSuccess: (data) => notification.success('User status updated')
  });

  const onSuspendHandler = (id: string) => window.confirm('Are you sure') ? suspendUser.mutate(id) : false;

  React.useEffect(() => {
    if ([suspendUser.status].includes('success')) setRefetch(c => ++c);
  }, [suspendUser.status])

  return <>
    <Grid display="flex" flexDirection="row">
      <Tooltip arrow title="Edit User" onClick={() => setOpenCrudModal(true)}>
        <LoadingIconButton>
          <EditTwoToneIcon fontSize="small" />
        </LoadingIconButton>
      </Tooltip>

      <Tooltip arrow title="Detail User" onClick={() => navigate(`/dashboard/members/${row.id}`)}>
        <LoadingIconButton>
          <InfoTwoToneIcon fontSize="small" />
        </LoadingIconButton>
      </Tooltip>

      <Tooltip arrow title={row.status === DB_USER_STATUS.ACTIVE ? "Stop" : "Start"} onClick={() => onSuspendHandler(row.id)}>
        <LoadingIconButton loading={suspendUser.isLoading}>
          {row.status === DB_USER_STATUS.ACTIVE ? <SuspendUserOnIcon fontSize="small" /> : <SuspendUserOffIcon fontSize="small" />}
        </LoadingIconButton>
      </Tooltip>
    </Grid>

    <CrudModal openCrudModal={openCrudModal} setOpenCrudModal={setOpenCrudModal}>
      <MemberEditForm setOpenCrudModal={setOpenCrudModal} user={row} setRefetch={setRefetch} />
    </CrudModal>
  </>
}