import { Outlet } from 'react-router-dom';

import {
  Box,
  Grid,
  Container,
  CssBaseline,
  Divider,
} from '@mui/material';

import Footer from './Footer';
import Header, { DrawerHeader } from './Header/Index';

export default function DashboardLayout() {

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Header />

      <Box component="main" sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        position: 'relative',
      }}>
        <DrawerHeader />
        <Divider />

        <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Outlet />
            </Grid>
          </Grid>
        </Container>

        <Footer props={{
          py: 3,
          px: 2,
          mt: 'auto',
        }} />
      </Box>
    </Box>
  );
}
