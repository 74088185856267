import { Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';

export const ProductFeaturesPage = () => {
  return (
    <Container disableGutters maxWidth={'md'}>
      <Grid container sx={{ display: 'flex' }}>
        <Grid item xs={12} my={3}>
          <Card
            sx={{
              boxShadow: 'none',
              backgroundColor: 'transparent'
            }}
          >
            <CardMedia
              alt="about"
              component="img"
              image="/images/product_features.png"
            />
            <CardContent sx={{ px: 0 }}>
              <Typography variant="h1" sx={{ pb: 1 }} align='center'>
                Product Features
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 3 }}>
                <strong>Dashboard</strong><br />
                Get a quick overview of essential information such as total asset balance, the number of wallets, and total transaction count with the dashboard feature, helping you better understand and manage your wallets. This allows you to assess your portfolio's status, monitor multiple wallets more effectively, and summarize your financial activities. Additionally, you can access real-time price data and charts for your crypto assets through the watchlist feature.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 3 }}>
                <strong>Addresses</strong><br />
                Gain full control over your cryptocurrency addresses. You can filter addresses based on their status, change their status, and provide custom labels.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 3 }}>
                <strong>Wallet Management</strong><br />
                Wallet management is a powerful tool for understanding and efficiently managing your digital asset portfolio. Easily browse your wallets to monitor your asset portfolio, understand the total value of your digital assets, and perform various operations such as sending and receiving funds.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 3 }}>
                <strong>Transaction History</strong><br />
                With our system, you can quickly review your transactions retrospectively, enabling you to execute all transfers efficiently.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 3 }}>
                <strong>Deposit</strong><br />
                You can consolidate your assets from different blockchains or cryptocurrencies into a single central wallet, allowing you to diversify your crypto investment strategies and interact with a broader crypto ecosystem.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 3 }}>
                <strong>Send</strong><br />
                Transfer cryptocurrencies from your wallet to other wallet addresses or cryptocurrency recipients. You can use this method for making payments, conducting trades, or simply storing your assets in a different wallet.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 3 }}>
                <strong>Sweep Rules (Regular Transfer)</strong><br />
                Automate and schedule your cryptocurrency management more effectively with predefined rules. You can automatically transfer your cryptocurrencies to other wallets at regular intervals according to your chosen frequency, whether hourly, daily, weekly, or monthly. Moreover, you can edit or delete the created orders at any time.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 3 }}>
                <strong>API</strong><br />
                Seamlessly integrate your wallet with external applications or services. You can also create and easily list new cryptocurrency addresses. Instantly calculate the USDT equivalent of received payments and receive notifications when payments reach you.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 3 }}>
                <strong>User Management</strong><br />
                Create sub-users with different roles and statuses based on your account. You can view all these sub-users on a single screen and apply filters based on specific features.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 3 }}>
                <strong>User Settings</strong><br />
                Easily modify your personal information through the profile page. You can change passwords, enable two-factor authentication, access login information based on IP and date, and manage notification preferences as desired.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};