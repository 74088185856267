import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  Grid,
  ListItem,
  List,
  ListItemText,
  Divider,
  Button,
  CardHeader,
  Box,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  useTheme,
  useMediaQuery,
  Icon,
  CircularProgress,
  IconButton,
  Slide,
  DialogActions,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import CrudModal from 'components/Modals/crud.modal';
import UserUpdatePass from '../_forms/UserUpdatePass';
import DataTable from 'components/DataTable/DataTable';
import { cols_UserLog, fields_UserLog } from "./definitions";
import { getUserLogs } from 'models/api/User';
import useUserContext from 'hooks/useUserContext';
import { nameMapping_UserLog } from 'models/db/UserLog';
import { MyIconButton } from 'components/Buttons/Index';

import { z } from 'zod';
import useStore from 'store';
import zionApi from 'api/zionApi';
import { DB_User } from 'models/db/User';
import TwoFactorAuth from 'components/TwoFactorAuth';
import { TransitionProps } from "@mui/material/transitions";
import { LoadingButton } from 'components/Buttons/LoadingButton';
import { TRUE } from 'sass';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SecurityTab() {
  const theme = useTheme();
  const store = useStore();
  const user = store.authUser;
  const { authState } = useUserContext();
  const [openCrudModal, setOpenCrudModal] = React.useState(false);
  const mdFullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [secret, setSecret] = React.useState({ base32: "", otpAuthURL: "" });

  const generateQrCode = async ({ userId, email }: { userId: string; email: string; }) => {
    try {
      store.setRequestLoading(true);
      const response = await zionApi.post<{
        otpAuthURL: string;
        base32: string;
      }>("/auth/otp/generate", { generate: { userId, email } });
      store.setRequestLoading(false);

      if (response.status === 200) {
        handleOtpModal(true);
        console.log({
          base32: response.data.base32,
          otpAuthURL: response.data.otpAuthURL,
        });
        setSecret({
          base32: response.data.base32,
          otpAuthURL: response.data.otpAuthURL,
        });
      }
    } catch (error: any) {
      store.setRequestLoading(false);
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();
      toast.error(resMessage, {
        position: "top-right",
      });
    }
  };

  const disableTwoFactorAuth = async (userId: string) => {
    try {
      store.setRequestLoading(true);
      const {
        data: { user },
      } = await zionApi.post<{
        user: DB_User;
        otpDisabled: boolean;
      }>("/auth/otp/disable", { disable: { userId } });
      store.setRequestLoading(false)
      store.setAuthUser(user)
      toast.warning("Two Factor Authentication Disabled", {
        position: "top-right",
      });
    } catch (error: any) {
      store.setRequestLoading(false)
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(resMessage, {
        position: "top-right",
      });
    }
  };

  const [step, setStep] = React.useState(0);
  const [otpModal, setOtpModal] = React.useState(false);

  const [otpSwitch, setOtpSwitch] = React.useState(Boolean(user?.otp_enabled));

  const [enable2fa, setEnable2fa] = React.useState(false);
  const [disable2fa, setDisable2fa] = React.useState(false);
  const [confirmModal, setConfirmModal] = React.useState(false);

  const handleOtpModal = (status: boolean, resStatus?: boolean) => {
    setStep(0)
    setOtpModal(status)

    if (!status) {
      setEnable2fa(false)
      if (!Boolean(user?.otp_enabled)) {
        setOtpSwitch(false)
      }

      if (resStatus) {
        setOtpSwitch(true)
      }
    }
  };

  const handleOtpSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtpSwitch(event.target.checked)

    if (event.target.checked) {
      if (!Boolean(user?.otp_enabled)) {
        setEnable2fa(true)
      }
    } else {
      if (Boolean(user?.otp_enabled)) {
        setEnable2fa(false)
        setConfirmModal(true)
      }
    }
  };

  const handleDisableModalCancel = () => {
    setOtpSwitch(true)
    setConfirmModal(false)
  }
  const handleDisableModalApprove = () => {
    setDisable2fa(true)
    setConfirmModal(false)
  }

  React.useEffect(() => {
    if (enable2fa) {
      if (Boolean(user?.otp_enabled)) {
        alert('Enable2faCaseErr')
        return;
      }

      generateQrCode({ userId: user?.id!, email: user?.email! })
    }
  }, [enable2fa])

  React.useEffect(() => {
    if (disable2fa) {
      if (!Boolean(user?.otp_enabled)) {
        alert('Disable2faCaseErr')
        return;
      }

      disableTwoFactorAuth(user?.id!)
    }
  }, [disable2fa])

  const SeitcherIcon = () => (
    <>
      {store.requestLoading ? (
        <CircularProgress size={20} color="primary" thickness={6} />
      ) : (
        <Icon sx={{
          ...(otpSwitch ? {} : {
            fontSize: 0,
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: '#f5f6fa',
            border: "1px solid rgba(77, 77, 77, 0.3)",
            boxShadow: "0px 9px 14px rgba(77, 77, 77, 0.1), 0px 2px 2px rgba(77, 77, 77, 0.1)",
          })
        }}>circle</Icon>
      )}
    </>
  )

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {/* <Box pb={2}>
          <Typography variant="h3">Security</Typography>
          <Typography variant="subtitle2">
            Change your security preferences below
          </Typography>
        </Box> */}

          <Card>
            <List>
              <ListItem sx={{ px: 3, py: 2 }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                  secondaryTypographyProps={{
                    variant: 'subtitle2',
                    lineHeight: 1
                  }}
                  primary="Change Password"
                  secondary="You can change your password here"
                />

                <Button size="large" variant="outlined" onClick={() => setOpenCrudModal(true)}>
                  Change password
                </Button>

                {authState.user && (
                  <CrudModal openCrudModal={openCrudModal} setOpenCrudModal={setOpenCrudModal} closeable>
                    <UserUpdatePass setOpenCrudModal={setOpenCrudModal} user={authState.user} />
                  </CrudModal>
                )}
              </ListItem>

              <Divider component="li" />

              <ListItem sx={{ p: 3 }}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
                  secondaryTypographyProps={{
                    variant: 'subtitle2',
                    lineHeight: 1
                  }}
                  primary="Two-Factor Authentication (2FA)"
                  secondary="Enable PIN verification for all sign in attempts"
                />

                <Switch
                  color="primary"
                  checked={otpSwitch}
                  onChange={handleOtpSwitch}
                  inputProps={{ 'aria-label': 'controlled' }}
                  checkedIcon={<SeitcherIcon />}
                  icon={<SeitcherIcon />}
                  disabled={store.requestLoading}
                  sx={{ padding: otpSwitch ? '12px 12px 8px 8px' : '12px 12px 12px 8px' }}
                />
              </ListItem>
            </List>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader
              sx={{ px: 3 }}
              subheaderTypographyProps={{}}
              titleTypographyProps={{}}
              title="Access Logs"
              subheader="Recent sign in activity logs"
            />
            <Divider />
            <Box sx={{ px: 3 }}>
              <DataTable
                title='Logs'
                dataName='logs'
                cols={cols_UserLog}
                fields={fields_UserLog}
                nameMapping={nameMapping_UserLog}
                getFilteredRecords={getUserLogs}
                rowsPerPage={100}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={otpModal}
        onClose={e => handleOtpModal(false)}
        fullScreen={mdFullScreen}
        aria-labelledby="responsive-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="responsive-dialog-title" variant='h4'>
          {/* {"Mobile App Authentication (2FA)"} */}

          <MyIconButton
            aria-label="close"
            onClick={e => handleOtpModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </MyIconButton>
        </DialogTitle>

        <DialogContent>
          {otpModal && (
            <TwoFactorAuth
              base32={secret.base32}
              otpAuthURL={secret.otpAuthURL}
              userId={store.authUser?.id!}
              closeModal={(status) => handleOtpModal(false, status)}
              step={step}
              setStep={setStep}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmModal}
        onClose={handleDisableModalCancel}
        TransitionComponent={Transition}
        PaperProps={{ sx: { px: 1, py: 1 } }}
      >
        <DialogTitle variant="h4">Are You Sure?</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Do you want to <strong>DISABLED</strong> 2FA?
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', mx: 1 }}>
          <LoadingButton size="small" variant='outlined' onClick={handleDisableModalCancel}>
            Cancel
          </LoadingButton>
          <LoadingButton size="small" variant='contained' onClick={(e) => handleDisableModalApprove()}>
            Approve
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
