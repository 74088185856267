import { ColumnDefinitionType } from "components/DataTable/Table";
import { DB_Todo, TodoFieldName } from "models/db/Todo";
import { getStatusLabel } from "components/Label/StatusLabel";
import { DB_STATUS } from "models/Generic";
import dayjs from "dayjs";
import { GenericField } from "components/DataTable/filter/FilterType";
import { MyIconButton } from "components/Buttons/Index";
import { Icon } from "@mui/material";
import notification from "helpers/notification";

export const cols_Todo: Array<ColumnDefinitionType<DB_Todo>> = [
    // { key: 'id', header: 'ID' },
    { key: 'title', header: 'Title' },
    { key: 'content', header: 'Content' },
    { key: 'category', header: 'Category' },
    { key: 'image', header: 'Image' },
    {
        key: 'createdAt',
        header: 'Created At',
        cell: (item) => dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
        key: 'status',
        header: 'Status',
        cell: (item) => getStatusLabel(item.status),
    },
    {
        key: 'actions',
        header: 'Actions',
        cell: (item) => {
            return (
                <MyIconButton
                    onClick={() => {
                        Promise.resolve(
                            window.confirm('Are you sure you want to delete this row?')
                        )
                            .then(async (confirmed: boolean) => {
                                if (confirmed) {
                                    notification.success('success')
                                }
                            })
                            .catch(console.error);
                    }}
                // sx={{ pl: 0, pr: 0, background: 'transparent', color: 'inherit', ":hover": { background: 'transparent' } }}
                >
                    <Icon>delete</Icon>
                </MyIconButton>)
        },
    },
];

export const fields_Todo: Array<GenericField<DB_Todo>> = [
    {
        name: 'title',
        title: 'Title',
        type: 'text',
        search: true,
    },
    {
        name: 'content',
        title: 'Content',
        type: 'text',
        search: true,
    },
    {
        name: 'category',
        title: 'Category',
        type: 'text',
        search: true,
    },
    {
        name: 'status',
        title: 'Status',
        type: 'select',
        selectOptions: Object.keys(DB_STATUS).filter((v) => isNaN(Number(v)))
    },
    {
        name: 'createdAt',
        title: 'Date',
        type: 'date',
    },
];