import React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { getFilteredSweepers } from "models/api/Sweeper";
import { cols_Sweeper, fields_Sweeper } from "./definitions";
import { FilterGroup } from "components/DataTable/filter/FilterGroup";

import { DB_User } from "models/db/User";
import { SweeperFieldName, nameMapping_Sweeper } from "models/db/Sweeper";

export default function UserSweepersTab({ targetUser }: { targetUser: DB_User }): React.JSX.Element {

  if (!targetUser.id) {
    return <>Require UserId</>;
  }

  return (
    <>
      <Helmet><title>All Sweepers</title></Helmet>

      <DataTable
        title='Sweepers'
        dataName='sweeper'
        cols={cols_Sweeper}
        fields={fields_Sweeper}
        nameMapping={nameMapping_Sweeper}
        rowsPerPage={25}
        settings={{ toolbar: { export: 'excel' } }}
        getFilteredRecords={async (params: FilterGroup<SweeperFieldName>) => {
          const userParams: FilterGroup<SweeperFieldName> = {
            ...params,
            filters: [[['user', { Equal: String(targetUser.id) }]], ...params.filters],
          };
          return await getFilteredSweepers(userParams)
        }}
      />
    </>
  );
}