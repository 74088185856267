import { ColumnDefinitionType } from "components/DataTable/Table";
import { getTxStatusLabel } from "components/Label/TxStatusLabel";
import { getTxTypeLabel } from "components/Label/TxTypeLabel";
import { DB_Transaction, TX_STATUS, TX_TYPES } from "models/db/Transaction";
import dayjs from "dayjs";
import { GenericField } from "components/DataTable/filter/FilterType";
import AddressCell from "components/Table/AddressCell";

export const cols_Transaction: Array<ColumnDefinitionType<DB_Transaction>> = [
    // { key: 'id', header: 'ID' },
    {
        width: 250,
        key: 'fromAddress',
        header: 'From Address',
        hideFilter: true,
        cell: (item) => <AddressCell value={item.fromAddress.address} />,
    },
    {
        width: 250,
        key: 'toAddress',
        header: 'To Address',
        cell: (item) => <AddressCell value={item.toAddress} />,
    },
    {
        key: 'amount',
        header: 'Amount',
        cell: (item) => `${item.amount} ${item.currency.symbol}`,
    },
    {
        key: 'txStatus',
        header: 'Status',
        cell: (item) => getTxStatusLabel(item.txStatus),
    },
    {
        key: 'txType',
        header: 'Type',
        cell: (item) => getTxTypeLabel(item.txType),
    },
    {
        key: 'createdAt',
        header: 'Date',
        cell: (item) => dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },

];

export const fields_Transaction: Array<GenericField<DB_Transaction>> = [
    {
        name: 'toAddress',
        title: 'ToAddress',
        type: 'text',
        search: true,
    },
    {
        name: 'amount',
        title: 'Amount',
        type: 'text',
    },
    {
        name: 'txStatus',
        title: 'Status',
        type: 'select',
        selectOptions: Object.keys(TX_STATUS).filter((v) => isNaN(Number(v)))
    },
    {
        name: 'txType',
        title: 'Type',
        type: 'select',
        selectOptions: Object.keys(TX_TYPES).filter((v) => isNaN(Number(v)))
    },
    {
        name: 'createdAt',
        title: 'Date',
        type: 'date',
    },
];