import React from 'react';

import {
  styled,
  Box,
  Grid,
  Typography,
  Avatar,
  alpha,
  Icon,
  Card as _Card,
  CardContent as _CardContent,
  CardHeader as _CardHeader,
  IconButton as _IconButton,
} from '@mui/material';

import { useDataContext } from 'context/data.context';
import { CryptoAvatar as _CryptoAvatar } from 'components/Avatar';
import { DB_Currency } from 'models/db/Currency';
import config from 'config';
import { formatBalance } from 'helpers/utils';

const IconButton = styled(_IconButton)(({ theme }) => ({
  '&:hover': { background: 'transparent' },
}));

const CoinSymbolTop = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  color: 'rgba(34, 51, 84, 0.7)',
  fontWeight: '400',
  fontSize: 'inherit',
  marginLeft: theme.spacing(0.4)
}));

const CoinSymbolBottom = styled(Typography)(({ theme }) => ({

}));

const Card = styled(_Card)(({ theme }) => ({
  // color: theme.palette.primary.contrastText,
  // background: theme.palette.primary.main,
  // '&:hover': { background: theme.palette.primary.dark },
  borderRadius: 4,
  border: '1px solid #dbdfea',
  boxShadow: 'none',
}));

const CardHeader = styled(_CardHeader)(({ theme }) => ({
  paddingTop: 30
}));

const CardContent = styled(_CardContent)(({ theme }) => ({

}));

const CryptoAvatar = styled(_CryptoAvatar)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, -0.5),
  img: {
    padding: theme.spacing(0.1),
  }
}));

interface IWalletProps {
  walletItem: DB_Currency;
}

const Wallet: React.FC<IWalletProps> = ({ walletItem }) => {
  const { state } = useDataContext();

  return (
    <>
      <Grid xs={12} sm={6} md={4} item>
        <Card sx={{
          px: 1,
          display: 'flex',
          justifyContent: "space-between"
        }}>
          <CardContent>
            <Typography variant="h5" noWrap>
              <IconButton>
                <CryptoAvatar src={`/icons/${walletItem.symbol.toLowerCase()}.svg`} />
                {walletItem.name}
              </IconButton>
            </Typography>
            <Box
              sx={{
                pt: 3
              }}
            >
              <Typography variant="h3" gutterBottom noWrap>
                {state.showBalance ? formatBalance(walletItem.totalBalance) : '****'}
                <CoinSymbolTop variant="subtitle2">{walletItem.symbol.toUpperCase()}</CoinSymbolTop>
              </Typography>
              <CoinSymbolBottom variant="subtitle2" noWrap>
                {state.showBalance ? formatBalance(walletItem.totalUsdtBalance) : '****'} {walletItem.symbol}
              </CoinSymbolBottom>
            </Box>
          </CardContent>

          {/* <CardHeader
            action={
              <IconButton size="small" color="secondary">
                <Icon>more_horiz</Icon>
              </IconButton>
            }
          /> */}
        </Card>
      </Grid>
    </>
  );
};

export default Wallet;
