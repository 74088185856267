import { useState, createContext } from 'react';
import { checkItem, setItem } from 'helpers/utils';
type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
};

type SidebarProviderProps = { children: React.ReactNode };

export const SidebarContext = createContext<SidebarContext>({} as SidebarContext);

export const SidebarProvider = ({ children }: SidebarProviderProps) => {
  const [sidebarToggle, setSidebarToggle] = useState(checkItem('sideBarStatus', 'true'));
  const toggleSidebar = () => {
    setItem('sideBarStatus', !sidebarToggle)
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setItem('sideBarStatus', false)
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, closeSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
