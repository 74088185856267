import zionApi from 'api/zionApi';

import { FilterGroup, getSingleRecordOrThrow } from 'components/DataTable/filter/FilterGroup';
import { DTResponse, GenericResponse } from 'models/Generic';
import { DB_User, UserFieldName } from 'models/db/User';
import { DB_UserLog, UserLogFieldName } from 'models/db/UserLog';

export const getFilteredUsers = async (
  filterParams: FilterGroup<UserFieldName>
): Promise<DTResponse<DB_User>> => {
  const response = await zionApi.get<DTResponse<DB_User>>(`admin/users`, {
    params: { filterParams }
  });

  response.data.lastParams = filterParams;
  return response.data;
};

export const getUser = async (id: string): Promise<DB_User> => {
  const params: FilterGroup<UserFieldName> = {
    filters: [[['id', { Equal: id }]]],
    orderBy: [],
  };

  const res = await getFilteredUsers(params);
  return getSingleRecordOrThrow(res.data, `Unable to find any users with the id: ${id}`)
};

export const getFilteredUserLogs = async (
  userId: string,
  filterParams: FilterGroup<UserLogFieldName>,
): Promise<DTResponse<DB_UserLog>> => {
  const response = await zionApi.get<DTResponse<DB_UserLog>>(`admin/users/${userId}/logs`, {
    params: { filterParams }
  });

  response.data.lastParams = filterParams;
  return response.data;
};

export const getUserLogs = async (
  filterParams: FilterGroup<UserLogFieldName>
): Promise<DTResponse<DB_UserLog>> => {
  const response = await zionApi.get<DTResponse<DB_UserLog>>(`users/me/logs`, {
    params: { filterParams }
  });

  response.data.lastParams = filterParams;
  return response.data;
};

export const suspendUserFn = async (id: string) => {
  const response = await zionApi.delete<GenericResponse<DB_User>>(`users/${id}`);
  return response.data;
};