import React, { createContext, useState } from "react";

type CurrencyContext = {
  firstAmount: number;
  setFirstAmount: (firstAmount: number) => void;
  fromCurrency: string;
  setFromCurrency: (fromCurrency: string) => void;
  toCurrency: string;
  setToCurrency: (toCurrency: string) => void;
};

type CurrencyProviderProps = { children: React.ReactNode };

export const CurrencyContext = createContext<CurrencyContext>({} as CurrencyContext)

const CurrencyProvider = ({ children }: CurrencyProviderProps) => {
  const [fromCurrency, setFromCurrency] = useState("USDT");
  const [toCurrency, setToCurrency] = useState("TRY");
  const [firstAmount, setFirstAmount] = useState(1);

  const value = {
    fromCurrency,
    setFromCurrency,
    toCurrency,
    setToCurrency,
    firstAmount,
    setFirstAmount
  };

  return (
    <CurrencyContext.Provider value={value}>
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
