import zionApi from 'api/zionApi';

import { FilterGroup } from 'components/DataTable/filter/FilterGroup';
import { DTResponse, GenericResponse } from 'models/Generic';
import { ConfigFieldName, DB_Config } from 'models/db/Config';

export const getFilteredConfigs = async (
  filterParams: FilterGroup<ConfigFieldName>
): Promise<DTResponse<DB_Config>> => {
  const response = await zionApi.get<DTResponse<DB_Config>>(`admin/configs`, {
    params: { filterParams }
  });

  response.data.lastParams = filterParams;
  return response.data;
};

export const getConfig = async (configName: string): Promise<GenericResponse<DB_Config>> => {
  const response = await zionApi.get<GenericResponse<DB_Config>>(`admin/configs/${configName}`);
  return response.data;
};