import zionApi from 'api/zionApi';

import { FilterGroup } from 'components/DataTable/filter/FilterGroup';
import { DTResponse, GenericResponse } from 'models/Generic';
import { DB_BankAccount, BankAccountFieldName, DB_Bank } from 'models/db/BankAccount';
import { TCreateBankAccount } from 'pages/UserProfile/BankAccountsTab/forms/AddBankAccount';

export const getFilteredBankAccounts = async (
  filterParams: FilterGroup<BankAccountFieldName>
): Promise<DTResponse<DB_BankAccount>> => {
  const response = await zionApi.get<DTResponse<DB_BankAccount>>(`bankaccounts`, {
    params: { filterParams }
  });

  response.data.lastParams = filterParams;
  return response.data;
};

export const createBankAccountFn = async (record: TCreateBankAccount): Promise<GenericResponse<DB_BankAccount>> => {
  const response = await zionApi.post<GenericResponse<DB_BankAccount>>(`bankaccounts`, record);
  return response.data;
};

export const getBanks = async () => {
  const response = await zionApi.get<GenericResponse<DB_Bank[]>>(`bankaccounts/banks`)
  return response.data;
};