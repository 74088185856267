import {
    Stack,
    Icon,
    Typography,
    IconButton,
    Link,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { replaceKeysInString } from 'helpers/utils';
import { DB_Transaction } from 'models/db/Transaction';

const ExplorerTX = (item: DB_Transaction): JSX.Element => {
    // console.log({ item });

    if (!item.txHash) return <></>;
    const txHash = item.txHash;

    const targetUrl = replaceKeysInString(item.fromAddress.network.urlTxHash, { txHash })

    return (
        <Stack direction="row" alignItems="center" sx={{
            position: 'relative',
            padding: 1,
            ml: -1.5,
            ':hover': {
                zIndex: '1',
                overflow: 'visible',
                background: '#f5f6fa',
                borderRadius: 1,
                '.dots': {
                    display: 'none'
                },
                '.middle': {
                    display: 'inline'
                }
            }
        }}>
            <IconButton aria-label="copy" size="small" onClick={e => copy(String(txHash))} sx={{}}>
                <Icon fontSize='inherit'>content_copy</Icon>
            </IconButton>

            <Link href={targetUrl} target="_blank" sx={{ ":visited": { color: 'inherit' } }}>
                {String(txHash).slice(0, 5)}
                <Typography component='span' className='dots'>...</Typography>
                <Typography component='span' className='middle' sx={{ display: 'none' }}>{String(txHash).slice(5, String(txHash).length - 5)}</Typography>
                {String(txHash).slice(String(txHash).length - 5, String(txHash).length)}
            </Link>
        </Stack>
    );
}

export default ExplorerTX;