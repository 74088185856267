import React from 'react';
import { useNavigate } from 'react-router-dom';
import useUserContext from 'hooks/useUserContext';
import { Avatar, Button, Grid, Typography, useTheme } from '@mui/material';

import {
  KeyboardReturnTwoTone as ReturnIcon
} from '@mui/icons-material';

const PageHeader = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authState } = useUserContext();

  return (
    <>
      <Grid
        container
        display="flex"
        alignItems="center"
      >
        <Grid sx={{ flexGrow: 2 }}>
          <Grid container alignItems="center">
            <Grid item>
              <Avatar
                alt={authState.user?.name}
                variant="rounded"
                sx={{ mr: 2, width: theme.spacing(8), height: theme.spacing(8) }}
              // src={targetUser.image}
              />
            </Grid>
            <Grid item>
              <Typography variant="h3" component="h3" gutterBottom>
                {authState.user?.name}
              </Typography>
              <Typography variant="subtitle2">
                Your Profile & Settings
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={3}
            display="flex"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                onClick={() => navigate('/dashboard/profile?tab=users')}
                variant="contained"
                startIcon={<ReturnIcon fontSize="small" />}>
                return
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
}

export default PageHeader;
