import React, { Dispatch, SetStateAction } from "react";
import { useMutation } from "@tanstack/react-query";

import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Slide, TextField, Typography } from "@mui/material";

import notification from "helpers/notification";
import { LoadingButton } from "@mui/lab";
import { TransitionProps } from "@mui/material/transitions";
import { DB_Config } from "models/db/Config";
import zionApi from "api/zionApi";
import { GenericResponse } from "models/Generic";


import {
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { MyFormHelperText } from "components/Forms/MyFormHelper";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IUpdateItemProp {
  item: DB_Config;
  setRefetch: Dispatch<SetStateAction<number>>;
  setOpenCrudModal: (openCrudModal: boolean) => void;
}

const updateItemSchema = z.object({
  // name: z.string().min(1, 'Name is required'),
  value: z.object({
    address: z.object({
      btc: z.number().refine(val => val > 0, { message: 'The value must be greater than zero.' }),
      eth: z.number().refine(val => val > 0, { message: 'The value must be greater than zero.' }),
      erc20: z.number().refine(val => val > 0, { message: 'The value must be greater than zero.' }),
      trc20: z.number().refine(val => val > 0, { message: 'The value must be greater than zero.' }),
    })
  })
});

export type TypeOfUpdate = z.TypeOf<typeof updateItemSchema>;

export default function EditForm({ row: item, setRefetch }: { row: DB_Config; setRefetch: Dispatch<SetStateAction<number>>; }): React.JSX.Element {
  const [saveItemConfirm, setSaveItemConfirm] = React.useState(false);

  const saveItem = useMutation(
    async ({ id, inputData }: { id: string; inputData: TypeOfUpdate }) => {
      const response = await zionApi.patch<GenericResponse<DB_Config>>(`admin/configs/${id}`, inputData);
      return response.data;
    }, {
    onSuccess: () => {
      notification.success('Config updated successfully');
      setTimeout(() => setRefetch(c => ++c), 1000)
    }, onError: () => {
      setSaveItemConfirm(false)
    }
  });

  React.useEffect(() => {
    if ([saveItem.status].includes('success')) {
      setSaveItemConfirm(false)
    };
  }, [saveItem.status])

  const methods = useForm<TypeOfUpdate>({ resolver: zodResolver(updateItemSchema) });

  const { formState: { errors: formErrors, isSubmitted }, } = methods;

  if (!methods.formState.isValid && Object.keys(formErrors).length) {
    console.table(formErrors);
    // Object.values(formErrors).map((err) => notification.error(err?.message as string));
  }

  // const onSubmitHandler: SubmitHandler<TypeOfUpdate> = (formValues) => {
  //   saveItem.mutate({ id: item.id, inputData: formValues })
  // };

  React.useEffect(() => {
    if (item) {
      methods.reset({
        // name: item.name,
        value: item.value,
      });
    }
  }, [item]);

  return <>
    <Grid container maxWidth={'md'}>
      <Grid item xs={12}>

        {/* <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Edit Config Name : {item.name}
        </Typography>
      </Box> */}

        <FormProvider {...methods}>
          <Box
            component='form'
            noValidate
            autoComplete='off'
          // onSubmit={methods.handleSubmit(onSubmitHandler)}
          >
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField label='Config Name' value={item.name} disabled />
            </FormControl>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <TextField label='BTC Percent'
                    type="number"
                    inputProps={{ min: 0 }}
                    {...methods.register('value.address.btc', {
                      valueAsNumber: true,
                      setValueAs: v => parseFloat(v),
                    })} />
                  <MyFormHelperText error={!!formErrors?.value} message={formErrors?.value?.address?.btc?.message} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <TextField label='ETH Percent'
                    type="number"
                    inputProps={{ min: 0 }}
                    {...methods.register('value.address.eth', {
                      valueAsNumber: true,
                      setValueAs: v => parseFloat(v),
                    })} />
                  <MyFormHelperText error={!!formErrors?.value} message={formErrors?.value?.address?.eth?.message} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <TextField label='ERC20 Percent'
                    type="number"
                    inputProps={{ min: 0 }}
                    {...methods.register('value.address.erc20', {
                      valueAsNumber: true,
                      setValueAs: v => parseFloat(v),
                    })} />
                  <MyFormHelperText error={!!formErrors?.value} message={formErrors?.value?.address?.erc20?.message} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <TextField label='TRC20 Percent'
                    type="number"
                    inputProps={{ min: 0 }}
                    {...methods.register('value.address.trc20', {
                      valueAsNumber: true,
                      setValueAs: v => parseFloat(v),
                    })} />
                  <MyFormHelperText error={!!formErrors?.value} message={formErrors?.value?.address?.trc20?.message} />
                </FormControl>
              </Grid>
            </Grid>

            {/* 
            <Box display="flex" flexDirection="row" justifyContent={'space-between'}
              sx={{
                mb: 2,
                '& .MuiFormControl-root': { width: '15ch' },
              }}>
            </Box> 
            */}

            <Grid container justifyContent="center" >
              <LoadingButton
                variant='contained'
                type='button'
                loading={saveItem.isLoading}
                onClick={() => {
                  methods.trigger();
                  console.log({ error: methods.formState.errors });
                  if (methods.formState.isValid) {
                    setSaveItemConfirm(true)
                  }
                }}
              >
                Save
              </LoadingButton>
            </Grid>
          </Box>
        </FormProvider>
      </Grid>
    </Grid>

    <Dialog
      open={saveItemConfirm}
      onClose={e => setSaveItemConfirm(false)}
      TransitionComponent={Transition}
      PaperProps={{ sx: { px: 1, py: 1 } }}
    >
      <DialogTitle variant="h4">Are You Sure?</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Do you want to <strong>UPDATE</strong> this item?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', mx: 1 }}>
        <LoadingButton size="small" variant='outlined' onClick={e => setSaveItemConfirm(false)}>
          Close
        </LoadingButton>
        <LoadingButton
          size="small"
          variant='contained'
          loading={saveItem.isLoading}
          onClick={(e) => saveItem.mutate({ id: item.id, inputData: methods.getValues() })}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  </>
}