import React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { getFilteredUserLogs } from "models/api/User";
import { cols_UserLog, fields_UserLog } from "./definitions";

import { DB_User } from "models/db/User";
import { UserLogFieldName, nameMapping_UserLog } from "models/db/UserLog";
import { FilterGroup } from "components/DataTable/filter/FilterGroup";

export default function MemberLogsTab({ targetUser }: { targetUser: DB_User }): React.JSX.Element {

  if (!targetUser.id) {
    return <>Require User</>;
  }

  return (
    <>
      <Helmet><title>All User Logs</title></Helmet>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <MyCard>
            <Grid>
              <DataTable
                title='User Logs'
                dataName='log'
                cols={cols_UserLog}
                fields={fields_UserLog}
                nameMapping={nameMapping_UserLog}
                getFilteredRecords={async (params: FilterGroup<UserLogFieldName>) => await getFilteredUserLogs(targetUser.id, params)}
                rowsPerPage={25}
              />
            </Grid>
          </MyCard>
        </Grid>
      </Grid>
    </>
  );
}