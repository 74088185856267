import { ColumnDefinitionType } from "components/DataTable/Table";
import { DB_Sweeper, DB_FREQUENCY, SweeperFieldName } from "models/db/Sweeper";
import { getStatusLabel } from "components/Label/StatusLabel";
import { DB_STATUS } from "models/Generic";
import dayjs from "dayjs";
import { GenericField } from "components/DataTable/filter/FilterType";
import AddressCell from "components/Table/AddressCell";
import config from "config";
import { formatBalance } from "helpers/utils";
import { balanceFilterBySymbol } from "helpers/balance";
import { Typography } from "@mui/material";

export const cols_Sweeper: Array<ColumnDefinitionType<DB_Sweeper>> = [
    // { key: 'id', header: 'ID' },
    {
        key: 'userName',
        header: 'User',
        hideFilter: true,
        cell: (item) => item.user.name,
    },
    {
        width: 250,
        key: 'fromAddress',
        header: 'From Address',
        hideFilter: true,
        cell: (row) => {
            if (!row.fromAddress) return <Typography>Sweep all my <strong>{row.currency.symbol}</strong> balances</Typography>;
            return <AddressCell value={row.fromAddress.address} />
        },
    },
    {
        width: 250,
        key: 'toAddress',
        header: 'To Address',
        cell: (item) => <AddressCell value={item.toAddress} />,
    },
    {
        key: 'amount',
        header: 'Amount',
        cell: (item) => `${item.amount} ${item.currency.symbol}`,
    },
    {
        key: 'sweepBalance',
        header: 'Balance',
        hideFilter: true,
        cell: (row) => {
            if (!row.fromAddress) return `0 ${row.currency.symbol}`;
            return `${formatBalance(balanceFilterBySymbol(row.fromAddress.currencyBalances, row.currency.symbol).balance)} ${row.currency.symbol}`
        },
    },
    {
        key: 'status',
        header: 'Status',
        cell: (item) => getStatusLabel(item.status),
    },
    {
        key: 'frequency',
        header: 'Frequency',
        // cell: (item) => getStatusLabel(item.frequency),
    },
    {
        key: 'createdAt',
        header: 'Created At',
        cell: (item) => dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
        key: 'lastRun',
        header: 'Last Run',
        cell: (item) => dayjs(item.lastRun).format('YYYY-MM-DD HH:mm:ss'),
    },

];

export const fields_Sweeper: Array<GenericField<DB_Sweeper>> = [
    {
        name: 'toAddress',
        title: 'ToAddress',
        type: 'text',
        search: true,
    },
    {
        name: 'amount',
        title: 'Amount',
        type: 'text',
    },
    {
        name: 'status',
        title: 'Status',
        type: 'select',
        selectOptions: Object.keys(DB_STATUS).filter((v) => isNaN(Number(v)))
    },
    {
        name: 'frequency',
        title: 'Frequency',
        type: 'select',
        selectOptions: Object.keys(DB_FREQUENCY).filter((v) => isNaN(Number(v)))
    },
    {
        name: 'createdAt',
        title: 'Date',
        type: 'date',
    },
];