import zionApi from 'api/zionApi';

import { FilterGroup } from 'components/DataTable/filter/FilterGroup';
import { DTResponse } from 'models/Generic';
import { DB_Address, AddressFieldName } from 'models/db/Address';

export const getFilteredAddresses = async (
  filterParams: FilterGroup<AddressFieldName>
): Promise<DTResponse<DB_Address>> => {
  const response = await zionApi.get<DTResponse<DB_Address>>(`admin/addresses`, {
    params: { filterParams }
  });

  response.data.lastParams = filterParams;
  return response.data;
};

export const updateAddress = async (
  id: string,
  data: DB_Address
): Promise<DB_Address> => {
  console.log({ id, data });

  const response = await zionApi.patch<DB_Address>(`admin/addresses/${id}`, data);
  return response.data
};