import { Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';

export const PrivacyPolicyPage = () => {
  return (
    <Container disableGutters maxWidth={'md'}>
      <Grid container sx={{ display: 'flex' }}>
        <Grid item xs={12} my={3}>
          <Card
            sx={{
              boxShadow: 'none',
              backgroundColor:'transparent'
            }}
          >
            <CardContent sx={{ px: 0 }}>
              <Typography variant="h1" sx={{ pb: 1 }} align='center'>
                Privacy Policy
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                At Ziontrader, we take the privacy and security of our clients' personal and financial information very seriously. We are committed to protecting your privacy and to complying with all applicable privacy laws and regulations.
              </Typography>
              
              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Collection of Personal Information: We collect personal information from our clients in order to provide our services and to comply with legal and regulatory requirements. The personal information we collect may include your name, contact information, and financial information.
              </Typography>
                
              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Use of Personal Information: We use your personal information to provide our services, to comply with legal and regulatory requirements, and to improve our platform. We do not sell or rent your personal information to third parties.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Sharing of Personal Information: We may share your personal information with third parties in order to provide our services or to comply with legal and regulatory requirements. For example, we may share your information with banks and payment processors in order to process your transactions.
              </Typography>
              
              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Security of Personal Information: We take measures to protect your personal information from unauthorized access, use, disclosure, and alteration. We use industry-standard security protocols, such as encryption and secure socket layer (SSL) technology, to protect your personal information.
              </Typography>
              
              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Cookies: We use cookies to improve your experience on our platform and to collect information about how you use our site. You can control the use of cookies in your browser settings.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Access to Personal Information: You have the right to access and update your personal information at any time. If you would like to access or update your personal information, please contact us.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                Changes to Privacy Policy: We may update our Privacy Policy from time to time. We will notify you of any changes to our Privacy Policy by posting the updated policy on our website.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 4 }}>
                If you have any questions or concerns about our Privacy Policy, please do not hesitate to contact us. We are committed to protecting your privacy and to ensuring that your personal information is handled in a secure and responsible manner.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};