import React, { FC, useEffect } from 'react';
import notification from 'helpers/notification';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    Icon,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { CloseButton } from "components/Buttons/LoadingButton";

import {
    Controller,
    FormProvider,
    SubmitHandler,
    useForm,
} from 'react-hook-form';

import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { DB_ApiKey } from 'models/db/ApiKey';
import { DB_STATUS, GenericResponse } from 'models/Generic';
import { updateUserKeyFn } from 'api/userApi';
import { MyFormHelperText } from 'components/Forms/MyFormHelper';

interface TUpdateApiKey {
    item: DB_ApiKey;
    setOpenCrudModal: (openCrudModal: boolean) => void;
}

const updateItemSchema = z.object({
    clientIp: z.string(),
    status: z.nativeEnum(DB_STATUS),
    callbackUrl: z.string().optional(),
    mainWallet: z.string().optional(),
})

export type TUpdateItem = z.TypeOf<typeof updateItemSchema>;

const EditForm = ({ item, setOpenCrudModal }: TUpdateApiKey): React.JSX.Element => {
    // const [formData, setFormData] = React.useReducer((state: TUpdateItem, newState: Partial<TUpdateItem>) => ({ ...state, ...newState, }), {
    //     clientIp: item.clientIp,
    //     status: item.status,
    //     callbackUrl: item.callbackUrl,
    // });

    const queryClient = useQueryClient();
    const { isLoading, mutate: updateItem } = useMutation(
        ({ id, inputData }: { id: number; inputData: TUpdateItem }) =>
            updateUserKeyFn({ id, inputData }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user_api_keys']);
                notification.success('Api settings updated successfully');
                setOpenCrudModal(false);
            }
        }
    );

    const methods = useForm<TUpdateItem>({ resolver: zodResolver(updateItemSchema) });

    const { formState: { errors: formErrors, isSubmitted, isSubmitSuccessful } } = methods;

    if (isSubmitted && Object.keys(formErrors).length) {
        console.log({ formErrors, values: methods.getValues() });
        // Object.values(formErrors).map((err) => notification.error(err?.message as string));
    }

    useEffect(() => {
        if (item) {
            methods.reset({
                status: item.status,
                clientIp: item.clientIp,
                callbackUrl: item.callbackUrl,
                mainWallet: item.mainWallet,
            });
        }
    }, [item]);

    const onSubmitHandler: SubmitHandler<TUpdateItem> = (formValues) => {
        console.log('formValues', formValues);
        updateItem({ id: item.id, inputData: formValues })
    };

    return (
        <Box>
            <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
                <Typography variant='h4' component='h1'>
                    Edit Item
                </Typography>

                <CloseButton loading={isLoading}
                    onClick={() => setOpenCrudModal(false)}>
                    <Icon>close</Icon>
                </CloseButton>
            </Box>

            <FormProvider {...methods}>
                <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    onSubmit={methods.handleSubmit(onSubmitHandler)}>

                    <FormControl fullWidth sx={{ mb: 4 }}>
                        <Grid container>
                            <Grid item xs={1}>
                                ApiKey:
                            </Grid>
                            <Grid item xs={11}>
                                <Typography sx={{ pl: 1 }}>
                                    {item.apiKey}
                                </Typography>
                            </Grid>
                        </Grid>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="status-label">Select Status</InputLabel>
                        <Controller
                            name="status"
                            control={methods.control}
                            defaultValue={item.status}
                            render={({ field: { onChange: fieldOnChange, ...field } }) => (
                                <Select {...field}
                                    label="Select Status"
                                    labelId="status-label"
                                    onChange={event => fieldOnChange(event)}
                                >
                                    <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                                    <MenuItem value="SUSPEND">SUSPEND</MenuItem>
                                </Select>
                            )}
                        />
                        <MyFormHelperText error={!!formErrors?.status} message={formErrors?.status?.message} />
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <TextField {...methods.register('clientIp')} label='ClientIp' fullWidth />
                        <MyFormHelperText error={!!formErrors?.clientIp} message={formErrors?.clientIp?.message} />
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <TextField {...methods.register('callbackUrl')} label="Callback URL" fullWidth />
                        <MyFormHelperText error={!!formErrors?.callbackUrl} message={formErrors?.callbackUrl?.message} />
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <TextField {...methods.register('mainWallet')} label="Main Wallet" fullWidth />
                        <MyFormHelperText error={!!formErrors?.mainWallet} message={formErrors?.mainWallet?.message} />
                    </FormControl>

                    <Grid container justifyContent="center" >
                        <LoadingButton
                            type='submit'
                            variant='contained'
                            loading={isLoading}
                        // disabled={isLoading}
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                </Box>
            </FormProvider>
        </Box>
    );
};

export default EditForm;