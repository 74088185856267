import { FC, ChangeEvent, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import copy from "copy-to-clipboard";
import {
    Tooltip,
    Checkbox,
    TableCell,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';

import { getStatusLabel } from 'components/Label/StatusLabel';
import { LoadingIconButton } from 'components/Buttons/LoadingButton';
import { EditTwoTone, DeleteTwoTone } from '@mui/icons-material';
import CrudModal from 'components/Modals/crud.modal';
import EditForm from '../forms/EditForm';
import { DB_ApiKey } from 'models/db/ApiKey';
import ShortText from 'components/Table/ShortText';

interface IDataTableRowProps {
    item: DB_ApiKey;
    isItemSelected: boolean;
    handleSelectOneItem: (
        event: ChangeEvent<HTMLInputElement>,
        itemId: number
    ) => void;
}

const DataTableRow: FC<IDataTableRowProps> = ({ item, isItemSelected, handleSelectOneItem }) => {
    const theme = useTheme();
    const queryClient = useQueryClient();
    const [openCrudModal, setOpenCrudModal] = useState(false);

    // const { mutate: deleteItem } = useMutation((id: number) => deleteItemFn(id), {
    //     onSuccess(data) {
    //         queryClient.invalidateQueries(['todos']);
    //         notification.success('Todo deleted successfully');
    //     }
    // });

    // const onDeleteHandler = (id: number) => {
    //     if (window.confirm('Are you sure')) {
    //         deleteItem(id);
    //     }
    // };

    const [tooltipText, setToolTipText] = useState("copy me");
    const copyToClipboard = (text: string) => {
        copy(text);
        setToolTipText("👍 Copied!");
    };

    return (
        <>
            <TableRow
                hover
                key={item.id}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            handleSelectOneItem(event, item.id)
                        }
                        value={isItemSelected}
                    />
                </TableCell>

                <TableCell>
                    <Typography>
                        {item.id}
                    </Typography>
                </TableCell>

                <TableCell>
                    <Typography>
                        <Tooltip arrow title={tooltipText}
                            TransitionProps={{ onExited: () => setToolTipText("copy me") }}>
                            <span onClick={() => copyToClipboard(item.apiKey)}>{item.apiKey}</span>
                        </Tooltip>
                    </Typography>
                </TableCell>

                <TableCell>
                    {item.clientIp}
                </TableCell>

                {/* <TableCell><Typography>{format(parseISO(item.expDate), 'PPP')}</Typography></TableCell> */}

                <TableCell>
                    {item.callbackUrl && <Typography><ShortText value={item.callbackUrl} tooltip={item.callbackUrl}></ShortText></Typography>}
                </TableCell>

                <TableCell>
                    {getStatusLabel(item.status)}
                </TableCell>

                <TableCell align="center">
                    <Tooltip title="Edit Item" arrow onClick={() => setOpenCrudModal(true)}>
                        <LoadingIconButton loading={false}>
                            <EditTwoTone fontSize="small" />
                        </LoadingIconButton>
                    </Tooltip>

                    {/* <Tooltip title="Delete Item" arrow onClick={() => onDeleteHandler(item.id)}>
                        <LoadingIconButton loading={false}>
                            <DeleteTwoTone fontSize="small" />
                        </LoadingIconButton>
                    </Tooltip> */}

                    <CrudModal openCrudModal={openCrudModal} setOpenCrudModal={setOpenCrudModal}>
                        <EditForm setOpenCrudModal={setOpenCrudModal} item={item} />
                    </CrudModal>
                </TableCell>
            </TableRow>
        </>
    )
}

export default DataTableRow