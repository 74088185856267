import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';
import { Tab, Grid, Divider, Container } from '@mui/material';
import { TabContext, TabList, TabPanel as TabPanel_ } from '@mui/lab';

import AddressesTab from './addresses/Index';
import TransactionsTab from './transactions/Index';
import SweepersTab from './sweepers/Index';
import TodosTab from './todos/Index';
import UsersTab from './users/Index';
import config from 'config';

const TabPanel = styled(TabPanel_)(({ theme }) => ({
  padding: 0,
  scrollableX: {
    overflowX: "auto !important"
  }
}));

const Admin = () => {
  const [tabs, setTabs] = useState<{ value: string; label: string; }[]>([
    { value: 'users', label: 'Users' },
    { value: 'transactions', label: 'Transactions' },
    { value: 'sweepers', label: 'Sweepers' },
    { value: 'addresses', label: 'Addresses' },
    ...[config.MODE !== 'prod' ? { value: 'todos', label: 'Todos' } : {} as any]
  ])

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<string>(searchParams.get('tab') || tabs[0].value);

  useEffect(() => {
    if (currentTab !== searchParams.get('tab')) {
      setCurrentTab(searchParams.get('tab') || tabs[0].value)
    }
  }, [searchParams.get('tab')])

  return (
    <>
      <Helmet><title>Admin</title></Helmet>

      <TabContext value={currentTab}>
        <Container maxWidth={false} disableGutters>

          <Container maxWidth={false}>
            <Grid sx={{ mb: 3 }}>
              <TabList onChange={(event: React.SyntheticEvent, value: string): void => setCurrentTab(value)}>
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} onClick={e => setSearchParams({ 'tab': tab.value })} />
                ))}
              </TabList>
              <Divider />
            </Grid>
          </Container>

          <Container maxWidth={false}>
            <Grid>
              <TabPanel value="users"><UsersTab /></TabPanel>
              <TabPanel value="transactions"><TransactionsTab /></TabPanel>
              <TabPanel value="sweepers"><SweepersTab /></TabPanel>
              <TabPanel value="addresses"><AddressesTab /></TabPanel>
              <TabPanel value="todos"><TodosTab /></TabPanel>
            </Grid>
          </Container>

        </Container>
      </TabContext>
    </>
  );
}

export default Admin;
