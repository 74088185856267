import {
    Stack,
    Icon,
    Typography,
    IconButton,
} from '@mui/material';
import copy from 'copy-to-clipboard';

const AddressCell = ({ value }: { value: string | number }): JSX.Element => {
    return (
        <Stack direction="row" alignItems="center" sx={{
            position: 'relative',
            padding: 1,
            ml: -1.5,
            ':hover': {
                zIndex: '1',
                overflow: 'visible',
                background: '#f5f6fa',
                borderRadius: 1,
                '>button': {
                    color: 'inherit'
                },
                '>.dots': {
                    display: 'none'
                },
                '>.middle': {
                    display: 'inline'
                }
            }
        }}>
            <IconButton aria-label="copy" size="small" onClick={e => copy(String(value))}>
                <Icon fontSize='inherit'>content_copy</Icon>
            </IconButton>
            {String(value).length < 10 ? (
                <Typography component='span'>{String(value)}</Typography>
            ) : (
                <>
                    <Typography component='span'>{String(value).slice(0, 5)}</Typography>
                    <Typography component='span' className='dots'>...</Typography>
                    <Typography component='span' className='middle' sx={{ display: 'none' }}>{String(value).slice(5, String(value).length - 5)}</Typography>
                    <Typography component='span'>{String(value).slice(String(value).length - 5, String(value).length)}</Typography>
                </>
            )}
        </Stack>
    );
}

export default AddressCell;