import React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { getFilteredTodos } from "models/api/Todo";
import { cols_Todo, fields_Todo } from "./definitions";
import { nameMapping_Todo } from "models/db/Todo";

export default function TodosTab(): React.JSX.Element {
  return (
    <>
      <Helmet><title>All Todos</title></Helmet>

      <DataTable
        title='Todos'
        dataName='todo'
        rowsPerPage={25}
        cols={cols_Todo}
        fields={fields_Todo}
        settings={{ toolbar: { export: 'excel' } }}
        nameMapping={nameMapping_Todo}
        getFilteredRecords={getFilteredTodos}
      />
    </>
  );
}