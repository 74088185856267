import { Container, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import SuspenseLoader from 'components/SuspenseLoader';

import PageTitleWrapper from 'components/PageTitleWrapper';
import PageHeader from './PageHeader';

import DataTable from './datatable/DataTable';
import { getUsersKeysFn } from 'api/userApi';
import Message from 'components/Message';
import { MyCard } from 'components/Card/Index';

const ApiKeysTab = () => {
  const { isLoading, data: items } = useQuery(
    ['user_api_keys'],
    () => getUsersKeysFn(),
    {
      select: (data) => data.data,
      onSuccess(data) { }
    }
  );

  if (isLoading) {
    return <SuspenseLoader />;
  }

  return (
    <>
      <Grid sx={{ py:3 }}>
        <PageHeader />
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <MyCard>
            <Grid>
              {items?.length === 0 ? (
                <Message type='info' title='Info'>
                  No data at the moment
                </Message>
              ) : (<DataTable items={items || []} />)}
            </Grid>
          </MyCard>
        </Grid>
      </Grid>
    </>
  );
};

export default ApiKeysTab;
