import { DB_STATUS } from "models/Generic";

export enum CRYPTO_NETWORK {
    TRC20 = 'TRC20',
    ERC20 = 'ERC20',
    SOLANA = 'SOLANA',
}

export interface DB_Network {
    id: string;
    name: string;
    symbol: CRYPTO_NETWORK;
    status: DB_STATUS;
    urlTxHash: string;
    urlAddress: string;
    createdAt: string;
    updatedAt: string;
}

export interface DB_Networks {
    status: string;
    data: DB_Network[];
    totalRows: number;
}