import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useReducer } from 'react';
import { pickBy } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import Text from 'components/Text';
import Label from 'components/Label';
import { LoadingButton } from "components/Buttons/LoadingButton";

import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { updateUserFn } from 'api/userApi';

import { DB_User, DB_User_Commission, DB_USER_STATUS, DB_UserPermission, EMemberRoleEnumType } from 'models/db/User';
import { Helmet } from 'react-helmet-async';
import { useFetch } from 'hooks/useFetch';
import zionApi from 'api/zionApi';
import { GenericResponse } from 'models/Generic';

interface IUpdateUserProp {
  targetUser: DB_User;
  setRefetch?: () => void;
}

const updateUserSchema = z.object({
  status: z.nativeEnum(DB_USER_STATUS),
  name: z.string().min(1, 'Full name is required').max(50),
  email: z.string()
    .min(1, 'Email address is required')
    .email('Email address is invalid'),
  customerGroup: z.string().min(1, 'CustomerGroup is required')
}).partial();

type TUpdateUser = z.TypeOf<typeof updateUserSchema>;

const UserSettingsTab: FC<IUpdateUserProp> = ({ targetUser, setRefetch }) => {
  const { isLoading, mutate: updateItem } = useMutation(
    ({ id, formData }: { id: string; formData: FormData }) =>
      updateUserFn({ id, formData }),
    {
      onSuccess: () => {
        setRefetch?.()
        notification.success('User updated successfully');
      }
    }
  );

  const methods = useForm<TUpdateUser>({
    resolver: zodResolver(updateUserSchema),
  });

  const { formState: { errors: formErrors, isSubmitting, isSubmitSuccessful }, } = methods;

  // useEffect(() => {
  //   if (isSubmitSuccessful) {
  //     methods.reset();
  //   }
  // }, [isSubmitSuccessful]);

  useEffect(() => {
    if (targetUser) {
      methods.reset({
        name: targetUser.name,
        email: targetUser.email,
        status: targetUser.status,
        customerGroup: String(targetUser.commission.id),
      });
    }
  }, [targetUser]);

  const [userPermission, setUserPermission] = useReducer((state: DB_UserPermission, newState: Partial<DB_UserPermission>) => {
    return { ...state, ...newState, };
  }, { ...(targetUser?.permission ?? {}) });

  const onSubmitHandler: SubmitHandler<TUpdateUser> = (values) => {
    const formData = new FormData();
    const filteredFormData = pickBy(values, (value) => value !== '' && value !== undefined);

    if (userPermission && targetUser.role === 'member') {
      Object.assign(filteredFormData, {
        permissions: {
          role: userPermission.role ?? EMemberRoleEnumType.VIEWER,
          read: userPermission.read ?? false,
          write: userPermission.write ?? false,
          update: userPermission.update ?? false,
          delete: userPermission.delete ?? false,
          readAll: userPermission.readAll ?? false,
        }
      })
    }

    formData.append('data', JSON.stringify(filteredFormData));
    updateItem({ id: targetUser?.id!, formData });
  };


  const { data: commissions, error, loading, refetch } = useFetch<DB_User_Commission[]>({
    qkey: `users/${targetUser.id}`,
    callback: async () => {
      const response = await zionApi.get<GenericResponse<DB_User_Commission[]>>(`users/commissions`);
      return response.data.data ?? [];
    }
  })

  useEffect(() => {
    if (targetUser.role !== 'member') refetch()
  }, [])

  return (
    <>
      <Helmet><title>User Profile - {targetUser.name}</title></Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <FormProvider {...methods}>
            <Box
              noValidate
              component='form'
              autoComplete='on'
              onSubmit={methods.handleSubmit(onSubmitHandler)}
            >
              <Card>
                <Box
                  p={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="h4">Settings</Typography>
                  </Box>
                </Box>
                <Divider />

                <CardContent sx={{ p: 3 }}>
                  <Grid container spacing={0}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                      <TextField label='Name' {...methods.register('name')} />
                      <FormHelperText error={!!formErrors?.name}>{formErrors?.name?.message}</FormHelperText>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 3 }}>
                      <TextField label='Email' {...methods.register('email')} />
                      <FormHelperText error={!!formErrors?.email}>{formErrors?.email?.message}</FormHelperText>
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 3 }}>
                      <InputLabel id="status-label">Select Status</InputLabel>
                      <Controller
                        name="status"
                        control={methods.control}
                        defaultValue={targetUser?.status || DB_USER_STATUS['ACTIVE']}
                        render={({ field: { onChange: fieldOnChange, ...field } }) => (
                          <Select {...field}
                            label="Select Status"
                            labelId="status-label"
                            onChange={e => fieldOnChange(e)}
                          >
                            <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                            <MenuItem value="SUSPEND">SUSPEND</MenuItem>
                          </Select>
                        )}
                      />

                      <FormHelperText error={!!formErrors?.status}>{formErrors?.status?.message}</FormHelperText>
                    </FormControl>

                    {targetUser.role === 'member' && (
                      <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel id="role-label">Select Role</InputLabel>
                        <Select
                          label='Select Role'
                          labelId='role-label'
                          name='role'
                          value={userPermission.role}
                          onChange={e => setUserPermission({ [e.target.name]: e.target.value })}
                        >
                          <MenuItem value="viewer">viewer</MenuItem>
                          <MenuItem value="modarator">modarator</MenuItem>
                        </Select>
                      </FormControl>
                    )}

                    {targetUser.role !== 'member' && commissions?.length && (
                      <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel id="customerGroup-label">Customer Group</InputLabel>
                        <Controller
                          name="customerGroup"
                          control={methods.control}
                          defaultValue={String(targetUser.commission.id)}
                          render={({ field: { onChange: fieldOnChange, ...field } }) => (
                            <Select {...field}
                              label="Customer Group"
                              labelId="customerGroup-label"
                              onChange={fieldOnChange}
                            >
                              {commissions.map(item => <MenuItem key={item.id} value={String(item.id)}>{item.name} ({item.value})</MenuItem>)}
                            </Select>
                          )}
                        />

                        <FormHelperText error={!!formErrors?.customerGroup}>{formErrors?.customerGroup?.message}</FormHelperText>
                      </FormControl>
                    )}

                  </Grid>

                  <LoadingButton
                    fullWidth
                    type='submit'
                    variant='contained'
                    loading={isLoading}
                  >
                    Save
                  </LoadingButton>
                </CardContent>
              </Card>
            </Box>
          </FormProvider>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Card>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h4">Account Details</Typography>
              </Box>
            </Box>
            <Divider />

            <CardContent sx={{ p: 3 }}>
              <Grid container spacing={0} sx={{ mb: 2, xs: { mb: 1 } }}>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box sx={{ pr: 3 }}>
                    <Typography variant="subtitle2">
                      Status:
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Label color={targetUser?.status === DB_USER_STATUS.ACTIVE ? 'success' : 'warning'}>
                    <b>{targetUser?.status}</b>
                  </Label>
                </Grid>
              </Grid>

              {targetUser.role !== 'member' && (
                <>
                  <Grid container spacing={0} sx={{ mb: 2, xs: { mb: 1 } }} alignItems={'center'}>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box sx={{ pr: 3 }}>
                        <Typography variant="subtitle2">
                          Customer Group:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Text color="black">
                        <b>{targetUser?.commission.id}</b>
                      </Text>
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} sx={{ mb: 2, xs: { mb: 1 } }} alignItems={'center'}>
                    <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                      <Box sx={{ pr: 3 }}>
                        <Typography variant="subtitle2">
                          Commission:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                      <Text color="black">
                        {/* <b>{Number(targetUser?.commission.value) * 100}</b> */}
                        <b>{targetUser?.commission.name}</b>
                      </Text>
                    </Grid>
                  </Grid>
                </>
              )}

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default UserSettingsTab;