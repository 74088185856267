import { Button, Grid } from "@mui/material"
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useContext } from "react";
import { CurrencyContext } from "context/CurrencyContext";

const SwitchCurrency = (props: { quantity: number }): React.JSX.Element => {
  const { quantity = 0 } = props;

  const {
    firstAmount,
    setFirstAmount,
    fromCurrency,
    setFromCurrency,
    toCurrency,
    setToCurrency,
  } = useContext(CurrencyContext)

  const handleSwitch = () => {
    setFirstAmount(quantity)
    setFromCurrency(toCurrency);
    setToCurrency(fromCurrency);
  }
  return (
    <Grid item xs={12} md="auto">
      <Button onClick={handleSwitch} sx={{
        borderRadius: 1,
        height: "100%"
      }}>
        <CompareArrowsIcon sx={{ fontSize: 30 }} />
      </Button>
    </Grid>
  )
}

export default SwitchCurrency