import { Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';

export const TermOfUsePage = () => {
  return (
    <Container disableGutters maxWidth={'md'}>
      <Grid container sx={{ display: 'flex' }}>
        <Grid item xs={12} my={3}>
          <Card
            sx={{
              boxShadow: 'none',
              backgroundColor: 'transparent'
            }}
          >
            <CardContent sx={{ px: 0 }}>
              <Typography variant="h1" sx={{ pb: 1 }} align='center'>
                Terms of Use
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 5 }}>
                Please read these Terms of Use carefully. By using the Ziontrader platform, you agree to the following terms and conditions. This agreement governs your use of the Ziontrader website and all related services.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 5 }}>
                <strong>Acceptance of Terms</strong><br />
                By accessing and using the Ziontrader platform, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use. If you do not agree with any part of these terms, you may not use our services.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 5 }}>
                <strong>Account Registration</strong><br />
                In order to use the Ziontrader platform, you must register and create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 5 }}>
                <strong>Eligibility</strong><br />
                By using the Ziontrader platform, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into a binding agreement. You also agree to comply with all applicable laws and regulations.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 5 }}>
                <strong>User Conduct</strong><br />
                You agree to use the Ziontrader platform for lawful purposes only. You must not engage in any activity that violates any laws, infringes upon the rights of others, or is harmful, threatening, abusive, harassing, defamatory, or otherwise objectionable.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 5 }}>
                <strong>Intellectual Property</strong><br />
                The Ziontrader platform and all its contents, including but not limited to logos, trademarks, texts, images, graphics, and software, are the property of Ziontrader or its licensors and are protected by intellectual property laws. You may not copy, modify, distribute, or reproduce any part of the platform without prior written consent.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 5 }}>
                <strong>Disclaimer of Warranty</strong><br />
                The Ziontrader platform is provided on an "as is" and "as available" basis without any warranties of any kind, whether express or implied. Ziontrader does not guarantee the accuracy, reliability, or completeness of any information or content on the platform.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 5 }}>
                <strong>Limitation of Liability</strong><br />
                To the maximum extent permitted by law, Ziontrader and its affiliates, officers, directors, employees, and agents shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the Ziontrader platform.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 5 }}>
                <strong>Modification of Terms</strong><br />
                Ziontrader reserves the right to modify these Terms of Use at any time without prior notice. It is your responsibility to review these terms periodically. Your continued use of the Ziontrader platform after any modifications constitutes your acceptance of the updated terms.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 5 }}>
                <strong>Governing Law and Jurisdiction</strong><br />
                These Terms of Use shall be governed by and construed in accordance with the laws of the jurisdiction where Ziontrader operates. Any disputes arising from or related to these terms shall be subject to the exclusive jurisdiction of the courts in that jurisdiction.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};