import { DB_STATUS } from "models/Generic";

export interface DB_Commission {
    id: string;
    status: DB_STATUS;
    createdAt: string;
    updatedAt: string;
    name: string;
    value: number;

    // fake fields
    actions: string | null;
}

export interface DB_Commissions {
    status: string;
    data: DB_Commission[];
    totalRows: number;
}

export type CommissionFieldName = keyof DB_Commission;

export const nameMapping_Commission: { [key in keyof DB_Commission]: CommissionFieldName } = {
    id: "id",
    createdAt: "createdAt",
    updatedAt: "updatedAt",
    status: "status",
    name: "name",
    value: "value",
    actions: "actions",
};