import { Card, CardContent, CardMedia, Typography } from "@mui/material"

export const AboutCard = () => {
    return (
        <Card
            sx={{
                // maxWidth: 460,
                boxShadow: 'none',
                backgroundColor: 'transparent'
            }}
        >
            <CardMedia
                // height="140"
                alt="about"
                component="img"
                image="/images/homepage.png"
            />
            <CardContent sx={{ px: 0 }}>
                {/* <Typography variant="h5" sx={{ pb: 1 }}>
                    Zion Trader Wallet is your gateway to 'One Wallet, All Blockchains.'
                </Typography> */}
                <Typography variant="body1" color="text.secondary" sx={{ pb: 1 }}>
                    Our groundbreaking solution enables you to effortlessly access and manage your digital assets across multiple blockchain networks through our secure and user-friendly platform. Say goodbye to the complexity of multiple integrations and experience the convenience of managing your entire cryptocurrency portfolio without hassle.
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    Join us today to explore the limitless possibilities of cryptocurrency management. Be part of the revolution we've initiated to reshape the financial future in the digital world.
                </Typography>
            </CardContent>
        </Card>
    )
}