import zionApi from './zionApi';

import { TCreateItem } from 'pages/Sweeper/forms/AddForm';
import { TUpdateItem } from 'pages/Sweeper/forms/EditForm';
import { DB_Sweeper, DB_Sweepers } from 'models/db/Sweeper';
import { GenericResponse } from 'models/Generic';
import { DB_Transaction } from 'models/db/Transaction';

export const getAllItemsFn = async (params: object = {}) => {
  const response = await zionApi.get<DB_Sweepers>(`sweepers` + (Object.keys(params).length ? `?${new URLSearchParams(Object.entries(params)).toString()}` : ''));
  return response.data;
};

export const getItemFn = async (id: string) => {
  const response = await zionApi.get<GenericResponse<DB_Sweeper>>(`sweepers/${id}`);
  return response.data;
};

export const createItemFn = async (inputData: TCreateItem) => {
  const response = await zionApi.post<GenericResponse<DB_Sweeper>>(`sweepers`, inputData);
  return response.data;
};

export const updateItemFn = async ({ id, inputData }: { id: string; inputData: TUpdateItem; }) => {
  const response = await zionApi.patch<GenericResponse<DB_Sweeper>>(`sweepers/${id}`, inputData);
  return response.data;
};

export const deleteItemFn = async (id: string) => {
  const response = await zionApi.delete<GenericResponse<DB_Sweeper>>(`sweepers/${id}`);
  return response.data;
};

export const sweepRunNowFn = async (id: string) => {
  const response = await zionApi.post<{ status: string, transaction: DB_Transaction }>(`sweepers/run/${id}`);
  return response.data;
};
