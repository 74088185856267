import React from "react";
import { Helmet } from "react-helmet-async";
import { Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";

import DataTable from 'components/DataTable/DataTable';
import { cols_Address, fields_Address } from "./definitions";
import { AddressFieldName, DB_Address, DB_Addresses, nameMapping_Address } from "models/db/Address";
import { FilterGroup } from "components/DataTable/filter/FilterGroup";
import zionApi from "api/zionApi";
import CrudModal from "components/Modals/crud.modal";
import AddForm from "./forms/AddForm";
import useUserContext from "hooks/useUserContext";
import { TransitionProps } from "@mui/material/transitions";
import { LoadingButton } from "@mui/lab";
import PurchaseForm from "./forms/PurchaseForm";
import { DTResponse } from "models/Generic";

const Transition = React.forwardRef(
  (props: TransitionProps & { children: React.ReactElement<any, any>; }, ref: React.Ref<unknown>,) => <Slide direction="down" ref={ref} {...props} />
);


export default function Addresses(): React.JSX.Element {
  // const { authState } = useUserContext()
  const [openCrudModal, setOpenCrudModal] = React.useState(false);
  const [refetchTable, setRefetchTable] = React.useState<number>(0);
  const [purchaseConfirm, setPurchaseConfirm] = React.useState(false);

  return (
    <>
      <Helmet><title>Addresses</title></Helmet>

      {/* <PageTitle
        heading="Addresses"
        // crud={{ add: authState.user?.permission?.role !== EMemberRoleEnumType.VIEWER }}
        // setOpenCrudModal={setOpenCrudModal}
        sx={{ my: 3 }}
      /> */}

      <CrudModal openCrudModal={openCrudModal} setOpenCrudModal={setOpenCrudModal} closeable>
        <AddForm setRefetch={setRefetchTable} setOpenCrudModal={setOpenCrudModal} setPurchaseConfirm={setPurchaseConfirm} />
      </CrudModal>

      <Container maxWidth={false}>
        <DataTable
          title='Addresses'
          dataName='address'
          rowsPerPage={25}
          cols={cols_Address}
          fields={fields_Address}
          nameMapping={nameMapping_Address}
          actions={[{
            icon: 'add_circle_outline',
            title: 'Add Address',
            event: setOpenCrudModal,
          }]}
          fetchCounter={[refetchTable, setRefetchTable]}
          updateRecord={async (row, column, value) => {
            const newRow = structuredClone(row);
            newRow[column.key] = value as never;
            const response = await zionApi.patch<DB_Address>(`addresses/${row.id}`, newRow);
            return response.data
          }}
          getFilteredRecords={async (filterParams: FilterGroup<AddressFieldName>) => {
            const response = await zionApi.get<DTResponse<DB_Address>>(`addresses`, {
              params: {
                filterParams
              }
            });
            return response.data;
          }}
        />
      </Container>

      <Dialog
        open={purchaseConfirm}
        onClose={e => setPurchaseConfirm(false)}
        TransitionComponent={Transition}
        PaperProps={{ sx: { px: 1, py: 1 } }}
      >
        <PurchaseForm setRefetch={setRefetchTable} setPurchaseConfirm={setPurchaseConfirm} />
      </Dialog>
    </>
  );
}