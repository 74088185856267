import { Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';

export const FeesPage = () => {
  return (
    <Container disableGutters maxWidth={'md'}>
      <Grid container sx={{ display: 'flex' }}>
        <Grid item xs={12} my={3}>
          <Card
            sx={{
              boxShadow: 'none',
              backgroundColor: 'transparent'
            }}
          >
            <CardMedia
              alt="about"
              component="img"
              image="/images/fees.png"
            />
            <CardContent sx={{ px: 0 }}>
              <Typography variant="h1" sx={{ pb: 1 }} align='center'>
                Fees
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 1 }}>
                Deposit transactions require a commission fee, which is automatically deducted upon completion of the transaction. Your account type plays a significant role in determining commission fees, and the transfer amount also affects these fees. Various factors can impact commission fees.
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ pb: 1 }}>
                Feel free to reach out to us for more information about commission fees or any questions related to this matter.
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ pb: 1 }}>
                <strong>You can contact us using the following contact information:</strong>
                <br />
                <strong>Email: info@ziontrader.com</strong>
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ pb: 1 }}>
                Rest assured that we will be delighted to assist you as soon as possible.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};