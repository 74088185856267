import React from 'react';
import { useMatch } from 'react-router-dom';

import {
    Box,
    Card,
    CardMedia,
    Grid,
    Typography,
    useTheme,
} from '@mui/material';
import { LinkItem } from 'components/Buttons/LinkItem';

type IFooter = {
    props?: object;
};

const Footer: React.FC<IFooter> = ({
    props
}) => {
    const theme = useTheme();
    const isPrivate = useMatch("dashboard/*");

    return (
        <Box component="footer" {...props}>
            {!isPrivate && (
                <Grid container>
                    <Grid item xs={12} md={8} sx={{
                        mb: 2,
                        display: 'flex',
                        columnGap: 3,
                        alignItems: 'center',
                        justifyContent: { xs: 'center', md: 'start' },
                    }}>
                        <Grid item>
                            <Typography variant='h4'>
                                <LinkItem to='/term-of-use'>Term of Use</LinkItem>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='h4'>
                                <LinkItem to='/privacy-policy'>Privacy Policy</LinkItem>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='h4'>
                                <LinkItem to='/aml-kyc'>AML & KYC</LinkItem>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{
                        mb: 2,
                        display: 'flex',
                        columnGap: 1,
                        alignItems: 'center',
                        justifyContent: { xs: 'center', md: 'end' },
                    }}>
                        <Grid item>
                            <Card
                                sx={{
                                    // maxWidth: 460,
                                    boxShadow: 'none',
                                    backgroundColor: 'transparent',
                                }}
                            >
                                <CardMedia
                                    height="36"
                                    alt="about"
                                    component="img"
                                    image="/images/btc_logo.png"
                                />
                            </Card>
                        </Grid>

                        <Grid item>
                            <Card
                                sx={{
                                    // maxWidth: 460,
                                    boxShadow: 'none',
                                    backgroundColor: 'transparent',
                                }}
                            >
                                <CardMedia
                                    height="36"
                                    alt="about"
                                    component="img"
                                    image="/images/tether_logo.png"
                                />
                            </Card>
                        </Grid>

                        <Grid item>
                            <Card
                                sx={{
                                    // maxWidth: 460,
                                    boxShadow: 'none',
                                    backgroundColor: 'transparent',
                                }}
                            >
                                <CardMedia
                                    height="36"
                                    alt="about"
                                    component="img"
                                    image="/images/eth_logo.png"
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <Typography textAlign='center' color={theme.colors.alpha.black[100]}>
                {"Copyright © "}
                <LinkItem to='/'>ZionTrader</LinkItem>
                {" " + new Date().getFullYear()}
            </Typography>
        </Box>
    );
}


export default Footer;