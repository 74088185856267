import React, { useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  Icon,
  lighten,
  List,
  ListItem,
  ListItemIcon as _ListItemIcon,
  ListItemText,
  Popover,
  Switch,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useAuthState } from 'context/auth.context';
import { setItem } from 'helpers/utils';
import { useDataContext } from 'context/data.context';

import { LoadingButton } from 'components/Buttons/LoadingButton';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { userSettingItems } from 'router/route.items';

const ListItemIcon = styled(_ListItemIcon)(({ theme }) => ({
  minWidth: '36px'
}));

const UserBoxButton = styled(Button)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

const MenuUserBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.colors.alpha.black[5],
}));

const UserBoxText = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  paddingLeft: theme.spacing(1),
}));

const UserBoxLabel = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
}));

const UserBoxDescription = styled(Typography)(({ theme }) => ({
  color: lighten(theme.palette.secondary.main, 0.5),
}));

const HeaderUserbox: React.FC<{
  isLoading: boolean,
  onLogoutHandler: () => void,
}> = ({
  isLoading,
  onLogoutHandler,
}) => {
    const userProvider = useAuthState();
    const { authState: { user } } = userProvider;

    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const { state, dispatch: dispatchData } = useDataContext();
    const showBalanceHandleClick = () => {
      setItem('showBalance', !state.showBalance)
      dispatchData({
        type: 'SET_DATA',
        payload: {
          showBalance: !state.showBalance,
        }
      });
    }

    const handleOpen = (): void => {
      setOpen(true);
    };

    const handleClose = (): void => {
      setOpen(false);
    };

    return (
      <>
        <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
          <Avatar
            variant="rounded"
            alt={user?.name}
          // src={user.avatar}
          />
          <Hidden mdDown>
            <UserBoxText>
              <UserBoxLabel variant="body1">{user?.name}</UserBoxLabel>
              <UserBoxDescription variant="body2">
                {user?.role}
              </UserBoxDescription>
            </UserBoxText>
          </Hidden>
          <Hidden smDown>
            <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
          </Hidden>
        </UserBoxButton>

        <Popover
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuUserBox sx={{ minWidth: 210 }} display="flex">
            <Avatar variant="rounded" alt={user?.name} />
            <UserBoxText>
              <UserBoxLabel variant="body1">{user?.name}</UserBoxLabel>
              <UserBoxDescription variant="body2">
                {user?.role}
              </UserBoxDescription>
            </UserBoxText>
          </MenuUserBox>
          <Divider sx={{ mb: 0 }} />

          <List component="div">
            {userSettingItems.sort((a, b) => a.priority - b.priority)
              .filter(item => item?.title && item?.visible && item?.private)
              .map((route, index) => (
                <ListItem key={`userbox-${index}`} to={route.path} component={Link} >
                  <ListItemIcon><Icon>{route.icon}</Icon></ListItemIcon>
                  <ListItemText id={`userbox-label-${route.path}`} primary={route.title} />
                </ListItem>
              ))}

            <ListItem>
              <ListItemIcon>{state.showBalance ? (<VisibilityIcon sx={{ mr: 1 }} />) : (<VisibilityOffIcon sx={{ mr: 1 }} />)}</ListItemIcon>
              <ListItemText id="userbox-label-balance" primary={state.showBalance ? 'Balance Show' : 'Balance Hide'} />
              <Switch
                edge="end"
                onChange={showBalanceHandleClick}
                checked={state.showBalance}
                inputProps={{
                  'aria-labelledby': 'userbox-label-balance',
                }}
              />
            </ListItem>

          </List>
          <Divider />

          {/* <Box sx={{ m: 1 }}>
            <MyIconButton onClick={showBalanceHandleClick} sx={{ width: '100%' }}>
              {state.showBalance ? (<VisibilityIcon sx={{ mr: 1 }} />) : (<VisibilityOffIcon sx={{ mr: 1 }} />)}
              <Typography>Balance {state.showBalance ? 'Hide' : 'Show'}</Typography>
            </MyIconButton>
          </Box> */}

          <Box sx={{ m: 1 }}>
            <LoadingButton fullWidth variant='contained' loading={isLoading} onClick={onLogoutHandler}>
              <Icon sx={{ mr: 1 }} className='material-icons-two-tone'>logout</Icon>
              Log out
            </LoadingButton>
          </Box>
        </Popover>
      </>
    );
  }

export default HeaderUserbox;
