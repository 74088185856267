import zionApi from "api/zionApi";
import { GenericResponse } from "models/Generic";
import { DB_Network, DB_Networks } from "models/db/Network";

export const getNetwork = async ({ networkId }: { networkId: string }) => {
    const response = await zionApi.get<GenericResponse<DB_Network>>(`networks/${networkId}`);
    return response.data;
};

export const getNetworks = async () => {
    const response = await zionApi.get<DB_Networks>(`networks`);
    return response.data;
};