import React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { getFilteredAddresses, updateAddress } from "models/api/Address";
import { cols_Address, fields_Address } from "./definitions";
import { nameMapping_Address } from "models/db/Address";

export default function AddressesTab(): React.JSX.Element {
  return (
    <>
      <Helmet><title>All Addresses</title></Helmet>

      <DataTable
        title='Addresses'
        dataName='address'
        rowsPerPage={25}
        cols={cols_Address}
        fields={fields_Address}
        nameMapping={nameMapping_Address}
        settings={{ toolbar: { export: 'excel' } }}
        getFilteredRecords={getFilteredAddresses}
        updateRecord={async (row, column, value) => {
          const newRow = structuredClone(row);
          newRow[column.key] = value as never;
          return await updateAddress(row.id, newRow);
        }}
      />
    </>
  );
}