import { useEffect } from 'react';

import notification from 'helpers/notification';
import { useAuthState } from 'context/auth.context';

import { useMutation } from '@tanstack/react-query';

import { Box, Container, Grid, Icon, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import z from 'zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import FormInput from 'components/FormInput';
import { forgotPasswordFn, getMeFn } from 'api/authApi';
import { LoadingButton } from 'components/Buttons/LoadingButton';
import { LinkItem } from 'components/Buttons/LinkItem';
import { AboutCard } from './about.card';
import useLayoutContext from 'hooks/useLayoutContext';
import useUserContext from 'hooks/useUserContext';

const forgotPasswordSchema = z.object({
  email: z.string().min(1, 'Email address is required').email('Email Address is invalid')
});

export type ForgotPasswordInput = z.TypeOf<typeof forgotPasswordSchema>;

const ForgotPasswordPage = () => {
  const theme = useTheme();
  const { authState } = useUserContext()
  const { headerDimensions, footerDimensions } = useLayoutContext()

  const methods = useForm<ForgotPasswordInput>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  //  API Login Mutation
  const forgotPassword = useMutation(
    (userData: ForgotPasswordInput) => forgotPasswordFn(userData),
    {
      onSuccess: (data) => {
        // query.refetch();
        notification.success('You successfully logged in');
      },
      onError: (notification.error)
    }
  );

  const { reset, handleSubmit, formState: { isSubmitSuccessful }, } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      // reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<ForgotPasswordInput> = (values) => {
    // 👇 Executing the loginUser Mutation
    forgotPassword.mutate(values);
  };

  if (forgotPassword.isSuccess) {
    return (
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: `calc(100vh - ${headerDimensions.height + footerDimensions.height}px)`,
        }}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
          <Box>
            <Icon sx={{ fontSize: 48 }}>mail_outline</Icon>
          </Box>
          <Box>
            <Typography variant='h1'>
              Check Your Email
            </Typography>
          </Box>
          <Box>
            <Typography variant='subtitle2'>
              We have sent you a password recovery email.
            </Typography>
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container disableGutters maxWidth={authState.user ? 'md' : false}>
      <Grid container sx={{ display: 'flex' }}>

        {!authState.user && (
          <Grid item xs={12} md={5} my={3}>
            <FormProvider {...methods}>
              <Box
                component='form'
                onSubmit={handleSubmit(onSubmitHandler)}
                noValidate
                autoComplete='off'
                width='100%'
                sx={{
                  backgroundColor: '#fff',
                  p: { xs: '1rem', sm: '2rem' },
                  borderRadius: 2,
                  maxWidth: { xs: '100%', md: '28rem' }
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
                  <Box>
                    <Typography variant="h1" component="h1" sx={{ mb: 1 }}>Forgot Password</Typography>
                    <Typography variant="subtitle2">
                      Enter your email address and we’ll send you a link to reset your password.
                    </Typography>
                  </Box>
                </Box>

                <FormInput name='email' type='email' label='Email Address' placeholder='Enter your email address' />

                <LoadingButton
                  fullWidth
                  type='submit'
                  disableElevation
                  variant='contained'
                  loading={forgotPassword.isLoading}
                >
                  Send Reset Link
                </LoadingButton>

                <Typography sx={{ mt: '1rem', textAlign: 'center' }}>
                  <LinkItem to='/login'>
                    Back to Login
                  </LinkItem>
                </Typography>
              </Box>
            </FormProvider>
          </Grid>
        )}

        <Grid item xs={12} md={authState.user ? 12 : 7} my={3}>
          <AboutCard />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgotPasswordPage;
