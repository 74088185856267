import {
    styled,
    Card,
} from "@mui/material";

export const MyCard = styled(Card)(({ theme }) => ({
    borderRadius: 4,
    // border: '1px solid #dbdfea',
    boxShadow: 'none',
    backgroundColor: 'transparent',
}));