import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Avatar,
  alpha,
  Tooltip,
  CardActionArea,
  styled
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useDataContext } from 'context/data.context';
import { formatBalance, setItem } from 'helpers/utils';
import { MyIconButton } from 'components/Buttons/Index';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { DB_Currency } from 'models/db/Currency';
import { CryptoAvatar } from 'components/Avatar';
import { DB_Asset } from 'models/db/Asset';
import config from 'config';

const CoinSymbolTop = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  color: 'rgba(34, 51, 84, 0.7)',
  fontWeight: '400',
  fontSize: 'inherit',
  marginLeft: theme.spacing(0.4)
}));

const CoinSymbolBottom = styled(Typography)(({ theme }) => ({}));

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[10]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);

const CardAddAction = styled(Card)(
  ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100%;
        color: ${theme.colors.primary.main};
        transition: ${theme.transitions.create(['all'])};
        
        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[70]};
        }
`
);
interface IWalletsProps {
  wallets: DB_Currency[];
  assets: DB_Asset[];
}

const Wallets: React.FC<IWalletsProps> = ({ wallets = [], assets = [] }) => {
  const navigate = useNavigate();
  const { state, dispatch } = useDataContext();

  const showBalanceHandleClick = () => {
    setItem('showBalance', !state.showBalance)
    dispatch({
      type: 'SET_DATA',
      payload: {
        showBalance: !state.showBalance,
      }
    });
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pb: 3 }}
      >
        <Typography variant="h3">
          Wallets

          <MyIconButton onClick={showBalanceHandleClick}>
            {state.showBalance ? (<VisibilityIcon />) : (<VisibilityOffIcon />)}
          </MyIconButton>
        </Typography>
        <Button
          size="small"
          variant="outlined"
          onClick={() => navigate('/dashboard/addresses')}
          startIcon={<AddTwoToneIcon fontSize="small" />}
        >
          Add new wallet
        </Button>
      </Box>
      <Grid container spacing={3}>
        {assets?.length > 0 && assets.map((asset) =>
          <Grid xs={12} sm={6} md={3} item key={asset.id}>
            <Card sx={{ px: 1 }}>
              <CardContent>
                <CryptoAvatar src={`/icons/${asset.symbol.toLowerCase()}.svg`} />
                <Typography variant="h5" noWrap>
                  {asset.name}
                </Typography>
                <Typography variant="subtitle1" noWrap>
                  {asset.symbol.toUpperCase()}
                </Typography>
                <Box sx={{ pt: 3 }}>
                  <Typography variant="h3" gutterBottom noWrap>
                    {state.showBalance ? formatBalance(asset.totalBalance) : '****'}
                    <CoinSymbolTop variant="subtitle2">{asset.symbol.toUpperCase()}</CoinSymbolTop>
                  </Typography>
                  <CoinSymbolBottom variant="subtitle2" noWrap>
                    {state.showBalance ? formatBalance(asset.totalUsdtBalance) : '****'} USDT
                  </CoinSymbolBottom>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}

        {wallets?.length > 0 && wallets.slice(0, 8).map((walletItem) =>
          <Grid xs={12} sm={6} md={3} item key={walletItem.id}>
            <Card sx={{ px: 1 }}>
              <CardContent>
                <CryptoAvatar src={`/icons/${walletItem.symbol.toLowerCase()}.svg`} />
                <Typography variant="h5" noWrap>
                  {walletItem.name}
                </Typography>
                <Typography variant="subtitle1" noWrap>
                  {walletItem.symbol.toUpperCase()}
                </Typography>
                <Box sx={{ pt: 3 }}>
                  <Typography variant="h3" gutterBottom noWrap>
                    {state.showBalance ? formatBalance(walletItem.totalBalance) : '****'}
                    <CoinSymbolTop variant="subtitle2">{walletItem.symbol.toUpperCase()}</CoinSymbolTop>
                  </Typography>
                  <CoinSymbolBottom variant="subtitle2" noWrap>
                    {state.showBalance ? formatBalance(walletItem.totalUsdtBalance, 2) : '****'} USDT
                  </CoinSymbolBottom>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}

        {wallets?.length < 4 && (
          <Grid xs={12} sm={6} md={3} item>
            <Tooltip arrow title="Click to add a new wallet">
              <CardAddAction onClick={() => navigate('/dashboard/addresses')}>
                <CardActionArea sx={{ px: 1 }}>
                  <CardContent>
                    <AvatarAddWrapper>
                      <AddTwoToneIcon fontSize="large" />
                    </AvatarAddWrapper>
                  </CardContent>
                </CardActionArea>
              </CardAddAction>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Wallets;
