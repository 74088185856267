import React, { Dispatch, SetStateAction } from 'react';
import { useMutation } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  FormControl,
  Grid,
  Icon,
  TextField,
  Typography,
} from '@mui/material';

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { MyFormHelperText } from 'components/Forms/MyFormHelper';
import { DB_Commission } from 'models/db/Commission';
import zionApi from 'api/zionApi';
import { GenericResponse } from 'models/Generic';

interface IUpdateItemProp {
  item: DB_Commission;
  setRefetch: Dispatch<SetStateAction<number>>;
  setOpenCrudModal: (openCrudModal: boolean) => void;
}

const updateItemSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  value: z.number(),
});

export type TypeOfUpdate = z.TypeOf<typeof updateItemSchema>;

const EditForm: React.FC<IUpdateItemProp> = ({ item, setRefetch, setOpenCrudModal }) => {
  const queryUpdateItem = useMutation(
    async ({ id, inputData }: { id: string; inputData: TypeOfUpdate }) => {
      const response = await zionApi.patch<GenericResponse<DB_Commission>>(`admin/commissions/${id}`, inputData);
      return response.data;
    }, {
    onSuccess: () => {
      setRefetch(c => ++c)
      notification.success('Commission updated successfully');
      setOpenCrudModal(false);
      methods.reset({ ...item });
    }
  });

  const methods = useForm<TypeOfUpdate>({ resolver: zodResolver(updateItemSchema) });

  const { formState: { errors: formErrors, isSubmitted }, } = methods;

  if (isSubmitted && Object.keys(formErrors).length) {
    console.log({ formErrors, values: methods.getValues() });
    Object.values(formErrors).map((err) => notification.error(err?.message as string));
  }

  const onSubmitHandler: SubmitHandler<TypeOfUpdate> = (formValues) => {
    queryUpdateItem.mutate({ id: item.id, inputData: formValues })
  };

  React.useEffect(() => {
    if (item) {
      methods.reset({
        name: item.name,
        value: item.value,
      });
    }
  }, [item]);

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Edit Commission Id#{item.id}
        </Typography>

        <CloseButton loading={queryUpdateItem.isLoading}
          onClick={() => setOpenCrudModal(false)}>
          <Icon>close</Icon>
        </CloseButton>
      </Box>

      <FormProvider {...methods}>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField label='Name'
              {...methods.register('name')}
            />
            <MyFormHelperText error={!!formErrors?.name} message={formErrors?.name?.message} />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField label='Value'
              {...methods.register('value', { valueAsNumber: true, setValueAs: v => parseFloat(v) })}
              type="number"
              inputProps={{ min: 0 }}
            />
            <MyFormHelperText error={!!formErrors?.value} message={formErrors?.value?.message} />
          </FormControl>

          <Grid container justifyContent="center" >
            <LoadingButton
              variant='contained'
              type='submit'
              loading={queryUpdateItem.isLoading}
            >
              Save
            </LoadingButton>
          </Grid>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default EditForm;