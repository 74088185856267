import React, { FC } from 'react';
import { pickBy } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import notification from 'helpers/notification';

import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  TextField,
  Typography,
} from '@mui/material';

import {
  CloseButton,
  LoadingButton
} from "components/Buttons/LoadingButton";

import {
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { updateProfileFn } from 'api/authApi';

import { DB_User } from 'models/db/User';

interface IUpdateUserProp {
  user: DB_User;
  setOpenCrudModal: (openCrudModal: boolean) => void;
}

const updateUserSchema = z.object({
  password: z.string().min(1, "Current password is required"),
  newPassword: z.string().min(1, "New password is required"),
  newPasswordConfirm: z.string().min(1, "New password confirm is required"),
});

export type TUpdateUser = z.TypeOf<typeof updateUserSchema>;

const UserUpdatePass: FC<IUpdateUserProp> = ({ user, setOpenCrudModal }) => {
  const [userState, setUserState] = React.useReducer(
    (state: TUpdateUser, newState: Partial<TUpdateUser>) => ({ ...state, ...newState, }), {
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  console.log({ ...userState });

  const methods = useForm<TUpdateUser>({
    resolver: zodResolver(updateUserSchema),
  });

  const { formState: { errors: formErrors, isSubmitSuccessful }, } = methods;

  const { isLoading, mutate } = useMutation(({ id, formData }: { id: string; formData: FormData }) =>
    updateProfileFn({ id, formData }), {
    onSuccess: () => {
      notification.success('Password successfully updated');
      setOpenCrudModal(false);
    }
  });

  if (Object.keys(formErrors).length) {
    console.log({ formErrors });
  }

  const onSubmitHandler: SubmitHandler<TUpdateUser> = (formValues) => {
    if (Object.keys(formErrors).length) {
      Object.values(formErrors).map((item) => notification.error(item?.message as string));
      return;
    }

    const formData = new FormData();
    const filteredFormData = pickBy(formValues, (value) => value !== '' && value !== undefined);
    formData.append('data', JSON.stringify({
      security: filteredFormData
    }));
    mutate({ id: user?.id!, formData })
  };

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' sx={{ mb: 3 }}>
        <Typography variant='h4' component='h1'>
          Change Password
        </Typography>

        <CloseButton loading={isLoading}
          onClick={() => setOpenCrudModal(false)}>
          <Icon>close</Icon>
        </CloseButton>
      </Box>

      <FormProvider {...methods}>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler)}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField {...methods.register('password')}
              label='Current password'
              type='password'
              onChange={(e) => setUserState({ password: e.target.value })}
            />
            <FormHelperText error={!!formErrors?.password}>{formErrors?.password?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField {...methods.register('newPassword')}
              label='New password'
              type='password'
              onChange={(e) => setUserState({ newPassword: e.target.value })}
            />
            <FormHelperText error={!!formErrors?.newPassword}>{formErrors?.newPassword?.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField {...methods.register('newPasswordConfirm')}
              label='New password confirm'
              type='password'
              onChange={(e) => setUserState({ newPasswordConfirm: e.target.value })}
            />
            <FormHelperText error={!!formErrors?.newPasswordConfirm}>{formErrors?.newPasswordConfirm?.message}</FormHelperText>
          </FormControl>

          <Grid container justifyContent="center" >
            <LoadingButton
              variant='contained'
              type='submit'
              loading={isLoading}
              sx={{ color: "#fff" }}
            >
              Save
            </LoadingButton>
          </Grid>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default UserUpdatePass;