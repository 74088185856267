import React from "react";
import { CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import { DB_BankAccount, nameMapping_BankAccount } from "models/db/BankAccount";

export default function UserBankAccountsTab({ bankAccounts }: { bankAccounts: DB_BankAccount[] }): React.JSX.Element {
  // const { authState } = useUserContext()

  return (
    <>
      <Helmet><title>User Bank Accounts</title></Helmet>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <MyCard>
            <CardContent sx={{ p: 2 }}>
              {bankAccounts.length > 0 && (
                <>
                  <Grid container spacing={0} sx={{ mb: 2, xs: { mb: 1 } }} alignItems={'center'}>
                    <Grid item xs={12}>
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell key={"bank-name"} component={'th'}>{"Bank Name"}</TableCell>
                              <TableCell key={"bank-currency"} component={'th'}>{"Currency"}</TableCell>
                              <TableCell key={"bank-iban"} component={'th'}>{"IBAN"}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {bankAccounts.map((item, itemIndex) => (
                              <TableRow key={itemIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell>{item.bank.name}</TableCell>
                                <TableCell>{item.currency}</TableCell>
                                <TableCell>{item.iban}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </>
              )}

            </CardContent>
          </MyCard>
        </Grid>
      </Grid>
    </>
  );
}