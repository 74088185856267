import React from "react";
import { Box, Button, Container, Grid } from "@mui/material";

import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { cols_Sweeper, fields_Sweeper } from "./definitions";
import { FilterGroup } from "components/DataTable/filter/FilterGroup";

import zionApi from "api/zionApi";
import useUserContext from "hooks/useUserContext";
import PageTitle from "components/PageTitle";
import CrudModal from "components/Modals/crud.modal";
import AddForm from "./forms/AddForm";
import { EMemberRoleEnumType } from "models/db/User";
import { DB_Sweeper, DB_Sweepers, SweeperFieldName, nameMapping_Sweeper } from "models/db/Sweeper";
import { DTResponse } from "models/Generic";

export default function Sweepers(): React.JSX.Element {
  const { authState } = useUserContext()
  const [openCrudModal, setOpenCrudModal] = React.useState(false);
  const [refetchTable, setRefetchTable] = React.useState<number>(0);

  return (
    <>
      <Helmet><title>Sweep Rules</title></Helmet>

      {/* <PageTitle
        heading="Sweep Rules"
        // crud={{ add: authState.user?.permission?.role !== EMemberRoleEnumType.VIEWER }}
        // setOpenCrudModal={setOpenCrudModal}
        sx={{ my: 3 }}
      /> */}

      <CrudModal openCrudModal={openCrudModal} setOpenCrudModal={setOpenCrudModal} closeable>
        <AddForm setOpenCrudModal={setOpenCrudModal} setRefetch={setRefetchTable} />
      </CrudModal>

      {/* <Button onClick={() => setRefetchTable(refetchTable => ++refetchTable)}>Refetch</Button> */}

      <Container maxWidth={false}>
        <DataTable
          title='Sweepers'
          dataName='sweeper'
          rowsPerPage={25}
          cols={cols_Sweeper}
          fields={fields_Sweeper}
          nameMapping={nameMapping_Sweeper}
          actions={[{
            icon: 'add_circle_outline',
            title: 'Add Sweeper',
            event: setOpenCrudModal,
          }]}
          fetchCounter={[refetchTable, setRefetchTable]}
          getFilteredRecords={async (filterParams: FilterGroup<SweeperFieldName>) => {
            const response = await zionApi.get<DTResponse<DB_Sweeper>>(`sweepers`, {
              params: {
                filterParams
              }
            });
            return response.data;
          }}
        />
      </Container>
    </>
  );
}