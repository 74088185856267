import React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { getFilteredUserLogs } from "models/api/User";
import { cols_UserLog, fields_UserLog } from "./definitions";

import { DB_User } from "models/db/User";
import { DB_UserLog, UserLogFieldName, nameMapping_UserLog } from "models/db/UserLog";
import { FilterGroup } from "components/DataTable/filter/FilterGroup";

export default function UserLogsTab({ targetUser }: { targetUser: DB_User }): React.JSX.Element {

  if (!targetUser.id) {
    return <>Require User</>;
  }

  return (
    <>
      <Helmet><title>All User Logs</title></Helmet>

      <DataTable
        title='User Logs'
        dataName='log'
        rowsPerPage={25}
        cols={cols_UserLog}
        fields={fields_UserLog}
        nameMapping={nameMapping_UserLog}
        settings={{ toolbar: { export: 'excel' } }}
        getFilteredRecords={async (params: FilterGroup<UserLogFieldName>) => await getFilteredUserLogs(targetUser.id, params)}
      />
    </>
  );
}