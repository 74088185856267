import React from 'react';
import { Grid } from '@mui/material';

import { Helmet } from 'react-helmet-async';
import { DB_Config } from 'models/db/Config';
import { getConfig } from 'models/api/Config';
import useUserContext from 'hooks/useUserContext';
import { GenericResponse } from 'models/Generic';

import { useFetch } from 'hooks/useFetch';
import notification from 'helpers/notification';
import EditForm from './forms/EditForm';

interface IUpdateUserProp { }

const PurchasesTab: React.FC<IUpdateUserProp> = ({ }) => {
  const { authState } = useUserContext()
  const [refetchTable, setRefetchTable] = React.useState<number>(0)

  const { data, error, loading, refetch, action, counter } = useFetch<GenericResponse<DB_Config>>({
    callback: async () => await getConfig('purchases')
  })

  React.useEffect(() => {
    console.log({ counter, action, loading, data, error, });
    if (error) {
      notification.error(error);
      return;
    }

    if (counter === 0) {
      refetch()
    }

  }, [action])

  React.useEffect(() => {
    refetch()
  }, [refetchTable])

  return (
    <>
      <Helmet><title>Wallet Purchase Settings</title></Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {data?.data && (
            <EditForm key={1} row={data?.data} setRefetch={setRefetchTable} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PurchasesTab;