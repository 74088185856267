import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar, Button, Grid, Icon, IconButton, Typography } from '@mui/material';
import {
  KeyboardReturnTwoTone as ReturnIcon
} from '@mui/icons-material';

import { useTheme } from '@mui/material/styles';
import { DB_User } from 'models/db/User';

const PageHeader: React.FC<{ targetUser: DB_User }> = ({ targetUser }) => {

  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        display="flex"
        alignItems="center"
      >
        <Grid sx={{ flexGrow: 2 }}>
          <Grid container alignItems="center">
            <Grid item>
              <Avatar
                alt={targetUser?.name}
                variant="rounded"
                sx={{ mr: 2, width: theme.spacing(8), height: theme.spacing(8) }}
              // src={targetUser.image}
              />
            </Grid>
            <Grid item>
              <Typography variant="h3" component="h3" gutterBottom>
                {targetUser?.name}
              </Typography>
              <Typography variant="subtitle2">
                settings panel.
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={3}
            display="flex"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                onClick={() => navigate('/dashboard/profile?tab=users')}
                variant="contained"
                startIcon={<ReturnIcon fontSize="small" />}>
                return
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default PageHeader;