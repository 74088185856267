import React, { useContext, useEffect, useState } from 'react';
import zionApi from 'api/zionApi';

import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import SelectCurrency from 'components/CurrencyConverter/SelectCurrency';
import SwitchCurrency from 'components/CurrencyConverter/SwitchCurrency';
import { CurrencyContext } from 'context/CurrencyContext';
import { GenericResponse } from 'models/Generic';
import CrudModal from 'components/Modals/crud.modal';
import ConvertTxForm from '../Forms/ConvertTxForm';
import { TX_TYPES } from 'models/db/Transaction';

import { CryptoAvatar as _CryptoAvatar } from 'components/Avatar';
import { styled } from "@mui/system";
const CryptoAvatar = styled(_CryptoAvatar)(({ theme }) => ({
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  height: theme.spacing(3.2),
  width: theme.spacing(3.2),
  img: {
    padding: theme.spacing(0),
    height: theme.spacing(3),
    width: theme.spacing(3),
  }
}));

const CurrencyConverter = (): React.JSX.Element => {
  const [openConvertModal, setOpenConvertModal] = useState(false);

  const {
    firstAmount,
    setFirstAmount,
    fromCurrency,
    setFromCurrency,
    toCurrency,
    setToCurrency,
  } = useContext(CurrencyContext);

  const codeFromCurrency = fromCurrency.toLowerCase();
  const codeToCurrency = toCurrency.toLowerCase();

  const [usdtPrice, setUsdtPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    if (firstAmount) {
      (async () => {
        const response = await zionApi.get<GenericResponse<{ price: number; usdtTryPrice: number; }>>('convert/currency', {
          params: {
            from: codeFromCurrency,
            to: codeToCurrency,
          }
        })

        const data = response.data.data;
        setUsdtPrice(data.price)
        setQuantity(data.price * Number(firstAmount))
      })();
    }
  }, [firstAmount, fromCurrency, toCurrency])

  useEffect(() => {
    if (firstAmount !== quantity / usdtPrice) {
      setFirstAmount(quantity / usdtPrice)
    }
  }, [quantity])

  // console.log({
  //   firstAmount,
  //   usdtPrice,
  // });

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Typography variant='h3'>Crypto Converter</Typography>
          {/* <Typography>{usdtPrice && (`Market Price ≈ ${usdtPrice} ${toCurrency}`)}</Typography> */}
        </Grid>

        <Grid item xs={12}>
          <Card variant='outlined'>
            <CardContent sx={{ py: 4 }}>
              <Grid container spacing={2}>

                <Grid item xs={12} md={4.5}>
                  <TextField
                    value={firstAmount}
                    onChange={e => setFirstAmount(Number(e.target.value))}
                    label={"Amount"}
                    fullWidth
                    InputProps={{
                      type: "number",
                      endAdornment: (
                        <>
                          <CryptoAvatar src={`/icons/${fromCurrency.toLowerCase()}.svg`} sx={{ ml: 2, mr: 1, }} />
                          <SelectCurrency value={fromCurrency} setValue={setFromCurrency} label="From" hiddens={[toCurrency]} />
                        </>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={1.5} textAlign={'center'}>
                  <SwitchCurrency quantity={quantity} />
                </Grid>

                <Grid item xs={12} md={4.5}>
                  <TextField
                    value={quantity}
                    onChange={e => setQuantity(Number(e.target.value))}
                    label={"Quantity"}
                    fullWidth
                    InputProps={{
                      type: "number",
                      endAdornment: (
                        <>
                          <CryptoAvatar src={`/icons/${toCurrency.toLowerCase()}.svg`} sx={{ ml: 2, mr: 1, }} />
                          <SelectCurrency value={toCurrency} setValue={setToCurrency} label="To" hiddens={[fromCurrency]} />
                        </>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={1.5} alignSelf={'center'} textAlign={'center'}>
                  {quantity && (
                    <Button variant="outlined" onClick={() => setOpenConvertModal(true)}>
                      {fromCurrency === 'TRY' ? 'BUY' : 'SELL'}
                    </Button>
                  )}
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <CrudModal openCrudModal={openConvertModal} setOpenCrudModal={setOpenConvertModal}>
        <ConvertTxForm
          setOpenCrudModal={setOpenConvertModal}
          data={{
            price: usdtPrice,
            amount: firstAmount,
            fromCurrency,
            toCurrency,
            txType: fromCurrency === 'TRY' ? TX_TYPES.BUYING_CRYPTO : TX_TYPES.SELLING_CRYPTO
          }}
        />
      </CrudModal>
    </>
  )
}

export default CurrencyConverter;
