import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import SuspenseLoader from 'components/SuspenseLoader';

import PublicLayout from 'components/Layout/Public/Layout';
import DashboardLayout from 'components/Layout/DashboardLayout';
import RequireUser from 'components/RequireUser';

import BaseLayout from 'components/Layout/BaseLayout';
import DashboardCrypto from 'pages/Dashboards/Crypto/Index';
import Status404 from 'pages/Status/Status404';
import Wallets from 'pages/Wallets/Index';
import Addresses from 'pages/Addrs/Index';
import Sweepers from 'pages/Sweeper/Index';
import Transactions from 'pages/Transaction/Index';
import ForgotPasswordPage from 'pages/Auth/password_forgot.page';
import ApiDoc from 'pages/ApiDoc/Index';
import Admin from 'pages/Admin/Index';
import UserDetail from 'pages/Admin/users/tabs/Index';
import { AboutPage } from 'pages/Lp/about.page';
import { EnterprisePage } from 'pages/Lp/enterprise.page';
import { FeesPage } from 'pages/Lp/fees.page';
import { ProductFeaturesPage } from 'pages/Lp/product_features.page';
import { TermOfUsePage } from 'pages/Lp/term_of_use.page';
import { PrivacyPolicyPage } from 'pages/Lp/privacy_policy.page';
import { AML_KYCPage } from 'pages/Lp/aml_kyc.page';
import ContactPage from 'pages/Lp/contact.page';
import UserProfile from 'pages/UserProfile/Index';
import MemberDetail from 'pages/UserProfile/UsersTab/detail/Index';
import Settings from 'pages/Settings/Index';
import Validate2faPage from 'pages/Auth/validate2fa.page';
import PreDeposit from 'pages/Frame/PreDeposit';

const Loadable =
  (Component: React.ComponentType<any>) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const LoginPage = Loadable(lazy(() => import('../pages/Auth/login.page')));
const RegisterPage = Loadable(lazy(() => import('../pages/Auth/register.page')));
const UnauthorizePage = Loadable(lazy(() => import('../pages/Status/Status401/Index')));
const EmailVerificationPage = Loadable(lazy(() => import('../pages/Auth/verifyemail.page')));
const ResetPasswordPage = Loadable(lazy(() => import('../pages/Auth/password_reset.page')));

export type IRouteObject = RouteObject & {
  children?: IRouteObject[];
  meta?: {
    title?: string;
    [propName: string]: any;
  };
};


export const authRoutes = {
  path: '*',
  element: <PublicLayout />,
  children: [
    {
      index: true,
      element: <LoginPage />,
    },
    {
      path: 'login',
      children: [
        {
          path: "",
          element: <LoginPage />,
        },
        {
          path: "validate",
          element: <Validate2faPage />,
        },
      ],
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'verifyemail',
      element: <EmailVerificationPage />,
      children: [
        {
          path: ':verificationCode',
          element: <EmailVerificationPage />,
        },
      ],
    },
    {
      path: 'forgotpassword',
      element: <ForgotPasswordPage />,
    },
    {
      path: "resetpassword",
      element: <ResetPasswordPage />,
      children: [
        {
          path: ':resetCode',
          element: <ResetPasswordPage />,
        },
      ],
    },
  ],
};

export const publicRoutes = {
  path: '*',
  element: <PublicLayout />,
  children: [
    {
      path: 'contact',
      element: <ContactPage />,
    },
    {
      path: 'about',
      element: <AboutPage />,
    },
    {
      path: 'enterprise',
      element: <EnterprisePage />
    },
    {
      path: 'fees',
      element: <FeesPage />
    },
    {
      path: 'product',
      element: <ProductFeaturesPage />,
    },
    {
      path: 'term-of-use',
      element: <TermOfUsePage />,
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicyPage />,
    },
    {
      path: 'aml-kyc',
      element: <AML_KYCPage />,
    },
  ],
};

export const privateRoutes = {
  path: '*',
  element: <DashboardLayout />,
  children: [
    {
      element: <RequireUser allowedRoles={['admin', 'user', 'member']} />,
      children: [
        {
          path: "dashboard",
          element: <DashboardCrypto />,
        },
        {
          path: 'dashboard/profile',
          element: <UserProfile />,
        },
        {
          path: "dashboard/wallets",
          element: <Wallets />,
        },
        {
          path: "dashboard/addresses",
          element: <Addresses />,
        },
        {
          path: "dashboard/sweepers",
          element: <Sweepers />,
        },
        {
          path: "dashboard/transactions",
          element: <Transactions />,
        },
      ],
    },
    {
      element: <RequireUser allowedRoles={['admin', 'user']} />,
      children: [
        {
          path: "dashboard/apidocs",
          element: <ApiDoc />,
        }
      ],
    },
    {
      element: <RequireUser allowedRoles={['admin']} />,
      children: [
        {
          path: "dashboard/admin",
          element: <Admin />,
        },
        {
          path: 'dashboard/admin/users/:userId',
          element: <UserDetail />,
        },
        {
          path: "dashboard/settings",
          element: <Settings />,
        },
      ],
    },
    {
      element: <RequireUser allowedRoles={['admin', 'user']} />,
      children: [
        {
          path: 'dashboard/members/:userId',
          element: <MemberDetail />,
        },
      ],
    },
    {
      path: 'unauthorized',
      element: <UnauthorizePage />,
    },
  ],
};

export const baseRoutes = {
  path: '*',
  element: <BaseLayout />,
  children: [
    {
      path: 'deposit',
      element: <PreDeposit />
    },
    {
      path: '*',
      element: <Status404 />
    }
  ],
};

const routes: IRouteObject[] = [authRoutes, publicRoutes, privateRoutes, baseRoutes];

export default routes;
