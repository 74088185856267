import { DB_Todos, type DB_Todo, TodoFieldName } from 'models/db/Todo';
import {
  type FilterGroup,
  getSingleRecordOrThrow,
} from 'components/DataTable/filter/FilterGroup';
import { type Todo } from 'models/frontend/Todo';
import zionApi from 'api/zionApi';
import { DTResponse, GenericResponse } from 'models/Generic';

type InvokeArgs = Record<string, unknown>;
declare function invoke<T>(cmd: string, args?: InvokeArgs): Promise<T>;

export const getTodos = async (): Promise<DB_Todos> => {
  const response = await zionApi.get<DB_Todos>(`todos`);
  return response.data;
};

export const getFilteredTodos = async (
  filterParams: FilterGroup<TodoFieldName>
): Promise<DTResponse<DB_Todo>> => {
  console.log(JSON.stringify(filterParams));

  const response = await zionApi.get<DTResponse<DB_Todo>>(`admin/todos`, {
    params: { filterParams }
  });

  response.data.lastParams = filterParams;
  return response.data;
};

export const getTodo = async (name: string): Promise<DB_Todo> => {
  const params: FilterGroup<TodoFieldName> = {
    filters: [[['title', { Equal: name }]]],
    orderBy: [],
  };
  const res = await getFilteredTodos(params);

  return getSingleRecordOrThrow(
    res.data,
    `Unable to find any todos with the name: ${name}`
  );
};

export const insertTodo = async (todo: Todo): Promise<void> => {
  await insertDbTodo(todo.generateRecord());
};

export const insertDbTodo = async (record: DB_Todo): Promise<void> => {
  await invoke('insert_todo', { record });
};

export const insertTodosFromFile = async (path: string): Promise<void> => {
  await invoke('insert_todos_from_file', { path });
};

export const deleteFilteredTodos = async (
  filterParams: FilterGroup<TodoFieldName>
) => {
  const response = await zionApi.delete<Promise<GenericResponse<DB_Todo>>>(`todos`, {
    params: { filterParams }
  });

  return response.data
};

export const deleteTodo = async (record: DB_Todo): Promise<void> => {
  const params: FilterGroup<TodoFieldName> = {
    filters: [[['title', { Equal: record.title }]]],
    orderBy: [],
  };

  await deleteFilteredTodos(params);
};
