import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
    Box,
    List,
    Menu,
    Avatar,
    AppBar,
    Toolbar,
    useTheme,
    IconButton,
    Typography,
    MenuItem,
    ListItemButton,
    Icon,
} from '@mui/material';

import {
    Menu as MenuIcon,
} from '@mui/icons-material';

import { useAuthState } from 'context/auth.context';
import HeaderUserbox from '../Header/Userbox';
import { pablicPageItems } from 'router/route.items';
import { LoadingButton, LoadingIconButton } from 'components/Buttons/LoadingButton';
import { MyIconButton } from 'components/Buttons/Index';

const MyAppBar = (props: { isLoading: boolean, onLogoutHandler: () => void }): React.JSX.Element => {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const userProvider = useAuthState();

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static" sx={{
            boxShadow: 'none',
            backgroundColor: 'transparent',
        }}>
            <Toolbar disableGutters sx={{ display: 'flex', alignItems: "center" }}>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <Typography
                        to="/"
                        noWrap
                        variant="h6"
                        component={Link}
                        sx={{ mr: 1 }}
                    >
                        <Avatar variant="square" sx={{ width: 130 }} src="/logo/logo-dark.png" />
                    </Typography>
                </Box>

                {/* mobile */}
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <Typography
                        to="/"
                        noWrap
                        variant="h6"
                        component={Link}
                        sx={{ mr: 1 }}
                    >
                        <Avatar variant="square" sx={{ width: 130 }} src="/logo/logo-dark.png" />
                    </Typography>

                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        sx={{ color: theme.palette.primary.main }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {userProvider.authState.user && (
                            <MenuItem
                                to="dashboard"
                                component={Link}
                                key={`mobile-nav-dashboard`}
                                onClick={handleCloseNavMenu}>
                                Dashboard
                            </MenuItem>
                        )}

                        {pablicPageItems.sort((a, b) => a.priority - b.priority)
                            .filter(item => item?.title && item?.visible && !item?.private)
                            .map((route, index) => (
                                <MenuItem
                                    to={route.path}
                                    component={Link}
                                    key={`mobile-nav-${index}`}
                                    onClick={handleCloseNavMenu}>
                                    {route.title}
                                </MenuItem>
                            ))}
                    </Menu>
                </Box>

                <Box sx={{ my: 2, display: { xs: 'none', md: 'flex' } }}>
                    <List
                        component="nav"
                        onClick={handleCloseNavMenu}
                        sx={{
                            display: 'flex',
                            columnGap: 1,
                            fontWeight: 'bold',
                            color: theme.colors.alpha.black[100],
                        }}>
                        {userProvider.authState.user && (
                            <ListItemButton
                                to="dashboard"
                                component={Link}
                                key={`desktop-nav-dashboard`}>
                                Dashboard
                            </ListItemButton>
                        )}

                        {pablicPageItems.sort((a, b) => a.priority - b.priority)
                            .filter(item => item?.title && item?.visible && !item?.private)
                            .map((route, index) => (
                                <ListItemButton
                                    to={route.path}
                                    component={Link}
                                    key={`desktop-nav-${index}`}
                                    selected={route.path === location.pathname}>
                                    {route.title}
                                </ListItemButton>
                            ))}
                    </List>
                </Box>

                {/* <Typography sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }} /> */}

                <Box sx={{ flexGrow: 0, ml: 3 }} display="flex" alignItems="center">
                    {userProvider.authState.user ? (
                        <HeaderUserbox onLogoutHandler={props.onLogoutHandler} isLoading={props.isLoading} />
                    ) : (
                        <>
                            <LoadingButton onClick={() => navigate('/login')} size='small'>
                                Log in
                            </LoadingButton>
                            <LoadingButton onClick={() => navigate('/register')} size='small' sx={{ ml: 1 }}>
                                Sign up
                            </LoadingButton>
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
}
export default MyAppBar;
