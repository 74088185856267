import React, { useState } from 'react';

import { Typography, Avatar, Grid, Button, backdropClasses, Box, Icon, Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAuthState } from 'context/auth.context';
import CrudModal from 'components/Modals/crud.modal';

import DepositForm from '../Forms/DepositForm';
import AddTransactionForm from 'pages/Transaction/forms/AddForm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function PageHeader() {
  const userProvider = useAuthState();
  const { authState: { user }, dispatch } = userProvider;

  const theme = useTheme();

  const [openCrudModal, setOpenCrudModal] = useState(false);
  const [openDepositModal, setOpenDepositModal] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Grid
      container
      display="flex"
      alignItems="center"
    >
      <Grid sx={{ flexGrow: 2 }}>
        <Grid container alignItems="center">
          <Grid item>
            <Avatar
              alt={user?.name}
              variant="rounded"
              sx={{ mr: 2, width: theme.spacing(8), height: theme.spacing(8) }}
            // src={user.avatar}
            />
          </Grid>
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              Welcome, {user?.name}!
            </Typography>
            {/* <Typography variant="subtitle2">
          Today is a good day to start trading crypto assets!
        </Typography> */}
          </Grid>
        </Grid>
      </Grid>

      <Grid sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={3}
          display="flex"
          justifyContent="flex-end"
        >
          <Grid item>
            <Button variant="contained" onClick={() => setOpenDepositModal(true)}>
              Deposit
            </Button>

            <CrudModal openCrudModal={openDepositModal} setOpenCrudModal={setOpenDepositModal}>
              <DepositForm setOpenDepositModal={setOpenDepositModal} />
            </CrudModal>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => setOpenCrudModal(true)}>
              Send
            </Button>

            <CrudModal openCrudModal={openCrudModal} setOpenCrudModal={setOpenCrudModal}>
              <AddTransactionForm setOpenCrudModal={setOpenCrudModal} />
            </CrudModal>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
