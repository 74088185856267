import { useEffect, useReducer, useRef, useState } from 'react';

type Cache<T> = { [qkey: string]: T }

type EventName = 'setup' | 'ready' | 'fetching' | 'fetched' | 'error';

// discriminated union type
type ActionEvent<T> =
    { type: 'setup'; }
    | { type: 'ready'; }
    | { type: 'fetching'; }
    | { type: 'fetched'; payload: T }
    | { type: 'error'; payload: Error }

interface SetState<T> {
    data?: T;
    error?: Error;
    loading: boolean;
    action: EventName;
    counter: number;
    refetch: (data?: any) => void;
}

interface UseFetchProps<T> {
    qkey?: string,
    options?: RequestInit,
    callback: (data?: any) => Promise<T>,
}

export const useFetch = <T>({ callback, qkey = (Math.random() + 1).toString(36).substring(7) }: UseFetchProps<T>): SetState<T> => {
    const cache = useRef<Cache<T>>({})

    // Used to prevent state update if the component is unmounted
    const cancelRequest = useRef<boolean>(false)

    const [fetchData, setFetchData] = useState<any>(null);
    const [fetchCount, setFetchCount] = useState(0);
    const [fetchEvent, setFetchEvent] = useState<EventName>('setup');

    const refech = (data?: any) => {
        setFetchData(data)
        setFetchCount(count => ++count)
    }

    const initialState: SetState<T> = {
        data: undefined,
        error: undefined,
        action: 'setup',
        loading: false,
        counter: fetchCount,
        refetch: refech,
    }

    // Keep state logic separated
    const fetchReducer = (state: SetState<T>, action: ActionEvent<T>): SetState<T> => {
        // console.log('ACT:', action.type);
        initialState.action = action.type
        initialState.loading = ['fetching'].includes(action.type)

        initialState.counter = fetchCount

        setFetchEvent(action.type)

        switch (action.type) {
            case 'setup':
                return { ...initialState }
            case 'ready':
                return { ...initialState }
            case 'fetching':
                return { ...initialState }
            case 'fetched':
                return { ...initialState, data: action.payload }
            case 'error':
                return { ...initialState, error: action.payload }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(fetchReducer, initialState)

    // useEffect(() => {
    //     console.log({ fetchEvent, fetchCount });
    // }, [fetchEvent])

    useEffect(() => {
        if (fetchCount === 0) {
            // console.log('Fetch is blocked for refetch.');
            dispatch({ type: 'ready' })
            return;
        }

        cancelRequest.current = false

        const fetchRun = async () => {
            dispatch({ type: 'fetching' })

            // If a cache exists for this qkey, return it
            // if (cache.current[qkey]) {
            //     dispatch({ type: 'fetched', payload: cache.current[qkey] })
            //     return
            // }

            try {
                const response = await callback(fetchData)
                cache.current[qkey] = response;
                if (cancelRequest.current) return
                dispatch({ type: 'fetched', payload: response })
            } catch (error) {
                if (cancelRequest.current) return
                dispatch({ type: 'error', payload: error as Error })
            }
        }

        void fetchRun()

        // Use the cleanup function for avoiding a possibly...
        // ...state update after the component was unmounted
        return () => {
            cancelRequest.current = true
        }
    }, [fetchCount])

    return state
}