import React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MyCard } from "components/Card/Index";

import DataTable from 'components/DataTable/DataTable';
import { cols_User, fields_User } from "./definitions";
import zionApi from "api/zionApi";
import { DB_User, DB_Users, UserFieldName, nameMapping_User } from "models/db/User";
import { FilterGroup } from "components/DataTable/filter/FilterGroup";
import CrudModal from "components/Modals/crud.modal";
import MemberAddForm from "./forms/MemberAddForm";
import { DTResponse } from "models/Generic";

export default function UsersTab(): React.JSX.Element {
  // const { authState } = useUserContext()
  const [openCrudModal, setOpenCrudModal] = React.useState(false);
  const [refetchTable, setRefetchTable] = React.useState<number>(0);

  return (
    <>
      <Helmet><title>User Settings</title></Helmet>

      <CrudModal openCrudModal={openCrudModal} setOpenCrudModal={setOpenCrudModal} closeable>
        <MemberAddForm setOpenCrudModal={setOpenCrudModal} setRefetch={setRefetchTable} />
      </CrudModal>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <MyCard>
            <Grid>
              <DataTable
                title='Users'
                dataName='user'
                cols={cols_User}
                fields={fields_User}
                rowsPerPage={25}
                nameMapping={nameMapping_User}
                fetchCounter={[refetchTable, setRefetchTable]}
                actions={[{
                  icon: 'add_circle_outline',
                  title: 'Add User',
                  event: setOpenCrudModal,
                }]}
                getFilteredRecords={async (filterParams: FilterGroup<UserFieldName>) => {
                  const response = await zionApi.get<DTResponse<DB_User>>(`users`, {
                    params: {
                      filterParams
                    }
                  });
                  return response.data;
                }}
              />
            </Grid>
          </MyCard>
        </Grid>
      </Grid>
    </>
  );
}