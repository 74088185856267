import React, { FC, useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import QRCode from "qrcode";
import useStore from "store";
import zionApi from "api/zionApi";
import notification from "helpers/notification";
import { DB_User } from "models/db/User";
import { Box, Button, DialogContentText, FormControl, Grid, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import { MyFormHelperText } from "./Forms/MyFormHelper";
import { LoadingButton } from "./Buttons/LoadingButton";

type TwoFactorAuthProps = {
    otpAuthURL: string;
    base32: string;
    userId: string;
    closeModal: (status?: boolean) => void;
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
};

const twoFactorAuthSchema = z.object({
    token: z.string().min(1, "Authentication code is required"),
});

type TwoFactorAuthInput = z.TypeOf<typeof twoFactorAuthSchema>;

const TwoFactorAuth: FC<TwoFactorAuthProps> = ({
    otpAuthURL,
    base32,
    userId,
    closeModal,
    step,
    setStep,
}) => {
    const store = useStore();
    const [qrcodeUrl, setqrCodeUrl] = useState("");

    const methods = useForm<TwoFactorAuthInput>({ resolver: zodResolver(twoFactorAuthSchema) });

    const verifyOtp = async (token: string) => {
        try {
            store.setRequestLoading(true);
            const {
                data: { user },
            } = await zionApi.post<{ otpVerified: string; user: DB_User }>(
                "/auth/otp/verify",
                {
                    verify: {
                        token,
                        userId,
                    }
                }
            );
            store.setRequestLoading(false);
            store.setAuthUser(user);
            closeModal(true);
            notification.success("Two-Factor Auth Enabled Successfully");
        } catch (error: any) {
            store.setRequestLoading(false);
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.response.data.detail ||
                error.message ||
                error.toString();
            notification.error(resMessage);
        }
    };

    const onSubmitHandler: SubmitHandler<TwoFactorAuthInput> = (values) => {
        verifyOtp(values.token);
    };

    useEffect(() => {
        QRCode.toDataURL(otpAuthURL).then(setqrCodeUrl);
    }, []);

    useEffect(() => {
        methods.setFocus("token");
    }, [methods.setFocus]);

    useEffect(() => {
        console.log({ step });
    }, [step]);

    return (
        <>
            <Box>
                <Typography variant='h5' component='h6'>
                    Configuring Authenticator For 2FA
                </Typography>

                <List sx={{ listStyle: "decimal", pl: 2 }}>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText>
                            Install authenticator app Google (IOS - Android) or Microsoft (IOS - Android).
                        </ListItemText>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText>
                            In the authenticator app, select "+" icon.
                        </ListItemText>
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                        <ListItemText>
                            Select "Scan a barcode (or QR code)" and use the phone's camera to scan this barcode.
                        </ListItemText>
                    </ListItem>
                </List>

                <Box sx={{ mb: 1 }}>
                    <Typography variant='h5' component='h6'>Scan QR Code</Typography>
                    <Box>
                        <img
                            src={qrcodeUrl}
                            alt="qrcode url"
                        />
                    </Box>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <Typography variant='h5' component='h6'>Or Enter Code Into Your App</Typography>
                    <Typography>SecretKey: {base32}</Typography>
                </Box>

                <Box sx={{ mb: 1 }}>
                    <FormProvider {...methods}>
                        <Box
                            component='form'
                            noValidate
                            autoComplete='off'
                            onSubmit={methods.handleSubmit(onSubmitHandler)}
                        >
                            <Box sx={{ mb: 2 }} style={{ ...(step > 0 ? { display: 'block' } : { display: 'none' }) }}>
                                <Typography variant='h5' component='h6'>Verify Code</Typography>
                                <Typography>
                                    Enter the 6-digit code you see in the app:
                                </Typography>

                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <TextField
                                        {...methods.register('token')}
                                        label='Authentication Code'
                                        fullWidth
                                    // disabled={!(step > 0)}
                                    />

                                    <MyFormHelperText error={!!methods.formState.errors.token} message={methods.formState.errors.token?.message} />
                                </FormControl>
                            </Box>

                            <Grid container justifyContent="center" sx={{ mt: 4 }}>
                                {/* <Button variant='contained' onClick={e => closeModal(false)}>Cancel</Button> */}
                                {step > 0 ? (
                                    <LoadingButton
                                        variant='contained'
                                        type='submit'
                                        loading={store.requestLoading}
                                    >
                                        Verify & Activate
                                    </LoadingButton>
                                ) : (
                                    <Button variant='contained' onClick={e => setStep((prev: number) => ++prev)}>Next</Button>
                                )}
                            </Grid>
                        </Box>
                    </FormProvider>
                </Box>
            </Box>
        </>
    );
};

export default TwoFactorAuth;